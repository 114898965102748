import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './PromoCodeBlock.css';
import { SS_ARK_PROMO_CODE } from '../../../constants/PromoCodes';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { Input } from '../../../FigmaStyleguide/Input/Input';

type PromoCodeBlockProps = {
  setCouponCodes: (v: string[]) => void;
  setCheckoutPricing: any; // recurly func
  recurlyPricingError: any; // recurly obj
  price: any; // recurly obj
  couponCodes: string[];
};


export const PromoCodeBlock: FC<PromoCodeBlockProps> = ({
  setCouponCodes,
  setCheckoutPricing,
  recurlyPricingError,
  price,
  couponCodes
}) => {
  const { t } = useTranslation();
  const [couponValue, setCouponValue] = useState<string>(couponCodes.join(''));
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValidCode, setIsValidCode] = useState<boolean>(true);
  const [isCodeApplied, setIsCodeApplied] = useState<boolean>(false);
  const onApply = () => {
    setCheckoutPricing((previous) => ({ ...previous, coupon: couponValue }));
  };

  useEffect(() => {
    if (price?.now?.discount !== '0.00' && price?.now?.discount !== undefined) {
      setIsCodeApplied(true);
      setCouponCodes([couponValue]);
      window.sessionStorage.setItem(SS_ARK_PROMO_CODE, JSON.stringify([couponValue]));
    }
  }, [price?.now?.discount]);

  useEffect(() => {
    // otherwise it is impossible to distinguish between errors coming from recurly-js :(
    if (recurlyPricingError?.message.toLocaleLowerCase().includes('coupon')) {
      setIsValidCode(false);
      setIsCodeApplied(false);
      setCouponCodes([]);
      setCheckoutPricing((previous) => ({ ...previous, coupon: '' }));
      window.sessionStorage.removeItem(SS_ARK_PROMO_CODE);
    }
  }, [recurlyPricingError]);

  const handleFocused = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    if (couponValue === '') {
      setIsFocused(false);
    }
  };
  const handleRemoveCode = () => {
    setIsCodeApplied(false);
    setCouponValue('');
    setCouponCodes([]);
    setCheckoutPricing((previous) => ({ ...previous, coupon: '' }));
    !isValidCode && setIsValidCode(true);
    window.sessionStorage.removeItem(SS_ARK_PROMO_CODE);
  };
  const handleSetValues = (evt: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = evt;

    setCouponValue(value.trim());
    !isValidCode && setIsValidCode(true);
  };
  const renderControlInputs = () => {
    if (isCodeApplied) {
      return (
        <span
          role="button"
          tabIndex={0}
          className={styles.removeLink}
          onClick={handleRemoveCode}
          onKeyDown={handleRemoveCode}
        >
          Remove promo code
        </span>
      );
    }

    if (isFocused) {
      return (
        <Button
          disabled={!isValidCode}
          secondaryStyle
          onClick={() => {
            isValidCode && onApply();
          }}
        >
          Apply
        </Button>
      );
    }

    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        {isCodeApplied ? t('PROMO_CODE.APPLIED_LABEL') : t('PROMO_CODE.DEFAULT_LABEL')}
      </div>
      <div className={styles.inputRow}>
        <Input
          value={couponValue}
          placeholder={t('PROMO_CODE.ENTER_PROMO_CODE')}
          onBlur={() => handleBlur()}
          onFocus={() => handleFocused()}
          onChange={handleSetValues}
          clickOnCloseIcon={handleRemoveCode}
          name="promoCode"
          errorMessage={t('PROMO_CODE.ERROR_MESSAGE')}
          isValid={isValidCode}
          disabled={isCodeApplied}
          className={classNames(!isValidCode && styles.invalidInput)}
          maxLength={30}
        />
      </div>
      <div className={classNames(styles.handlersRow, !isValidCode && styles.invalidRow)}>
        {renderControlInputs()}
      </div>
    </div>
  );
};
