import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const AmericanExpress = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="128"
            height="47"
            viewBox="0 0 128 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.7861 47V26.6567H46.0111L48.2883 29.6694L50.6408 26.6567H127.682V45.597C127.682 45.597 125.668 46.9797 123.337 47H80.6779L78.1104 43.7932V47H69.6971V41.5258C69.6971 41.5258 68.5478 42.29 66.0631 42.29H63.1994V47H50.4608L48.1868 43.9227L45.878 47H24.7861Z"
                fill="white"
            />
            <path
                d="M0 11.3241L4.78632 0H13.0638L15.78 6.34329V0H26.0697L27.6867 4.58473L29.2543 0H75.444V2.3049C75.444 2.3049 77.8722 0 81.8627 0L96.8496 0.0531517L99.519 6.31342V0H108.13L110.5 3.59602V0H119.19V20.3433H110.5L108.229 16.7356V20.3433H95.5773L94.305 17.1364H90.9039L89.6524 20.3433H81.0727C77.639 20.3433 75.444 18.0854 75.444 18.0854V20.3433H62.5079L59.9405 17.1364V20.3433H11.8375L10.5661 17.1364H7.17586L5.91346 20.3433H0V11.3241Z"
                fill="white"
            />
            <path
                d="M6.48011 2.50789L0.0244141 17.7403H4.22741L5.41856 14.6901H12.3433L13.5283 17.7403H17.8239L11.3743 2.50789H6.48011ZM8.8686 6.05293L10.9794 11.383H6.75167L8.8686 6.05293Z"
                fill="#016FD0"
            />
            <path
                d="M18.2686 17.7377V2.50532L24.2412 2.52783L27.7152 12.3487L31.1059 2.50532H37.0308V17.7377H33.2783V6.51385L29.3007 17.7377H26.0098L22.021 6.51385V17.7377H18.2686Z"
                fill="#016FD0"
            />
            <path
                d="M39.5986 17.7377V2.50532H51.8435V5.91258H43.3906V8.51811H51.646V11.7249H43.3906V14.4307H51.8435V17.7377H39.5986Z"
                fill="#016FD0"
            />
            <path
                d="M54.0156 2.50789V17.7403H57.7681V12.3288H59.3481L63.8473 17.7403H68.433L63.4955 12.1284C65.5219 11.9548 67.6121 10.1899 67.6121 7.44967C67.6121 4.24423 65.133 2.50789 62.3661 2.50789H54.0156ZM57.7681 5.91513H62.0575C63.0865 5.91513 63.835 6.73196 63.835 7.51855C63.835 8.53052 62.8651 9.12197 62.113 9.12197H57.7681V5.91513Z"
                fill="#016FD0"
            />
            <path d="M72.975 17.7377H69.1436V2.50532H72.975V17.7377Z" fill="#016FD0" />
            <path
                d="M82.0598 17.7377H81.2328C77.2313 17.7377 74.8018 14.5384 74.8018 10.1842C74.8018 5.72231 77.2041 2.50532 82.2573 2.50532H86.4047V6.11299H82.1057C80.0543 6.11299 78.6036 7.73759 78.6036 10.2217C78.6036 13.1717 80.2625 14.4107 82.6523 14.4107H83.6398L82.0598 17.7377Z"
                fill="#016FD0"
            />
            <path
                d="M90.2262 2.50789L83.7705 17.7403H87.9735L89.1647 14.6901H96.0894L97.2744 17.7403H101.57L95.1204 2.50789H90.2262ZM92.6147 6.05293L94.7254 11.383H90.4978L92.6147 6.05293Z"
                fill="#016FD0"
            />
            <path
                d="M102.007 17.7377V2.50532H106.778L112.869 12.0757V2.50532H116.622V17.7377H112.005L105.759 7.91684V17.7377H102.007Z"
                fill="#016FD0"
            />
            <path
                d="M27.3535 44.3945V29.162H39.5984V32.5693H31.1455V35.1748H39.4009V38.3817H31.1455V41.0874H39.5984V44.3945H27.3535Z"
                fill="#016FD0"
            />
            <path
                d="M87.3535 44.3945V29.162H99.5984V32.5693H91.1455V35.1748H99.3614V38.3817H91.1455V41.0874H99.5984V44.3945H87.3535Z"
                fill="#016FD0"
            />
            <path
                d="M40.0736 44.3945L46.0355 36.8722L39.9316 29.162H44.6592L48.2944 33.9284L51.942 29.162H56.4844L50.4607 36.7782L56.4337 44.3945H51.7068L48.1772 39.7032L44.7333 44.3945H40.0736Z"
                fill="#016FD0"
            />
            <path
                d="M56.8799 29.1646V44.397H60.7311V39.5868H64.681C68.0232 39.5868 70.5566 37.7874 70.5566 34.288C70.5566 31.3892 68.5697 29.1646 65.1686 29.1646H56.8799ZM60.7311 32.6094H64.8909C65.9706 32.6094 66.7424 33.281 66.7424 34.3632C66.7424 35.3798 65.9745 36.1169 64.8785 36.1169H60.7311V32.6094Z"
                fill="#016FD0"
            />
            <path
                d="M72.1855 29.162V44.3945H75.938V38.9829H77.518L82.0172 44.3945H86.6029L81.6654 38.7825C83.6918 38.609 85.782 36.844 85.782 34.1038C85.782 30.8984 83.3029 29.162 80.536 29.162H72.1855ZM75.938 32.5693H80.2274C81.2564 32.5693 82.0049 33.3861 82.0049 34.1727C82.0049 35.1847 81.0351 35.7761 80.2829 35.7761H75.938V32.5693Z"
                fill="#016FD0"
            />
            <path
                d="M101.336 44.3945V41.0874H108.846C109.957 41.0874 110.438 40.478 110.438 39.8097C110.438 39.1694 109.958 38.522 108.846 38.522H105.452C102.502 38.522 100.859 36.6981 100.859 33.9598C100.859 31.5174 102.364 29.162 106.747 29.162H114.055L112.475 32.5893H106.155C104.947 32.5893 104.575 33.2327 104.575 33.847C104.575 34.4785 105.034 35.1748 105.957 35.1748H109.512C112.801 35.1748 114.227 37.0678 114.227 39.5466C114.227 42.2117 112.637 44.3945 109.333 44.3945H101.336Z"
                fill="#016FD0"
            />
            <path
                d="M115.108 44.3945V41.0874H122.618C123.729 41.0874 124.21 40.478 124.21 39.8097C124.21 39.1694 123.731 38.522 122.618 38.522H119.225C116.275 38.522 114.632 36.6981 114.632 33.9598C114.632 31.5174 116.136 29.162 120.52 29.162H127.827L126.247 32.5893H119.927C118.719 32.5893 118.347 33.2327 118.347 33.847C118.347 34.4785 118.807 35.1748 119.73 35.1748H123.285C126.573 35.1748 128 37.0678 128 39.5466C128 42.2117 126.41 44.3945 123.106 44.3945H115.108Z"
                fill="#016FD0"
            />
        </svg>
    );
});
