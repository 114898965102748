import React, { useState, memo } from 'react';

import classNames from 'classnames';

import { TabButtonS } from '../../atoms/Buttons/TabButtonS/TabButtonS';
import styles from './TabsComponent.css';

type TabsComponentProps = {
    content: any;
    defaultTab: string;
    className?: string;
};

export const TabsComponent = memo(({ content, defaultTab, className }: TabsComponentProps) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    if (content.length > 0) {
        return (
            <>
                <div className={styles.tabHeader}>
                    {content.map((item) => (
                        <TabButtonS
                            active={activeTab === item.key}
                            key={item.key}
                            onClick={() => {
                                setActiveTab(item.key);
                                item.onClick?.();
                            }}
                            className={className}
                        >
                            {item.tabComponent}
                        </TabButtonS>
                    ))}
                </div>
                <>
                    {content.map((item) => (
                        <div
                            className={classNames(
                                styles.contentItem,
                                activeTab === item.key && styles.activeContentItem
                            )}
                            key={item.key}
                        >
                            {item.content}
                        </div>
                    ))}
                </>
            </>
        );
    }
    return null;
});
