import React, { useEffect, useState } from 'react';

import countryRegionData from 'country-region-data';
import { useTranslation } from 'react-i18next';

import styles from './AddressDetails.css';
import { ErrorMessage } from '../../../atoms/ErrorMessage/ErrorMessage';
import { Input } from '../../../atoms/HookFormInput/Input';
import { PadLockIcon } from '../../../FigmaStyleguide/Icons/PadLockIcon';
import { SearchSelect } from '../../../FigmaStyleguide/Select/SearchSelect';
import { InputWrapper } from '../InputWrapper/InputWrapper';

type AddressDetailsProps = {
  register?: any; // need to check ts support for react-hook-form
  errors: any; // need to check ts support for react-hook-form
  setValue: (name: string, value: string) => void;
  setValidation: (isValid: boolean, name: string, message?: string) => void;
  values: any; // need to check ts support for react-hook-form (just provide types with values)
};

export const AddressDetails = ({ errors, register, setValue, values, setValidation }: AddressDetailsProps) => {
  const [regionsData, setRegionsData] = useState<{ name: string, shortCode: string }[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (values.country?.length > 0) {
      const countryData = countryRegionData.filter((i) => i.countryShortCode === values.country);

      if (countryData) {
        setRegionsData(countryData[0]?.regions);
      }
    }
  }, [values.country]);

  return (
    <>
      {/* id added for smooth scrolling */}
      <div className={styles.mainStepHeading} id="billingFieldsRef">
        <h2>
          <strong>3. </strong>
          {t('PAYMENT_PAGE.ADDRESS_DETAILS_TITLE')}
        </h2>

        <PadLockIcon className={styles.padlockIcon}/>
      </div>

      <InputWrapper
        ariaInvalid={errors.address1 ? 'true' : 'false'}
        register={register}
        name="address1"
        type="text"
        label="address"
        errors={errors}
        isValid={!errors?.address1}
        errorMessage={errors?.address1?.message}
        description={'Include your Apt or Suite number'}
        descriptionId="addressDescription"
        placeholder="Your street address"
        dataRecurly="address1"
        value={values['address1']}
      />

      <InputWrapper
        ariaInvalid={errors.city ? 'true' : 'false'}
        register={register}
        name="city"
        type="text"
        label="city"
        errors={errors}
        isValid={!errors?.city}
        errorMessage={errors?.city?.message}
        placeholder="Enter your city's name"
        dataRecurly="city"
        value={values['city']}
      />
      <InputWrapper
        ariaInvalid={errors.country ? 'true' : 'false'}
        register={register}
        name="country"
        type="text"
        label="country"
        errors={errors}
        isValid={!errors?.country}
        errorMessage={errors?.country?.message}
        placeholder="Select your country"
        dataRecurly="country"
        select
        setValue={setValue}
        value={values['country']}
        setValidation={setValidation}
      />

      <div className={styles.bottomInputsWrapper}>
        <div className={styles.inputBlock}>
          <label className={styles.label} htmlFor="postal_code" style={{ textAlign: 'end' }}>
            ZIP/POSTAL CODE
          </label>
          <div style={{ position: 'relative' }}>
            <Input
              ariaInvalid={errors.postal_code ? 'true' : 'false'}
              register={register}
              name="postal_code"
              type="text"
              placeholder={'CODE'}
              isValid={!errors.postal_code}
              value={values['postal_code']}
              dataRecurly="postal_code"
            />
            {errors.postal_code && (
              <ErrorMessage id="postalCodeError" message={errors?.postal_code?.message}/>
            )}
          </div>
        </div>

        {values['country'] && (
          <div className={styles.inputBlock}>
            <label className={styles.label} htmlFor="state">
              STATE
            </label>
            <div style={{ position: 'relative' }}>
              <SearchSelect
                isValid={!errors.state}
                value={values.state}
                onChange={(value) => {
                  setValue('state', value);
                  setValidation(true, 'state');
                }}
                placeholder="State"
                items={regionsData?.map(({ name }) => name)}
                fullWidth={false}
                className={styles.stateSelect}
                name="state"
              />
              {errors['state'] && <ErrorMessage id={'stateError'} message={errors?.state?.message}/>}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
