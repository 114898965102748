import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { Trans, WithTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import { NavLink } from '../../../atoms/Link/Link';
import { SS_ARK_PROMO_CODE } from '../../../constants/PromoCodes';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_ACTIONS_TYPE,
  RECAPTCHA_MODES
} from '../../../molecules/Captcha/hooks/useCaptcha';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import UserService, { AuthType } from '../../../services/UserService';
import { gemsShopLocationSelector } from '../../../store/ducks/gems/gemsSelectors';
import { setSnackbarData } from '../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { setStepIndex } from '../../../store/ducks/subscription/common';
import { billingInfoInitValues, setBillingInfoValues } from '../../../store/ducks/subscription/paymentForm';
import styles from '../SubscriptionTemplate.css';

type SubscriptionStep3Props = {
  paymentType: string,
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions,
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
  isResend: boolean;
  setIsResend: (v: boolean) => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void
  t: WithTranslation['t'];
}

const INITIAL_RESEND_TIMER = 10; // 10 seconds

export const SubscriptionStep3 = ({
  paymentType,
  gemsAnalyticsProps,
  getCaptchaToken,
  showChallengeRecaptcha,
  clearCaptchaData,
  isResend,
  setIsResend,
  isLoading,
  setIsLoading,
  t
}: SubscriptionStep3Props) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout>(); // Add a ref to store the interval id
  const userData = useSelector(({ user }) => user);
  const isUserExists = useSelector(({ isUserExists }) => isUserExists);
  const authValues = useSelector(({ authValues }) => authValues);
  const gemsShopLocation = useSelector(gemsShopLocationSelector);
  const captchaToken = useSelector(({ recaptcha }) => recaptcha.recaptchaToken);
  const [timeLeft, setTimeLeft] = useState<number>(INITIAL_RESEND_TIMER);
  const isAdvantageSubscription = paymentType === PaymentType.subscription;
  const isGemsPurchase = paymentType === PaymentType.gems;
  const gameId = UrlService.getGameSlugFromSearchParam(window.location.search);
  const LOGGED_IN_USER_TOP_CONTENT = () => {
    return (
      <>
        {isAdvantageSubscription && <p>{t('PAYMENT_PAGE.ENJOY_WITHOUT_ADS')}</p>}
        <p>
          {t('PAYMENT_PAGE.RECEIPT_TEXT')}&nbsp;
          <strong>{userData ? userData?.email : authValues.email}</strong>.
        </p>
      </>
    );
  };
  const LOGGED_IN_USER_BOTTOM_CONTENT = () => {
    return (
      <>
        {isAdvantageSubscription && (
          <>
            <p>{t('PAYMENT_PAGE.RENEW_TEXT')}</p>
            <p>
              {t('PAYMENT_PAGE.CANCEL_OR_MANAGE_SUBSCRIPTION')}&nbsp;
              <NavLink to="/profile">{t('PAYMENT_PAGE.YOUR_PROFILE')}</NavLink>.
            </p>
          </>
        )}
        {isGemsPurchase && <p>{t('PAYMENT_PAGE.GEMS_READY')}</p>}
      </>
    );
  };
  const NON_LOGGED_IN_USER_TOP_CONTENT = () => {
    return (
      <p>
        <Trans i18nKey="PAYMENT_PAGE.CHECK_YOUR_EMAIL" values={{ email: authValues.email }} />
      </p>
    );
  };
  const NON_LOGGED_IN_USER_BOTTOM_CONTENT = () => {
    return (
      <p>
        {t('PAYMENT_PAGE.DONT_SEE_ACTIVATION_EMAIL')}&nbsp;
        <NavLink to="mailto:support@arkadium.com">support@arkadium.com</NavLink> {t('PAYMENT_PAGE.FOR_ASSISTANCE')}
      </p>
    );
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  useEffect(() => {
    if (captchaToken && !isUserExists && isResend) {
      void fetchResendConfirmationEmail();
    }
  }, [captchaToken]);

  const fetchResendConfirmationEmail = async () => {
    try {
      await UserService.resendConfirmationEmail({
        email: authValues.email,
        authType: AuthType.EmailPassword,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined

      });
      handleResendConfirmationEmailSuccess();
    } catch (err) {
      handleResendConfirmationError(err);
    }
  };
  const handleResendConfirmationEmailSuccess = () => {
    clearCaptchaData();
    setIsResend(false);
    setIsLoading(false);
    setTimeLeft(INITIAL_RESEND_TIMER);
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: t('PAYMENT_PAGE.EMAIL_HAS_BEEN_SENT'),
        type: 'success'
      })
    );
    void Analytics.trackEvent(Analytics.profile.resendConfirmation());
  };
  const handleResendConfirmationError = (err: number) => {
    setIsLoading(false);
    dispatch(setShowRecaptcha(err === 1023));
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(err),
        type: 'error'
      })
    );
  };
  const handleSubmit = () => {
    if (!isUserExists) {
      setIsResend(true);
      setIsLoading(true);
      getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
    }

    // self tab logic for subscription, kept to less regress testing
    if (isUserExists && paymentType === PaymentType.subscription) {
      window.sessionStorage.removeItem(SS_ARK_PROMO_CODE);
      batch(() => {
        dispatch(setStepIndex(0));
        dispatch(setBillingInfoValues(billingInfoInitValues));
        dispatch(push('/'));
      });
    }

    // new tab logic for gems
    if (isUserExists && paymentType === PaymentType.gems) {
      window.close();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const asyncEffect = async () => {
    if (isGemsPurchase) {
      void Analytics.trackEvent(
        Analytics.gems.gemPurchaseSuccessImpression(
          gemsAnalyticsProps.priceInGem,
          gemsAnalyticsProps.gemsPackId,
          gemsAnalyticsProps.gemsInPack,
          gemsShopLocation,
          gameId
        ),
        false
      );
    }
  };

  useEffect(() => {
    void asyncEffect();
  }, [isGemsPurchase]);

  return (
    <div className={styles.step3wrapper}>
      <div className={classNames(styles.mainStepHeading, styles.textCenter)}>
        {isAdvantageSubscription && t('PAYMENT_PAGE.SUBSCRIPTION_ACTIVATED')}
        {isGemsPurchase && t('PAYMENT_PAGE.GEMS_ADDED')}
      </div>
      {!isUserExists && (
        <div className={classNames(styles.noAdsBlock, styles.textCenter)}>
          {NON_LOGGED_IN_USER_TOP_CONTENT()}
        </div>
      )}
      <div className={classnames(styles.animationBlock, { [styles.__gems]: isGemsPurchase })} />
      {isUserExists && (
        <div className={classNames(styles.noAdsBlock, styles.textCenter)}>
          {LOGGED_IN_USER_TOP_CONTENT()}
        </div>
      )}
      <div className={classNames(styles.profileBlock, styles.textCenter)}>
        {isUserExists ? LOGGED_IN_USER_BOTTOM_CONTENT() : NON_LOGGED_IN_USER_BOTTOM_CONTENT()}
      </div>
      <div className={styles.mainBtnWrapper}>
        {isUserExists ? (
          <Button type="button" onClick={handleSubmit} className={styles.continueBtn}>
            {t('PAYMENT_PAGE.CONTINUE_PLAYING')}
          </Button>
        ) : (
          <Button
            className={styles.continueBtn}
            loading={isLoading}
            type="button"
            onClick={handleSubmit}
            disabled={Boolean(timeLeft)}
          >
            {timeLeft ? String(timeLeft) : t('PAYMENT_PAGE.RESEND')}
          </Button>
        )}
      </div>
    </div>
  );
};
