import React, { FormEvent, useEffect } from 'react';

import EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import styles from './AuthForm.css';
import { authTypeValidation, checkboxValidation, emailValidation, passwordValidation } from './AuthForm.validation';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import { ArrowRightIcon } from '../../atoms/Icons/Styleguide/ArrowRightIcon';
import { CheckboxTypes } from '../../atoms/Icons/Styleguide/CheckboxIcon';
import { Input } from '../../FigmaStyleguide/Input/Input';
import { checkValidation } from '../../services/ValidationService';
import { setAuthErrors, setAuthValues } from '../../store/ducks/authData/authData';
import { setIsValidForm, setSubscriptionStep2Content } from '../../store/ducks/subscription/common';
import { SubscriptionStep2Content } from '../../store/ducks/subscription/common';

type AuthFormProps = {
  step: number;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const AuthForm = React.forwardRef<HTMLFormElement, AuthFormProps>(
  ({ step, handleSubmit }: AuthFormProps, ref) => {
    const dispatch = useDispatch();
    const authValues = useSelector(({ authValues }) => authValues);
    const authFormErrors = useSelector(({ authErrors }) => authErrors);
    const { t } = useTranslation();
    const onBlur = (name: string, value: string) => {
      const { [name]: removedError, ...rest } = authFormErrors as any;
      const error = validate[name](value);

      dispatch(
        setAuthErrors({
          ...rest,
          ...(error && { [name]: error })
        })
      );
    };
    const validate = {
      email: emailValidation,
      password: passwordValidation,
      checkbox: checkboxValidation,
      authType: authTypeValidation
    };
    const checkIsValidForm = () => {
      switch (step) {
        case 1:
          dispatch(setIsValidForm(EmailValidator.validate(authValues.email)));
          break;
        case 2:
          dispatch(setIsValidForm(checkValidation(authValues, validate, authFormErrors)));
          break;

        default:
          break;
      }
    };

    useEffect(() => {
      checkIsValidForm();
    }, [authValues, authFormErrors, step]);

    // RESEARCH:error: Cannot ready property 'store' of undefined in SubscriptionStep2/AuthForm/PaymentService/UserService/AdFreeService
    // when it is fixed, then take handleSubmit from subscriptionTemplate
    // const handleSubmit
    return (
      <>
        {step === 2 && (
          <div
            role="presentation"
            onClick={() =>
              dispatch(setSubscriptionStep2Content({ type: SubscriptionStep2Content.AuthForm, step: 1 }))
            }
            className={styles.backButton}
          >
            <ArrowRightIcon className={styles.arrowIcon}/>
            <p>{t('PAYMENT_PAGE.BACK')}</p>
          </div>
        )}
        <form onSubmit={handleSubmit} ref={ref}>
          <input type="submit" hidden/>
          <div className={styles.inputWrapper}>
            <p className={styles.inputName}>{t('PAYMENT_PAGE.EMAIL_ADDRESS')}</p>
            <Input
              maxWidth={420}
              fullWidth
              value={authValues.email}
              onChange={(e) => dispatch(setAuthValues({ ...authValues, email: e.target.value }))}
              clickOnCloseIcon={() => dispatch(setAuthValues({ ...authValues, email: '' }))}
              placeholder={t('PAYMENT_PAGE.EMAIL_ADDRESS_PLACEHOLDER')}
              isValid={!authFormErrors.email}
              errorMessage={authFormErrors.email}
              onBlur={onBlur}
              name="email"
            />
          </div>
          {step === 2 && (
            <>
              <div className={styles.inputWrapper}>
                <p className={styles.inputName}>{t('PAYMENT_PAGE.PASSWORD')}</p>
                <Input
                  maxWidth={420}
                  fullWidth
                  value={authValues.password}
                  onChange={(e) =>
                    dispatch(setAuthValues({ ...authValues, password: e.target.value }))
                  }
                  clickOnCloseIcon={() => dispatch(setAuthValues({ ...authValues, password: '' }))}
                  placeholder={t('PAYMENT_PAGE.PASSWORD_PLACEHOLDER')}
                  isValid={!authFormErrors.password}
                  errorMessage={authFormErrors.password}
                  onBlur={onBlur}
                  name="password"
                  type="password"
                />
              </div>
              <div className={styles.inputWrapper} style={{ justifyContent: 'center' }}>
                <Checkbox
                  className={styles.checkboxContainer}
                  iconClassName={styles.checkbox}
                  labelClassName={styles.checkboxLabel}
                  onClick={() => {
                    dispatch(setAuthValues({ ...authValues, checkbox: !authValues.checkbox }));
                  }}
                  state={authValues.checkbox ? CheckboxTypes.ACTIVE : CheckboxTypes.INACTIVE}
                  caption={t('PAYMENT_PAGE.CHECKBOX_CAPTION')}
                  dataElementDescription="radio-button"
                  btnType="button"
                />
              </div>
            </>
          )}
        </form>
      </>
    );
  }
);
