import React from 'react';

import classNames from 'classnames';

import styles from './HeroBlockModular.css';
import { UrlService } from '../../../services/UrlService';

type HeroBlockModularProps = {
    isPostPage?: boolean;
    promoPost?: any; // add type when json file (archive_data) is ready
    showSubtitle?: boolean;
    onClick?: () => void;
    setUrl?: string;
};

const HeroBlockModular = React.memo(({ isPostPage, promoPost, showSubtitle = true, setUrl }: HeroBlockModularProps) => {
    const mainImageUrl = UrlService.createStrapiMediaUrl(promoPost?.modularBlogImage?.url);
    if (!promoPost) {
        return <div>No promo post found</div>;
    }
    return (
        <a href={setUrl} className={styles.noDecoration}>
            <div className={classNames(styles.wrapper)}>
                <picture className={styles.heroImg}>
                    {promoPost?.images &&
                        promoPost.images.map((img) => (
                            <source
                                key={`${img.name}-${img.id}`}
                                media={img.name.includes('desktop') ? '(min-width: 600px)' : '(max-width: 599px)'}
                                srcSet={img.url}
                                type={img.mime}
                            />
                        ))}
                    <img src={mainImageUrl} alt={promoPost.mainImgAlt} />
                </picture>
                <div className={styles.gradient} />

                <p className={styles.date}>{promoPost.date}</p>
                {isPostPage ? (
                    // if post page
                    <h1 className={styles.title}>{promoPost.modularBlogTitle}</h1>
                ) : (
                    // if archive page
                    <p className={styles.title}>{promoPost.modularBlogTitle}</p>
                )}
                {promoPost?.summary && showSubtitle && <p className={styles.subTitle}>{promoPost.summary}</p>}
            </div>
        </a>
    );
});

export default HeroBlockModular;
