import React, { useState } from 'react';

import { TabButton } from '../../atoms/Buttons/TabButton/TabButton';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Analytics } from '../../services/Analytics/Analytics';
import { GemsAnalyticsShopLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { purchasesTabType } from '../../templates/Profile/Tabs/PurchasesAndSubscription/PurchasesAndSubscription';

type PurchasesAndSubscriptionTabsType = {
    setActivePage: (value) => void;
};

export const PurchasesAndSubscriptionTabs = ({ setActivePage }: PurchasesAndSubscriptionTabsType) => {
    const [activeTab, setActiveTab] = useState(purchasesTabType.SUBSCRIPTION);
    const content = [
        { key: purchasesTabType.HISTORY, tabName: 'Purchases' },
        { key: purchasesTabType.SUBSCRIPTION, tabName: 'Subscription' },
    ];
    const handleClick = async (key) => {
        if (key === purchasesTabType.HISTORY) {
            await Analytics.trackEvent(Analytics.gems.manageGems(GemsAnalyticsShopLocations.PROFILE));
        }
        setActiveTab(key);
        setActivePage(key);
    };

    return (
        <div>
            {content.map(({ key, tabName }) => (
                <TabButton
                    tabKey={key}
                    active={activeTab === key}
                    onClick={() => handleClick(key)}
                    className="purchase"
                >
                    <I18nText keyName={tabName} />
                </TabButton>
            ))}
        </div>
    );
};
