import React, { Suspense, useEffect, lazy, useState, LazyExoticComponent } from 'react';

import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useScript, ScriptState } from '../../hooks/useScript';
import { MiscUtils } from '../../utils/MiscUtils';
import { AppLoader } from '../atoms/AppLoader/AppLoader';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { RECURLY_URL } from '../constants/Recurly';
import { SubscriptionPlans, SUBSCRIPTION_PLAN_NAME } from '../constants/SubscriptionPlan';
import { QUERY_STRING_CONSTS } from '../models/Enums';
import { PlatformType } from '../models/Subscription/SubscriptionData';
import { AppInsightService } from '../services/AppInsight';
import PaymentService from '../services/PaymentService';
import { UrlService } from '../services/UrlService';
import { setFullScreenLoading } from '../store/ducks/layout';
import { setPageType } from '../store/ducks/pages';
import { setActiveSubscriptionPlan, setActiveUserSubscriptions } from '../store/ducks/subscription/common';

export enum PurchasePagesTemplates {
  SUBSCRIPTION = 'subscribe',
  GEMS_SHOP = 'shop',
}

export const PurchasePage = ({ template }) => {
  let PurchasePageTemplate: LazyExoticComponent<() => JSX.Element>;

  switch (template) {
    case PurchasePagesTemplates.SUBSCRIPTION:
      PurchasePageTemplate = lazy(() =>
        import('../templates/PurchaseSubscription/PurchaseSubscriptionTemplate')
      );
      break;
    case PurchasePagesTemplates.GEMS_SHOP:
      PurchasePageTemplate = lazy(() =>
        import('../templates/PurchaseGemsShop/PurchaseGemsShopTemplate')
      );
      break;
    default:
      break;
  }

  const [selectedPlanByQSP, setSelectedPlanByQSP] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const userSubscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  const userProcessed = useSelector(({ userProcessed }) => userProcessed);
  const loading = useSelector(({ fullScreenLoading }) => fullScreenLoading);
  const history = useHistory();
  const redirectUrl = '/';

  useEffect(() => {
    dispatch(setPageType(PageTypes.Subscription));

    const selectedPlan = UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.SUBSCRIPTION_PLAN);

    selectedPlan === (SUBSCRIPTION_PLAN_NAME.MONTHLY || SUBSCRIPTION_PLAN_NAME.ANNUAL) &&
    setSelectedPlanByQSP(
      selectedPlan === SUBSCRIPTION_PLAN_NAME.MONTHLY ? SubscriptionPlans.MONTHLY : SubscriptionPlans.ANNUAL
    );
  }, []);

  useEffect(() => {
    if (selectedPlanByQSP) {
      dispatch(setActiveSubscriptionPlan(selectedPlanByQSP));
    }
  }, [selectedPlanByQSP]);

  // TODO: if possible move it to ssr
  const redirectHandler = () => {
    // if user logged in and already has a subscription -> redirect to the homepage
    if (template === PurchasePagesTemplates.SUBSCRIPTION && userProcessed && user !== null) {
      // checking if the store has subscriptions and checking for an active subscription - ssr case
      // if its INTERNAL(7-day bonus) so user can buy subscription anyway
      userSubscriptions?.length > 0 && userSubscriptions[0].platform.id !== PlatformType.INTERNAL
        ? history.push(redirectUrl) // redirect if user already has a subscription
        : dispatch(setFullScreenLoading(false));
      !userSubscriptions &&
      PaymentService.getSubscriptions()
        .then((res) => {
          res.length > 0 && res[0].platform.id !== PlatformType.INTERNAL
            ? history.push(redirectUrl) // redirect if user already has a subscription
            : dispatch(setFullScreenLoading(false));
          dispatch(setActiveUserSubscriptions(res));
        })
        .catch((err) => {
          console.error(' PaymentService.getSubscriptions()', err);
          AppInsightService.trackAppError(err, { data: 'SubscriptionPage' });
        });
    }
  };

  useEffect(() => {
    redirectHandler();
  }, [userProcessed]);

  const scriptState = useScript(RECURLY_URL);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>

      <ErrorBoundary>
        {(loading || scriptState === ScriptState.loading) && <AppLoader fullPage/>}
        {!MiscUtils.isServer && scriptState === ScriptState.ready && (
          <Suspense fallback={<AppLoader fullPage/>}>
            <RecurlyProvider
              required={['cvv', 'address1', 'city', 'postal_code', 'state', 'country']}
              publicKey={environment.RECURLY_PUBLIC_KEY}
            >
              <Elements>
                <PurchasePageTemplate/>
              </Elements>
            </RecurlyProvider>
          </Suspense>
        )}
      </ErrorBoundary>
    </>
  );
};
