import React from 'react';

import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { StringUtils } from '../../utils/StringUtils';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../models/PagesData';
import { DeviceType } from '../services/DeviceDetector';
import { PageService } from '../services/PageService';
import { setPageType } from '../store/ducks/pages';
import GameSubmissionDisclaimerTemplate from '../templates/GameSubmissionDisclaimer/GameSubmissionDisclaimerTemplate';

type GameSubmissionDisclaimerProps = {
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    dispatch?: any;
    location: any;
    url: string;
    route: any;
    currentLang: string;
    pageType: PageTypes;
    deviceType: DeviceType;
};

class GameSubmissionDisclaimersPageBase extends React.PureComponent<GameSubmissionDisclaimerProps> {
    private pageData: GeneralPageSEO;
    //  private pageTypes: any;

    constructor(props) {
        super(props);
        const { pages } = this.props;
        const { pageType } = this.props.route;
        this.pageData = pages.find((p) => StringUtils.equalIgnoreCase(p.pageName, pageType));
    }

    componentDidMount() {
        this.props.dispatch(setPageType(this.props.route.pageType));
    }

    render() {

        const { currentLang, url, pageType, deviceType } = this.props;
        const pageSEO = PageService.getPageSEOByPageName(this.props.pages, 'GameSubmissionDisclaimer');

        return (
            <>
                <Helmet
                    title={this.pageData.metaHTMLTitle[this.props.currentLang]}
                    link={[{ rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` }]}
                    meta={[
                        { name: 'twitter:title', content: pageSEO.metaHTMLTitle[currentLang] },
                        { property: 'og:title', content: pageSEO.metaHTMLTitle[currentLang] },
                        {
                            name: 'description',
                            content: pageSEO.onPageDescription[currentLang],
                        },
                        {
                            property: 'og:description',
                            content: pageSEO.onPageDescription[currentLang],
                        },
                        {
                            name: 'twitter:description',
                            content: pageSEO.onPageDescription[currentLang],
                        },
                    ]}
                />
                <ErrorBoundary>
                    <GameSubmissionDisclaimerTemplate
                        page={this.pageData}
                        currentLang={currentLang}
                        pageType={pageType}
                        deviceType={deviceType}
                    />
                </ErrorBoundary>
            </>
        );
    }
}

export default connect((state, props: RouteComponentProps<any>) => ({
    pages: PagesData.getOnlyNonCategoriesPages(state.pages),
    location: state.router.location,
    url: props.location.pathname,
    currentLang: state.currentLang,
    pageType: state.pageType,
    deviceType: state.deviceType,
}))(GameSubmissionDisclaimersPageBase);
