export const AvatarsData = {
    0: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_14.png',
        analyticsKey: '14',
    },
    1: {
        backgroundColor: '#d79df5',
        filename: 'avatar_24.png',
        analyticsKey: '24',
    },
    2: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_43.png',
        analyticsKey: '43',
    },
    3: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_4.png',
        analyticsKey: '4',
    },
    4: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_38.png',
        analyticsKey: '38',
    },
    5: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_32.png',
        analyticsKey: '32',
    },
    6: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_19.png',
        analyticsKey: '19',
    },
    7: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_31.png',
        analyticsKey: '31',
    },
    8: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_12.png',
        analyticsKey: '12',
    },
    9: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_34.png',
        analyticsKey: '34',
    },
    10: {
        backgroundColor: '#d79df5',
        filename: 'avatar_16.png',
        analyticsKey: '16',
    },
    11: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_3.png',
        analyticsKey: '3',
    },
    12: {
        backgroundColor: '#d79df5',
        filename: 'avatar_41.png',
        analyticsKey: '41',
    },
    13: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_11.png',
        analyticsKey: '11',
    },
    14: {
        backgroundColor: '#d79df5',
        filename: 'avatar_40.png',
        analyticsKey: '40',
    },
    15: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_37.png',
        analyticsKey: '37',
    },
    16: {
        backgroundColor: '#d79df5',
        filename: 'avatar_39.png',
        analyticsKey: '39',
    },
    17: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_23.png',
        analyticsKey: '23',
    },
    18: {
        backgroundColor: '#20a695',
        filename: 'avatar_33.png',
        analyticsKey: '33',
    },
    19: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_5.png',
        analyticsKey: '5',
    },
    20: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_9.png',
        analyticsKey: '9',
    },
    21: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_2.png',
        analyticsKey: '2',
    },
    22: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_28.png',
        analyticsKey: '28',
    },
    23: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_26.png',
        analyticsKey: '26',
    },
    24: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_6.png',
        analyticsKey: '6',
    },
    25: {
        backgroundColor: '#20a695',
        filename: 'avatar_35.png',
        analyticsKey: '35',
    },
    26: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_22.png',
        analyticsKey: '22',
    },
    27: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_20.png',
        analyticsKey: '20',
    },
    28: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_30.png',
        analyticsKey: '30',
    },
    29: {
        backgroundColor: '#20a695',
        filename: 'avatar_7.png',
        analyticsKey: '7',
    },
    30: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_13.png',
        analyticsKey: '13',
    },
    31: {
        backgroundColor: '#d79df5',
        filename: 'avatar_1.png',
        analyticsKey: '1',
    },
    32: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_17.png',
        analyticsKey: '17',
    },
    33: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_10.png',
        analyticsKey: '10',
    },
    34: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_8.png',
        analyticsKey: '8',
    },
    35: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_15.png',
        analyticsKey: '15',
    },
    36: {
        backgroundColor: '#d79df5',
        filename: 'avatar_21.png',
        analyticsKey: '21',
    },
    37: {
        backgroundColor: '#3b7ddf',
        filename: 'avatar_36.png',
        analyticsKey: '36',
    },
    38: {
        backgroundColor: '#20a695',
        filename: 'avatar_18.png',
        analyticsKey: '18',
    },
    39: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_27.png',
        analyticsKey: '27',
    },
    40: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_29.png',
        analyticsKey: '29',
    },
    41: {
        backgroundColor: '#20a695',
        filename: 'avatar_25.png',
        analyticsKey: '25',
    },
    42: {
        backgroundColor: '#20a695',
        filename: 'avatar_42.png',
        analyticsKey: '42',
    },
    43: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_44.png',
        analyticsKey: '44',
    },
    44: {
        backgroundColor: '#20a695',
        filename: 'avatar_45.png',
        analyticsKey: '45',
    },
    45: {
        backgroundColor: '#d79df5',
        filename: 'avatar_46.png',
        analyticsKey: '46',
    },
    46: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_47.png',
        analyticsKey: '47',
    },
    47: {
        backgroundColor: '#7cabf1',
        filename: 'avatar_48.png',
        analyticsKey: '48',
    },
    48: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_49.png',
        analyticsKey: '49',
    },
    49: {
        backgroundColor: '#20a695',
        filename: 'avatar_50.png',
        analyticsKey: '50',
    },
    50: {
        backgroundColor: '#4bd0ac',
        filename: 'avatar_51.png',
        analyticsKey: '51',
    },
};
export const SPECIAL_AVATARS = {
    'naturalgasoline@msn.com': {
        image: 'kenny.png',
        backgroundColor: '#5e9efe',
    },
    'jessica@arkadium.com': { image: 'jess.png', backgroundColor: '#e9a2d7' },
};
