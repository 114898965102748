import React, { useState } from 'react';

import { Button } from '../../../../../atoms/Buttons/Base/Base';
import { BorderedButton } from '../../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Input } from '../../../../../atoms/Input/Input';
import { ProfileRightSideRoutes } from '../../../../../constants/Pages';
import { DeviceDetector, DeviceType } from '../../../../../services/DeviceDetector';
import { ProfileProcessService } from '../../../../../services/ProfileProcessService';
import { TranslationService } from '../../../../../services/TranslationService';
import styles from './BirthdayField.css';

export const BirthdayField = React.memo((props: any) => {
    const [birthdayField, setBirthdayField] = useState('');

    function onClick() {
        const deviceDetector = new DeviceDetector();
        const deviceType = deviceDetector.DetectDevice();
        if (deviceType === DeviceType.DESKTOP) {
            props.openPanel(ProfileRightSideRoutes.BIRTHDAY_PANEL);
        } else {
            const el = document.getElementById('profile-bday-input2');
            el.focus();
            el.click();
        }
    }

    function saveTheDate(e) {
        props.saveUser({
            ...{ birthday: new Date(e).toISOString() },
            ...ProfileProcessService.getStepForUpdate(31),
        });
    }

    function buttonClick() {
        if (birthdayField) {
            saveTheDate(birthdayField);
        } else {
            onClick();
        }
    }

    return (
        <>
            <Button fullWidth noPadding onClick={() => onClick()}>
                <Input placeholder="Your birthday" value={birthdayField} />
            </Button>
            <div className={styles.hiddenCalendar}>
                <input
                    type="date"
                    id="profile-bday-input2"
                    onChange={(e) => {
                        setBirthdayField(e.target.value);
                        window.scrollTo(0, 0);
                    }}
                />
            </div>
            <div className={styles.footer}>
                <div>
                    <BorderedButton
                        caption={TranslationService.translateIt('ENTER')}
                        onClick={() => buttonClick()}
                        filled
                    />
                </div>
            </div>
        </>
    );
});
