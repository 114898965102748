import React from 'react';

import {useDispatch} from "react-redux";

import { BorderedButton } from '../../../atoms/Buttons/BorderedButton/BorderedButton';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { ProfileRightSideRoutes } from '../../../constants/Pages';
import { TranslationService } from '../../../services/TranslationService';
import { UrlService } from '../../../services/UrlService';
import { setProfilePanelState } from '../../../store/ducks/layout';
import styles from './NotLoggedInPanelMobile.css';

const imgUrl = UrlService.toCDNUrl('/illustrations/profile/promo-not-logged-in-small.png');

export const NotLoggedInPanelMobile = React.memo(() => {
    const dispatch = useDispatch();
    return (
        <div className={styles.notLoggedInPanelContainer}>
            <div className={styles.topPanel}>
                <I18nText as="p" keyName="NOT_LOGGED_IN_PANEL_TITLE1" className={styles.title1} />
            </div>
            <div className={styles.asset} style={{ backgroundImage: `url(${imgUrl})` }} />
            <div className={styles.body}>
                <I18nText as="p" keyName="NOT_LOGGED_IN_PANEL_TITLE2" className={styles.title2} />
                <I18nText as="p" keyName="NOT_LOGGED_IN_PANEL_TITLE3" className={styles.title3} />
            </div>
            <div className={styles.loginButtonStickyWrapper}>
                <BorderedButton
                    filled
                    caption={TranslationService.translateIt('NOT_LOGGED_IN_PANEL_LOGIN_BUTTON_TEXT')}
                    className={styles.loginButton}
                    dataElementDescription="not-logged-in-panel-login-button"
                    onClick={() => {
                        dispatch(
                            setProfilePanelState({
                                isOpened: true,
                                caption: TranslationService.translateIt('REGISTER_SIGN_IN'),
                                target: ProfileRightSideRoutes.SIGN_IN,
                            })
                        );
                    }}
                />
            </div>
        </div>
    );
});
