import React from 'react';

import classNames from 'classnames';

import { Button } from '../../../atoms/Buttons/Base/Base';
import { CloseIcon } from '../../../atoms/Icons/Styleguide/CloseIcon';
import styles from './ProgressBarMobileTemplate.css';

interface ProgressMobileProps {
    currentStep: number;
    doneSteps: number[];
    headerText: string;
    contentText: string;
    contentComponent: any;
    onClick: () => void;
    headerHidden: boolean;
}

export const ProgressBarMobileTemplate = React.memo((props: ProgressMobileProps) => {
    const { currentStep, doneSteps, headerText, contentText, contentComponent, onClick, headerHidden } = props;

    return (
        <div
            className={classNames(styles.progressMobile, {
                [styles.noTopSpace]: headerHidden,
            })}
        >
            <div className={styles.progressMobileContainer}>
                {!headerHidden && (
                    <div className={styles.header}>
                        <Button className={styles.closeIconWrapper} onClick={() => onClick()} noPadding>
                            <CloseIcon />
                        </Button>
                        <div className={styles.headerContent}>
                            <div className={styles.headerText}>{headerText}</div>
                            <div className={styles.headerSteps}>
                                {[1, 2, 3].map((item) => (
                                    <div
                                        key={item}
                                        className={classNames(
                                            styles.step,
                                            {
                                                [styles.done]: doneSteps.indexOf(item) !== -1,
                                            },
                                            {
                                                [styles.progress]: currentStep === item,
                                            }
                                        )}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.contentWrapper}>
                    {contentText && <div className={styles.contentText}>{contentText}</div>}
                    <div className={styles.contentComponent}>{contentComponent}</div>
                </div>
            </div>
        </div>
    );
});
