import { TAuthValues } from '../store/ducks/authData/authData';

export const checkValidation = (
  values: TAuthValues,
  validate: unknown,
  errors: {
    email: string,
    password: string,
    checkbox: string,
    authType?: null
  }
) => {
  const formValidation = Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);

      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError })
        }
      };
    },
    {
      errors: { ...errors }
    }
  );

  // temp hack for checkbox of auth form
  if (
    Object.values(formValidation.errors).filter((err) => Boolean(err)).length <= 1 &&
    Object.keys(formValidation.errors).includes('checkbox')
  ) {
    return true;
  }

  return !Object.values(formValidation.errors).length;
};
