import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { NotLoggedInPanelDesktop } from './NotLoggedInPanel/NotLoggedInPanelDesktop';
import { NotLoggedInPanelMobile } from './NotLoggedInPanel/NotLoggedInPanelMobile';
import { ProfileBottomAds } from './ProfileAds/ProfileBottomAds';
import { ProfileSidebarAds } from './ProfileAds/ProfileSidebarAds';
import { ProfileHeader } from './ProfileHeader/ProfileHeader';
import styles from './ProfileTemplate.css';
import { ProgressBar } from './ProgressBar/ProgressBar';
import { ProgressBarMobile } from './ProgressBarMobile/ProgressBarMobile';
import { ProfileTemplateSkeleton } from './Skeleton/ProfileTemplate.skeleton';
import { AccountProfileTemplate } from './Tabs/Account/Account';
import { CollectionsTemplate } from './Tabs/Collections/Collections';
import { EmailProfileTemplate } from './Tabs/Email/Email';
import { PersonalProfileTemplate } from './Tabs/Personal/Personal';
import { PublicProfileTemplate } from './Tabs/Public/Public';
import { PurchasesAndSubscription } from './Tabs/PurchasesAndSubscription/PurchasesAndSubscription';
import { devConsole } from '../../../utils/DevConsole';
import { MiscUtils } from '../../../utils/MiscUtils';
import { Button } from '../../atoms/Buttons/Base/Base';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { ProfilePages } from '../../constants/ProfilePages';
import { IGame } from '../../models/Game/Game';
import { PublicUserModel, UserModel } from '../../models/User/UserModel';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { HighScoreModel } from '../../services/HighScoreService';
import { LocalStorageService } from '../../services/LocalStorage';
import { ProfileProcessService } from '../../services/ProfileProcessService';
import { RecentlyPlayedModel } from '../../services/RecentlyPlayedService';
import { TranslationService } from '../../services/TranslationService';

const getTitlesMap = (isMyProfile: boolean) => {
    const TitlesMap = new Map<string, string>();

    TitlesMap.set(ProfilePages.PUBLIC, TranslationService.translateIt(isMyProfile ? 'MY_PROFILE' : 'PROFILE'));
    TitlesMap.set(ProfilePages.PERSONAL, TranslationService.translateIt('MY_INFORMATION'));
    TitlesMap.set(ProfilePages.ACCOUNT, TranslationService.translateIt('MY_ARKADIUM_ACCOUNT'));
    TitlesMap.set(ProfilePages.EMAIL, TranslationService.translateIt('MY_EMAIL_PREFERENCES'));
    // TODO: need to add transcriptions for Collections tab
    TitlesMap.set(ProfilePages.COLLECTIONS, TranslationService.translateIt('MY_COLLECTIONS'));
    // TODO: need to add transcriptions for Payment and Subscription tab
    TitlesMap.set(ProfilePages.PAYMENT_AND_SUBSCRIPTION, TranslationService.translateIt('Payment and Subscription'));
    return TitlesMap;
};
const HeaderTextMap = new Map<string, string>();

HeaderTextMap.set(ProfilePages.PUBLIC, TranslationService.translateIt('THIS_INFORMATION_CAN_BE_VIEWED'));
HeaderTextMap.set(ProfilePages.PERSONAL, TranslationService.translateIt('THIS_INFORMATION_CANT_BE_VIEWED'));
HeaderTextMap.set(ProfilePages.ACCOUNT, TranslationService.translateIt('THIS_INFORMATION_CANT_BE_VIEWED'));
HeaderTextMap.set(ProfilePages.EMAIL, TranslationService.translateIt('THIS_INFORMATION_CANT_BE_VIEWED'));
HeaderTextMap.set(
    ProfilePages.PAYMENT_AND_SUBSCRIPTION,
    TranslationService.translateIt('THIS_INFORMATION_CANT_BE_VIEWED')
);

const PagesMap = new Map<string, React.ElementType>();

PagesMap.set(ProfilePages.PUBLIC, PublicProfileTemplate);
PagesMap.set(ProfilePages.PERSONAL, PersonalProfileTemplate);
PagesMap.set(ProfilePages.ACCOUNT, AccountProfileTemplate);
PagesMap.set(ProfilePages.EMAIL, EmailProfileTemplate);
PagesMap.set(ProfilePages.COLLECTIONS, CollectionsTemplate);
PagesMap.set(ProfilePages.PAYMENT_AND_SUBSCRIPTION, PurchasesAndSubscription);

type ProfileTemplateProps = {
    games: IGame[];
    recentlyPlayed: RecentlyPlayedModel[];
    highScore: HighScoreModel[];
    currentLang: string;
    user: UserModel;
    loginLoader: boolean;
    publicUser: PublicUserModel;
    adFree: boolean;
    profileProgress?: number;
    isMyProfile?: boolean;
    isOpenedProfilePanel?: boolean;
    userId?: string;
    dispatch?: any;
};

const ProfileTemplate = React.memo((props: ProfileTemplateProps) => {
    const { loginLoader, user, userId, isMyProfile, recentlyPlayed, highScore, publicUser } = props;
    const [content, setContent] = useState(
        <ProfileTemplateSkeleton visible={loginLoader && !user} forPublic={Boolean(userId)} asPlaceholder={false} />
    );

    useEffect(() => {
        devConsole('HighScore GOT', highScore);

        if (!loginLoader) {
            user !== null || !isMyProfile
                ? setContent(<LoggedInView {...props} />)
                : setContent(<NotLoggedInView {...props} />);
        }
    }, [user, publicUser, loginLoader, recentlyPlayed, highScore]);

    return content;
});
const LoggedInView = React.memo((props: ProfileTemplateProps) => {
    const { currentLang, user, adFree, isMyProfile, loginLoader, publicUser } = props;
    const [activePage, setActivePage] = useState(ProfilePages.PUBLIC);
    const dispatch = useDispatch();
    const currentStep = isMyProfile ? ProfileProcessService.getCurrentStep() : 0;
    const TitlesMap = getTitlesMap(isMyProfile);
    const dropdownItems = Array.from(TitlesMap).filter(
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        ([key, value]) => value !== TitlesMap.get(activePage)
    );
    const sidebarTabs = isMyProfile
        ? [
              ProfilePages.PUBLIC,
              ProfilePages.COLLECTIONS,
              ProfilePages.PERSONAL,
              ProfilePages.ACCOUNT,
              ProfilePages.EMAIL,
              ProfilePages.PAYMENT_AND_SUBSCRIPTION,
          ]
        : [ProfilePages.PUBLIC];

    useEffect(() => {
        if (isMyProfile) {
            ProfileProcessService.initProgressBar();
        }
    }, [isMyProfile]);

    const authProvider = LocalStorageService.getItem('authProvider');

    return (
        <main
            className={classNames(styles.pageWrapper, {
                [styles.collapsePage]: loginLoader,
            })}
        >
            <ProfileHeader
                currentLang={currentLang}
                activePage={TitlesMap.get(activePage)!}
                activePageText={HeaderTextMap.get(activePage)!}
                profilePages={new Map<string, string>(dropdownItems)}
                setActivePage={(e) => setActivePage(e)}
                publicUsername={publicUser?.name}
                isMyProfile={isMyProfile}
            />
            <div
                className={classNames(styles.pageGrid, {
                    [styles.wideContentTab]:
                        activePage === ProfilePages.PAYMENT_AND_SUBSCRIPTION || activePage === ProfilePages.COLLECTIONS,
                })}
            >
                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                    <div className={styles.sidebarNav}>
                        <ul>
                            {sidebarTabs.map((item) => {
                                return (
                                    <li key={item}>
                                        <Button
                                            noPadding
                                            className={classNames(styles.sidebarItem, {
                                                [styles.activeSidebarNav]: activePage === item,
                                            })}
                                            onClick={() => setActivePage(item)}
                                            aria-current={activePage === item}
                                        >
                                            {TitlesMap.get(item)}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Responsive>
                <div
                    className={classNames(styles.content, {
                        [styles.wideContentTab]:
                            activePage === ProfilePages.PAYMENT_AND_SUBSCRIPTION ||
                            activePage === ProfilePages.COLLECTIONS,
                    })}
                >
                    {currentStep !== 40 &&
                        isMyProfile &&
                        activePage !== ProfilePages.PAYMENT_AND_SUBSCRIPTION &&
                        activePage !== ProfilePages.COLLECTIONS && (
                            <>
                                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                                    <ProgressBar
                                        currentStep={currentStep}
                                        switchPage={() => setActivePage(ProfilePages.PERSONAL)}
                                        dispatch={(e) => dispatch(e)}
                                        activePagePublic={activePage !== ProfilePages.PERSONAL}
                                        user={user}
                                        loginRegistrationSource={MiscUtils.getAuthSource(authProvider)}
                                    />
                                </Responsive>
                                <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                                    <ProgressBarMobile user={user} dispatch={(e) => dispatch(e)} />
                                </Responsive>
                            </>
                        )}
                    <TabRouter activeState={activePage} componentsMap={PagesMap} {...props} />
                    {!adFree &&
                        activePage !== ProfilePages.PUBLIC &&
                        activePage !== ProfilePages.PAYMENT_AND_SUBSCRIPTION &&
                        activePage !== ProfilePages.COLLECTIONS && <ProfileBottomAds />}
                </div>

                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                    <div
                        className={classNames(styles.sidebarAds, {
                            [styles.customBackground]: activePage === ProfilePages.PAYMENT_AND_SUBSCRIPTION,
                        })}
                    >
                        <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP}>
                            {!adFree &&
                                activePage !== ProfilePages.PAYMENT_AND_SUBSCRIPTION &&
                                activePage !== ProfilePages.COLLECTIONS && <ProfileSidebarAds />}
                        </Responsive>
                    </div>
                </Responsive>
            </div>
        </main>
    );
});
const NotLoggedInView = React.memo(({ currentLang, isMyProfile, publicUser }: ProfileTemplateProps) => {
    return (
        <main className={styles.pageWrapper}>
            <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                <NotLoggedInPanelMobile />
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <ProfileHeader
                    currentLang={currentLang}
                    activePage=""
                    activePageText=""
                    profilePages={new Map<string, string>([])}
                    // tslint:disable-next-line: no-empty
                    setActivePage={() => {}}
                    isMyProfile={isMyProfile}
                    publicUsername={publicUser?.name}
                />
                <NotLoggedInPanelDesktop />
            </Responsive>
        </main>
    );
});

export default ProfileTemplate;
