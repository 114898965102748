import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import { MiscUtils } from '../../../../../../utils/MiscUtils';
import { ProfanityUtils } from '../../../../../../utils/ProfanityUtils';
import { Button } from '../../../../../atoms/Buttons/Base/Base';
import { BorderedButton } from '../../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { TickIcon } from '../../../../../atoms/Icons/Styleguide/TickIcon';
import { Input } from '../../../../../atoms/Input/Input';
import { ProfileProcessService } from '../../../../../services/ProfileProcessService';
import { TranslationService } from '../../../../../services/TranslationService';
import UserService from '../../../../../services/UserService';
import styles from './NameField.css';

type Props = {
    saveUser: (user: any) => void;
    fieldName: string;
    placeholder: string;
    minWidth?: number;
    step: number;
    name: string;
    errorMessage?: string;
};

export const NameField = React.memo((props: Props) => {
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false);
    const myRef = useRef(null);
    const [isValid, setIsValid] = useState(true);
    const [errorMessageBackend, setErrorMessageBackend] = useState('');

    function scrollTop() {
        if (!MiscUtils.isServer) {
            window.scrollTo(0, 0);
        }
    }

    const saveUser = async () => {
        const isProfanity = await ProfanityUtils.checkWord(value);
        setErrorMessageBackend('');
        if (isProfanity) {
            if (isValid) {
                saveData({ [props.fieldName]: value }).then((res) => {
                    if (res === 200 && res) {
                        props.saveUser({
                            [props.fieldName]: value,
                            ...ProfileProcessService.getStepForUpdate(props.step),
                        });
                    } else {
                        setErrorMessageBackend(UserService.errorCodeToText(res));
                    }
                });
            }
        } else {
            setErrorMessageBackend(TranslationService.translateIt('SORRY_THAT_SCREEN_NAME_IS_NOT_AVAILABLE'));
        }
    };

    async function saveData(userData): Promise<number> {
        let result;
        await UserService.userUpdate(userData).then((res) => {
            result = res;
        });
        return result;
    }

    return (
        <div className={styles.content}>
            <div
                className={classNames(styles.inputContainer, {
                    [styles.inputContainerFocused]: focus && value.length >= (props.minWidth || 0),
                })}
            >
                <Input
                    value={value}
                    onValueChange={setValue}
                    ref={myRef}
                    onFocus={() => {
                        setFocus(true);
                        scrollTop();
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            setFocus(false);
                            scrollTop();
                        }, 200);
                        setErrorMessageBackend('');
                    }}
                    placeholder={props.placeholder}
                    name={props.name}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    errorMessageOverride={errorMessageBackend}
                />
                <Button
                    noPadding
                    className={classNames(styles.saveButton, {
                        [styles.hide]: !focus,
                        [styles.disabled]: !isValid || value.length === 0,
                    })}
                    onMouseDown={saveUser}
                >
                    <TickIcon className={styles.tickIcon} />
                </Button>
            </div>
            <div className={classNames(styles.footer, { [styles.hide]: focus })}>
                <div>
                    <BorderedButton
                        caption={TranslationService.translateIt('ENTER')}
                        disabled={value.length < (props.minWidth || 0)}
                        onClick={saveUser}
                        filled
                    />
                </div>
            </div>
        </div>
    );
});
