import React from 'react';

import { ProfileRightSideRoutes } from '../../../constants/Pages';
import { UserModel } from '../../../models/User/UserModel';
import { ProfileProcessService } from '../../../services/ProfileProcessService';
import { TranslationService } from '../../../services/TranslationService';
import { UrlService } from '../../../services/UrlService';
import { setMobileProgressBarState, setProfilePanelState } from '../../../store/ducks/layout';
import { UserEffects } from '../../../store/effects/user.effects';
import { AvatarField } from './ProgressBarFields/AvatarField/AvatarField';
import { BirthdayField } from './ProgressBarFields/BirthdayField/BirthdayField';
import { FinalScreen } from './ProgressBarFields/FinalScreen/FinalScreen';
import { LastStep } from './ProgressBarFields/LastStep/LastStep';
import { NameField } from './ProgressBarFields/NameField/NameField';
import { ProgressBarMobileTemplate } from './ProgressBarMobileTemplate';

interface ProgressBarMobileProps {
    user: UserModel;
    dispatch: any;
}

export class ProgressBarMobile extends React.Component<ProgressBarMobileProps> {
    componentDidMount() {
        this.props.dispatch(
            setMobileProgressBarState({
                isOpened: ProfileProcessService.getCurrentStep() !== 40,
            })
        );
    }

    componentWillUnmount() {
        this.props.dispatch(
            setMobileProgressBarState({
                isOpened: false,
            })
        );
    }

    saveUser = (updatedUser: any) => {
        this.props.dispatch(
            UserEffects.updateUser({
                ...updatedUser,
            })
        );
    };

    render() {
        const { user } = this.props;
        const avatar = (
            <AvatarField
                user={this.props.user}
                onClick={() =>
                    this.props.dispatch(
                        setProfilePanelState({
                            isOpened: true,
                            caption: TranslationService.translateIt('CHOOSE_YOUR_AVATAR'),
                            target: ProfileRightSideRoutes.AVATARS_PANEL,
                        })
                    )
                }
            />
        );

        const screenName = (
            <NameField
                saveUser={(u) => {
                    this.saveUser(u);
                }}
                fieldName="name"
                minWidth={3}
                placeholder={user.name}
                step={21}
                name="screenName"
            />
        );

        const firstName = (
            <NameField
                saveUser={(u) => this.saveUser(u)}
                fieldName="firstName"
                placeholder={TranslationService.translateIt('FIRST_NAME')}
                minWidth={2}
                step={22}
                name="firstName"
            />
        );

        const birthDay = (
            <BirthdayField
                saveUser={(u) => this.saveUser(u)}
                openPanel={() => {
                    this.props.dispatch(
                        setProfilePanelState({
                            isOpened: true,
                            target: ProfileRightSideRoutes.BIRTHDAY_PANEL,
                            caption: TranslationService.translateIt('BIRTHDAY'),
                        })
                    );
                }}
            />
        );

        const lastStep = (
            <LastStep
                onClick={() => {
                    this.props.dispatch(
                        UserEffects.updateUser({
                            emailRetrievalConsent: true,
                            ...ProfileProcessService.getStepForUpdate(32),
                        })
                    );
                }}
            />
        );

        const finalScreen = (
            <FinalScreen
                onClick={() => {
                    ProfileProcessService.finishProcess();
                    window.location.href = UrlService.createURL(`/`);
                }}
                user={this.props.user}
            />
        );

        const currentStep = ProfileProcessService.getCurrentStep();

        const variations = {
            11: {
                currentStep: 1,
                doneSteps: [],
                headerText: TranslationService.translateIt('ONLY_3_STEPS_TO_COMPLETE_YOUR_PROFILE'),
                contentText: TranslationService.translateIt('CHOOSE_YOUR_AVATAR_AND_SCREEN_NAME'),
                contentComponent: avatar,
            },
            12: {
                currentStep: 1,
                doneSteps: [],
                headerText: TranslationService.translateIt('ONLY_3_STEPS_TO_COMPLETE_YOUR_PROFILE'),
                contentText: TranslationService.translateIt('CREATE_YOUR_SCREEN_NAME'),
                contentComponent: screenName,
            },
            21: {
                currentStep: 2,
                doneSteps: [1],
                headerText: TranslationService.translateIt('YOURE_ALMOST_THERE_ONLY_2_MORE_STEPS_TO_GO'),
                contentText: TranslationService.translateIt('ENTER_YOUR_REAL_NAME_AND_DATE_OF_BIRTH'),
                contentComponent: firstName,
            },
            22: {
                currentStep: 2,
                doneSteps: [1],
                headerText: TranslationService.translateIt('ENTER_YOUR_BIRTHDAY_WE_DONT_SHARE'),
                contentText: TranslationService.translateIt('ENTER_YOUR_BIRTHDAY_WE_DONT_SHARE'),
                contentComponent: birthDay,
            },
            31: {
                currentStep: 3,
                doneSteps: [1, 2],
                headerText: TranslationService.translateIt('CONGRATULATIONS_YOURE_JUST_1_STEP_AWAY'),
                contentText: '',
                contentComponent: lastStep,
            },
            32: {
                currentStep: 4,
                doneSteps: [],
                headerText: '',
                contentText: '',
                contentComponent: finalScreen,
            },
        };

        return (
            <ProgressBarMobileTemplate
                currentStep={Math.trunc(currentStep / 10)}
                doneSteps={variations[currentStep].doneSteps}
                headerText={variations[currentStep].headerText}
                contentText={variations[currentStep].contentText}
                contentComponent={variations[currentStep].contentComponent}
                onClick={() => {
                    ProfileProcessService.finishProcess();
                    this.props.dispatch(
                        setMobileProgressBarState({
                            isOpened: false,
                        })
                    );
                }}
                headerHidden={variations[currentStep].currentStep === 4}
                key={currentStep}
            />
        );
    }
}
