import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { ScrollBackToTopButton } from '../FigmaStyleguide/ScrollBackToTopButton/ScrollBackToTopButton';
import { PagesData } from '../models/PagesData';
import { MetaInfoService } from '../services/MetaInfoService';
import { PageService } from '../services/PageService';
import { UrlService } from '../services/UrlService';
import { setFullScreenLoading } from '../store/ducks/layout';
import { setPageType } from '../store/ducks/pages';
import BlogArchiveTemplate from '../templates/Blog/BlogArchiveTemplate/BlogArchiveTemplate';
import { P404Template } from '../templates/p404/404Template';

const BlogArchivePage = React.memo(() => {
    BlogArchivePage.displayName = 'BlogArchivePage';

    const currentLang = useSelector((state) => state.currentLang);
    const pagesFromStore = useSelector((state) => state.pages);
    const routerFromRedux = useSelector((state) => state.router);
    const archiveData = useSelector((state) => state.blogArchiveData);

    const [canonicalHref, setCanonicalHref] = useState('');

    const pages = PagesData.getPages(pagesFromStore);
    const pageSEO = PageService.getPageSEOByPageName(pages, 'Blog');
    const enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.BlogArchive)}/`;
    const linksInfo = MetaInfoService.getLinksInfo(enPathname);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageType(PageTypes.BlogArchive));
        dispatch(setFullScreenLoading(false));
    }, []);

    useEffect(() => {
        try {
            if (routerFromRedux) {
                const url = UrlService.buildAbsoluteUrl(routerFromRedux);
                setCanonicalHref(`${environment.SITE_BASE_URL}${url}`);
            }
        } catch (err) {
            console.error(err);
        }
    }, [routerFromRedux]);


    // Check page exist
    if (!pageSEO || !archiveData) {
        dispatch(setPageType(PageTypes.NotFound));
        return <P404Template />;
    }

    return (
        <>
            <Helmet
                title={pageSEO.metaHTMLTitle[currentLang]}
                link={[{ rel: 'canonical', href: canonicalHref }, ...linksInfo]}
                meta={[
                    { name: 'twitter:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    { property: 'og:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    {
                        name: 'description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        property: 'og:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        name: 'twitter:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        name: 'robots',
                        content: 'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
                    },
                ]}
            />
            <ErrorBoundary>
                <ScrollBackToTopButton />
                <BlogArchiveTemplate />
            </ErrorBoundary>
        </>
    );
});

export default BlogArchivePage;
