import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './BlogArchiveTemplate.css';
import { PageTypes } from '../../../constants/Pages';
import { QUERY_STRING_CONSTS } from '../../../models/Enums';
import { BreadcrumbItem } from '../../../molecules/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsSection } from '../../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { BlogPostCard } from '../../../organisms/Blog/BlogPostCard/BlogPostCard';
import { BlogPostModularCard } from '../../../organisms/Blog/BlogPostModularCard/BlogPostModularCard';
import { TranslationService } from '../../../services/TranslationService';
import { UrlService } from '../../../services/UrlService';
import BlogPostSidePanel from '../BlogPostSidePanel/BlogPostSidePanel';
import HeroBlock from '../HeroBlock/HeroBlock';

export const getBreadcrumbLinks = (pageType, breadcrumbLabel?): BreadcrumbItem[] => {
    //TODO: Create switch-case
    const breadcrumbsArray: BreadcrumbItem[] = [
        {
            to: UrlService.createURL('/'),
            label: TranslationService.translateIt('HOME_PAGE'),
            position: 1,
        },
    ];
    switch (pageType) {
        case PageTypes.BlogArchive: {
            breadcrumbsArray.push({
                to: UrlService.createURL('/blog/'),
                label: 'Blog Archive',
                position: 2,
            });
            break;
        }
        case PageTypes.BlogPost: {
            breadcrumbsArray.push({
                to: UrlService.createURL('/blog/'),
                label: 'Blog Archive', // TODO: Translation here?
                position: 2,
            });
            breadcrumbsArray.push({
                to: '',
                label: breadcrumbLabel,
                position: 3,
            });
            break;
        }
    }

  return breadcrumbsArray;
};

const BlogArchiveTemplate = React.memo(() => {
    const pageType = useSelector(({ pageType }) => pageType);
    const archiveData = useSelector(({ blogArchiveData }) => blogArchiveData);
    const currentLang = useSelector(({ currentLang }) => currentLang);
    // const adFree = useSelector((state) => state.preLoadData.adFree); // AdFree for ads/noAds
    /* FOR TEST ADFREE or NOT*/
    const [adFree, setAdFree] = useState<boolean>(true);
    useEffect(() => {
        const withAds = UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.BLOG_ADS_TEST) === '1';
        setAdFree(!withAds);
    }, []);
    /* END FOR TEST ADFREE or NOT*/

    const getUrlBlogPost = (slug: string) => UrlService.createURL(`blog/${slug}/`);

    const getUrlBlogPostModular = (slug: string) => UrlService.createURL(`blog/${slug}?modular=true`);

    if (!archiveData) {
        return <main>Archive data is missing.</main>;
    }

    return (
        <main>
            <Paper square={true} className={styles.wrapper}>
                <Grid columns={{ xs: 4, md: 8, xl: 12 }} container>

                    <Grid item xl={9}>
                        <Grid item xs={4} md={8} xl={12} px={0}>
                            <BreadcrumbsSection
                                onLightBg
                                links={getBreadcrumbLinks(pageType)}
                                className={classNames(styles.breadcrumbsRow, styles.container)}
                            />
                        </Grid>
                        <Grid item xs={4} md={8} xl={12} px={0}>
                            <Grid item xl={12}>
                                <Box className={classNames(styles.mainHeaderRow, styles.container)}>
                                    <h1>Blog Archive</h1>
                                </Box>
                            </Grid>
                            <Grid item xl={!adFree ? 9 : 12} md={8} xs={4} className={styles.leftSide}>
                                {/*  Main Post Block  */}
                                {archiveData.promoPost && (
                                    <div className={styles.heroBlock}>
                                        <HeroBlock
                                            setUrl={getUrlBlogPost(archiveData.promoPost.slug[currentLang])}
                                            promoPost={archiveData.promoPost}
                                        />
                                    </div>
                                )}
                                {/*  END Main Post Block  */}

                                {/*   Posts  */}
                                <Grid item xl={12} py={8}>
                                    <Box display="grid" className={classNames(styles.postGrid, styles.container)}>
                                        {archiveData.filteredPostsModular?.map(
                                            ({
                                                date,
                                                category,
                                                modularBlogTitle,
                                                summary,
                                                slug,
                                                altPreview,
                                                modularBlogImage,
                                            }) => (
                                                <BlogPostModularCard
                                                    key={slug}
                                                    imgAlt={
                                                        altPreview ||
                                                        modularBlogImage?.alternativeText ||
                                                        modularBlogImage?.name ||
                                                        'image'
                                                    }
                                                    date={date}
                                                    category={category}
                                                    title={modularBlogTitle}
                                                    summary={summary}
                                                    image={modularBlogImage}
                                                    setUrl={getUrlBlogPost(slug)}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xl={12} py={8}>
                                    <Box display="grid" className={classNames(styles.postGrid, styles.container)}>
                                        {archiveData.filteredPosts?.map(
                                            ({ date, category, h1Title, summary, slug, altPreview, images }) => (
                                                <BlogPostCard
                                                    key={slug[currentLang]}
                                                    imgAlt={altPreview}
                                                    date={date}
                                                    category={category}
                                                    title={h1Title}
                                                    summary={summary}
                                                    images={
                                                        images?.filter((img: { name: string }) => !img.name.includes('desktop')) || []
                                                    }
                                                    setUrl={getUrlBlogPost(slug[currentLang])}
                                                />
                                            )
                                        )}
                                    </Box>
                                </Grid>
                                {/*  END Posts  */}
                            </Grid>
                            {!adFree && (
                                <Grid item xl={3} md={4} xs={4} className={styles.rightSide}>
                                    <Box>Filter</Box>
                                    <Box>ADS</Box>
                                </Grid>
                            )}
                            {/* <Grid item xl={12} md={8} xs={4} className={styles.pagination}>
                        <Box>Pagination Component</Box>
                    </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xl={3} md={12} className={styles.blogSidePanel}>
                        <BlogPostSidePanel categories={archiveData.allCategories} blogCta={archiveData.blogCta}/>
                    </Grid>

                </Grid>
            </Paper>
        </main>
    );
});

export default BlogArchiveTemplate;
