import React, { RefObject } from 'react';

import { Trans, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SubmitButton from './SubmitButton';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../atoms/Link/Link';
import { PageTypes } from '../../../constants/Pages';
import { RecurlyPlanObject } from '../../../constants/RecurlyPurchase';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { PCILogo } from '../../../FigmaStyleguide/Icons/PCILogo';
import { SOCLogo } from '../../../FigmaStyleguide/Icons/SOCLogo';
import { RECAPTCHA_ACTIONS_TYPE } from '../../../molecules/Captcha/hooks/useCaptcha';
import { PromoCodeBlock } from '../../../molecules/Subscription/PromoCodeBlock/PromoCodeBlock';
import { SummaryBlock } from '../../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import styles from '../SubscriptionTemplate.css';

type SummaryInfoProps = {
  totalSum: string;
  taxSum: string | null;
  setCouponCodes: (v: string[]) => void;
  setCheckoutPricing: any; // recurly func
  recurlyPricingError: any; // recurly obj
  price: any; // recurly obj
  couponCodes: string[];
  formRef: RefObject<HTMLFormElement>;
  giftCardCode: string;
  paymentGoodPlan: RecurlyPlanObject;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  planId: string;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void
  t: WithTranslation['t']
};

const SummaryInfo = React.memo(
  ({
    totalSum,
    taxSum,
    setCouponCodes,
    setCheckoutPricing,
    recurlyPricingError,
    price,
    couponCodes,
    formRef,
    giftCardCode,
    paymentGoodPlan,
    paymentType,
    gemsAnalyticsProps,
    planId,
    getCaptchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
    isLoading,
    setIsLoading,
    t
  }: SummaryInfoProps) => {
    const stepIndex = useSelector(({ stepIndex }) => stepIndex);
    const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;

    return (
      <div className={styles.summaryInfo}>
        <div className={styles.sticky}>
          <SummaryBlock
            heading={stepIndex !== 2 ? t('PAYMENT_PAGE.SUMMARY') : t('PAYMENT_PAGE.ORDER_SUMMARY')}
            totalSum={totalSum}
            taxSum={taxSum}
            hidden={stepIndex === 2}
            price={price}
            couponCodes={couponCodes}
            paymentGoodPlan={paymentGoodPlan}
            paymentType={paymentType}
            gemsAnalyticsProps={gemsAnalyticsProps}
            t={t}
          />
          <div className={styles.summaryInfo__nextStep}>
            {stepIndex !== 2 && (
              <div
                className={styles.mainBtnWrapper}
                style={{ bottom: pageTypeIsGiftCard ? '3.75rem' : 0 }}
              >
                <SubmitButton
                  setCheckoutPricing={setCheckoutPricing}
                  couponCodes={couponCodes}
                  formRef={formRef}
                  giftCardCode={giftCardCode}
                  paymentType={paymentType}
                  gemsAnalyticsProps={gemsAnalyticsProps}
                  planId={planId}
                  totalSum={totalSum}
                  getCaptchaToken={getCaptchaToken}
                  showChallengeRecaptcha={showChallengeRecaptcha}
                  clearCaptchaData={clearCaptchaData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </div>
            )}
            {stepIndex !== 2 && (
              <div className={styles.nextstepText}>
                {!pageTypeIsGiftCard && stepIndex === 0 ? (
                  <p>
                    <I18nText keyName="PAYMENT_PAGE.ABLE_TO_REVIEW_INFORMATION" />
                  </p>
                ) : (
                  <p>
                    <Trans i18nKey="PAYMENT_PAGE.BY_CONFIRMING" />&nbsp;
                    <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
                      <I18nText keyName="PLAYER_AGREEMENT" />
                    </NavLink>
                    {!(paymentType === PaymentType.subscription || UserService.isUserSubscriber()) && <>&nbsp;</>}
                    {(paymentType === PaymentType.subscription || UserService.isUserSubscriber()) && (
                      <>
                        ,&nbsp;
                        <NavLink
                          to="/arkadium-advantage-agreement.pdf"
                          target="_blank"
                          rel="noopener"
                        >
                          <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
                        </NavLink>&nbsp;
                      </>
                    )}
                    <I18nText keyName="PAYMENT_PAGE.AND" />&nbsp;
                    <NavLink
                      to={UrlService.createURL('privacy-policy')}
                      target="_blank"
                      rel="noopener"
                    >
                      <I18nText keyName="FOOTER_PRIVACY_POLICY" />
                    </NavLink>&nbsp;
                  </p>
                )}
              </div>
            )}
          </div>
          {!pageTypeIsGiftCard && stepIndex === 0 ? (
            <PromoCodeBlock
              recurlyPricingError={recurlyPricingError}
              setCheckoutPricing={setCheckoutPricing}
              couponCodes={couponCodes}
              setCouponCodes={setCouponCodes}
              price={price}
            />
          ) : null}
          {stepIndex !== 2 && (
            <div className={styles.secureTypesBlock}>
              <div className={styles.pciLogo}>
                <PCILogo />
              </div>
              <div className={styles.socLogo}>
                <SOCLogo />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default SummaryInfo;
