import React from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { Button } from '../../../../../../atoms/Buttons/Base/Base';
import { SortingFilterIcon } from '../../../../../../atoms/Icons/Styleguide/SortingFilterIcon';
import { Dropdown } from '../../../../../../molecules/Dropdown/Dropdown';
import { TranslationService } from '../../../../../../services/TranslationService';
import { UrlService } from '../../../../../../services/UrlService';
import styles from './Table.css';

type LeaderboardRow = {
    arkadiumSlug: string;
    name: string;
    imageUrl: string;
    score: number;
    scoreTeaser: string;
    date: number;
};

type HighscoreProps = {
    tableData: LeaderboardRow[];
    handleDropDownMobileClick: (e: string) => void;
    userOpenedDropdown: boolean;
    setUserOpenedDropdown: (boolean: boolean) => void;
    activeSorting: string;
    dropdownItems: Map<string, string>;
    isMyProfile: boolean;
};

export default function HighscoreTable({
    tableData,
    handleDropDownMobileClick,
    userOpenedDropdown,
    setUserOpenedDropdown,
    activeSorting,
    dropdownItems,
    isMyProfile,
}: HighscoreProps) {
    const history = useHistory();
    const handleLeaderBoardRowClick = (rowData: LeaderboardRow) => {
        const url = UrlService.createURL(`/games/${rowData.arkadiumSlug}`);
        history.push(url);
    };
    return (
        <TableContainer component={Paper}>
            <Table aria-label="Highscore table">
                <TableHead className={styles.header}>
                    <TableRow>
                        <TableCell>Games</TableCell>
                        <TableCell align="right">{isMyProfile ? 'My highest score' : 'Highest score'}</TableCell>
                        <TableCell>
                            <Button
                                noPadding
                                onClick={() => setUserOpenedDropdown(true)}
                                ariaLabel="open-highscore-filter"
                            >
                                <SortingFilterIcon className={styles.highscoreFilterIcon} />
                            </Button>
                            <Dropdown
                                className={userOpenedDropdown ? 'showDropdown' : ''}
                                headerItem={TranslationService.translateIt('SORT_BY')}
                                activeItem={activeSorting}
                                dropdownItems={dropdownItems}
                                onClick={handleDropDownMobileClick}
                                close={() => setUserOpenedDropdown(false)}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((rowData, index) => (
                        <TableRow
                            className={classNames(styles.highscoreTableRow, {
                                [styles.striped]: index % 2 === 0,
                            })}
                            key={rowData.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Button
                                    className={styles.button}
                                    key={rowData.name}
                                    onClick={() => handleLeaderBoardRowClick(rowData)}
                                >
                                    <div className={styles.leaderImage}>
                                        <img src={rowData.imageUrl} alt={rowData.name} />
                                    </div>
                                    <div className={styles.leaderName} title={rowData.name}>
                                        {rowData.name}
                                    </div>
                                </Button>
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <div className={styles.leaderScores}>{rowData.scoreTeaser}</div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
