import React, { createRef, useEffect, useState } from 'react';

import { useCheckoutPricing, UseCheckoutPricingInput } from '@recurly/react-recurly';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';


import { PurchaseNewTabHelper } from './PurchaseNewTabHelper';
import { SubscriptionStep1 } from './SubscriptionSteps/SubscriptionStep1';
import { SubscriptionStep2 } from './SubscriptionSteps/SubscriptionStep2';
import { SubscriptionStep3 } from './SubscriptionSteps/SubscriptionStep3';
import styles from './SubscriptionTemplate.css';
import SummaryInfo from './SummaryInfo/SummaryInfo';
import { QueryParamConstants } from '../../appbody/AppBodyWrapper';
import { environment } from '../../config/environment';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes } from '../../constants/Pages';
import { SS_ARK_PROMO_CODE } from '../../constants/PromoCodes';
import {
  chooseGemsPacksPlan,
  PaymentType,
  RecurlyGoodsNames,
  RecurlyPlanObject,
  RecurlyPlans
} from '../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../constants/SubscriptionPlan';
import { ProgressBar } from '../../FigmaStyleguide/ProgressBar/ProgressBar';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../../models/Enums';
import { PaymentMethod } from '../../models/Subscription/PaymentForm';
import { SubscriptionSource } from '../../models/Subscription/SubscriptionData';
import { RegistrationSource } from '../../models/User/RegistrationSource';
import CaptchaIframe from '../../molecules/Captcha/CaptchaIframe';
import useCaptcha, { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '../../molecules/Captcha/hooks/useCaptcha';
import { ChallengeCaptchaModal } from '../../molecules/Captcha/modals/ChallengeCaptchaModal';
import { SummaryBlock } from '../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { GemsAnalyticsCustomDimensions } from '../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../services/Analytics/Analytics';
import {
  getSubscriptionTypeBySubscriptionPlanCode,
  LEANPLUM_EVENTS,
  LeanplumAnalytics
} from '../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../services/AppInsight';
import { CookieService } from '../../services/CookieService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps } from '../../services/LocalStorageListenerLogic';
import { Media } from '../../services/MediaService';
import PaymentService from '../../services/PaymentService';
import { UrlService } from '../../services/UrlService';
import UserService, { AuthType } from '../../services/UserService';
import { setIsUserExists } from '../../store/ducks/authData/authData';
import { gemsShopLocationSelector } from '../../store/ducks/gems/gemsSelectors';
import { setFullScreenLoading, setSideMenuOpened, setSnackbarData } from '../../store/ducks/layout';
import {
  GemsAnalyticsRegistrationLocations,
  GemsAnalyticsShopLocations,
  setGemsShopLocation,
  setSocialRegistrationLocation
} from '../../store/ducks/leanplum/lpAnalytics';
import { setShowRecaptcha } from '../../store/ducks/recaptcha';
import {
  setStepIndex,
  setSubscriptionSource,
  setSubscriptionStep2Content,
  SubscriptionStep2Content
} from '../../store/ducks/subscription/common';
import { setUserProcessed } from '../../store/ducks/user';

type SubscriptionTemplateProps = {
  paymentType?: PaymentType;
  paymentMethodsApplied?: PaymentMethod[];
  paymentGoodPlan?: RecurlyPlanObject;
};

const paymentMethodsAppliedDefault: PaymentMethod[] = [
  PaymentMethod.CARD,
  PaymentMethod.GIFT_CARD,
  PaymentMethod.PAYPAL
];
const SubscriptionTemplate = ({
  paymentType = PaymentType.subscription,
  paymentMethodsApplied = paymentMethodsAppliedDefault
}: SubscriptionTemplateProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = createRef<HTMLFormElement>();
  // new data from redux store
  const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
  const chosenGemsPackItemName =
    useSelector(({ chosenGemsPackItemName }) => chosenGemsPackItemName) || RecurlyGoodsNames[PaymentType.gems][0];
  const recurlyToken = useSelector(({ recurlyToken }) => recurlyToken);
  const stepIndex = useSelector(({ stepIndex }) => stepIndex);
  const subscriptionStep2Content = useSelector(({ subscriptionStep2Content }) => subscriptionStep2Content);
  const [isResend, setIsResend] = useState<boolean>(false);
  const authValues = useSelector(({ authValues }) => authValues);
  const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
  const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource || '');
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);
  const isValidForm = useSelector(({ isValidForm }) => isValidForm);
  const taxData = useSelector(({ billingDataForTax }) => billingDataForTax);
  const user = useSelector(({ user }) => user);
  const subscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  const activeSubscriptionPlan = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan);
  const shopLocation = useSelector(gemsShopLocationSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [smoothScrolling, setSmoothScrolling] = useState<{
    isScrolling: boolean,
    id: string | null
  }>({ isScrolling: false, id: null });
  const [couponCodes, setCouponCodes] = useState<string[]>(() => {
    let promoCodes: string[];

    try {
      const saved = window.sessionStorage.getItem(SS_ARK_PROMO_CODE);

      if (saved) {
        promoCodes = JSON.parse(saved);
      } else {
        promoCodes = [];
      }
    } catch (e) {
      promoCodes = [];
    }

    return promoCodes;
  });
  const [recurlyPricingError, setRecurlyPricingError] = useState(null);
  const [giftCardCode, setGiftCardCode] = useState<string>(
    UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE)
      ? UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE).toUpperCase()
      : ''
  );
  const [isSubscriber, setIsSubscriber] = useState<boolean>(UserService.isUserSubscriber());
  const [isSubscriptionPurchaseProceed, setIsSubscriptionPurchaseProceed] = useState<boolean>(false);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();

  useEffect(() => {
    setIsSubscriber(UserService.isUserSubscriber());
  }, [user, subscriptions]);

  const paymentGoodPlan =
    paymentType === PaymentType.gems
      ? chooseGemsPacksPlan(isSubscriber)[chosenGemsPackItemName]
      : RecurlyPlans[PaymentType.subscription][activeSubscriptionPlan];
  const planCode = paymentGoodPlan?.plan || RecurlyPlans[PaymentType.subscription][SubscriptionPlans.ANNUAL];
  const planForAnalytics = paymentGoodPlan?.key?.toLocaleLowerCase();
  const gameSlugForAnalytics = CookieService.getArkCookie(LS_COOKIE_CONSTS.GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE);
  const initialPricingInput: UseCheckoutPricingInput = {
    [paymentType === PaymentType.subscription ? 'subscriptions' : 'adjustments']: [
      {
        [paymentType === PaymentType.subscription ? 'plan' : 'itemCode']: paymentGoodPlan?.plan
      }
    ],
    address:
      paymentType === PaymentType.subscription || paymentMethod === PaymentMethod.PAYPAL || !taxData
        ? null
        : taxData
  };
  const [{ price }, setCheckoutPricing] = useCheckoutPricing(initialPricingInput, setRecurlyPricingError);

  useEffect(() => {
    setCheckoutPricing((prev) => ({ ...prev, ...initialPricingInput, coupon: couponCodes[0] || '' }));
  }, [paymentType, planCode, chosenGemsPackItemName, paymentMethod, taxData, setCheckoutPricing]);

  // Prices
  const isGiftCardWithPrice = price?.now?.giftCard && price?.now?.giftCard !== '0.00';
  const taxSum =
    paymentType === PaymentType.subscription || // Subscriptions don't depend on tax values
    !price ||
    !price?.now?.taxes ||
    price?.now?.taxes === '0.00'
      ? null
      : `${price?.currency?.symbol}${price?.now?.taxes}`;
  const totalSum = price?.now?.total; // check subtotal for test
  // For Analytics
  const packObj = chooseGemsPacksPlan(isSubscriber)[chosenGemsPackItemName];
  const gemsAnalyticsProps: GemsAnalyticsCustomDimensions = {
    gemsPackId: chosenGemsPackItemName,
    priceInGem: parseFloat(price?.now?.subtotal) || 0,
    gemsInPack: packObj?.gemsAmount,
    shopLocation
  };

  useEffect(() => {
    if (
      paymentType === PaymentType.subscription &&
      socialRegistrationLocation !== GemsAnalyticsRegistrationLocations.PURCHASE_PAGE
    ) {
      dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.PURCHASE_PAGE));
    }
  }, [paymentType, socialRegistrationLocation]);

  useEffect(() => {
    if (paymentType === PaymentType.subscription) {
      void Analytics.trackEvent(Analytics.subscription.entryPointImpression(subscriptionSource), false);
    }
  }, [paymentType, subscriptionSource]);

  useEffect(() => {
    const isPaymentSubscription = paymentType === PaymentType.subscription;
    const isPaymentGems = paymentType === PaymentType.gems;
    const shouldRedirectSubscription =
      isPaymentSubscription &&
      subscriptions &&
      subscriptions?.length &&
      !pageTypeIsGiftCard;
    const shouldRedirectGems = isPaymentGems && !user && !PurchaseNewTabHelper.isTabData();
    const shouldRedirect = shouldRedirectSubscription || shouldRedirectGems;
    const redirectTab: HeaderSideMenuTabs | '' = isPaymentSubscription
      ? HeaderSideMenuTabs.SUBSCRIPTION_TAB
      : isPaymentGems
        ? HeaderSideMenuTabs.SHOP_TAB
        : '';
    const redirectTarget = `/?${QueryParamConstants.OPEN_TAB}=${redirectTab}`;

    if (shouldRedirect && redirectTab) {
      dispatch(push(redirectTarget));
    } else if (isPaymentGems && PurchaseNewTabHelper.isTabData()) {
      PurchaseNewTabHelper.handleTabData({
        newTabData: PurchaseNewTabHelper.getTabData(),
        dispatch,
        chosenGemsPackItemName,
        redirectTarget,
        user
      });
    }
  }, [user, subscriptions, paymentType, PurchaseNewTabHelper.getTabData(), chosenGemsPackItemName]);

  useEffect(() => {
    if (UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE)) {
      dispatch(setSubscriptionSource(SubscriptionSource.EMAIL_REDEMPTION));
      void Analytics.trackEvent(
        Analytics.subscription.giftStart(
          SubscriptionSource.EMAIL_REDEMPTION,
          planForAnalytics,
          PaymentMethod.GIFT_CARD
        )
      );
    }

    if (stepIndex === 0) {
      dispatch(setFullScreenLoading(false));
    }
  }, []);

  useEffect(() => {
    dispatch(setSideMenuOpened(false));
  }, [paymentType]);

  useEffect(() => {
    if (smoothScrolling.isScrolling) {
      formRef?.current?.children.namedItem(smoothScrolling.id).scrollIntoView({
        behavior: 'smooth'
      });
      setSmoothScrolling({ isScrolling: false, id: null });
    }
  }, [smoothScrolling]);

  useEffect(() => {
    // if a gift card is entered and confirmed
    if (isGiftCardWithPrice) {
      dispatch(setStepIndex(1));
    }
  }, [isGiftCardWithPrice]);

  useEffect(() => {
    const shopInitiator = LocalStorageService.getItem('shopOpenRequest');

    if (shopInitiator) {
      if (shopInitiator === LocalStorageListenedProps.GAME_PURCHASE_REQUEST) {
        dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.GAME));
      } else if (shopInitiator === GemsAnalyticsShopLocations.COMMON_HEADER_ICON) {
        dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.COMMON_HEADER_ICON));
      }

      LocalStorageService.removeItem('shopOpenRequest');
    }
  }, []);

  const fetchPurchaseSubscription = async () => {
    try {
      const subscriptionId = await PaymentService.purchaseSubscription(authValues.email, {
        arenaName: environment.ARENA_DOMAIN,
        tokenId: recurlyToken,
        couponCodes,
        giftCardRedemptionCode: giftCardCode,
        planCode,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });

      handlePurchaseSubscriptionSuccess(subscriptionId);
    } catch (err) {
      handlePurchaseSubscriptionError(err);
    }
  };
  const handlePurchaseSubscriptionSuccess = (subscriptionId: string) => {
    batch(() => {
      clearCaptchaData();
      setIsLoading(false);
      dispatch(setStepIndex(2));

      if (isSubscriptionPurchaseProceed) {
        dispatch(setIsUserExists(false));
      }

      setIsSubscriptionPurchaseProceed(false);
    });
    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.SUBSCRIPTION_PURCHASE, {
      subscriptionType: getSubscriptionTypeBySubscriptionPlanCode(planCode)
    });
    LeanplumAnalytics.setUserAttributesCustom({ subscriber: true });
    void Analytics.trackEvent(
      Analytics.subscription.subscriptionPurchase(
        subscriptionSource,
        planForAnalytics,
        paymentMethod,
        subscriptionId,
        gameSlugForAnalytics,
        totalSum
      )
    );

    if (paymentMethod === PaymentMethod.GIFT_CARD) {
      void Analytics.trackEvent(
        Analytics.subscription.giftRedeemed(
          subscriptionSource,
          planForAnalytics,
          paymentMethod,
          subscriptionId,
          false
        )
      );
    }

    CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, subscriptionId, 30);
  };
  const handlePurchaseSubscriptionError = (err: { Message: string, ErrorCode: number }) => {
    // Turning off requesting UI-mode
    batch(() => {
      setIsLoading(false);
      dispatch(setIsUserExists(false));
      dispatch(setShowRecaptcha(err.ErrorCode === 1010));
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: err.Message,
          type: 'error'
        })
      );
    });

    // ERROR: USER NOT FOUND
    if (err.ErrorCode === 1001) {
      clearCaptchaData();
      batch(() => {
        dispatch(
          setSubscriptionStep2Content({
            type: SubscriptionStep2Content.AuthForm,
            step: 2
          })
        );
        dispatch(
          setSnackbarData({
            isOpened: true,
            message: err[0].Message,
            type: 'error'
          })
        );
      });
    }

    // ERROR: GIFT CARD HAS ALREADY BEEN REDEEMED
    if (err.ErrorCode === 2003) {
      dispatch(setStepIndex(0));
      setCheckoutPricing((previous) => ({ ...previous, giftCard: '' as any }));
    }

    // ERROR: You already have a subscription to this plan.
    if (err.ErrorCode === 2004) {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: err[0].Message,
          type: 'error'
        })
      );
    }

    AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
  };
  const fetchUserRegister = async () => {
    try {
      const result = await UserService.userRegister(
        {
          email: authValues.email,
          password: authValues.password,
          emailRetrievalConsent: authValues.checkbox,
          registrationPlaceUrl: new URL(UserService.generateHostUrl()),
          user: undefined,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
        }
      );

      handleUserRegisterSuccess(result);
    } catch (err) {
      handleUserRegisterError(err);
    }
  };
  const handleUserRegisterSuccess = (uid: string) => {
    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
    dispatch(setUserProcessed(true));
    clearCaptchaData();

    void Analytics.trackEvent(
      Analytics.profile.registration(
        PageTypes.Subscription,
        '',
        RegistrationSource.EMAIL,
        '',
        '',
        GemsAnalyticsRegistrationLocations.PURCHASE_PAGE,
        uid
      )
    );

    if (paymentType === PaymentType.subscription) {
      getCaptchaToken(RECAPTCHA_ACTIONS.PurchaseSubscriptionByEmail);
      setIsSubscriptionPurchaseProceed(true);
    }
  };
  const handleUserRegisterError = (err: number) => {
    batch(() => {
      // Turning off requesting UI-mode
      setIsLoading(false);
      dispatch(setIsUserExists(false));
      dispatch(setShowRecaptcha(err === 1023));
      // Provide information
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(err),
          type: 'error'
        })
      );
    });
  };


  useEffect(() => {
    if (
      captchaToken &&
      subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm &&
      subscriptionStep2Content.step === 1 && !isResend
    ) {
      if (paymentType === PaymentType.subscription) {
        void fetchPurchaseSubscription();
      }
    }

    if (captchaToken &&
      subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm &&
      subscriptionStep2Content.step === 2 && !isResend
    ) {
      if (isSubscriptionPurchaseProceed) {
        void fetchPurchaseSubscription();
      } else {
        void fetchUserRegister();
      }
    }
  }, [captchaToken]);

  const handleSubmit = (e: Event) => {
    e.preventDefault();

    if (isValidForm) {
      setIsLoading(true);

      if (
        subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm &&
        subscriptionStep2Content.step === 1
      ) {
        if (paymentType === PaymentType.subscription) {
          getCaptchaToken(RECAPTCHA_ACTIONS.PurchaseSubscriptionByEmail);
        }
      }

      if (
        subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm &&
        subscriptionStep2Content.step === 2
      ) {
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
      }
    }
  };
  const getStepView = (stepIndex: number) => {
    switch (stepIndex) {
      case 1:
        return (
          <SubscriptionStep2
            paymentGoodPlan={paymentGoodPlan}
            paymentType={paymentType}
            price={price}
            giftCardCode={giftCardCode}
            handleSmoothScrolling={setSmoothScrolling}
            handleSubmit={handleSubmit}
            ref={formRef}
            isSubscriber={isSubscriber}
            gemsAnalyticsProps={gemsAnalyticsProps}
            t={t}
          />
        );
      case 2:
        return (
          <SubscriptionStep3
            getCaptchaToken={getCaptchaToken}
            showChallengeRecaptcha={showChallengeRecaptcha}
            clearCaptchaData={clearCaptchaData}
            paymentType={paymentType}
            gemsAnalyticsProps={gemsAnalyticsProps}
            isResend={isResend}
            setIsResend={setIsResend}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            t={t}
          />
        );
      default:
        return null;
    }
  };

  return (
    <main className={styles.subscriptionTemplate}>
      <div className={styles.paymentInfo}>
        {!pageTypeIsGiftCard && (
          <div className={styles.progressBarBlock}>
            <ProgressBar
              steps={[
                { label: t('PAYMENT_PAGE.PAYMENT_INFO') },
                { label: t('PAYMENT_PAGE.REVIEW') },
                { label: t('PAYMENT_PAGE.CONFIRMATION') }
              ]}
              activeStepIndex={stepIndex}
            />
          </div>
        )}
        <Media lessThan="ARK_SMALL_MOBILE_FIGMA" className={styles.summaryTopMobileBlock}>
          <SummaryBlock
            heading={stepIndex !== 2 ? t('PAYMENT_PAGE.YOUR_ORDER') : t('PAYMENT_PAGE.ORDER_SUMMARY')}
            totalSum={totalSum}
            taxSum={taxSum}
            couponCodes={couponCodes}
            price={price}
            paymentGoodPlan={paymentGoodPlan}
            paymentType={paymentType}
            gemsAnalyticsProps={gemsAnalyticsProps}
            t={t}
          />
        </Media>
        {/* this is not in the getStepView() function, because we want to save the user's credit card data if user returns from step 2
                 (the data is in the iframe, so the component won't unmount to save the data) */}
        <SubscriptionStep1
          isStepVisible={stepIndex === 0}
          giftCardCode={giftCardCode}
          setGiftCardCode={setGiftCardCode}
          ref={formRef}
          recurlyPricingError={recurlyPricingError}
          setCheckoutPricing={setCheckoutPricing}
          paymentMethodsApplied={paymentMethodsApplied}
          paymentType={paymentType}
          gemsAnalyticsProps={gemsAnalyticsProps}
          t={t}
        />
        {getStepView(stepIndex)}
      </div>
      <SummaryInfo
        formRef={formRef}
        setCheckoutPricing={setCheckoutPricing}
        totalSum={totalSum}
        taxSum={taxSum}
        setCouponCodes={setCouponCodes}
        recurlyPricingError={recurlyPricingError}
        couponCodes={couponCodes}
        price={price}
        giftCardCode={giftCardCode}
        paymentGoodPlan={paymentGoodPlan}
        paymentType={paymentType}
        gemsAnalyticsProps={gemsAnalyticsProps}
        planId={planCode}
        getCaptchaToken={getCaptchaToken}
        showChallengeRecaptcha={showChallengeRecaptcha}
        clearCaptchaData={clearCaptchaData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        t={t}
      />
      <ChallengeCaptchaModal
        modalClassName={classNames({ [styles.captchaModalWrapper]: showChallengeRecaptcha })}
        onClose={clearCaptchaData}
        isVisible={showChallengeRecaptcha}
      >
        <CaptchaIframe
          iframeRef={iframeRef}
          iframeURL={iframeURL}
          setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}
        />
      </ChallengeCaptchaModal>
    </main>
  );
};

export default SubscriptionTemplate;
