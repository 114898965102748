import React, { FC } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PaymentBlock.css';
import { AmericanExpress } from '../../../FigmaStyleguide/Icons/AmericanExpress';
import { ApplePayIcon } from '../../../FigmaStyleguide/Icons/ApplePayIcon';
import { BlankCard } from '../../../FigmaStyleguide/Icons/BlankCard';
import { MasterCard } from '../../../FigmaStyleguide/Icons/MasterCard';
import { PayPalIcon } from '../../../FigmaStyleguide/Icons/PayPalIcon';
import { VisaCardIcon } from '../../../FigmaStyleguide/Icons/VisaCardIcon';
import { PaymentMethod, BillingInfoValues } from '../../../models/Subscription/PaymentForm';
import { setStepIndex } from '../../../store/ducks/subscription/common';
import subsStyles from '../SubscriptionStyles.css';

type PaymentBlockProps = {
  payment?: string;
  userData: BillingInfoValues;
  handleSmoothScrolling: (v: { isScrolling: boolean, id: string }) => void;
  giftCardCode: string;
};

export const PaymentBlock: FC<PaymentBlockProps> = ({ userData, handleSmoothScrolling, giftCardCode }) => {
  const dispatch = useDispatch();
  const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
  const renderCreditCard = (creditCardCompany: string) => {
    switch (creditCardCompany.toLowerCase()) {
      case 'unknown':
        return <BlankCard/>;
      case 'master':
        return <MasterCard/>;
      case 'visa':
        return <VisaCardIcon/>;
      case 'american_express':
        return <AmericanExpress/>;
      default:
        return <BlankCard/>;
    }
  };
  const renderPaymentMethodIcon = (paymentMethod: PaymentMethod, creditCardCompany?: string) => {
    switch (paymentMethod) {
      case PaymentMethod.CARD:
        return renderCreditCard(creditCardCompany);
      case PaymentMethod.PAYPAL:
        return <PayPalIcon/>;
      case PaymentMethod.APPLE_PAY:
        return <ApplePayIcon/>;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <span className={subsStyles.heading}>Payment </span>
        {paymentMethod !== PaymentMethod.GIFT_CARD && (
          <span
            role="presentation"
            className={subsStyles.link}
            onClick={() => {
              handleSmoothScrolling({ isScrolling: true, id: 'paymentFieldsRef' });
              dispatch(setStepIndex(0));
            }}
          >
            Edit
          </span>
        )}
      </div>
      <div
        className={classNames(
          subsStyles.infoBlock,
          paymentMethod !== PaymentMethod.GIFT_CARD && styles.infoBlockSingleIcon
        )}
      >
        <div className={styles.paymentType}>
          {renderPaymentMethodIcon(
            paymentMethod,
            paymentMethod === PaymentMethod.CARD && userData.creditBrand
          )}
        </div>
        {paymentMethod === PaymentMethod.CARD && (
          <div className={styles.paymentDesc}>
            <ul>
              <li className={subsStyles.paymentHeading}>Card</li>
              <li>
                <strong>
                  <span>&#8226;&#8226;&#8226;&#8226;</span>
                  {userData.creditLastFour}
                </strong>
              </li>
            </ul>
          </div>
        )}
        {paymentMethod === PaymentMethod.GIFT_CARD && (
          <div className={styles.paymentDesc}>
            <ul>
              <li className={subsStyles.paymentHeading}>Gift Card</li>
              <li>
                <strong>
                  <span>{giftCardCode}</span>
                </strong>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
