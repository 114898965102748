import React, { useState, memo } from 'react';

import { CardCvvElement, CardNumberElement } from '@recurly/react-recurly';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './CreditInput.css';
import { ExpirationDateInput } from './ExpirationDateInput';
import { PageTypes } from '../../constants/Pages';
import { SubscriptionPlans } from '../../constants/SubscriptionPlan';
import { AmericanExpress } from '../../FigmaStyleguide/Icons/AmericanExpress';
import { BlankCard } from '../../FigmaStyleguide/Icons/BlankCard';
import { MasterCard } from '../../FigmaStyleguide/Icons/MasterCard';
import { Visa } from '../../FigmaStyleguide/Icons/Visa';
import { Analytics } from '../../services/Analytics/Analytics';

type InputProps = {
  dataRecurly?: string;
  className?: string;
  placeholder?: string;
  setValue: (name: string, value: string | Object) => void;
  setValidation: (isValid: boolean, name: string, message: string) => void;
  errors: unknown;
};

export const CreditInput = memo(
  ({ dataRecurly, className, placeholder, setValue, setValidation, errors }: InputProps) => {
    const [valueLength, setValueLength] = useState<number>(0);
    const [touched, setTouched] = useState({ cardNumber: false, cvv: false, month: false, year: false });
    const [creditCardCompany, setCreditCardCompany] = useState<string>('unknown');
    const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource || '');
    const planCode = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan || SubscriptionPlans.ANNUAL);
    const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
    const handleCreditChange = (change: any, type: string) => {
      setValueLength(change.length);

      if (type === 'cardNumber') {
        const creditBrand = change.brand as string;
        const creditLastFour = change.lastFour as string;

        setValue('cardNumber', { creditBrand, creditLastFour });
        setCreditCardCompany(creditBrand);
      } else {
        setValue(type, change.valid);
      }
    };
    const getErrorMessage = (dataRecurly: string) => {
      switch (dataRecurly) {
        case 'cardNumber':
          return 'Please enter a valid credit card number';
        case 'cvv':
          return 'Please enter valid code';
        case 'month':
          return 'Please enter a valid date';
        case 'year':
          return 'Please enter a valid date';
        default:
          break;
      }
    };
    const handleBlur = (change?: any, dataRecurly?: any) => {
      setTouched({ ...touched, [dataRecurly]: true });
      setValidation(change.state.valid, dataRecurly, getErrorMessage(dataRecurly));
      pageTypeIsGiftCard &&
      Analytics.trackEvent(
        Analytics.subscription.giftPaymentFieldOnBlur(dataRecurly, subscriptionSource, planCode)
      );
    };
    const renderCreditCard = (creditCardCompany: string) => {
      switch (creditCardCompany) {
        case 'unknown':
          return <BlankCard className={styles.creditCardIcon} />;
        case 'master':
          return <MasterCard className={styles.creditCardIcon} />;
        case 'visa':
          return <Visa className={styles.creditCardIcon} />;
        case 'american_express':
          return <AmericanExpress className={styles.creditCardIcon} />;
        default:
          return <BlankCard className={styles.creditCardIcon} />;
      }
    };
    const generateStyles = (dataRecurly: any, additionalStyle?: any) => {
      return classNames(
        className,
        styles.input,
        styles.cardInput,
        additionalStyle,
        (touched[dataRecurly] || valueLength > 0) && !errors[dataRecurly] && styles.successful,
        errors[dataRecurly] && styles.error
      );
    };
    const renderInput = (dataRecurly: string) => {
      switch (dataRecurly) {
        case 'cardNumber':
          return (
            <CardNumberElement
              onChange={(e) => handleCreditChange(e, dataRecurly)}
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              onBlur={(e: any) => handleBlur(e, 'cardNumber')}
              className={generateStyles('cardNumber')}
              style={{ fontSize: '18px', placeholder: { content: placeholder } }}
            />
          );
        case 'cvv':
          return (
            <CardCvvElement
              onChange={(e) => handleCreditChange(e, dataRecurly)}
              // @ts-expect-error: Let's ignore a compile error like this unreachable code
              onBlur={(e: any) => handleBlur(e, 'cvv')}
              className={generateStyles('cvv', styles.cvvElement)}
              style={{ fontSize: '18px', placeholder: { content: placeholder } }}
            />
          );
        default:
          return null;
      }
    };

    return (
      <>
        {dataRecurly === 'expirationDate' ? (
          <ExpirationDateInput
            errors={errors}
            touched={touched}
            handleCreditChange={handleCreditChange}
            onBlur={handleBlur}
          />
        ) : (
          renderInput(dataRecurly)
        )}
        {dataRecurly === 'cardNumber' && renderCreditCard(creditCardCompany)}
      </>
    );
  }
);
