import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const PayPalIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="131"
            height="31"
            viewBox="0 0 131 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M101.685 13.5108C101.294 16.0327 99.3342 16.0327 97.4384 16.0327H96.3598L97.1165 11.3243C97.1621 11.0398 97.4118 10.8302 97.7051 10.8302H98.1996C99.4897 10.8302 100.709 10.8302 101.337 11.5526C101.713 11.9849 101.827 12.6255 101.685 13.5108ZM100.86 6.9317H93.7103C93.2216 6.9317 92.8055 7.28123 92.7293 7.75579L89.8385 25.7699C89.7813 26.1251 90.0608 26.4465 90.4271 26.4465H94.0952C94.4374 26.4465 94.7288 26.2018 94.7819 25.8697L95.6018 20.7628C95.678 20.2883 96.0941 19.9387 96.5828 19.9387H98.8451C103.554 19.9387 106.272 17.6992 106.982 13.2608C107.302 11.3196 106.995 9.79423 106.07 8.72639C105.053 7.55245 103.252 6.9317 100.86 6.9317Z"
                fill="#2790C3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.6936 13.5108C50.3029 16.0327 48.3429 16.0327 46.4475 16.0327H45.3688L46.1252 11.3243C46.1711 11.0398 46.4208 10.8302 46.7138 10.8302H47.2083C48.4987 10.8302 49.7178 10.8302 50.3459 11.5526C50.7222 11.9849 50.8359 12.6255 50.6936 13.5108ZM49.8688 6.9317H42.7193C42.2306 6.9317 41.8145 7.28123 41.7384 7.75579L38.8472 25.7699C38.7903 26.1251 39.0699 26.4465 39.4359 26.4465H42.8494C43.3382 26.4465 43.7546 26.097 43.8307 25.6227L44.6109 20.7628C44.6867 20.2883 45.1031 19.9387 45.5918 19.9387H47.8542C52.563 19.9387 55.2809 17.6992 55.9913 13.2608C56.311 11.3196 56.0042 9.79423 55.0791 8.72639C54.0622 7.55245 52.2603 6.9317 49.8688 6.9317Z"
                fill="#27346A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.4662 19.9781C66.1355 21.9003 64.5826 23.1908 62.6021 23.1908C61.6092 23.1908 60.8143 22.8769 60.3034 22.2827C59.7971 21.6935 59.6065 20.8543 59.7669 19.9197C60.0753 18.0148 61.6529 16.6827 63.6033 16.6827C64.5756 16.6827 65.3647 16.9997 65.8859 17.5993C66.4103 18.2033 66.6162 19.0482 66.4662 19.9781ZM71.237 13.4305H67.8137C67.5204 13.4305 67.2707 13.6399 67.2251 13.925L67.0744 14.8656L66.835 14.5246C66.0938 13.4671 64.4416 13.1138 62.7917 13.1138C59.0095 13.1138 55.7788 15.9303 55.15 19.8805C54.8225 21.8511 55.2872 23.7342 56.4243 25.0486C57.4689 26.2563 58.9597 26.759 60.7359 26.759C63.7845 26.759 65.4752 24.8345 65.4752 24.8345L65.3223 25.7694C65.2651 26.125 65.5447 26.4467 65.9109 26.4467H68.9939C69.4826 26.4467 69.8987 26.0975 69.9749 25.6229L71.8256 14.1072C71.8828 13.7516 71.6033 13.4305 71.237 13.4305Z"
                fill="#27346A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.457 19.9781C117.126 21.9003 115.574 23.1908 113.593 23.1908C112.6 23.1908 111.805 22.8769 111.294 22.2827C110.788 21.6935 110.597 20.8543 110.758 19.9197C111.066 18.0148 112.644 16.6827 114.594 16.6827C115.566 16.6827 116.356 16.9997 116.876 17.5993C117.401 18.2033 117.607 19.0482 117.457 19.9781ZM122.228 13.4305H118.804C118.511 13.4305 118.261 13.6399 118.216 13.925L118.065 14.8656L117.826 14.5246C117.084 13.4671 115.432 13.1138 113.782 13.1138C110 13.1138 106.769 15.9303 106.141 19.8805C105.813 21.8511 106.278 23.7342 107.415 25.0486C108.46 26.2563 109.95 26.759 111.726 26.759C114.775 26.759 116.466 24.8345 116.466 24.8345L116.313 25.7694C116.256 26.125 116.535 26.4467 116.902 26.4467H119.984C120.473 26.4467 120.889 26.0975 120.966 25.6229L122.816 14.1072C122.873 13.7516 122.594 13.4305 122.228 13.4305Z"
                fill="#2790C3"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="131" height="31">
                <path d="M0.75 30.9993H130.75V0H0.75V30.9993Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M89.4695 13.4308H86.0286C85.6996 13.4308 85.3918 13.5912 85.207 13.8587L80.4606 20.7283L78.4489 14.1271C78.3233 13.7141 77.9364 13.4308 77.4975 13.4308H74.1151C73.7067 13.4308 73.4191 13.8255 73.5509 14.2054L77.3397 25.1339L73.7761 30.0752C73.4962 30.463 73.7787 30.9994 74.2622 30.9994H77.6999C78.0257 30.9994 78.3307 30.8425 78.5164 30.5792L89.9595 14.3503C90.2333 13.9619 89.9505 13.4308 89.4695 13.4308Z"
                    fill="#27346A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M126.263 7.42647L123.329 25.7699C123.272 26.1254 123.552 26.4465 123.918 26.4465H126.869C127.358 26.4465 127.774 26.097 127.85 25.6227L130.743 7.60865C130.8 7.25313 130.521 6.9317 130.154 6.9317H126.852C126.559 6.9317 126.309 7.14167 126.263 7.42647Z"
                    fill="#2790C3"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2747 29.7233L15.2357 23.7349L15.2978 23.4031C15.344 23.1145 15.4944 22.8505 15.7209 22.6605C15.9474 22.4704 16.2363 22.3655 16.5342 22.3655H17.2973C18.5693 22.3655 19.7379 22.232 20.7709 21.9687C21.873 21.688 22.8534 21.2507 23.6843 20.67C24.5653 20.0543 25.3053 19.2583 25.8839 18.3048C26.4944 17.2988 26.9427 16.0876 27.2164 14.7053C27.4584 13.4846 27.5037 12.3919 27.3517 11.4569C27.1907 10.4693 26.8035 9.62657 26.2011 8.95151C25.8358 8.54262 25.3682 8.18836 24.8117 7.89851L24.7986 7.89156L24.7982 7.87577C24.9926 6.65732 24.9852 5.64094 24.7761 4.76602C24.5659 3.8892 24.1405 3.10016 23.4744 2.35437C22.0944 0.809126 19.5843 0.0254517 16.0143 0.0254517H6.20951C5.88177 0.0254517 5.56367 0.140698 5.31434 0.350036C5.065 0.559373 4.89985 0.850173 4.84844 1.16844L0.765875 26.6088C0.728603 26.8409 0.796399 27.0768 0.951913 27.2548C1.10743 27.4339 1.33363 27.5365 1.57268 27.5365H7.6554L7.64994 27.5649L7.23223 30.1679C7.2001 30.3703 7.25922 30.5755 7.3945 30.7308C7.52977 30.8865 7.72673 30.9762 7.93494 30.9762H13.0367C13.323 30.9762 13.6003 30.8755 13.8178 30.6926C14.0353 30.5098 14.1799 30.2563 14.2246 29.9787L14.2747 29.7233Z"
                    fill="#27346A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7795 7.90809C10.8441 7.50489 11.1072 7.17494 11.4616 7.00791C11.6229 6.93213 11.8029 6.88982 11.9915 6.88982H19.6769C20.5875 6.88982 21.4364 6.94855 22.2126 7.07137C22.4344 7.10642 22.65 7.14684 22.8598 7.19262C23.0696 7.2384 23.273 7.28924 23.4699 7.34607C23.5686 7.37417 23.6653 7.40385 23.7607 7.43511C24.1418 7.55951 24.4965 7.70602 24.823 7.87589C25.2079 5.46456 24.8204 3.82364 23.4931 2.33744C22.0317 0.701255 19.3919 0.000305176 16.0143 0.000305176H6.20949C5.51932 0.000305176 4.93164 0.493812 4.824 1.16382L0.741442 26.6042C0.660793 27.1062 1.05568 27.5599 1.57267 27.5599H7.6255L10.7795 7.90809Z"
                    fill="#27346A"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8225 7.87622C24.7933 8.0603 24.7608 8.24753 24.7223 8.44045C23.4297 14.964 19.0059 17.2194 13.356 17.2194H10.4789C9.78877 17.2194 9.20592 17.7132 9.09828 18.3832L7.20737 30.1639C7.137 30.6028 7.48241 31 7.93449 31H13.0366C13.6403 31 14.1544 30.5684 14.2486 29.982L14.2987 29.7272L15.2597 23.7382L15.3217 23.4073C15.4159 22.821 15.93 22.3894 16.5337 22.3894H17.2968C22.2395 22.3894 26.1097 20.416 27.2407 14.7092C27.713 12.3247 27.4688 10.3343 26.2193 8.93491C25.8414 8.51181 25.371 8.16165 24.8225 7.87622Z"
                fill="#2790C3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4699 7.3459C23.2729 7.28906 23.0695 7.23823 22.8597 7.19245C22.6499 7.14666 22.4343 7.10625 22.2126 7.0712C21.4363 6.94838 20.5874 6.88965 19.6768 6.88965H11.9914C11.8028 6.88965 11.6228 6.93196 11.4615 7.00774C11.1071 7.17476 10.844 7.50472 10.7794 7.90792L9.14587 18.0856L9.09863 18.3827C9.20627 17.7127 9.78913 17.2189 10.4793 17.2189H13.3563C19.0062 17.2189 23.43 14.9635 24.7226 8.43995C24.7612 8.24703 24.7937 8.05979 24.8229 7.87571C24.4964 7.70584 24.1417 7.55934 23.7606 7.43494C23.6652 7.40368 23.5685 7.374 23.4699 7.3459Z"
                fill="#1F264F"
            />
        </svg>
    );
});
