import React from 'react';

import { connect } from 'react-redux';

import styles from './SuggetstionsSection.css';
import { MiscUtils } from '../../../../utils/MiscUtils';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { Responsive } from '../../../atoms/Layout/Responsive';
import SwipeSlider from '../../../atoms/SwipeSlider/SwipeSlider';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { IGame } from '../../../models/Game/Game';
import { SideMenuSuggestionsGames } from '../../../models/GamesList';
import { GameCard, GameCardTypes } from '../../../molecules/GameCard/GameCard';
import { GameService } from '../../../services/GameService';


type SuggestionsSectionProps = {
    games: IGame[];
    extendMediaQuery: boolean;
    sideMenuSuggestionsGames?: SideMenuSuggestionsGames[];
};

const mapStateToProps = (state) => ({
    sideMenuSuggestionsGames: state.gameLists.sideMenuSuggestionsGames,
});
const getSideMenuGamesByName = (name: string, games: SideMenuSuggestionsGames[]): string[] => {
    return games.find((s) => s.name.toLowerCase() === name.toLowerCase()).games;
};

export const SuggestionsSection = connect(mapStateToProps)(
    React.memo((props: SuggestionsSectionProps) => {
        return (
            <div className={styles.sectionContainer}>
                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                    {' '}
                    {/*  // @todo ask team about extendMediaQuery {props.extendMediaQuery ? ArkCssBreakpoints.ARK_SMALL_DESKTOP : ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW} */}
                    <>
                        <DesktopGrid
                            games={props.games}
                            caption="SEARCH_TAB_SUGGEST_TRENDING"
                            sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                        />

                        <DesktopGrid
                            games={props.games}
                            caption="SEARCH_TAB_SUGGEST_NEW"
                            sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                        />

                        <DesktopGrid
                            games={props.games}
                            caption="SEARCH_TAB_SUGGEST_CLASSIC"
                            sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                        />
                    </>
                </Responsive>
                <Responsive maxWidth={1024}>
                    {' '}
                    {/* {props.extendMediaQuery ? ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW  : ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE} */}
                    <MobileSlider
                        games={props.games}
                        caption="SEARCH_TAB_SUGGEST_TRENDING"
                        sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                    />
                    <MobileSlider
                        games={props.games}
                        caption="SEARCH_TAB_SUGGEST_NEW"
                        sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                    />
                    <MobileSlider
                        games={props.games}
                        caption="SEARCH_TAB_SUGGEST_CLASSIC"
                        sideMenuSuggestionsGames={props.sideMenuSuggestionsGames}
                    />
                </Responsive>
            </div>
        );
    })
);

type DesktopGridProps = {
    caption: string;
    games: IGame[];
    sideMenuSuggestionsGames: SideMenuSuggestionsGames[];
};

export const DesktopGrid = React.memo((props: DesktopGridProps) => {
    const games = getSideMenuGamesByName(props.caption, props.sideMenuSuggestionsGames).filter(
        (game) => !!GameService.findGameByArena5Slug(props.games, game)
    );

    return (
        <div className={styles.container}>
            <I18nText as="h2" keyName={props.caption} className={styles.heading} />

            <div className={styles.grid}>
                {games.map((g) => (
                    <div key={g} className={styles.gridItem}>
                        <GameCard
                            gameCardType={GameCardTypes.LARGE}
                            game={GameService.findGameByArena5Slug(props.games, g)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
});

const MobileSlider = React.memo((props: DesktopGridProps) => {
    const games = getSideMenuGamesByName(props.caption, props.sideMenuSuggestionsGames).filter(
        (game) => !!GameService.findGameByArena5Slug(props.games, game)
    );

    return (
        <div className={styles.container}>
            <I18nText as="h2" keyName={props.caption} className={styles.heading} />
            <SwipeSlider
                items={games}
                renderItem={(item) => {
                    return (
                        <GameCard
                            gameCardType={GameCardTypes.LARGE}
                            game={GameService.findGameByArena5Slug(props.games, item as any)}
                        />
                    );
                }}
                noShadows
            />
        </div>
    );
});
