import React, { FC } from 'react';

import classNames from 'classnames';
import classnames from 'classnames';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './SummaryBlock.css';
import { PageTypes } from '../../../constants/Pages';
import { PaymentType, RecurlyPlanObject } from '../../../constants/RecurlyPurchase';
import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { updateLabel } from '../ChosenPurchaseBlock/ChosenPurchaseBlock';

type SummaryBlockProps = {
  heading: string;
  totalSum: string;
  taxSum: string | null;
  hidden?: boolean;
  couponCodes: string[];
  price: any; // recurly obj
  paymentGoodPlan: RecurlyPlanObject;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  t: WithTranslation['t']
};
export const PAYPAL_TAXES_TEXT = 'During checkout, applicable sales tax may be applied to your total.';

export const SummaryBlock: FC<SummaryBlockProps> = ({
  heading,
  taxSum,
  totalSum,
  hidden,
  couponCodes,
  price,
  paymentGoodPlan,
  paymentType,
  gemsAnalyticsProps,
  t
}) => {
  const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
  const giftCardFormData = useSelector(({ giftCardPurchase }) => giftCardPurchase);
  const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
  const mainText = pageTypeIsGiftCard
    ? 'Gift Card'
    : updateLabel(paymentGoodPlan?.title, 'Gem', gemsAnalyticsProps, paymentType);
  const subText = pageTypeIsGiftCard ? giftCardFormData.recipientName : paymentGoodPlan?.description;
  const summaryInfoLabel = pageTypeIsGiftCard
    ? 29.99
    : price?.now?.[paymentType === PaymentType.subscription ? 'subscriptions' : 'adjustments'];
  const totalSumInfo = pageTypeIsGiftCard ? 29.99 : totalSum;
  const isGiftCardApplied = price?.now?.giftCard && price?.now?.giftCard !== '0.00';
  const paypalDisplayText = paymentMethod === PaymentMethod.PAYPAL
    ? t('PAYMENT_PAGE.PAYPAL_TAXES_TEXT_TBD')
    : (taxSum || '-');

  return (
    <div className={classNames(styles.summaryInfo__mainInfo, hidden && styles.blockHidden)}>
      <div>
        <span className={styles.summaryInfoLabel}>{heading}</span>
      </div>
      <div className={styles.advantageRow}>
        <div className={styles.advantageBlock}>
          <div className={styles.advantageBlock__text}>
            <p className={styles.mainText}>{mainText}</p>
            <p className={styles.subText}>{subText}</p>
          </div>
        </div>
        <div className={styles.summaryInfoLabel}>${summaryInfoLabel}</div>
      </div>
      {paymentType !== PaymentType.subscription && (
        <div className={classnames(styles.advantageRow, styles.taxRow)}>
          <div className={styles.advantageBlock}>
            <div className={styles.advantageBlock__text}>
              <p className={styles.subText}>{t('PAYMENT_PAGE.SALES_TAX')}</p>
            </div>
          </div>
          <div
            className={classnames(styles.summaryInfoLabel, {
              [styles.subText]: !taxSum && paymentMethod !== PaymentMethod.PAYPAL
            })}
          >
            {paypalDisplayText}
          </div>
        </div>
      )}
      {couponCodes.length > 0 && (
        <div className={classNames(styles.advantageRow, styles.appliedBlock)}>
          <div className={styles.advantageBlock}>
            <div className={styles.advantageBlock__text}>
              <p className={styles.mainText}>{t('PAYMENT_PAGE.PROMO_CODE_APPLIED')}</p>
              <p className={styles.subText}>
                {couponCodes.join('')}&nbsp;
                {price?.now &&
                  // calculating the percentage of the discount (recurly does not give it as a percentage by default)
                  ` -${Math.round(
                    parseFloat(price.now?.discount) /
                    (((paymentType === PaymentType.subscription
                      ? parseFloat(price.now?.subscriptions)
                      : parseFloat(price.now?.adjustments)) / 100) as number)
                  ).toFixed(0)}`}
                %
              </p>
            </div>
          </div>
          <div className={styles.summaryInfoLabel}>- ${price?.now?.discount}</div>
        </div>
      )}
      {isGiftCardApplied && (
        <div className={classNames(styles.advantageRow, styles.appliedBlock)}>
          <div className={styles.advantageBlock}>
            <div className={styles.advantageBlock__text}>
              <p className={styles.mainText}>Gift card applied</p>
              <p className={styles.subText}>
                {paymentGoodPlan?.description.replace('subscription', 'plan')}
              </p>
            </div>
          </div>
          <div className={styles.summaryInfoLabel}>- ${price?.now?.giftCard}</div>
        </div>
      )}
      <div className={styles.divider}/>
      <div className={styles.totalRow}>
        <div className={styles.summaryInfoLabel}>
          Total&nbsp;{`${
          !taxSum && paymentMethod === PaymentMethod.PAYPAL && paymentType !== PaymentType.subscription
            ? t('PAYMENT_PAGE.PAYPAL_TAXES_TEXT_TOTAL')
            : ''
        }`}</div>
        <div className={styles.summaryInfoLabel}>
          {couponCodes.length > 0 && (
            <s>
              ${price?.now?.[paymentType === PaymentType.subscription ? 'subscriptions' : 'adjustments']}
            </s>
          )}
          ${totalSumInfo}
        </div>
      </div>
    </div>
  );
};
