import React, { useState } from 'react';

import GemsTransactionTable from '../../../../molecules/GemsTransactionTable/GemsTransactionTable';
import { PurchasesAndSubscriptionTabs } from '../../../../molecules/PurchasesAndSubscriptionTabs/PurchasesAndSubscriptionTabs';
import { PaymentAndSubscription } from '../PaymentAndSubscription/PaymentAndSubscription';
import styles from '../PurchasesAndSubscription/PurchasesAndSubscription.css';

export enum purchasesTabType {
    SUBSCRIPTION = 'SUBSCRIPTION',
    HISTORY = 'HISTORY',
}

export const PurchasesAndSubscription = React.memo(() => {
    const [purchaseTab, setPurchaseTab] = useState(purchasesTabType.SUBSCRIPTION);

    return (
        <div className={styles.background}>
            <div className={styles.purchasesTabs}>
                <PurchasesAndSubscriptionTabs setActivePage={(value) => setPurchaseTab(value)} />
            </div>
            {purchaseTab === purchasesTabType.SUBSCRIPTION && <PaymentAndSubscription />}
            {purchaseTab === purchasesTabType.HISTORY && <GemsTransactionTable />}
        </div>
    );
});
