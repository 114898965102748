import React, { AnchorHTMLAttributes, useEffect, useState } from 'react';

import facebookIcon from '@iconify/icons-fa-brands/facebook-f';
import instagramIcon from '@iconify/icons-fa-brands/instagram';
import Icon from '@iconify/react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import styles from './BlogPostModularTemplate.css';
import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { IModularBlogPost, MediaWthLink } from '../../../models/Strapi';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { BreadcrumbsSection } from '../../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { OpenWebBlock } from '../../../molecules/OpenWeb/OpenWebConversations';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import { getBreadcrumbLinks } from '../BlogArchiveTemplate/BlogArchiveTemplate';
import HeroBlockModular from '../HeroBlockModular/HeroBlockModular';

function preprocessText(text) {
    return text.replace(/\n\n/g, '\n\n\n\n');
}

interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const RenderLink = ({ href, children, ...props }: ILinkProps) => (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
    </a>
);

interface IMarkdownTextFieldProps {
    readonly markdownText: string;
}

function MarkdownTextField({ markdownText }: IMarkdownTextFieldProps) {
    const processed = preprocessText(markdownText);

    return (
        <div className={styles.markdownText}>
            <ReactMarkdown
                components={{
                    a: RenderLink,
                }}
            >
                {processed}
            </ReactMarkdown>
        </div>
    );
}

const getImageOrLinkedImage = (image: MediaWthLink, customStyle: string) => {
    if (image.link) {
        return (
            <div className={styles[customStyle]}>
                <a href={UrlService.checkIfAbsoluteUrlOrCreate(image.link)} target="_blank" rel="noopener noreferrer">
                    <img
                        src={UrlService.createStrapiMediaUrl(image.media?.url)}
                        alt={image.media?.alternativeText || image.media?.name}
                    />
                </a>
            </div>
        );
    }

    return (
        <div className={styles[customStyle]}>
            <img
                src={UrlService.createStrapiMediaUrl(image.media?.url)}
                alt={image.media?.alternativeText || image.media?.name}
            />
        </div>
    );
};
const BlogPostTemplate = React.memo(() => {
    const pageType = useSelector((state) => state.pageType);
    const postData = useSelector<any, IModularBlogPost>((state) => state.blogPostData);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const activeUserSubscriptions = useSelector(state => state.activeUserSubscriptions);

    useEffect(() => {
        if (!activeUserSubscriptions) {
            setIsSubscriber(false);
            return;
        }

        const subscriberStatus = UserService.isUserSubscriber();

        setIsSubscriber(subscriberStatus);
    }, [activeUserSubscriptions]);

    if (!postData) {
        return <main>Post data is missing.</main>;
    }

    const handleAnalytics = (ctaText: string) => {
        Analytics.trackEvent(Analytics.blogAnalytics.blogCTAClick(ctaText));
    };

    return (
        <main>
            <Paper square={true} className={styles.wrapper}>
                <Grid columns={{ md: 8, xl: 12 }}>
                    <Grid container>
                        <Grid item xl={11}>
                            <BreadcrumbsSection
                                className={classNames(styles.breadcrumbsRow, styles.container)}
                                isSSRLink={true}
                                onLightBg
                                links={getBreadcrumbLinks(pageType, postData.breadcrumbLabel)}
                            />
                        </Grid>
                    </Grid>
                    <div className={styles.mainContainer}>
                        <div className={styles.contentWrapperNew}>
                            <HeroBlockModular isPostPage promoPost={postData} showSubtitle={false} />
                        </div>
                        <div className={styles.blogPostBody}>
                            <div className={styles.mainContent}>
                                <Box className={classNames(styles.contentWrapper, styles.container)}>
                                    {/*blocks*/}
                                    {postData?.modularBlogBlock?.map((block) => (
                                        <div key={block.id} className={styles.block}>
                                            {block.h2 && <h2>{block.h2}</h2>}
                                            {block.h3 && <h3>{block.h3}</h3>}

                                            {block.text && (
                                                <div className={styles.textFieldWithImage}>
                                                    <MarkdownTextField markdownText={block.text} />
                                                    {block.sideImage &&
                                                        getImageOrLinkedImage(block.sideImage, 'sideImageWrapper')}
                                                </div>
                                            )}
                                            {block.centralImage && (
                                                <div className={styles.imageContainer}>

                                                        {getImageOrLinkedImage(
                                                            block.centralImage,
                                                            'centralImageWrapper'
                                                        )}

                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    {/*CTA*/}
                                    {postData.modularBlogCta && (
                                        <div
                                            className={styles.cta}
                                            style={{
                                                backgroundColor: postData.modularBlogCta.backgroundColor,
                                                color: postData.modularBlogCta.textColor,
                                            }}
                                        >
                                            <h2 style={{ color: postData.modularBlogCta.textColor }}>
                                                {postData.modularBlogCta.heading}
                                            </h2>
                                            {postData.modularBlogCta.text}
                                            <div className={styles.buttonWrapper}>
                                                <StrapiButton
                                                    action={postData.modularBlogCta.button?.action}
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        postData.modularBlogCta.button?.url
                                                    )}
                                                    label={postData.modularBlogCta.button?.label}
                                                    analyticsCallback={() => {
                                                        handleAnalytics(postData.modularBlogCta.analytics?.analyticId);
                                                    }}
                                                    gradient={{
                                                        color1: postData.modularBlogCta.button?.gradientColor1,
                                                        color2: postData.modularBlogCta.button?.gradientColor2,
                                                        color3: postData.modularBlogCta.button?.gradientColor3,
                                                        textColor: postData.modularBlogCta.button?.textColor,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Box>
                            </div>
                            <div className={styles.sidebar}>
                                <div className={styles.container}>
                                    <p>Recommended posts</p>
                                    <ul>
                                        {postData.recommendedPosts.map((p, index) => (
                                            <li key={p.label + index}>
                                                <a href={p.link}>{p.label}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                { !isSubscriber && (
                                    <Ad
                                        id="ark_display_r1"
                                        hasBackground={false}
                                        captionPosition={CaptionPositionTypes.CENTER}
                                        adType={[AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                        className={styles.rightAd}
                                        keepSize
                                    />
                                )}
                                <div className={styles.container}>
                                    <p>Follow us</p>
                                    <div className={styles.socials}>
                                        {postData.instagramLink && (
                                            <a href={postData.instagramLink} className={styles.social}>
                                                <Icon icon={instagramIcon} className={styles.icon} />
                                                Instagram
                                            </a>
                                        )}
                                        {postData.facebookLink && (
                                            <a href={postData.facebookLink} className={styles.social}>
                                                <Icon icon={facebookIcon} className={styles.icon} />
                                                Facebook
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.container}>
                            {postData.blogCategory && (
                                <p className={styles.categories}>
                                    <span>Category: </span> {postData.blogCategory.name}
                                </p>
                            )}
                        </div>
                    </div>

                    <Grid className={styles.container} item xs={12}>
                        <OpenWebBlock topicId={`${postData.uid}__${postData.id}`} linkPath={`blog/${postData.slug}`} />
                    </Grid>
                </Grid>
            </Paper>
        </main>
    );
});

export default BlogPostTemplate;
