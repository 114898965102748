import React, { memo, RefObject, useEffect, useState } from 'react';

import { HttpError } from '@arkadium/eagle-user-client/dist/types/utils';
import { useRecurly } from '@recurly/react-recurly';
import { batch, useDispatch, useSelector } from 'react-redux';

import { GRM } from '../../../../modules/GameRenderingModule';
import { environment } from '../../../config/environment';
import { PageTypes } from '../../../constants/Pages';
import { chooseGemsPacksPlan, PaymentType, RecurlyGoodsNames } from '../../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { LS_COOKIE_CONSTS } from '../../../models/Enums';
import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_ACTIONS_TYPE,
  RECAPTCHA_MODES
} from '../../../molecules/Captcha/hooks/useCaptcha';
import { PAYPAL_TAXES_TEXT } from '../../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { dateConvert } from '../../../organisms/PaymentForm/PaymentForm';
import AdFreeService from '../../../services/AdFreeService';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import {
  getSubscriptionTypeBySubscriptionPlanCode,
  LEANPLUM_EVENTS,
  LeanplumAnalytics,
  LeanplumGemsTrack
} from '../../../services/Analytics/LeanplumAnalytics';
import { AppInsightService } from '../../../services/AppInsight';
import { CookieService } from '../../../services/CookieService';
import GemsService from '../../../services/GemsService';
import { LocalStorageService } from '../../../services/LocalStorage';
import { lsSetGamePurchaseRequest } from '../../../services/LocalStorageListenerLogic';
import PaymentService from '../../../services/PaymentService';
import { UrlService } from '../../../services/UrlService';
import UserService from '../../../services/UserService';
import { gemsShopLocationSelector } from '../../../store/ducks/gems/gemsSelectors';
import { setGiftCardStep } from '../../../store/ducks/giftCard';
import { setSnackbarData } from '../../../store/ducks/layout';
import { LS_SHOP_LOCATION_PROP } from '../../../store/ducks/leanplum/lpAnalytics';
import { setPreLoadData } from '../../../store/ducks/preLoadData';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import {
  setRecurlyToken,
  setStepIndex,
  setSubscriptionStep2Content,
  SubscriptionStep2Content
} from '../../../store/ducks/subscription/common';
import { PurchaseNewTabHelper } from '../PurchaseNewTabHelper';
import styles from '../SubscriptionTemplate.css';

type SummaryInfoProps = {
  setCheckoutPricing: any; // recurly func
  couponCodes: string[];
  formRef: RefObject<HTMLFormElement>;
  giftCardCode: string;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  planId: string;
  totalSum: any;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void
};

export const LS_SENT_AI_GEMS_NEXT_STEP = `${environment.STORAGE_PREFIX}LS_SENT_AI_GEMS_NEXT_STEP`;

const SubmitButton = memo(
  ({
    setCheckoutPricing,
    couponCodes,
    formRef,
    giftCardCode,
    paymentType,
    gemsAnalyticsProps,
    planId,
    totalSum,
    getCaptchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
    isLoading,
    setIsLoading
  }: SummaryInfoProps) => {
    const dispatch = useDispatch();
    const recurly = useRecurly();
    const stepIndex = useSelector(({ stepIndex }) => stepIndex);
    const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
    const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource);
    const planCode = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan || SubscriptionPlans.ANNUAL);
    const user = useSelector(({ user }) => user);
    const userProcessed = useSelector(({ userProcessed }) => userProcessed);
    const subscriptionStep2Content = useSelector(({ subscriptionStep2Content }) => subscriptionStep2Content);
    const recurlyToken = useSelector(({ recurlyToken }) => recurlyToken);
    const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
    const isValidForm = useSelector(({ isValidForm }) => isValidForm);
    const giftCardForm = useSelector(({ giftCardPurchase }) => giftCardPurchase);
    const initialGemsAmount = useSelector(({ gemsAmount }) => gemsAmount);
    const gemsShopLocation = useSelector(gemsShopLocationSelector);
    const shopLocation = LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || gemsShopLocation;
    const captchaToken = useSelector(({ recaptcha }) => recaptcha.recaptchaToken);
    const chosenGemsPackItemName =
      useSelector(({ chosenGemsPackItemName }) => chosenGemsPackItemName) || RecurlyGoodsNames[PaymentType.gems][0];
    const [mainBtnLabel, setMainBtnLabel] = useState<string>('Next step');
    const planForAnalytics = planCode === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual';
    const gameSlugForAnalytics = CookieService.getArkCookie(LS_COOKIE_CONSTS.GAME_SLUG_FOR_SUBSCRIPTION_PURCHASE);
    let displayName: string;

    if (paymentType === PaymentType.subscription) {
      displayName = 'Arkadium Advantage Subscription';
    } else if (paymentType === PaymentType.gems) {
      displayName = `Arkadium ${gemsAnalyticsProps.gemsInPack} Gem purchase. ${PAYPAL_TAXES_TEXT}`;
    } else {
      displayName = 'Arkadium TEST purchase';
    }

    const paypal = recurly.PayPal({
      display: {
        displayName: displayName
      }
    });


    paypal.on('error', function (err) {
      dispatch(setSnackbarData({ isOpened: true, message: err.message, type: 'error' }));
    });

    paypal.on('token', function (token) {
      if (pageTypeIsGiftCard) {
        setIsLoading(true);
        PaymentService.purchaseGiftCard(
            'USD',
            2999, // gift card cost in cents: 2999
            giftCardForm.customMessage,
            dateConvert(giftCardForm.deliveryDate),
            environment.RECURLY_GIFT_CARD_PRODUCT_CODE,
            giftCardForm.recipientEmail,
            giftCardForm.recipientName,
            giftCardForm.senderEmail,
            giftCardForm.senderName,
            token.id
          )
          .then(() => {
            void Analytics.trackEvent(
              Analytics.subscription.gifterPurchaseButton(
                subscriptionSource,
                planCode,
                PaymentMethod.PAYPAL
              )
            );
            batch(() => {
              setIsLoading(false);
              dispatch(setGiftCardStep(2));
            });
          })
          .catch((err) => {
            batch(() => {
              setIsLoading(false);
              dispatch(
                setSnackbarData({
                  isOpened: true,
                  message: err.body.details[0].Message,
                  type: 'error'
                })
              );
            });
            AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
          });
      } else {
        if (paymentType === PaymentType.subscription) {
          void Analytics.trackEvent(
            Analytics.subscription.subscriptionNextStep(
              subscriptionSource,
              planForAnalytics,
              PaymentMethod.PAYPAL
            )
          );
        }

        batch(() => {
          dispatch(setRecurlyToken(token.id));
          dispatch(setStepIndex(1));
        });
      }
    });

    const gameId = UrlService.getGameSlugFromSearchParam(window.location.search);
    const getButtonLabel = () => {
      if (stepIndex === 1 && subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm) {
        return 'Continue';
      }

      return stepIndex === 0 && !pageTypeIsGiftCard ? 'Next step' : 'Confirm Purchase';
    };

    useEffect(() => {
      setMainBtnLabel(getButtonLabel());
    }, [stepIndex, subscriptionStep2Content, pageTypeIsGiftCard, paymentMethod]);

    useEffect(() => {
      paymentMethod === PaymentMethod.PAYPAL && stepIndex === 0 && setMainBtnLabel('Confirm');
      paymentMethod === PaymentMethod.CARD && !pageTypeIsGiftCard && setMainBtnLabel('Next step');
    }, [paymentMethod]);

    const fetchPurchaseSubscription = async () => {
      try {
        const result = await PaymentService.purchaseSubscription(
          user.email,
          {
            arenaName: environment.ARENA_DOMAIN,
            tokenId: recurlyToken,
            couponCodes,
            giftCardRedemptionCode: giftCardCode,
            planCode: planId,
            captchaToken,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
          }
        );

        handlePurchaseSubscriptionSuccess(result);
      } catch (err) {
        handlePurchaseSubscriptionError(err);
      }
    };
    const handlePurchaseSubscriptionSuccess = (subscriptionId: string) => {
      LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.SUBSCRIPTION_PURCHASE, {
        subscriptionType: getSubscriptionTypeBySubscriptionPlanCode(planCode)
      });
      LeanplumAnalytics.setUserAttributesCustom({ subscriber: true });
      void Analytics.trackEvent(
        Analytics.subscription.subscriptionPurchase(
          subscriptionSource,
          planForAnalytics,
          paymentMethod,
          subscriptionId,
          gameSlugForAnalytics,
          totalSum
        )
      );
      batch(() => {
        clearCaptchaData();
        dispatch(setStepIndex(2));
        setIsLoading(false);
        // SET Subscription
        dispatch(setPreLoadData({ subscription: true }));
      });
      CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION, 'true');
      CookieService.setArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID, subscriptionId, 30);

      AdFreeService.vignetteAdsFix();
    };
    const handlePurchaseSubscriptionError = (err: { Message: string, ErrorCode: number }) => {
      setIsLoading(false);

      // GIFT CARD HAS ALREADY BEEN REDEEMED
      if (err.ErrorCode === 2003) {
        setCheckoutPricing((previous: any) => ({ ...previous, giftCard: '' }));
        dispatch(setStepIndex(0));
      }

      dispatch(setShowRecaptcha(err.ErrorCode === 1010));
      // ANOTHER ERROR
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: err.Message,
          type: 'error'
        })
      );
      AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
    };
    const fetchPurchaseItem = async () => {
      const itemCode = chooseGemsPacksPlan(UserService.isUserSubscriber())[chosenGemsPackItemName].plan;

      try {
        await PaymentService.purchaseItem({
            arenaName: environment.ARENA_DOMAIN,
            itemCode,
            tokenId: recurlyToken,
            quantity: 1,
            couponCodes,
            gameKey: undefined,
            captchaToken,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
          }
        );
        void handlePurchaseItemSuccess();
      } catch (err) {
        handlePurchaseItemError(err);
      }
    };
    const fetchGetUserGemsAmount = async () => {
      try {
        const result = await GemsService.getUserGemsAmount();

        handleGetUserGemsAmountSuccess(result);
      } catch (err) {
        handleGetUserGemsAmountError(err);
      }
    };
    const handleGetUserGemsAmountSuccess = (gemsAmount: number) => {
      batch(() => {
        dispatch(setStepIndex(2));
        setIsLoading(false);
      });

      if (gemsAmount > initialGemsAmount) {
        PurchaseNewTabHelper.dispatchPurchaseDone();
        lsSetGamePurchaseRequest('success');
        GRM.setGamePurchaseRequestResult(true, true);
      }
    };
    const handleGetUserGemsAmountError = (err: HttpError) => {
      AppInsightService.trackAppError(err, { data: 'getUserGemsAmount()' });
      lsSetGamePurchaseRequest('fail');
      GRM.setGamePurchaseRequestResult(false, true);
      batch(() => {
        dispatch(
          setSnackbarData({
            isOpened: true,
            message: err.body.details[0].Message,
            type: 'error'
          })
        );
        setIsLoading(false);
      });
    };
    const handlePurchaseItemSuccess = async () => {
      const { gemsPackId, gemsInPack, priceInGem } = gemsAnalyticsProps;

      void LeanplumGemsTrack.virtualItemPurchaseSuccess({
        purchasableItemId: gemsPackId,
        purchasableItemAmount: gemsInPack,
        shopLocation
      });
      void Analytics.trackEvent(
        Analytics.gems.gemPurchaseSuccess(
          priceInGem,
          gemsPackId,
          gemsInPack,
          gemsShopLocation,
          gameId
        )
      );
      clearCaptchaData();
      setIsLoading(false);
      void fetchGetUserGemsAmount();
    };
    const handlePurchaseItemError = (err: { Message: string, ErrorCode: number }) => {
      AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
      lsSetGamePurchaseRequest('fail');
      GRM.setGamePurchaseRequestResult(false, true);
      batch(() => {
        setIsLoading(false);
        dispatch(setShowRecaptcha(err.ErrorCode === 1010));
        dispatch(
          setSnackbarData({
            isOpened: true,
            message: err.Message,
            type: 'error'
          })
        );
      });
    };

    useEffect(() => {
      if (
        captchaToken &&
        (userProcessed || paymentType === PaymentType.gems) &&
        user &&
        subscriptionStep2Content.type === SubscriptionStep2Content.PurchaseDetails
      ) {
        if (captchaToken && paymentType === PaymentType.subscription) {
          void fetchPurchaseSubscription();
        } else if (captchaToken && paymentType == PaymentType.gems) {
          void fetchPurchaseItem();
        }
      }
    }, [captchaToken]);

    const handleSubmit = async () => {
      if (stepIndex === 0) {
        if (paymentMethod === PaymentMethod.CARD) {
          // PaymentForm.tsx handleSubmit trigger
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }

        if (paymentMethod === PaymentMethod.GIFT_CARD) {
          // GiftCardForm.tsx handleSubmit trigger
          void Analytics.trackEvent(
            Analytics.subscription.giftNextStep(subscriptionSource, planForAnalytics, paymentMethod)
          );
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }

        if (paymentMethod === PaymentMethod.PAYPAL) {
          paypal.start();
          pageTypeIsGiftCard && void Analytics.trackEvent(Analytics.subscription.gifterPayPalConfirm());
        }

        // Gems Analytics
        if (paymentType === PaymentType.gems && !window.sessionStorage.getItem(LS_SENT_AI_GEMS_NEXT_STEP)) {
          const { gemsPackId, gemsInPack, priceInGem } = gemsAnalyticsProps;

          void LeanplumGemsTrack.virtualItemPurchaseNextStep({
            purchasableItemId: gemsPackId,
            purchasableItemAmount: gemsInPack,
            shopLocation
          });
          void Analytics.trackEvent(
            Analytics.gems.gemPurchaseNextStepClick(priceInGem, gemsPackId, gemsInPack, gemsShopLocation)
          );
        }
      }

      if (stepIndex === 1) {
        if (
          userProcessed &&
          !user &&
          subscriptionStep2Content.type === SubscriptionStep2Content.PurchaseDetails
        ) {
          dispatch(setSubscriptionStep2Content({ type: SubscriptionStep2Content.AuthForm, step: 1 }));
        }

        if (
          !userProcessed &&
          !user &&
          subscriptionStep2Content.type === SubscriptionStep2Content.PurchaseDetails
        ) {
          dispatch(setSubscriptionStep2Content({ type: SubscriptionStep2Content.AuthForm, step: 2 }));
        }

        if (subscriptionStep2Content.type === SubscriptionStep2Content.AuthForm) {
          // AuthForm.tsx handleSubmit trigger
          if (paymentMethod === PaymentMethod.GIFT_CARD) {
            void Analytics.trackEvent(
              Analytics.subscription.giftEmailCheck(subscriptionSource, planForAnalytics, paymentMethod)
            );
          }

          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }

        if (
          paymentMethod === PaymentMethod.GIFT_CARD &&
          subscriptionStep2Content.type === SubscriptionStep2Content.PurchaseDetails
        ) {
          void Analytics.trackEvent(
            Analytics.subscription.giftPurchaseButton(subscriptionSource, planForAnalytics, paymentMethod)
          );
        }

        if (
          (userProcessed || paymentType === PaymentType.gems) &&
          user &&
          subscriptionStep2Content.type === SubscriptionStep2Content.PurchaseDetails
        ) {
          if (paymentType === PaymentType.subscription) {
            void Analytics.trackEvent(
              Analytics.subscription.subscriptionPurchaseButton(
                subscriptionSource,
                planForAnalytics,
                paymentMethod
              )
            );
            setIsLoading(true);
            getCaptchaToken(RECAPTCHA_ACTIONS.PurchaseSubscriptionByEmail);
          } else if (paymentType == PaymentType.gems) {
            setIsLoading(true);
            getCaptchaToken(RECAPTCHA_ACTIONS.PurchaseItem);
          }
        }
      }
    };

    return (
      <div>
        <Button
          pseudoDisabled={stepIndex === 0 && paymentMethod === PaymentMethod.CARD && !isValidForm}
          loading={isLoading}
          disabled={(!isValidForm || isLoading) && paymentMethod !== PaymentMethod.PAYPAL}
          onClick={handleSubmit}
          className={styles.nextStepBtn}
        >
          {mainBtnLabel}
        </Button>
      </div>
    );
  }
);

export default SubmitButton;
