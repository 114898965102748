import React from 'react';

import classNames from 'classnames';

import { StringUtils } from '../../../utils/StringUtils';
import { Button } from '../../atoms/Buttons/Base/Base';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { SearchSmallIcon } from '../../atoms/Icons/Styleguide/SearchSmallIcon';
import styles from './SearchDropDown.css';

export type SearchDropDownItemWrapper = {
    searchField: string;
    element: any;
    metaData: any;
};

export type SearchDropDownProps = {
    id?: string;
    searchItems: SearchDropDownItemWrapper[];
    preSelectedItem: SearchDropDownItemWrapper;
    onSelectedItemChange: (item: SearchDropDownItemWrapper) => void;
};

type SearchDropDownState = {
    selectedItem: SearchDropDownItemWrapper;
    listShow: boolean;
    selectedElementClicked: boolean;
    searchText: string;
    filteredItems: SearchDropDownItemWrapper[];
};

export class SearchDropDown extends React.Component<SearchDropDownProps, SearchDropDownState> {
    readonly state = {
        selectedItem: null,
        listShow: false,
        selectedElementClicked: false,
        searchText: '',
        // TODO: why we need this here?
        // UPDATE: this component will be replaced later,
        // so for now no need to change its logic
        filteredItems: this.props.searchItems,
    };

    private listRef = React.createRef<HTMLDivElement>();

    private searchIconRef = React.createRef<HTMLButtonElement>();

    private clearIconRef = React.createRef<HTMLButtonElement>();

    private inputRef = React.createRef<HTMLInputElement>();

    componentDidMount() {
        if (this.props.preSelectedItem) {
            this.setState({ selectedItem: this.props.preSelectedItem });
        }
        window.document.addEventListener('click', this.handleDocumentClick);
    }

    componentDidUpdate(prevProps: SearchDropDownProps) {
        if (prevProps.preSelectedItem !== this.props.preSelectedItem) {
            this.setState({ selectedItem: this.props.preSelectedItem });
        }
    }

    componentWillUnmount() {
        window.document.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick = (e) => {
        if (this.state.selectedElementClicked) {
            this.setState({ selectedElementClicked: false });
            return;
        }

        const list = this.listRef?.current;
        const searchIcon = this.searchIconRef?.current;
        const clearIcon = this.clearIconRef?.current;
        const textbox = this.inputRef?.current;

        const clickOnSearch = searchIcon && (e.target === searchIcon || searchIcon.contains(e.target));
        const clickOnClear = clearIcon && (e.target === clearIcon || clearIcon.contains(e.target));
        const clickOnList = list && (e.target === list || list.contains(e.target));
        const clickOnTextBox = textbox && (e.target === textbox || textbox.contains(e.target));

        const clickOutside = !clickOnSearch && !clickOnList && !clickOnTextBox && !clickOnClear;

        if (clickOutside) {
            this.setState({ listShow: false });
            this.setState({ searchText: '' });
        }
    };

    handleSearchTextChange = (event) => {
        const text = event.target.value;
        this.setState({ searchText: text });
        this.setState({ listShow: true });
        this.filter(text);
    };

    handleSearchTextClick = () => {
        this.setState({ listShow: true });
        this.inputRef.current.focus();
    };

    handleListItemClick = (event, item: SearchDropDownItemWrapper) => {
        this.setState({ selectedItem: item });
        this.setState({ searchText: '' });
        this.props.onSelectedItemChange(item);
        this.setState({ listShow: false });
    };

    handleClearIconClick = () => {
        if (this.inputRef.current) {
            this.inputRef.current.blur();
        }
        this.clearText();
    };

    search(text: string) {
        if (!text) {
            return this.props.searchItems;
        }
        return this.props.searchItems.filter((s) => StringUtils.containsIgnoreCase(s.searchField, text));
    }

    filter(text: string) {
        const filtered = this.search(text);
        this.setState({ filteredItems: filtered });
    }

    clearText() {
        this.setState({ searchText: '' });
    }

    render() {
        const { selectedItem, listShow, searchText, filteredItems } = this.state;

        return (
            <>
                <div className={styles.inputWrapper}>
                    {!listShow && selectedItem && !searchText && (
                        <Button
                            className={styles.selectedItemWrapper}
                            onClick={() => {
                                this.setState({ selectedElementClicked: true });
                                this.handleSearchTextClick();
                            }}
                            noPadding
                        >
                            {selectedItem.element}
                        </Button>
                    )}
                    <button ref={this.searchIconRef} className={styles.searchIcon} onClick={this.handleSearchTextClick}>
                        <SearchSmallIcon />
                    </button>

                    <input
                        id={this.props.id}
                        type="text"
                        autoComplete="new-password"
                        ref={this.inputRef}
                        maxLength={30}
                        className={classNames(styles.input)}
                        placeholder={!selectedItem ? 'Enter you country here' : ''}
                        value={searchText}
                        onChange={this.handleSearchTextChange}
                        onFocus={this.handleSearchTextChange}
                        onClick={this.handleSearchTextClick}
                    />
                    {searchText && (
                        <button
                            ref={this.clearIconRef}
                            className={styles.clearIcon}
                            onClick={this.handleClearIconClick}
                        >
                            <CloseIcon />
                        </button>
                    )}

                    {listShow &&
                        (filteredItems.length > 0 ? (
                            <div className={styles.listWrapper} ref={this.listRef}>
                                <ul>
                                    {filteredItems.map((item) => (
                                        <li key={item.searchField}>
                                            <Button
                                                onClick={(e) => {
                                                    this.handleListItemClick(e, item);
                                                }}
                                                fullWidth
                                                noPadding
                                            >
                                                {item.element}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div className={styles.listWrapper}>
                                <div className={styles.noResultsWrapper}>
                                    <I18nText className={styles.noResultsText} keyName="NOT_FOUND_COUNTRY" />
                                </div>
                            </div>
                        ))}
                </div>
            </>
        );
    }
}
