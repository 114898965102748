import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './CountryEditor.css';
import { StringUtils } from '../../../../../utils/StringUtils';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import CountriesList from '../../../../constants/CountriesList';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { PublicUserModel, UserModel } from '../../../../models/User/UserModel';
import { SearchDropDown, SearchDropDownItemWrapper } from '../../../../molecules/SearchDropDown/SearchDropDown';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import { FieldEdit } from '../../EditorTemplates/FieldEdit/FieldEdit';
import { FieldView } from '../../EditorTemplates/FieldView/FieldView';

export type CountryEditorProps = {
  inEdit: boolean;
  setInEdit: any;
  user: UserModel | PublicUserModel;
  showTitleAsValue?: boolean;
  updateUser: (updatedUser: { countryId: string }) => void;
  rowContentClassName: string;
  rowEditContentClassName: string;
  captionClassName: string;
  openPanel: (panel: ProfileRightSideRoutes) => void;
  hover?: boolean;
  viewTitle: string;
};

const COUNTRY_EDITOR_INPUT_ID = 'country-name-input';

export const CountryEditor = (props: CountryEditorProps) => {
  const { inEdit, setInEdit, user, updateUser, hover } = props;
  const [selectedCountry, setSelectedCountry] = useState<SearchDropDownItemWrapper>(findCountry(user.countryId));
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedCountry(findCountry(user.countryId));
  }, [props.user.countryId]);

  const updateCountry = async () => {
    const updatedUser = {
      countryId: selectedCountry.metaData.countryCode
    };

    updateUser(updatedUser);
    setIsLoading(false);
  };
  const onSelectedItemChange = (item: SearchDropDownItemWrapper) => {
    if (selectedCountry !== item) {
      setSaveButtonEnabled(true);
      setSelectedCountry(item);
    } else {
      setSaveButtonEnabled(false);
    }
  };
  const onCancelButtonClick = () => {
    setInEdit(false);
    setSelectedCountry(findCountry(user.countryId));
    setSaveButtonEnabled(false);
  };
  const onSaveButtonClick = async () => {
    setIsLoading(true);
    setInEdit(false);
    setSaveButtonEnabled(false);
    await updateCountry();
  };
  const viewComponent = () => {
    if (!selectedCountry) {
      return props.showTitleAsValue ? (
        <>
          <Responsive maxWidth={1024}>
            <span className={styles.emptyCountry}>{TranslationService.translateIt(props.viewTitle)}</span>
          </Responsive>
        </>
      ) : (
        <span/>
      );
    }

    return (
      <CountryCodeBox
        caption={countriesMap.get(selectedCountry.metaData.countryCode)}
        imgUrl={UrlService.toCDNUrl(
          `/icons/flags-icons/${selectedCountry.metaData.countryCode.toLowerCase()}.png`
        )}
        classNameProp={styles.viewField}
        isHovered={hover}
      />
    );
  };
  const editComponent = () => {
    return (
      <SearchDropDown
        id={COUNTRY_EDITOR_INPUT_ID}
        searchItems={countries}
        preSelectedItem={selectedCountry}
        onSelectedItemChange={onSelectedItemChange}
      />
    );
  };

  return (
    <>
      <div className={classNames(styles.fields, { [styles.visible]: !inEdit }, { [styles.notVisible]: inEdit })}>
        <FieldView
          title={TranslationService.translateIt(props.viewTitle)}
          onEditClick={() => setInEdit(true)}
          onMobileEditClick={() => props.openPanel(ProfileRightSideRoutes.COUNTRY_PICKER_PANEL)}
          renderViewComponent={viewComponent}
          viewComponentValue={selectedCountry ? selectedCountry.metaData.countryCode : undefined}
          hover={hover}
        />
      </div>
      <div className={classNames(styles.fields, { [styles.notVisible]: !inEdit }, { [styles.visible]: inEdit })}>
        <FieldEdit
          htmlFor={COUNTRY_EDITOR_INPUT_ID}
          title={TranslationService.translateIt('COUNTRY_EDITOR_CAPTION')}
          cancelField={() => onCancelButtonClick()}
          renderEditComponent={editComponent}
          saveField={async () => onSaveButtonClick()}
          saveButtonDisabled={!saveButtonEnabled}
          isLoading={isLoading}
          lastRowStyle
        />
      </div>
    </>
  );
};

type CountryCodeBoxProps = {
  classNameProp?: string;
  imgUrl: string;
  caption: string;
  isHovered?: boolean;
};

const CountryCodeBox = (props: CountryCodeBoxProps) => {
  return (
    <div
      className={classNames(
        styles.countryBox,
        { [styles.viewField]: props.classNameProp },
        { [styles.hovered]: props.isHovered }
      )}
    >
      <img alt={props.caption} key={props.imgUrl} src={props.imgUrl} className={styles.countryImg}/>
      <span className={styles.countryLabel}>{props.caption}</span>
    </div>
  );
};
const countriesMap: Map<string, string> = new Map<string, string>();

CountriesList.forEach((item) => {
  countriesMap.set(item.id, item.name);
});

const getCountryBox = (countryCode: string): JSX.Element => {
  return (
    <div className={styles.countryBoxItemWrapper}>
      <CountryCodeBox
        caption={countriesMap.get(countryCode)}
        imgUrl={UrlService.toCDNUrl(`/icons/flags-icons/${countryCode.toLowerCase()}.png`)}
      />
    </div>
  );
};
const countries: SearchDropDownItemWrapper[] = [];

CountriesList.forEach((item) => {
  countries.push({
    searchField: countriesMap.get(item.id),
    element: getCountryBox(item.id),
    metaData: { countryCode: item.id }
  });
});

function findCountry(code: string): SearchDropDownItemWrapper {
  if (code) {
    return countries.find((c) => StringUtils.equalIgnoreCase(c.metaData.countryCode, code));
  }

  return null;
}
