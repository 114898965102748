import React from 'react';

import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { CircleWithIcon } from '../../../../molecules/CircleWIthIcon/CircleWithIcon';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import { TranslationService } from '../../../../services/TranslationService';
import { UrlService } from '../../../../services/UrlService';
import styles from './FinalScreen.css';

export const FinalScreen = React.memo((props: any) => {
    const { colorFilled, circleSize, colorEmpty } = props;

    return (
        <div className={styles.finalScreen} data-testid="final-screen">
            <div className={styles.finalMessageCircle}>
                <CircleWithIcon
                    backgroundColor={colorFilled}
                    size={circleSize}
                    innerObjectColor={colorEmpty}
                    innerObject=""
                />
            </div>
            <div className={styles.finalMessageText}>
                {TranslationService.translateIt('PROFILE_COMPLETE_WHOS_BETTER_THAN_YOU')}
            </div>
            <div className={styles.button}>
                <BorderedButton
                    caption={TranslationService.translateIt('TAKE_ME_TO_THE_GAMES')}
                    onClick={() => {
                        ProfileProcessService.finishProcess();
                        window.location.href = UrlService.createURL(`/`);
                    }}
                    filled
                />
            </div>
        </div>
    );
});
