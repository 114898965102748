import * as yup from 'yup';

const commonValidationString = 'is a required field';

export const validationSchema = yup.object().shape({
  first_name: yup.string().required(`First name ${commonValidationString}`).min(1, '1 test'),
  last_name: yup.string().required(`Last name ${commonValidationString}`),
  address1: yup.string().required(`Address ${commonValidationString}`),
  city: yup.string().required(`City ${commonValidationString}`),
  country: yup.string().required(`Country ${commonValidationString}`),
  postal_code: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  // credit data (recurly iframes)
  cardNumber: yup.object({
    creditBrand: yup.string().required('Please enter a valid credit card number'),
    creditLastFour: yup.string().required('Please enter a valid credit card number')
  }),
  year: yup.boolean().isTrue('Please enter a valid date'),
  month: yup.boolean().isTrue('Please enter a valid date'),
  cvv: yup.boolean().isTrue('Please enter valid code')
});
export const defaultValues = {
  first_name: '',
  last_name: '',
  address1: '',
  city: '',
  country: '',
  postal_code: '',
  state: '',
  cardNumber: {
    creditBrand: '',
    creditLastFour: ''
  },
  year: false,
  month: false,
  cvv: false
};
