import React, { useEffect, useState, forwardRef, memo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Input } from '../../FigmaStyleguide/Input/Input';
import { setSubscriptionStep2Content } from '../../store/ducks/subscription/common';
import { SubscriptionStep2Content } from '../../store/ducks/subscription/common';
import styles from './PaymentForm.css';

type GiftCardFormProps = {
    giftCardCode: string;
    setGiftCardCode: (v) => void;
    recurlyPricingError: any; // recurly obj
    setCheckoutPricing: any; // recurly func
};

export const GiftCardForm = memo(
    forwardRef<HTMLFormElement, GiftCardFormProps>(
        ({ giftCardCode, setGiftCardCode, recurlyPricingError, setCheckoutPricing }, ref) => {
            const dispatch = useDispatch();

            const isValidForm = useSelector((state) => state.isValidForm);

            const [isValid, setIsValid] = useState(true);

            const GIFT_CARD_ERROR_MESSAGE = `Sorry, this code isn't valid`;
            const GIFT_CARD_INPUT_LABEL = 'Gift card ID';

            useEffect(() => {
                // hardcoded because recurly does not have error_code separation, so you can only find out from the message
                recurlyPricingError?.message.toLocaleLowerCase().includes('gift card') && setIsValid(false);
            }, [recurlyPricingError]);

            const handleSubmit = (e) => {
                e.preventDefault();
                if (isValidForm) {
                    dispatch(setSubscriptionStep2Content({ type: SubscriptionStep2Content.PurchaseDetails, step: 1 }));
                    setCheckoutPricing((previous) => ({
                        ...(previous as any),
                        giftCard: giftCardCode,
                    }));
                }
            };

            return (
                <form ref={ref} onSubmit={handleSubmit}>
                    <div className={styles.inputWrapper}>
                        <p className={styles.inputName}>{GIFT_CARD_INPUT_LABEL}</p>
                        <Input
                            maxWidth={420}
                            fullWidth
                            value={giftCardCode}
                            onChange={(e) => {
                                setGiftCardCode(e.target.value);
                                giftCardCode.length === 16 && setIsValid(true);
                            }}
                            clickOnCloseIcon={() => setGiftCardCode('')}
                            placeholder={'XXXXXXXX'}
                            isValid={isValid}
                            errorMessage={GIFT_CARD_ERROR_MESSAGE}
                            maxLength={16}
                            dataRecurly={'gift_card'}
                        />
                    </div>
                </form>
            );
        }
    )
);
