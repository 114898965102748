import React from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './HomeTemplate.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { StringUtils } from '../../../utils/StringUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Responsive } from '../../atoms/Layout/Responsive';
import SwipeSlider from '../../atoms/SwipeSlider/SwipeSlider';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { PageTypes } from '../../constants/Pages';
import { IGame } from '../../models/Game/Game';
import { CategorySection, GamesListFn } from '../../models/GamesList';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../models/PagesData';
import { Ad, AdTypes, CaptionPositionTypes } from '../../molecules/Ad/Ad';
import { AdDividerPromoCard } from '../../molecules/Cards/AdDividerPromoCard/AdDividerPromoCard';
import { SeoCard, SeoCardIllustration } from '../../molecules/Cards/SeoCard/SeoCard';
import { GameCard, GameCardTypes } from '../../molecules/GameCard/GameCard';
import HomepageSlider from '../../molecules/HomepageSlider/HomepageSlider';
import { PromoCard } from '../../molecules/PromoCard/PromoCard';
import { HomepageCarousel } from '../../organisms/HomepageCarousel/HomepageCarousel';
import { Analytics } from '../../services/Analytics/Analytics';
import { DeviceType } from '../../services/DeviceDetector';
import { GameService } from '../../services/GameService';
import { PageService } from '../../services/PageService';
import { PWAService } from '../../services/PWAService';
import { UrlService } from '../../services/UrlService';
import { StrapiPromoType } from '../../store/ducks/categoryPromo/categoryPromo';


interface HomeProps extends RouteComponentProps {
    games: IGame[];
    currentLang: string;
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    homepageCategoriesList: CategorySection[];
    deviceType: DeviceType;
    strapiPromoData?: StrapiPromoType;
}

// I will remove it later, still need the references
const SEO_SETTINGS = [
    {
        category: 'solitaire',
        imageType: SeoCardIllustration.FIRST,
        buttonCaption: 'HOME_SEO_SOLITAIER_BUTTON',
        text: 'HOME_SEO_SOLITAIER_TEXT',
    },
    {
        category: 'mahjong',
        imageType: SeoCardIllustration.SECOND,
        buttonCaption: 'HOME_SEO_MAHJONG_BUTTON',
        text: 'HOME_SEO_MAHJONG_TEXT',
    },
    {
        category: 'puzzles',
        imageType: SeoCardIllustration.THIRD,
        buttonCaption: 'HOME_SEO_PUZZLE_BUTTON',
        text: 'HOME_SEO_PUZZLE_TEXT',
    },
];

export class HomeTemplateBase extends React.PureComponent<HomeProps> {
    seoRef = React.createRef<HTMLDivElement>();
    page = React.createRef<HTMLDivElement>();

    alreadyTracked: { [key: string]: boolean } = {};

    categoriesList: CategorySection[] = this.props.homepageCategoriesList.map((i) => ({
        ...i,
        gamesListFn: ((gamesList: IGame[]) =>
            i.gameSlugs
                .filter((slug) => gamesList.find((game) => game.slug === slug))
                .map((slug) => gamesList.find((game) => game.slug === slug))) as GamesListFn,
    }));

    getCategorySectionByName(name: string): CategorySection {
        return this.categoriesList.find((s) => s.key.toLowerCase() === name.toLowerCase());
    }

    state = { hydrateRerender: 0 };

    trackVisibleElements = () => {
        const html = document.body.parentElement;

        if (!this.alreadyTracked.seo) {
            const el = this.seoRef.current;

            if (el) {
                const elR = el.getBoundingClientRect();

                if (elR.y + elR.height * 0.5 <= html.clientHeight) {
                    this.alreadyTracked.seo = true;
                    Analytics.trackEvent(Analytics.newHome.seoImpression());
                }
            }
        }

        const pageEl = this.page.current;

        if (!pageEl) {
            return;
        }

        let rows: Element[];
        let dataTheme = 'data-right-rail-theme';

        if (html.clientWidth < 1200) {
            rows = Array.from(pageEl.querySelectorAll(`[data-theme], [data-mobile-theme]`));
            dataTheme = 'data-mobile-theme';
        } else {
            rows = Array.from(pageEl.querySelectorAll(`.${styles.row}`));
        }

        rows.forEach((item, ind) => {
            if (this.alreadyTracked[`row_${ind}`]) {
                return;
            }

            const rect = item.getBoundingClientRect();

            if (rect.y + rect.height * 0.5 <= html.clientHeight) {
                this.alreadyTracked[`row_${ind}`] = true;

                if (item.hasAttribute('data-theme')) {
                    Analytics.trackEvent(
                        Analytics.newHome.promoboxImpression(
                            item.getAttribute('data-theme'),
                            `${item.getAttribute('data-size')}|Body|${ind + 1}`
                        )
                    );
                }

                if (item.hasAttribute(dataTheme)) {
                    const promoTarget = item.getAttribute(dataTheme);
                    const position = parseInt(item.getAttribute('data-right-rail-position'), 10);
                    let promoType = `Ad unit|RightRail|${position}`;

                    if (html.clientWidth < 1200) {
                        promoType = `Ad unit|Body|${ind + 1}`;
                    }

                    Analytics.trackEvent(Analytics.newHome.promoboxImpression(promoTarget, promoType));
                }
            }
        });
    };

    selectLayout = (desktopMobile, tablet, mobile?) => {
        const html = document.body.parentElement;

        if (
            html.clientWidth > ArkCssBreakpoints.ARK_LARGE_MOBILE_BELOW &&
            html.clientWidth < ArkCssBreakpoints.ARK_MEDIUM_DESKTOP
        ) {
            return tablet;
        }

        if (html.clientWidth < ArkCssBreakpoints.ARK_LARGE_MOBILE) {
            if (mobile) {
                return mobile;
            }
        }

        return desktopMobile;
    };

    componentDidMount() {
        window.addEventListener('scroll', this.trackVisibleElements);
        this.trackVisibleElements();
        PWAService.getUserVisitPWA(undefined, undefined, true);
        this.setState({ hydrateRerender: 1 });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.trackVisibleElements);
    }

    getLocalizedLink(categorySlug: string) {
        return `${UrlService.getPageLocalizedRoute(this.props.currentLang, PageTypes.Category)}/${
            PageService.getPageSEOByPageName(this.props.pages, categorySlug).slug[this.props.currentLang]
        }/`;
    }

    openSeeAll(categorySlug: string) {
        const link = this.getLocalizedLink(categorySlug);

        this.props.history.push(link);
    }

    getGameBySlug(slug): IGame {
        return this.props.games.find((g) => StringUtils.equalIgnoreCase(g.alias, slug));
    }

    getGameImage = (url) => {
        const game = this.getGameBySlug(UrlService.getSlugFromFullPath(url));

        if (!game) {
            return undefined;
        }

        const { promoTile1000x312, promoTile1000x312webp } = game;

        return { promoTile1000x312, promoTile1000x312webp };
    };

    render() {
        const { games, deviceType, currentLang, pages, history } = this.props;
        const { strapiPromoData } = this.props;
        const gamesFiltered = GameService.filterGamesByLang(games, currentLang);
        // predefined games
        const blockChampGame: IGame = games.find((g) => StringUtils.equalIgnoreCase(g.alias, 'block-champ'));
        const strategyGames = ['chess', '5roll', 'mahjong-remix', 'sweet-shuffle'].map((slug) =>
            GameService.findGameByArena5Slug(games, slug)
        );


        // I will remove it later, still need the references
        //const promoBlockImg = UrlService.toCDNUrl('/illustrations/promo-card/stan-homepage.jpg');
        // const promoBlockImg = UrlService.toCDNUrl(strapiPromoData.contentSpotlight.contentSpotlight1.image.data.attributes.url);
        // const promoBlockImgwebp = UrlService.toCDNUrl('/illustrations/promo-card/stan-homepage.webp');

        // eslint-disable-next-line padding-line-between-statements
        const contentCollectionArray =
            strapiPromoData?.contentCollection && Object.values(strapiPromoData?.contentCollection);

        return (
            <>
                <HomepageCarousel />
                <main className={styles.main}>
                    <div className={styles.content} ref={this.page} key={this.state.hydrateRerender}>
                        {this.getCategorySectionByName('Arkadium fan favorites')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <>
                                {deviceType !== DeviceType.MOBILE && (
                                    <div
                                        className={classNames(styles.row, styles.visibleGrid)}
                                        data-size="Medium"
                                        data-theme="Arkadium Fan Favorites"
                                        data-position="1"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName(
                                                    'Arkadium fan favorites'
                                                ).gamesListFn(games)}
                                                gameCardType={GameCardTypes.MEDIUM}
                                                promoboxCategory="Arkadium Fan Favorites"
                                                promoBoxPosition="1"
                                                caption={this.getCategorySectionByName('Arkadium fan favorites').name}
                                                headerLink={this.getLocalizedLink('best')}
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Arkadium fan favorites',
                                                            `Body`,
                                                            '2',
                                                            'Arkadium fan favorites'
                                                        )
                                                    );
                                                    this.openSeeAll('best');
                                                }}
                                            />
                                        </div>
                                        <div className={styles.side}>
                                            <Responsive minWidth={1200}>
                                                <Ad
                                                    id="ark_display_home_r1"
                                                    hasBackground={false}
                                                    captionPosition={CaptionPositionTypes.CENTER}
                                                    adType={[
                                                        AdTypes.AD_336x280,
                                                        AdTypes.AD_300x250,
                                                        AdTypes.AD_250x250,
                                                    ]}
                                                />
                                            </Responsive>
                                        </div>
                                    </div>
                                )}

                                {deviceType === DeviceType.MOBILE && (
                                    <div
                                        className={classNames(styles.row, styles.visibleGrid)}
                                        data-size="Small"
                                        data-theme="Arkadium Fan Favorites"
                                        data-position="1"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName(
                                                    'Arkadium fan favorites'
                                                ).gamesListFn(games)}
                                                gameCardType={GameCardTypes.SMALL}
                                                promoboxCategory="Arkadium Fan Favorites"
                                                promoBoxPosition="1"
                                                caption={this.getCategorySectionByName('Arkadium fan favorites').name}
                                                headerLink={this.getLocalizedLink('best')}
                                            />
                                        </div>
                                        <div className={styles.side} />
                                    </div>
                                )}
                            </>
                        )}
                        {GameService.getNewCategoryGames(games).length > 0 && !!this.state.hydrateRerender && (
                            <div
                                className={styles.row}
                                data-size="Large"
                                data-theme="New Game Releases"
                                data-right-rail-theme="support"
                                data-right-rail-position="0"
                                data-position="2"
                            >
                                <div className={styles.rowContent}>
                                    <HomepageSlider
                                        items={GameService.getNewCategoryGames(games)}
                                        gameCardType={GameCardTypes.LARGE}
                                        promoboxCategory="New Game Releases"
                                        promoBoxPosition="2"
                                        caption={this.getCategorySectionByName('New Game Releases').name}
                                        onSeeAllClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'New Game Releases',
                                                    `Body`,
                                                    '2',
                                                    'New Game Releases'
                                                )
                                            );
                                            this.openSeeAll('New');
                                        }}
                                        headerLink={this.getLocalizedLink('new')}
                                        pseudoTile
                                    />
                                </div>
                                {currentLang === 'fr' ? (
                                    <div className={styles.sideCenter}>
                                        <AdDividerPromoCard
                                            newWindow
                                            url="https://support.arkadium.com/en/support/home"
                                            imagePath={UrlService.toCDNUrl(
                                                '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-help.png'
                                            )}
                                            caption="AD_DIVIDER_HELP_CAPTION"
                                            description="AD_DIVIDER_HELP_TEXT"
                                            buttonCaption="SEE_MORE"
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                    Analytics.newHome.promoboxSlotClick(
                                                        'support',
                                                        `Ad unit|RightRail`,
                                                        '0'
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.sideCenter}>
                                        {strapiPromoData?.contentRail?.contentRail1 &&
                                            strapiPromoData?.contentRail?.contentRail1?.button?.url &&
                                            strapiPromoData?.contentRail?.contentRail1?.image?.url && (
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        strapiPromoData?.contentRail?.contentRail1?.button?.url
                                                    )}
                                                    imagePath={strapiPromoData?.contentRail?.contentRail1?.image?.url}
                                                    caption={strapiPromoData?.contentRail?.contentRail1?.title}
                                                    description={strapiPromoData?.contentRail?.contentRail1?.text}
                                                    buttonCaption={
                                                        strapiPromoData?.contentRail?.contentRail1?.button?.label
                                                    }
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                strapiPromoData?.contentRail?.contentRail1
                                                                    ?.promoBoxEvent,
                                                                `Ad unit|RightRail`,
                                                                '0'
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        )}
                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m1"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m1"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)} data-mobile-theme="support">
                                <Ad
                                    id="ark_display_home_tab1"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                                {currentLang === 'fr' ? (
                                    <div className={styles.mobileAdDivider}>
                                        <AdDividerPromoCard
                                            newWindow
                                            url="https://support.arkadium.com/en/support/home"
                                            imagePath={UrlService.toCDNUrl(
                                                '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-help.png'
                                            )}
                                            caption="AD_DIVIDER_HELP_CAPTION"
                                            description="AD_DIVIDER_HELP_TEXT"
                                            buttonCaption="SEE_MORE"
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                    Analytics.newHome.promoboxSlotClick('support', `Ad unit|body`, '3')
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.mobileAdDivider}>
                                        {strapiPromoData?.contentRail?.contentRail1 &&
                                            strapiPromoData?.contentRail?.contentRail1?.button?.url &&
                                            strapiPromoData?.contentRail?.contentRail1?.image?.url && (
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        strapiPromoData?.contentRail?.contentRail1?.button?.url
                                                    )}
                                                    imagePath={strapiPromoData?.contentRail?.contentRail1?.image?.url}
                                                    caption={strapiPromoData?.contentRail?.contentRail1?.title}
                                                    description={strapiPromoData?.contentRail?.contentRail1?.text}
                                                    buttonCaption={
                                                        strapiPromoData?.contentRail?.contentRail1?.button?.label
                                                    }
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                strapiPromoData?.contentRail?.contentRail1
                                                                    ?.promoBoxEvent,
                                                                `Ad unit|Body`,
                                                                '3'
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </Responsive>
                        {this.getCategorySectionByName('Daily Games')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <>
                                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}>
                                    <div
                                        className={classNames(styles.row, styles.visibleLargeDevices)}
                                        data-theme="Daily Games"
                                        data-size="Medium"
                                        data-position="3"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                items={this.getCategorySectionByName('Daily Games').gamesListFn(games)}
                                                pseudoTile
                                                gameCardType={GameCardTypes.MEDIUM}
                                                promoboxCategory="Daily Games"
                                                promoBoxPosition="3"
                                                caption={this.getCategorySectionByName('Daily Games').name}
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Daily Games',
                                                            `Body`,
                                                            'Daily Games'
                                                        )
                                                    );
                                                    this.openSeeAll('Daily Games');
                                                }}
                                                headerLink={this.getLocalizedLink('Daily Games')}
                                            />
                                        </div>
                                        <div className={styles.side}>
                                            <Responsive minWidth={1200}>
                                                <Ad
                                                    id="ark_display_home_r2"
                                                    hasBackground={false}
                                                    captionPosition={CaptionPositionTypes.CENTER}
                                                    adType={[
                                                        AdTypes.AD_336x280,
                                                        AdTypes.AD_300x250,
                                                        AdTypes.AD_250x250,
                                                    ]}
                                                />
                                            </Responsive>
                                        </div>
                                    </div>
                                </Responsive>

                                <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}>
                                    <div
                                        className={classNames(styles.row, styles.visibleSmallDevices)}
                                        data-theme="Daily Games"
                                        data-size="Small"
                                        data-position="3"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                items={this.getCategorySectionByName('Daily Games').gamesListFn(games)}
                                                gameCardType={GameCardTypes.SMALL}
                                                promoboxCategory="Daily Games"
                                                promoBoxPosition="3"
                                                caption={this.getCategorySectionByName('Daily Games').name}
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Daily Games',
                                                            `Body`,
                                                            'Daily Games'
                                                        )
                                                    );
                                                    this.openSeeAll('Daily Games');
                                                }}
                                                headerLink={this.getLocalizedLink('Daily Games')}
                                            />
                                        </div>
                                        <div className={styles.side} />
                                    </div>
                                </Responsive>
                            </>
                        )}

                        {this.getCategorySectionByName('Solitaire')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <>
                                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}>
                                    <div
                                        className={classNames(styles.row, styles.visibleLargeDevices)}
                                        data-size="Medium"
                                        data-theme="Solitaire"
                                        data-right-rail-theme="solitaireTips"
                                        data-right-rail-position="1"
                                        data-position="4"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Solitaire').gamesListFn(games)}
                                                gameCardType={GameCardTypes.MEDIUM}
                                                promoboxCategory="Solitaire"
                                                promoBoxPosition="4"
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Solitaire',
                                                            `Body`,
                                                            this.selectLayout('4', '5'),
                                                            'Solitaire'
                                                        )
                                                    );
                                                    this.openSeeAll('Solitaire');
                                                }}
                                                caption={this.getCategorySectionByName('Solitaire').name}
                                                headerLink={this.getLocalizedLink('solitaire')}
                                            />
                                        </div>
                                        {currentLang === 'fr' ? (
                                            <div className={styles.sideCenter}>
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url="https://support.arkadium.com/en/support/search?term=Solitaire"
                                                    imagePath={UrlService.toCDNUrl(
                                                        '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-solitaire.png'
                                                    )}
                                                    caption="AD_DIVIDER_SOLITAIRE_CAPTION"
                                                    description="AD_DIVIDER_SOLITAIRE_TEXT"
                                                    buttonCaption="READ_MORE"
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'solitaireTips',
                                                                `Ad unit|Body`,
                                                                '6'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={styles.sideCenter}>
                                                {strapiPromoData?.contentRail?.contentRail2 &&
                                                    strapiPromoData?.contentRail?.contentRail2?.button?.url &&
                                                    strapiPromoData?.contentRail?.contentRail2?.image?.url && (
                                                        <AdDividerPromoCard
                                                            newWindow
                                                            url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                                strapiPromoData?.contentRail?.contentRail2?.button?.url
                                                            )}
                                                            imagePath={
                                                                strapiPromoData?.contentRail?.contentRail2?.image?.url
                                                            }
                                                            caption={strapiPromoData?.contentRail?.contentRail2?.title}
                                                            description={
                                                                strapiPromoData?.contentRail?.contentRail2?.text
                                                            }
                                                            buttonCaption={
                                                                strapiPromoData?.contentRail?.contentRail2?.button
                                                                    ?.label
                                                            }
                                                            onClick={() => {
                                                                Analytics.trackEvent(
                                                                    Analytics.newHome.promoboxSlotClick(
                                                                        strapiPromoData?.contentRail?.contentRail2
                                                                            ?.promoBoxEvent,
                                                                        `Ad unit|Body`,
                                                                        '6'
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </Responsive>

                                <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}>
                                    <div
                                        className={classNames(styles.row, styles.visibleSmallDevices)}
                                        data-size="Small"
                                        data-theme="Solitaire"
                                        data-right-rail-theme="solitaireTips"
                                        data-right-rail-position="1"
                                        data-position="4"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Solitaire').gamesListFn(games)}
                                                gameCardType={GameCardTypes.SMALL}
                                                promoboxCategory="Solitaire"
                                                promoBoxPosition="4"
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Solitaire',
                                                            `Body`,
                                                            this.selectLayout('4', '5'),
                                                            'Solitaire'
                                                        )
                                                    );
                                                    this.openSeeAll('Solitaire');
                                                }}
                                                caption={this.getCategorySectionByName('Solitaire').name}
                                                headerLink={this.getLocalizedLink('solitaire')}
                                            />
                                        </div>
                                        {currentLang === 'fr' ? (
                                            <div className={styles.sideCenter}>
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url="https://support.arkadium.com/en/support/search?term=Solitaire"
                                                    imagePath={UrlService.toCDNUrl(
                                                        '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-solitaire.png'
                                                    )}
                                                    caption="AD_DIVIDER_SOLITAIRE_CAPTION"
                                                    description="AD_DIVIDER_SOLITAIRE_TEXT"
                                                    buttonCaption="READ_MORE"
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'solitaireTips',
                                                                `Ad unit|Body`,
                                                                '6'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={styles.sideCenter}>
                                                {strapiPromoData?.contentRail?.contentRail2 &&
                                                    strapiPromoData?.contentRail?.contentRail2?.button?.url &&
                                                    strapiPromoData?.contentRail?.contentRail2?.image?.url && (
                                                        <AdDividerPromoCard
                                                            newWindow
                                                            url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                                strapiPromoData?.contentRail?.contentRail2?.button?.url
                                                            )}
                                                            imagePath={
                                                                strapiPromoData?.contentRail?.contentRail2?.image?.url
                                                            }
                                                            caption={strapiPromoData?.contentRail?.contentRail2?.title}
                                                            description={
                                                                strapiPromoData?.contentRail?.contentRail2?.text
                                                            }
                                                            buttonCaption={
                                                                strapiPromoData?.contentRail?.contentRail2?.button
                                                                    ?.label
                                                            }
                                                            onClick={() => {
                                                                Analytics.trackEvent(
                                                                    Analytics.newHome.promoboxSlotClick(
                                                                        strapiPromoData?.contentRail?.contentRail2
                                                                            ?.promoBoxEvent,
                                                                        `Ad unit|RightRail`,
                                                                        '1'
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </Responsive>
                            </>
                        )}

                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m2"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m2"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)} data-mobile-theme="solitaireTips">
                                <Ad
                                    id="ark_display_home_tab2"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                                {currentLang === 'fr' ? (
                                    <div className={styles.mobileAdDivider}>
                                        <AdDividerPromoCard
                                            newWindow
                                            url="https://support.arkadium.com/en/support/search?term=Solitaire"
                                            imagePath={UrlService.toCDNUrl(
                                                '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-solitaire.png'
                                            )}
                                            caption="AD_DIVIDER_SOLITAIRE_CAPTION"
                                            description="AD_DIVIDER_SOLITAIRE_TEXT"
                                            buttonCaption="READ_MORE"
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                    Analytics.newHome.promoboxSlotClick(
                                                        'solitaireTips',
                                                        `Ad unit|Body`,
                                                        '6'
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.mobileAdDivider}>
                                        {strapiPromoData?.contentRail?.contentRail2 &&
                                            strapiPromoData?.contentRail?.contentRail2?.button?.url &&
                                            strapiPromoData?.contentRail?.contentRail2?.image?.url && (
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        strapiPromoData?.contentRail?.contentRail2?.button?.url
                                                    )}
                                                    imagePath={strapiPromoData?.contentRail?.contentRail2?.image?.url}
                                                    caption={strapiPromoData?.contentRail?.contentRail2?.title}
                                                    description={strapiPromoData?.contentRail?.contentRail2?.text}
                                                    buttonCaption={
                                                        strapiPromoData?.contentRail?.contentRail2?.button?.label
                                                    }
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                strapiPromoData?.contentRail?.contentRail2
                                                                    ?.promoBoxEvent,
                                                                `Ad unit|Body`,
                                                                '6'
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div
                                className={classNames(styles.row, styles.visibleLargeDevices)}
                                data-size="Medium"
                                data-theme="Strategy"
                                data-position="4"
                            >
                                <div className={styles.rowContent}>
                                    <HomepageSlider
                                        pseudoTile
                                        promoboxCategory="Strategy"
                                        promoBoxPosition="4"
                                        items={strategyGames}
                                        gameCardType={GameCardTypes.MEDIUM}
                                        onSeeAllClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'Strategy',
                                                    `Body`,
                                                    this.selectLayout('5', '7'),
                                                    'Strategy'
                                                )
                                            );
                                            this.openSeeAll('Strategy');
                                        }}
                                        caption="Strategy"
                                        headerLink={this.getLocalizedLink('strategy')}
                                    />
                                </div>
                            </div>
                        </Responsive>

                        <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}>
                            <div
                                className={classNames(styles.row, styles.visibleSmallDevices)}
                                data-size="Small"
                                data-theme="Strategy"
                                data-position="4"
                            >
                                <div className={styles.rowContent}>
                                    <HomepageSlider
                                        pseudoTile
                                        promoboxCategory="Strategy"
                                        promoBoxPosition="4"
                                        items={strategyGames}
                                        gameCardType={GameCardTypes.SMALL}
                                        onSeeAllClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'Strategy',
                                                    this.selectLayout(`Body|5`, `Body|7`),
                                                    'Strategy'
                                                )
                                            );
                                            this.openSeeAll('Strategy');
                                        }}
                                        caption="Strategy"
                                        headerLink={this.getLocalizedLink('strategy')}
                                    />
                                </div>
                            </div>
                        </Responsive>

                        <div
                            className={classNames(styles.row, styles.promoFullscreen)}
                            data-size="Detailed"
                            data-theme={currentLang === 'fr' ? `Block Champ` : `Stan Newman's Crosswords`}
                            data-right-rail-theme="games"
                            data-right-rail-position="2"
                            data-position="5"
                        >
                            <div className={styles.rowContent}>
                                {currentLang === 'fr' ? (
                                    <div className={styles.promoCardContainer}>
                                        <I18nText
                                            as="h2"
                                            className={styles.promoHeading}
                                            keyName="PROMO_CHAMPION_TITLE"
                                        />
                                        <PromoCard
                                            url={UrlService.createURL('games/block-champ/')}
                                            image={blockChampGame?.promoTile1000x312}
                                            imageWebp={blockChampGame?.promoTile1000x312webp}
                                            caption="PROMO_CHAMPION_GAME_CAPTION"
                                            description="PROMO_CHAMPION_GAME_DESCRIPTION"
                                            buttonCaption="PLAY_NOW"
                                            promoCardClassName={styles.promoDividerClassName}
                                            onButtonClick={() => {
                                                Analytics.trackEvent(
                                                    Analytics.newHome.promoboxSlotClick(
                                                        'Block Champ',
                                                        `Detailed|Body`,
                                                        this.selectLayout('5', '8'),
                                                        'block-champ'
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    strapiPromoData?.contentSpotlight?.contentSpotlight1 &&
                                    strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.url &&
                                    strapiPromoData?.contentSpotlight?.contentSpotlight1?.image?.url && (
                                        <div className={styles.promoCardContainer}>
                                            <I18nText
                                                as="h2"
                                                className={styles.promoHeading}
                                                keyName={
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight1?.sectionTitle
                                                }
                                            />
                                            <PromoCard
                                                url={UrlService.createURL(
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.url
                                                )}
                                                image={
                                                    this.getGameImage(
                                                        strapiPromoData?.contentSpotlight?.contentSpotlight1?.button
                                                            ?.url
                                                    )?.promoTile1000x312
                                                }
                                                imageWebp={
                                                    this.getGameImage(
                                                        strapiPromoData?.contentSpotlight?.contentSpotlight1?.button
                                                            ?.url
                                                    )?.promoTile1000x312webp
                                                }
                                                caption={strapiPromoData?.contentSpotlight?.contentSpotlight1?.title}
                                                description={strapiPromoData?.contentSpotlight?.contentSpotlight1?.text}
                                                buttonCaption={
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight1?.button?.label
                                                }
                                                promoCardClassName={styles.promoDividerClassName}
                                                onButtonClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            strapiPromoData?.contentSpotlight?.contentSpotlight1
                                                                ?.promoBoxEvent,
                                                            `Detailed|Body`,
                                                            this.selectLayout('5', '8'),
                                                            UrlService.getSlugFromFullPath(
                                                                strapiPromoData?.contentSpotlight?.contentSpotlight1
                                                                    ?.button?.url
                                                            )
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            { Object.keys(strapiPromoData?.contentSpotlight?.contentSpotlight1 || {}).length > 0 &&  (
                                <div className={styles.sideCenter}>
                                    <div className={styles.fourTilesBlock}>
                                        <I18nText as="h2" keyName="HOME_STRATEGY_CAT" />
                                        <div className={styles.fourTilesBlock_games}>
                                            <div className={styles.fourTilesBlock_game}>
                                                <GameCard
                                                    gameCardType={GameCardTypes.SMALL}
                                                    disableDescription
                                                    game={GameService.findGameByArena5Slug(games, 'chess')}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'Strategy Games',
                                                                'Small|RightRail',
                                                                '2',
                                                                'chess'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.fourTilesBlock_game}>
                                                <GameCard
                                                    gameCardType={GameCardTypes.SMALL}
                                                    disableDescription
                                                    game={GameService.findGameByArena5Slug(games, '5roll')}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'Strategy Games',
                                                                'Small|RightRail',
                                                                '2',
                                                                '5roll'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.fourTilesBlock_game}>
                                                <GameCard
                                                    gameCardType={GameCardTypes.SMALL}
                                                    disableDescription
                                                    game={GameService.findGameByArena5Slug(games, 'mahjong-remix')}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'Strategy Games',
                                                                'Small|RightRail',
                                                                '2',
                                                                'mahjong-remix'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.fourTilesBlock_game}>
                                                <GameCard
                                                    gameCardType={GameCardTypes.SMALL}
                                                    disableDescription
                                                    game={GameService.findGameByArena5Slug(games, 'sweet-shuffle')}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                'Strategy Games',
                                                                'Small|RightRail',
                                                                '2',
                                                                'sweet-shuffle'
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m3"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_tab3"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>
                        {this.getCategorySectionByName('Popular Now')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <>
                                <Responsive minWidth={1200}>
                                    <div
                                        className={styles.row}
                                        data-size="Super Large"
                                        data-theme="Popular Now"
                                        data-position="6"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Popular Now').gamesListFn(games)}
                                                gameCardType={GameCardTypes.SUPER_LARGE}
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Popular Now',
                                                            `Body`,
                                                            '6',
                                                            'Popular Now'
                                                        )
                                                    );

                                                    // remove after category release
                                                    try {
                                                        this.openSeeAll('Best');
                                                    } catch {
                                                        this.openSeeAll('popular');
                                                    }
                                                }}
                                                caption={this.getCategorySectionByName('Popular Now').name}
                                                promoboxCategory="Popular Now"
                                                promoBoxPosition="6"
                                                headerLink={this.getLocalizedLink('best')}
                                            />
                                        </div>
                                        <div className={styles.side}>
                                            <Ad
                                                id="ark_display_home_r3"
                                                hasBackground={false}
                                                captionPosition={CaptionPositionTypes.CENTER}
                                                adType={[
                                                    AdTypes.AD_300x600,
                                                    AdTypes.AD_336x280,
                                                    AdTypes.AD_300x250,
                                                    AdTypes.AD_250x250,
                                                    AdTypes.AD_160x600,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </Responsive>

                                <Responsive maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}>
                                    <div className={styles.row} data-theme="Popular Now" data-size="Super Large">
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Popular Now').gamesListFn(games)}
                                                gameCardType={GameCardTypes.LARGE}
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Popular Now',
                                                            `Body`,
                                                            this.selectLayout('7', '10'),
                                                            'Popular Now'
                                                        )
                                                    );

                                                    // remove after category release
                                                    try {
                                                        this.openSeeAll('Best');
                                                    } catch {
                                                        this.openSeeAll('popular');
                                                    }
                                                }}
                                                caption={this.getCategorySectionByName('Popular Now').name}
                                                promoboxCategory="Popular Now"
                                                promoBoxPosition="6"
                                                headerLink={this.getLocalizedLink('best')}
                                            />
                                        </div>
                                        <div className={styles.side} />
                                    </div>
                                </Responsive>
                            </>
                        )}

                        {this.getCategorySectionByName('Crosswords')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <>
                                <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}>
                                    <div
                                        className={classNames(styles.row, styles.visibleLargeDevices)}
                                        data-size="Medium"
                                        data-theme="Crosswords"
                                        data-right-rail-theme="crosswordHelp"
                                        data-right-rail-position="3"
                                        data-position="7"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Crosswords').gamesListFn(games)}
                                                gameCardType={GameCardTypes.MEDIUM}
                                                promoboxCategory="Crosswords"
                                                promoBoxPosition="7"
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Crosswords',
                                                            `Body`,
                                                            this.selectLayout('7', '11', '8'),
                                                            'Crosswords'
                                                        )
                                                    );
                                                    this.openSeeAll('Crosswords');
                                                }}
                                                caption={this.getCategorySectionByName('Crosswords').name}
                                                headerLink={this.getLocalizedLink('crosswords')}
                                            />
                                        </div>
                                        <div className={styles.sideCenter}>
                                            {strapiPromoData?.contentRail?.contentRail3 &&
                                                strapiPromoData?.contentRail?.contentRail3?.button?.url &&
                                                strapiPromoData?.contentRail?.contentRail3?.image?.url && (
                                                    <AdDividerPromoCard
                                                        url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                            strapiPromoData?.contentRail?.contentRail3?.button?.url
                                                        )}
                                                        imagePath={
                                                            strapiPromoData?.contentRail?.contentRail3?.image?.url
                                                        }
                                                        caption={strapiPromoData?.contentRail?.contentRail3?.title}
                                                        description={strapiPromoData?.contentRail?.contentRail3?.text}
                                                        buttonCaption={
                                                            strapiPromoData?.contentRail?.contentRail3?.button?.label
                                                        }
                                                        adDividerClassName={styles.adDividerClassNameSharp}
                                                        onClick={() => {
                                                            Analytics.trackEvent(
                                                                Analytics.newHome.promoboxSlotClick(
                                                                    strapiPromoData?.contentRail?.contentRail3
                                                                        ?.promoBoxEvent,
                                                                    `Ad unit|RightRail`,
                                                                    '3'
                                                                )
                                                            );
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </Responsive>

                                <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_BELOW}>
                                    <div
                                        className={classNames(styles.row, styles.visibleSmallDevices)}
                                        data-size="Small"
                                        data-theme="Crosswords"
                                        data-right-rail-theme="crosswordHelp"
                                        data-right-rail-position="3"
                                        data-position="7"
                                    >
                                        <div className={styles.rowContent}>
                                            <HomepageSlider
                                                pseudoTile
                                                items={this.getCategorySectionByName('Crosswords').gamesListFn(games)}
                                                gameCardType={GameCardTypes.SMALL}
                                                promoboxCategory="Crosswords"
                                                promoBoxPosition="7"
                                                onSeeAllClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            'Crosswords',
                                                            `Body`,
                                                            this.selectLayout('7', '11', '8'),
                                                            'Crosswords'
                                                        )
                                                    );
                                                    this.openSeeAll('Crosswords');
                                                }}
                                                caption={this.getCategorySectionByName('Crosswords').name}
                                                headerLink={this.getLocalizedLink('crosswords')}
                                            />
                                        </div>
                                        <div className={styles.sideCenter}>
                                            {strapiPromoData?.contentRail?.contentRail3 &&
                                                strapiPromoData?.contentRail?.contentRail3?.button?.url &&
                                                strapiPromoData?.contentRail?.contentRail3?.image?.url && (
                                                    <AdDividerPromoCard
                                                        url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                            strapiPromoData?.contentRail?.contentRail3?.button?.url
                                                        )}
                                                        imagePath={
                                                            strapiPromoData?.contentRail?.contentRail3?.image?.url
                                                        }
                                                        caption={strapiPromoData?.contentRail?.contentRail3?.title}
                                                        description={strapiPromoData?.contentRail?.contentRail3?.text}
                                                        buttonCaption={
                                                            strapiPromoData?.contentRail?.contentRail3?.button?.label
                                                        }
                                                        adDividerClassName={styles.adDividerClassNameSharp}
                                                        onClick={() => {
                                                            Analytics.trackEvent(
                                                                Analytics.newHome.promoboxSlotClick(
                                                                    strapiPromoData?.contentRail?.contentRail3
                                                                        ?.promoBoxEvent,
                                                                    `Ad unit|RightRail`,
                                                                    '3'
                                                                )
                                                            );
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </Responsive>
                            </>
                        )}

                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m4"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m4"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)} data-mobile-theme="crosswordHelp">
                                <Ad
                                    id="ark_display_home_tab4"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                                {currentLang !== 'fr' && (
                                    <div className={styles.mobileAdDivider}>
                                        {strapiPromoData?.contentRail?.contentRail3 &&
                                            strapiPromoData?.contentRail?.contentRail3?.button?.url &&
                                            strapiPromoData?.contentRail?.contentRail3?.image?.url && (
                                                <AdDividerPromoCard
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        strapiPromoData?.contentRail?.contentRail3?.button?.url
                                                    )}
                                                    imagePath={strapiPromoData?.contentRail?.contentRail3?.image?.url}
                                                    caption={strapiPromoData?.contentRail?.contentRail3?.title}
                                                    description={strapiPromoData?.contentRail?.contentRail3?.text}
                                                    buttonCaption={
                                                        strapiPromoData?.contentRail?.contentRail3?.button?.label
                                                    }
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                strapiPromoData?.contentRail?.contentRail3
                                                                    ?.promoBoxEvent,
                                                                `Ad unit|Body`,
                                                                '12'
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </Responsive>
                        {this.getCategorySectionByName('Word Games')
                            .gamesListFn(gamesFiltered)
                            .filter((game) => !!game).length > 0 && (
                            <div className={styles.row} data-size="Large" data-theme="Word Games" data-position="8">
                                <div className={styles.rowContent}>
                                    <HomepageSlider
                                        pseudoTile
                                        items={this.getCategorySectionByName('Word Games').gamesListFn(games)}
                                        gameCardType={GameCardTypes.LARGE}
                                        promoboxCategory="Word Games"
                                        promoBoxPosition="8"
                                        onSeeAllClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'Word Games',
                                                    `Body`,
                                                    this.selectLayout('8', '13', '9'),
                                                    'Word Games'
                                                )
                                            );
                                            this.openSeeAll('Crosswords');
                                        }}
                                        caption={this.getCategorySectionByName('Word Games').name}
                                        headerLink={this.getLocalizedLink('word')}
                                    />
                                </div>
                                <div className={styles.side}>
                                    <Responsive minWidth={1200}>
                                        <Ad
                                            id="ark_display_home_r4"
                                            hasBackground={false}
                                            captionPosition={CaptionPositionTypes.CENTER}
                                            adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                        />
                                    </Responsive>
                                </div>
                            </div>
                        )}

                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m5"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m5"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
                        >
                            <div className={classNames(styles.row, styles.mobileRow)} data-mobile-theme="licensing">
                                <Ad
                                    id="ark_display_home_tab5"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                                {currentLang === 'fr' ? (
                                    <div className={styles.mobileAdDivider}>
                                        <AdDividerPromoCard
                                            newWindow
                                            url="https://www.arkadium.com/game-licensing/"
                                            imagePath={UrlService.toCDNUrl(
                                                '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-licening.png'
                                            )}
                                            caption="AD_DIVIDER_LICENSING_CAPTION"
                                            description="AD_DIVIDER_LICENSING_TEXT"
                                            buttonCaption="LEARN_MORE"
                                            adDividerClassName={styles.adDividerClassNameLicensing}
                                            onClick={() => {
                                                Analytics.trackEvent(
                                                    Analytics.newHome.promoboxSlotClick(
                                                        'licensing',
                                                        `Ad unit|body`,
                                                        '14'
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.mobileAdDivider}>
                                        {strapiPromoData?.contentRail?.contentRail4 &&
                                            strapiPromoData?.contentRail?.contentRail4?.button?.url &&
                                            strapiPromoData?.contentRail?.contentRail4?.image?.url && (
                                                <AdDividerPromoCard
                                                    newWindow
                                                    url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                        strapiPromoData?.contentRail?.contentRail4?.button?.url
                                                    )}
                                                    imagePath={strapiPromoData?.contentRail?.contentRail4?.image?.url}
                                                    caption={strapiPromoData?.contentRail?.contentRail4?.title}
                                                    description={strapiPromoData?.contentRail?.contentRail4?.text}
                                                    buttonCaption={
                                                        strapiPromoData?.contentRail?.contentRail4?.button?.label
                                                    }
                                                    adDividerClassName={styles.adDividerClassNameLicensing}
                                                    onClick={() => {
                                                        Analytics.trackEvent(
                                                            Analytics.newHome.promoboxSlotClick(
                                                                strapiPromoData?.contentRail?.contentRail4
                                                                    ?.promoBoxEvent,
                                                                `Ad unit|Body`,
                                                                '14'
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </Responsive>

                        <div
                            className={classNames(styles.row, styles.promoFullscreen)}
                            data-theme="All games"
                            data-size="Detailed"
                            data-position="10"
                        >
                            {currentLang === 'fr' ? (
                                <div className={styles.rowContent}>
                                    <PromoCard
                                        image={UrlService.toCDNUrl('/illustrations/all-games/1000x312.png')}
                                        imageWebp={UrlService.toCDNUrl('/illustrations/all-games/1000x312.webp')}
                                        description="ALL_PROMO_DESCRIPTION"
                                        buttonCaption="SEE_ALL"
                                        url={UrlService.createURL(
                                            UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)
                                        )}
                                        onButtonClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'All games',
                                                    'Detailed|Body',
                                                    this.selectLayout('10', '15', '15'),
                                                    'All games'
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={styles.rowContent}>
                                    {/*// TODO render conditionally if data
                                // TODO localized route here, it used to be this
                                 url={UrlService.createURL(*/}
                                    {/*//                                         UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)*/}
                                    {/*//                                     )}*/}

                                    {strapiPromoData?.contentSpotlight?.contentSpotlight4 &&
                                        strapiPromoData?.contentSpotlight?.contentSpotlight4?.button?.url &&
                                        strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url && (
                                            <PromoCard
                                                url={UrlService.createURL(
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight4?.button?.url
                                                )}
                                                image={strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url}
                                                imageWebp={
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight4?.image?.url
                                                }
                                                description={strapiPromoData?.contentSpotlight?.contentSpotlight4?.text}
                                                buttonCaption={
                                                    strapiPromoData?.contentSpotlight?.contentSpotlight4?.button?.label
                                                }
                                                promoCardClassName={styles.promoDividerClassName}
                                                onButtonClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            strapiPromoData?.contentSpotlight?.contentSpotlight4
                                                                ?.promoBoxEvent,
                                                            `Detailed|Body`,
                                                            this.selectLayout('10', '15', '11'),
                                                            UrlService.getSlugFromFullPath(
                                                                strapiPromoData?.contentSpotlight?.contentSpotlight4
                                                                    ?.promoBoxEvent
                                                            )
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                </div>
                            )}
                            {currentLang === 'fr' ? (
                                <div className={styles.sideCenter}>
                                    <AdDividerPromoCard
                                        newWindow
                                        url="https://www.arkadium.com/game-licensing/"
                                        imagePath={UrlService.toCDNUrl(
                                            '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-licening.png'
                                        )}
                                        caption="AD_DIVIDER_LICENSING_CAPTION"
                                        description="AD_DIVIDER_LICENSING_TEXT"
                                        buttonCaption="LEARN_MORE"
                                        adDividerClassName={styles.adDividerClassNameLicensing}
                                        onClick={() => {
                                            Analytics.trackEvent(
                                                Analytics.newHome.promoboxSlotClick(
                                                    'licensing',
                                                    `Ad unit|RightRail`,
                                                    '4'
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={styles.sideCenter}>
                                    {strapiPromoData?.contentRail?.contentRail4 &&
                                        strapiPromoData?.contentRail?.contentRail4?.button?.url &&
                                        strapiPromoData?.contentRail?.contentRail4?.image?.url && (
                                            <AdDividerPromoCard
                                                newWindow
                                                url={UrlService.checkIfAbsoluteUrlOrCreate(
                                                    strapiPromoData?.contentRail?.contentRail4?.button?.url
                                                )}
                                                imagePath={strapiPromoData?.contentRail?.contentRail4?.image?.url}
                                                caption={strapiPromoData?.contentRail?.contentRail4?.title}
                                                description={strapiPromoData?.contentRail?.contentRail4?.text}
                                                buttonCaption={
                                                    strapiPromoData?.contentRail?.contentRail4?.button?.label
                                                }
                                                adDividerClassName={styles.adDividerClassNameLicensing}
                                                onClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.promoboxSlotClick(
                                                            strapiPromoData?.contentRail?.contentRail4?.promoBoxEvent,
                                                            `Ad unit|RightRail`,
                                                            '4'
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                </div>
                            )}
                        </div>

                        <Responsive maxWidth={767}>
                            <div className={classNames(styles.row, styles.mobileRow)}>
                                <Ad
                                    id="ark_display_home_m6"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_336x280, AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                />
                            </div>
                        </Responsive>

                        <Responsive
                            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
                            maxWidth={ArkCssBreakpoints.ARK_MEDIUM_DESKTOP_BELOW}
                        >
                            <div className={classNames(styles.row, styles.mobileRow, styles.adBeforeSeo)}>
                                <Ad
                                    id="ark_display_home_tab6"
                                    hasBackground={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    adType={[AdTypes.AD_728x90]}
                                />
                            </div>
                        </Responsive>
                    </div>
                </main>

                {currentLang === 'fr' ? (
                    <div className={styles.seo} ref={this.seoRef}>
                        <I18nText as="h2" className={styles.homeSeoTitle} keyName="HOME_SEO_CAPTION" />
                        <I18nText as="p" className={styles.homeSeoSubTitle} keyName="HOME_SEO_DESCRIPTION" />
                        <Responsive maxWidth={1023}>
                            <SwipeSlider
                                items={SEO_SETTINGS}
                                noShadows
                                renderItem={(item: any, index) => (
                                    <SeoCard
                                        key={index}
                                        illustrationType={item.imageType}
                                        image={item.imageType}
                                        buttonCaption={item.buttonCaption}
                                        buttonType={item.buttonType}
                                        text={item.text}
                                        onClick={() => {
                                            Analytics.trackEvent(Analytics.newHome.seoClick(item.category));
                                            history.push(
                                                UrlService.createURL(
                                                    `${UrlService.getPageLocalizedRoute(
                                                        UrlService.currentLang,
                                                        PageTypes.Category
                                                    )}/${
                                                        PageService.getPageSEOByPageName(pages, item.category).slug[
                                                            currentLang
                                                        ]
                                                    }/`
                                                )
                                            );
                                        }}
                                    />
                                )}
                            />
                        </Responsive>
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                            <div className={styles.seogrid}>
                                {SEO_SETTINGS.map((it) => (
                                    <div className={styles.seoItem} key={it.category}>
                                        <SeoCard
                                            illustrationType={it.imageType}
                                            image={it.imageType}
                                            buttonCaption={it.buttonCaption}
                                            text={it.text}
                                            onClick={() => {
                                                Analytics.trackEvent(Analytics.newHome.seoClick(it.category));
                                                history.push(
                                                    UrlService.createURL(
                                                        `${UrlService.getPageLocalizedRoute(
                                                            UrlService.currentLang,
                                                            PageTypes.Category
                                                        )}/${
                                                            PageService.getPageSEOByPageName(pages, it.category).slug[
                                                                currentLang
                                                            ]
                                                        }/`
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Responsive>
                    </div>
                ) : (
                    <div className={styles.seo} ref={this.seoRef}>
                        <I18nText
                            as="h2"
                            className={styles.homeSeoTitle}
                            keyName={strapiPromoData?.contentCollection?.contentCollection1?.sectionTitle}
                        />

                        <I18nText
                            as="p"
                            className={styles.homeSeoSubTitle}
                            keyName={strapiPromoData?.contentCollection?.contentCollection1?.title}
                        />
                        <Responsive maxWidth={1023}>
                            {contentCollectionArray?.length > 0 && (
                                <SwipeSlider
                                    items={contentCollectionArray}
                                    noShadows
                                    renderItem={(item: any, index) =>
                                        item?.promoBoxEvent &&
                                        item?.image?.url && (
                                            <SeoCard
                                                key={index}
                                                image={item?.image?.url}
                                                buttonCaption={item?.button?.label}
                                                buttonType={item?.buttonType}
                                                text={item?.text}
                                                onClick={() => {
                                                    Analytics.trackEvent(
                                                        Analytics.newHome.seoClick(item?.promoBoxEvent)
                                                    );
                                                    history.push(
                                                        UrlService.createURL(
                                                            `${UrlService.getPageLocalizedRoute(
                                                                UrlService.currentLang,
                                                                PageTypes.Category
                                                            )}/${
                                                                PageService.getPageSEOByPageName(
                                                                    pages,
                                                                    item?.promoBoxEvent
                                                                ).slug[currentLang]
                                                            }/`
                                                        )
                                                    );
                                                }}
                                            />
                                        )
                                    }
                                />
                            )}
                        </Responsive>
                        {/*// TODO check that redirect is correctly set*/}
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
                            {contentCollectionArray?.length > 0 && (
                                <div className={styles.seogrid}>
                                    {contentCollectionArray?.map(
                                        (item) =>
                                            item?.promoBoxEvent &&
                                            item?.image?.url && (
                                                <div className={styles.seoItem} key={item?.promoBoxEvent}>
                                                    <SeoCard
                                                        image={item?.image?.url}
                                                        buttonCaption={item?.button?.label}
                                                        text={item?.text}
                                                        onClick={() => {
                                                            Analytics.trackEvent(
                                                                Analytics.newHome.seoClick(item?.promoBoxEvent)
                                                            );
                                                            history.push(
                                                                UrlService.createURL(
                                                                    `${UrlService.getPageLocalizedRoute(
                                                                        UrlService.currentLang,
                                                                        PageTypes.Category
                                                                    )}/${
                                                                        PageService.getPageSEOByPageName(
                                                                            pages,
                                                                            item?.promoBoxEvent
                                                                        ).slug[currentLang]
                                                                    }/`
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )
                                    )}
                                </div>
                            )}
                        </Responsive>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        strapiPromoData: state.strapiPromoData,
    };
};

export const HomeTemplate = withRouter(connect(mapStateToProps)(HomeTemplateBase));
