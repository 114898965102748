import React from 'react';

import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Ad, AdTypes } from '../../../molecules/Ad/Ad';
import styles from './ProfileBottomAds.css';

export const ProfileBottomAds = React.memo(() => {
    return (
        <div className={styles.bottomProfileAds}>
            <Responsive minWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                <Ad hasBackground id="bottom-profile" adType={[AdTypes.AD_728x90]} />
            </Responsive>
            <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}>
                <Ad hasBackground id="bottom-profile" adType={[AdTypes.AD_320x50]} />
            </Responsive>
        </div>
    );
});
