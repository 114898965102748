import React from 'react';

import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import styles from './TooltipMobile.css';

type TooltipMobileProps = {
    tooltipContent: JSX.Element | string;
};

export const TooltipMobile = React.memo((props: TooltipMobileProps) => {
    return (
        <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <div className={styles.tooltipMobile}>{props.tooltipContent}</div>
        </Responsive>
    );
});
