import React, { useEffect, useState } from 'react';

import facebookIcon from '@iconify/icons-fa-brands/facebook-f';
import instagramIcon from '@iconify/icons-fa-brands/instagram';
import Icon from '@iconify/react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './BlogPostTemplate.css';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { BreadcrumbsSection } from '../../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { OpenWebBlock } from '../../../molecules/OpenWeb/OpenWebConversations';
import UserService from '../../../services/UserService';
import { getBreadcrumbLinks } from '../BlogArchiveTemplate/BlogArchiveTemplate';
import HeroBlock from '../HeroBlock/HeroBlock';

const BlogPostTemplate = React.memo(() => {
    const pageType = useSelector((state) => state.pageType);
    const currentLang = useSelector((state) => state.currentLang);
    const postData = useSelector((state) => state.blogPostData);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const activeUserSubscriptions = useSelector(state => state.activeUserSubscriptions);

    useEffect(() => {
        if (!activeUserSubscriptions) {
            setIsSubscriber(false);
            return;
        }
        const subscriberStatus = UserService.isUserSubscriber();

        setIsSubscriber(subscriberStatus);
    }, [activeUserSubscriptions]);

    if (!postData) {
        return <main>Post data is missing.</main>;
    }

    return (
        <main>
            <Paper square={true} className={styles.wrapper}>
                <Grid columns={{ md: 8, xl: 12 }}>
                    <Grid container>
                        <Grid item xl={11}>
                            <BreadcrumbsSection
                                className={classNames(styles.breadcrumbsRow, styles.container)}
                                isSSRLink={true}
                                onLightBg
                                links={getBreadcrumbLinks(pageType, postData.breadcrumbLabel[currentLang])}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xl={12} px={0}>
                        <Grid item xs={12} md={12} xl={12}>
                            <HeroBlock isPostPage promoPost={postData} showSubtitle={false} />
                        </Grid>
                        <Grid container xs={12} spacing={2} className={styles.blogBody}>
                            <Grid item xs>
                                <Box className={classNames(styles.contentWrapper, styles.container)}>
                                    <div dangerouslySetInnerHTML={{__html: postData.content}}/>
                                </Box>
                            </Grid>
                            <Grid item className={styles.sidebar}>
                                <div>
                                    <p>Recommended posts</p>
                                    <ul>
                                        {postData.recommendedPosts.map((p, index) => (
                                            <li key={p.label + index}>
                                                <a href={p.link}>{p.label}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                { !isSubscriber && (
                                    <Ad
                                        id="ark_display_r1"
                                        hasBackground={false}
                                        captionPosition={CaptionPositionTypes.CENTER}
                                        adType={[AdTypes.AD_300x250, AdTypes.AD_250x250]}
                                        className={styles.rightAd}
                                        keepSize
                                    />
                                )}
                                <div>
                                    <p>Follow us</p>
                                    <div className={styles.socials}>
                                        {postData.instagramLink && (
                                            <a href={postData.instagramLink} className={styles.social}>
                                                <Icon
                                                    icon={instagramIcon}
                                                    width="22px"
                                                    height="22px"
                                                    color="rgba(35, 31, 32, 1)"
                                                />
                                                Instagram
                                            </a>
                                        )}
                                        {postData.facebookLink && (
                                            <a href={postData.facebookLink} className={styles.social}>
                                                <Icon
                                                    icon={facebookIcon}
                                                    width="22px"
                                                    height="22px"
                                                    color="rgba(35, 31, 32, 1)"
                                                />
                                                Facebook
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={styles.container}>
                                    {postData.blogCategory && (
                                        <p className={styles.categories}>
                                            <span>Category: </span> {postData.blogCategory.name}
                                        </p>
                                    )}
                                </div>
                                <OpenWebBlock
                                    topicId={`${postData.uid}__${postData.id}`}
                                    linkPath={`blog/${postData.slug[postData.locale]}`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </main>
    );
});

export default BlogPostTemplate;
