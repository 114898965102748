import React, { forwardRef, memo, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRecurly } from '@recurly/react-recurly';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { AddressDetails } from './AddressDetails/AddressDetails';
import { CardDetails } from './CardDetails/CardDetails';
import { defaultValues, validationSchema } from './validationSchemas/PaymentFormValidationSchema';
import { environment } from '../../config/environment';
import { LS_SENT_AI_GEMS_IMPRESSION } from '../../constants/GemsConstants';
import { PageTypes } from '../../constants/Pages';
import { SubscriptionPlans } from '../../constants/SubscriptionPlan';
import { PaymentMethod } from '../../models/Subscription/PaymentForm';
import { Analytics } from '../../services/Analytics/Analytics';
import { AppInsightService } from '../../services/AppInsight';
import PaymentService from '../../services/PaymentService';
import { setBillingDataForTax } from '../../store/ducks/gems/gems';
import { setGiftCardStep } from '../../store/ducks/giftCard';
import { setFullScreenLoading, setSnackbarData } from '../../store/ducks/layout';
import { setIsValidForm, setRecurlyToken, setStepIndex } from '../../store/ducks/subscription/common';
import { setBillingInfoValues } from '../../store/ducks/subscription/paymentForm';
dayjs.extend(utc);

export const PaymentForm = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    // form stuff
    const {
      register,
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      watch,
      formState: { errors }
    } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues
    });
    const values = watch();
    const setValidation = (valid: boolean, name: any, message?: string) => {
      valid
        ? clearErrors(name)
        : setError(name, {
          type: 'manual',
          message
        });
    };
    // redux stuff
    const dispatch = useDispatch();
    const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
    const giftCardForm = useSelector(({ giftCardPurchase }) => giftCardPurchase);
    const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource || '');
    const planCode = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan || SubscriptionPlans.ANNUAL);
    const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
    const stepIndex = useSelector(({ stepIndex }) => stepIndex);
    const billingDataForTax = useSelector(({ billingDataForTax }) => billingDataForTax);
    // recurly stuff
    const recurly = useRecurly();
    // another
    const planForAnalytics = planCode === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual';
    // Updating request for receiving taxes data for customer's ITEM purchase
    const updateTaxData = () => {
      const billingDataForTax = {
        first_name: 'NamePlaceholder',
        last_name: 'NamePlaceholder',
        postal_code: values.postal_code,
        country: values.country
      };
      const isBillingDataEnough = !Object.values(billingDataForTax).some((val) => !val);

      return isBillingDataEnough ? billingDataForTax : null;
    };

    // here is a specific validation due to the fact that there are inputs in iframes and requirements from UX
    // good practice to use isValid from react-hook-form useForm hook
    useEffect(() => {
      const isValidForm = stepIndex !== 0 || Object.values(values).every((i) => i !== false);

      dispatch(setIsValidForm(isValidForm));
    }, [values]);

    useEffect(() => {
      const updatedTaxData = updateTaxData();

      if (updatedTaxData && JSON.stringify(updatedTaxData) !== JSON.stringify(billingDataForTax)) {
        dispatch(setBillingDataForTax(updatedTaxData));
      }
    }, [values]);

    const onSubmit = (data: any) => {
      recurly.token((ref as any).current, (err, token) => {
        if (err) {
          (err as any).fields.forEach((i: any) => {
            const formattedName = i.replace(/[0-9]|_/g, ' '); // result: string without number and underscore
            // (e.g. postal code/address instead of postal_code/address1)

            setError(i, {
              type: 'manual',
              message: `Please enter a valid ${formattedName}`
            });
          });
          err.code === 'invalid-parameter'
            ? dispatch(
              setSnackbarData({
                isOpened: true,
                message: 'Please update the information flagged below',
                type: 'error'
              })
            )
            : dispatch(setSnackbarData({ isOpened: true, message: err.message, type: 'error' }));
        } else {
          dispatch(setRecurlyToken(token.id));

          if (pageTypeIsGiftCard) {
            void Analytics.trackEvent(
              Analytics.subscription.gifterPrePurchaseButton(
                subscriptionSource,
                planForAnalytics,
                paymentMethod
              )
            );
            PaymentService.purchaseGiftCard(
                'USD',
                2999, // gift card cost in cents: 2999
                giftCardForm.customMessage,
                dateConvert(giftCardForm.deliveryDate),
                environment.RECURLY_GIFT_CARD_PRODUCT_CODE,
                giftCardForm.recipientEmail,
                giftCardForm.recipientName,
                giftCardForm.senderEmail,
                giftCardForm.senderName,
                token.id
              )
              .then(() => {
                void Analytics.trackEvent(
                  Analytics.subscription.gifterPurchaseButton(
                    subscriptionSource,
                    planForAnalytics,
                    paymentMethod
                  )
                );
                batch(() => {
                  dispatch(setFullScreenLoading(false));
                  dispatch(setGiftCardStep(2));
                });
              })
              .catch((err) => {
                batch(() => {
                  dispatch(setFullScreenLoading(false));
                  dispatch(
                    setSnackbarData({
                      isOpened: true,
                      message: err.body.details[0].Message,
                      type: 'error'
                    })
                  );
                });
                AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
              });
          } else {
            // if it's a /subscription or /shop purchase page
            const billingInfo = {
              address1: data.addess1,
              city: data.city,
              country: data.country,
              postal_code: data.postal_code,
              creditBrand: data.cardNumber.creditBrand,
              creditLastFour: data.cardNumber.creditLastFour,
              state: data.state
            };

            if (!window.sessionStorage.getItem(LS_SENT_AI_GEMS_IMPRESSION)) {
              // hacky way to check it's not gems purchase without paymentMethod after step1 render
              void Analytics.trackEvent(
                Analytics.subscription.subscriptionNextStep(
                  subscriptionSource,
                  planForAnalytics,
                  PaymentMethod.CARD
                )
              );
            }

            // set these values to show on the 2nd step
            if (stepIndex !== 1) {
              batch(() => {
                dispatch(setBillingInfoValues(billingInfo));
                dispatch(setStepIndex(1));
              });
            }
          }
        }
      });
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} id="paymentForm" ref={ref}>
        <input type="submit" hidden/>
        {/* recurly is bound to inputs in the form, so we need to create these inputs and pass them the appropriate values ​​along with data-recurly  */}
        <input hidden defaultValue={values.country} data-recurly="country"/>
        <input hidden defaultValue={values.country ? values.state : ''} data-recurly="state"/>
        <CardDetails
          setValidation={setValidation}
          errors={errors}
          register={register}
          setValue={setValue}
          values={values}
        />
        <AddressDetails
          setValidation={setValidation}
          errors={errors}
          register={register}
          setValue={setValue}
          values={values}
        />
      </form>
    );
  })
);

/**
 * Convert delivery date by Recurly requirements
 * 1 If selected (delivery date) is today - add 2 hours to the delivery date
 * 2 If selected date is not today - set delivery Date and set time to 12:00 YOU LOCAL TIME
 * 3 Future date must be at least less than a year from the purchase date.
 *
 * @param {*} date
 * @return {*}
 */
export const dateConvert = (date: Date): string => {
  let resValue: string;
  const now = new Date();
  const selectedDate = date.toISOString().split('T')[0];
  const currentDate = now.toISOString().split('T')[0];

  if (currentDate === selectedDate) {
    const nowPlusTwoHours = new Date(now.getTime() + 2 * 1000 * 60 * 60);

    resValue = nowPlusTwoHours.toISOString();
  } else {
    const utcHourOffset = new Date().getTimezoneOffset() / 60;

    resValue = dayjs(selectedDate)
        .utc()
        .set('hour', 12 + utcHourOffset)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toString();
  }

  return resValue;
};
