import React, { useEffect, useState } from 'react';

import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import GiftCardStep1 from './GiftCardStep1/GiftCardStep1';
import GiftCardStep3 from './GiftCardStep3/GiftCardStep3';
import styles from './GiftCardTemplate.css';
import { withScrollTo } from '../../../helpers/hoc/withScrollTo';
import { useScript, ScriptState } from '../../../hooks/useScript';
import { environment } from '../../config/environment';
import { RECURLY_URL } from '../../constants/Recurly';
import { setGiftCardStep } from '../../store/ducks/giftCard';
import SubscriptionTemplate from '../Purchase/SubscriptionTemplate';

/*TODO: Maybe don`t need memo() at all, check in future updates*/
const GiftCardTemplate = React.memo(() => {
    GiftCardTemplate.displayName = 'GiftCardTemplate';
    const giftCardStep = useSelector((state) => state.giftCardStep);

    const dispatch = useDispatch();

    const history = useHistory();
    const searchHistory = history.location.search;

    const [step, setStep] = useState(giftCardStep);
    const isStep3 = step === 2;

    const scriptState = useScript(RECURLY_URL);

    useEffect(() => {
        setStep(giftCardStep);
        if (giftCardStep !== 0) {
            history.push({
                search: `?step=${giftCardStep + 1}`,
            });
        } else {
            if (searchHistory.includes('arkpromo')) {
                history.push({
                    search: `${searchHistory}`,
                });
            } else {
                history.push({
                    search: ``,
                });
            }
        }
    }, [giftCardStep]);

    const handleHistory = () => {
        if (giftCardStep > 0) {
            if (searchHistory === `?step=2`) {
                dispatch(setGiftCardStep(0));
            }
            if (searchHistory === `?step=3`) {
                dispatch(setGiftCardStep(1));
            }
        } else {
            history.goBack();
        }
    };

    useEffect(() => {
        window.addEventListener('popstate', handleHistory);
        return () => {
            window.removeEventListener('popstate', handleHistory);
        };
    }, [handleHistory]);

    const SubscriptionTemplateWithScrollTo = withScrollTo(SubscriptionTemplate);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {scriptState === ScriptState.ready && (
                <main className={classNames(styles.container, isStep3 && styles.container_step3)}>
                    {step === 0 && <GiftCardStep1 />}
                    {step === 1 && (
                        <RecurlyProvider
                            required={['cvv', 'address1', 'city', 'postal_code', 'state', 'country']}
                            publicKey={environment.RECURLY_PUBLIC_KEY}
                        >
                            <Elements>
                                <div className={styles.pageWrapper}>
                                    <SubscriptionTemplateWithScrollTo />
                                </div>
                            </Elements>
                        </RecurlyProvider>
                    )}
                    {step === 2 && <GiftCardStep3 />}
                </main>
            )}
        </>
    );
});

export default GiftCardTemplate;
