

import { PageTypes } from './client/constants/Pages';
import P404Page from './client/pages/404Page';
import AboutUsPage from './client/pages/AboutUsPage';
import BlogArchivePage from './client/pages/BlogArchivePage';
import BlogPostPage from './client/pages/BlogPostPage';
import CategoryPage from './client/pages/CategoryPage';
import DynamicPage from './client/pages/DynamicPage';
import GamePage from './client/pages/GamePage';
import GameSubmissionDisclaimerPage from './client/pages/GameSubmissionDisclaimerPage';
import GiftCardPage from './client/pages/GiftCardPage';
import HomePage from './client/pages/HomePage';
import PrivacyPolicyPage from './client/pages/PrivacyPolicyPage';
import ProfilePage from './client/pages/ProfilePage';
import GemsShopPage from './client/pages/PurchasePageGemsShop';
import SubscriptionPage from './client/pages/PurchasePageSubscription';
import SiteMapPage from './client/pages/SiteMapPage';

export type RouterObj = {
    exact: boolean;
    path: string;
    pageType: PageTypes;
    component: any;
};

type MappingBag = {
    [key: string]: { pageType: PageTypes; component: any };
};

type routesElements = {
    bases: string[];
    entities: MappingBag;
};

const page2RelatedEntities: MappingBag = {
    'free-online-games/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    categories: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'free-online-games/jigsaw-puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    'our-story': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'privacy-policy-california': {
        pageType: PageTypes.PrivacyPolicyCalifornia,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'coming-soon/:slug': {
        pageType: PageTypes.ComingSoonGame,
        component: DynamicPage,
    },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesFr: MappingBag = {
    'jeux-gratuits/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    /* Check this out */
    'notre-histoire': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    categorie: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'jeux-gratuits/puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesFrDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesEs: MappingBag = {
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
};
const page2RelatedEntitiesForLanding: MappingBag = {
    '': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesDe: MappingBag = {
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
};

function generateRoutes({ bases, entities }: routesElements): RouterObj[] {
    const routes: RouterObj[] = [];
    const pages = Object.keys(entities);

    for (const base of bases) {
        const newRoutes = pages.map((page) => {
            const { pageType, component } = entities[page];

            return { exact: true, path: `${base}${page}`, pageType, component };
        });

        routes.push(...newRoutes);
    }

    return routes;
}

function prepareRoutes(routesElement: routesElements[]) {
    const routes: RouterObj[] = [];

    routesElement.forEach((route) => {
        routes.push(...generateRoutes(route));
    });

    routes.push({
        exact: false,
        path: '*',
        pageType: PageTypes.NotFound,
        component: P404Page,
    });

    return routes;
}

const COMMON_BASES_EN = ['/', ...['en', '__empty__'].map((l) => `/${l}/`)];
const COMMON_BASES_FR = ['/fr/'];
const COMMON_BASES_ES = ['/es/'];
const COMMON_BASES_DE = ['/de/'];

export const routes = prepareRoutes([
    { bases: COMMON_BASES_FR, entities: page2RelatedEntitiesFr },
    { bases: COMMON_BASES_ES, entities: page2RelatedEntitiesEs },
    { bases: COMMON_BASES_DE, entities: page2RelatedEntitiesDe },
    { bases: COMMON_BASES_EN, entities: page2RelatedEntities },
    { bases: COMMON_BASES_FR, entities: page2RelatedEntitiesFrDynamic },
    { bases: COMMON_BASES_EN, entities: page2RelatedEntitiesDynamic },
]);

export const langingRoutes = prepareRoutes([
    {bases: COMMON_BASES_EN, entities: page2RelatedEntitiesForLanding},
])
