import React, { useEffect } from 'react';

import { Ad, AdTypes } from '../../../molecules/Ad/Ad';

// layout constants
const STICKY_SCROLL_TOP = 117;
const STICKY_SIDEBAR_TOP = 117;
const CONTENT_WIDTH = 1360;
const CONTENT_WIDTH_ADJ = 210;

export const ProfileSidebarAds = React.memo(() => {
    const sidebarRef = React.createRef<any>();

    const adjustSideBarHeight = () => {
        const scrollTop = window.scrollY;
        const sidebarEl = sidebarRef.current;

        const rightpos = (window.innerWidth - CONTENT_WIDTH) / 2 - CONTENT_WIDTH_ADJ;

        if (sidebarEl) {
            if (scrollTop >= STICKY_SCROLL_TOP) {
                sidebarEl.style.position = `fixed`;
                sidebarEl.style.top = `${STICKY_SIDEBAR_TOP}px`;
                sidebarEl.style.right = `${rightpos}px`;

                const footerEl = document.getElementById('siteFooter');
                if (!footerEl) {
                    return;
                }
                const footerTop = footerEl.getBoundingClientRect().top;

                if (footerTop < sidebarEl.getBoundingClientRect().height + 160) {
                    sidebarEl.style.top = `${-1 * (sidebarEl.getBoundingClientRect().height - footerTop + 65)}px`;
                }
            } else {
                sidebarEl.style.position = `initial`;
                sidebarEl.style.top = `initial`;
                sidebarEl.style.height = `auto`;
            }
        }
    };

    const onWindowScrollOrResize = () => {
        adjustSideBarHeight();
    };

    useEffect(() => {
        window.addEventListener('scroll', onWindowScrollOrResize);
        window.addEventListener('resize', onWindowScrollOrResize);
    });

    return (
        <div ref={sidebarRef}>
            <Ad hasBackground={false} id="profile-sidebar" adType={[AdTypes.AD_300x600]} />
        </div>
    );
});
