import EmailValidator from 'email-validator';

export const emailValidation = (email: string) => {
  if (EmailValidator.validate(email)) {
    return null;
  }

  if (email.trim() === '') {
    return 'Email is required';
  }

  return 'Please enter a valid email';
};

export const passwordValidation = (password: string) => {
  const error = {
    required: null,
    length: null,
    uppercase: null,
    unexpectedSymbols: null
  };

  if (password.trim() === '' || password.length === 0) {
    error.required = 'Password is required';
    return 'Password is required';
  }

  if (!/^[A-Za-z0-9!@#$%^&*()_[\]{},.<>+=-]*$/.test(password)) {
    error.unexpectedSymbols = `Sorry, your password contains characters that aren't allowed.`;
    return `Sorry, your password contains characters that aren't allowed.`;
  }

  if (password.length < 8) {
    error.length = 'Password must be longer than or equal to 8 characters';
    return 'Password must be longer than or equal to 8 characters';
  }

  if (!/(?=.*[A-Z])/.test(password)) {
    error.uppercase = 'At least 1 uppercase letter';
    return 'At least 1 uppercase letter';
  }

  if (Object.values(error).every((i) => i === null)) {
    return null;
  }

  return 'Please enter a valid password';
};

export const checkboxValidation = () => {
  return null;
};

export const authTypeValidation = () => {
  return null;
};
