// TODO: this component should be refactored with tsconfig --strictNullCheck turned On,
// There are a lot of potential problems with null|undefined objects

import React, { useMemo } from 'react';

import classNames from 'classnames';
import { History } from 'history';
import { batch, connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux-act';

import styles from './Leaderboard.css';
import { LeaderboardNotificationIcon } from './LeaderboardNotificationIcon';
import { Avatar, AvatarTypes } from '../../atoms/Avatar/Avatar';
import { Button } from '../../atoms/Buttons/Base/Base';
import { BorderedButton } from '../../atoms/Buttons/BorderedButton/BorderedButton';
import { Responsive } from '../../atoms/Layout/Responsive';
import { NavLink } from '../../atoms/Link/Link';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { DEFAULT_AVATAR_BACKGROUND } from '../../constants/AvatarImagesData';
import CountriesList from '../../constants/CountriesList';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { IGame } from '../../models/Game/Game';
import { UserModel } from '../../models/User/UserModel';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { LeaderboardDataModel, LeaderboardPublicItemModel } from '../../services/HighScoreService';
import { TranslationService } from '../../services/TranslationService';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setLeaderBoardNotificationType, setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';

enum timeFrames {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export type LeaderboardNotificationType = {
    ICON?: boolean;
    ROW?: boolean;
};

// helpers
const numberFormatter = new Intl.NumberFormat();
const ROW_HEIGHT = '4rem';
const ROW_LENGTH_LIMIT = 100;

function getCurrentUserData(
    user: UserModel,
    timeFrame: timeFrames,
    leaderboardData: LeaderboardDataModel
): LeaderboardPublicItemModel | null {
    if (user) {
        let result: LeaderboardPublicItemModel;
        const publicData = leaderboardData.public[timeFrame];
        const userScore = leaderboardData.user[timeFrame];

        if (publicData.length === 0) {
            return null;
        }

        publicData.forEach((item) => {
            if (item.uid === user.uid) {
                result = item;
            }
        });

        if (!result) {
            result = {
                uid: user.uid,
                name: user.name,
                avatar: user?.avatar || user?.subscriberAvatar?.avatar || '',
                countryId: user.countryId,
                isCurrentUser: false,
                score: userScore,
                avatarBackground: user?.avatarBackground,
                subscriberAvatar: user?.subscriberAvatar,
                isSubscriber: UserService.isUserSubscriber(),
            };
        } else {
            // update user fields from leaderboard table with actual from user profile just for case
            result.avatar = user?.avatar || user?.subscriberAvatar?.avatar || '';
            result.name = user.name;
            result.isCurrentUser = true;
            result.avatarBackground = user?.avatarBackground;
            result.subscriberAvatar = user?.subscriberAvatar;
            if (result.subscriberAvatar) {
                result.subscriberAvatar.frame = result.isSubscriber ? result.subscriberAvatar?.frame : '';
            }
        }

        return result;
    }
    return null;
}

function getCurrentUserPlace(user: UserModel, timeFrame: timeFrames, leaderboardData: LeaderboardDataModel): number {
    let result = 0;
    if (user) {
        leaderboardData.public[timeFrame].forEach((item, index) => {
            if (item.uid === user.uid) {
                result = index + 1;
            }
        });
        return result;
    }
    return result;
}

function getCupLink(leaderboardPlace: number): string {
    switch (leaderboardPlace) {
        case 1:
            return UrlService.toCDNUrl(`/icons/leaderboard_cups/lb_1st.svg`);
        case 2:
            return UrlService.toCDNUrl(`/icons/leaderboard_cups/lb_2nd.svg`);
        case 3:
            return UrlService.toCDNUrl(`/icons/leaderboard_cups/lb_3d.svg`);
        default:
            return '';
    }
}

export interface LeaderboardProps extends RouteComponentProps {
    user: UserModel;
    game: IGame;
    userGameEndScore: number;
    data: LeaderboardDataModel;
    leaderboardNotificationType: LeaderboardNotificationType;
    score: number;
    dispatch?: Dispatch;
}

type LeaderboardRowTooltipState = {
    show: boolean;
    text: string;
    rect?: DOMRect;
};

type LeaderboardState = {
    showUserTopPanel: boolean;
    showUserBottomPanel: boolean;
    headerTabClicked: boolean;
    timeFrame: timeFrames;
    bannersRightMargin: number;
    rowTooltipState: LeaderboardRowTooltipState;
};

class LeaderboardBase extends React.PureComponent<LeaderboardProps, LeaderboardState> {
    state = {
        showUserTopPanel: false,
        showUserBottomPanel: false,
        headerTabClicked: false,
        timeFrame: timeFrames.DAY,
        bannersRightMargin: 0,
        rowTooltipState: {
            show: false,
            text: '',
            rect: null,
        },
    };

    private currentUserRowRef = React.createRef<HTMLDivElement>();
    private leaderboardBodyRef = React.createRef<HTMLDivElement>();
    private leaderboardHeaderRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.setState({
            bannersRightMargin: this.getLeaderboardBodyScrollBarWidth(),
        });
        this.renderStickyBanners(this.leaderboardBodyRef.current);
        if (this.props.leaderboardNotificationType.ICON) {
            this.props.dispatch(
                setLeaderBoardNotificationType({
                    ...this.props.leaderboardNotificationType,
                    ICON: false,
                })
            );
        }

        this.leaderboardHeaderRef.current?.addEventListener('keydown', this.headerArrowClick);
    }

    componentDidUpdate() {
        this.setState({
            bannersRightMargin: this.getLeaderboardBodyScrollBarWidth(),
        });
        if (this.state.headerTabClicked) {
            const lb = this.leaderboardBodyRef.current;
            if (lb) {
                lb.scrollTop = 0;
            }
            this.renderStickyBanners(lb);
            this.setState({ headerTabClicked: false });
        }
    }

    componentWillUnmount() {
        const { leaderboardNotificationType, dispatch } = this.props;
        if (leaderboardNotificationType.ROW) {
            dispatch(
                setLeaderBoardNotificationType({
                    ...leaderboardNotificationType,
                    ROW: false,
                })
            );
        }

        this.leaderboardHeaderRef.current?.removeEventListener('keydown', this.headerArrowClick);
    }

    filterDuplicateRows(data: LeaderboardPublicItemModel[]): LeaderboardPublicItemModel[] {
        let rowsDataUniq = [];
        data.forEach((rd) => {
            if (!rowsDataUniq.find((rd2) => rd2.score === rd.score && rd2.name === rd.name)) {
                rowsDataUniq.push(rd);
            }
        });

        if (rowsDataUniq.length > ROW_LENGTH_LIMIT) {
            rowsDataUniq = rowsDataUniq.slice(0, ROW_LENGTH_LIMIT);
        }

        return rowsDataUniq;
    }

    headerOnClick = (e, timeFrame: timeFrames) => {
        this.setState({
            timeFrame,
            showUserTopPanel: false,
            showUserBottomPanel: false,
            headerTabClicked: true,
        });
    };

    headerArrowClick = ({ key }: KeyboardEvent) => {
        const tabs = [timeFrames.DAY, timeFrames.WEEK, timeFrames.MONTH];
        const currentTabIndex = tabs.findIndex((item) => item === this.state.timeFrame);

        if (key === 'ArrowLeft') {
            if (currentTabIndex === 0) {
                return;
            }
            this.setState({ timeFrame: tabs[currentTabIndex - 1] });
            document.getElementById(`${tabs[currentTabIndex - 1]}-tab`)?.focus();
        } else if (key === 'ArrowRight') {
            if (currentTabIndex === 2) {
                return;
            }
            this.setState({ timeFrame: tabs[currentTabIndex + 1] });
            document.getElementById(`${tabs[currentTabIndex + 1]}-tab`)?.focus();
        }
    };

    showTopPanel = () => {
        this.setState({ showUserTopPanel: true });
    };

    hideTopPanel = () => {
        this.setState({ showUserTopPanel: false });
    };

    showBottomPanel = () => {
        this.setState({ showUserBottomPanel: true });
    };

    hideBottomPanel = () => {
        this.setState({ showUserBottomPanel: false });
    };

    getLeaderboardBodyScrollBarWidth = () => {
        const lb = this.leaderboardBodyRef.current;
        let scrollbarWidth = 0;
        if (lb) {
            scrollbarWidth = lb.offsetWidth - lb.clientWidth;
        }
        return scrollbarWidth;
    };

    renderStickyBanners = (leaderboardBodyRef: HTMLDivElement) => {
        const userRow = this.currentUserRowRef.current;
        const leaderboardBody = leaderboardBodyRef;

        if (userRow && leaderboardBody) {
            const userRowRect = userRow.getBoundingClientRect();
            const userRowTop = userRowRect.top;
            const userRowHeight = userRowRect.bottom - userRowRect.top;

            const leaderboardBodyRect = leaderboardBody.getBoundingClientRect();
            const leaderboardBodyTop = leaderboardBodyRect.top;
            const leaderboardBodyHeight = leaderboardBodyRect.bottom - leaderboardBodyRect.top;
            const leaderboardBodyHeightPlusTop = leaderboardBodyHeight + leaderboardBodyRect.top;
            const userRowRelativeTop = leaderboardBodyTop - userRowTop;
            const userRowRelativeBottom = userRowTop + userRowHeight;

            if (userRowRelativeTop > 0) {
                this.showTopPanel();
            } else if (userRowRelativeBottom > leaderboardBodyHeightPlusTop) {
                this.showBottomPanel();
            } else {
                this.hideTopPanel();
                this.hideBottomPanel();
            }
        }
    };

    bannerOnClick = (e, rowCount: number, userNotInRange = false) => {
        const userRow = this.currentUserRowRef.current;
        const leaderboardBody = this.leaderboardBodyRef.current;

        if (userNotInRange && leaderboardBody) {
            leaderboardBody.scrollTo(0, leaderboardBody.scrollHeight);
            return;
        }

        if (userRow && leaderboardBody) {
            const userRowRect = userRow.getBoundingClientRect();
            const userRowHeight = userRowRect.bottom - userRowRect.top;

            const leaderboardBodyRect = leaderboardBody.getBoundingClientRect();
            const leaderboardBodyHeight = leaderboardBodyRect.bottom - leaderboardBodyRect.top;

            const rowsHeight = userRowHeight * (rowCount - 1);
            const bodyHalfHeight = leaderboardBodyHeight / 2;
            const rowHalfHeight = userRowHeight / 2;
            const scrollOffset = rowsHeight - bodyHalfHeight + rowHalfHeight;
            leaderboardBody.scrollTo(0, scrollOffset);
        }
    };

    tooltipShow = (scoreText: string, target: HTMLElement) => {
        this.setState({
            rowTooltipState: {
                show: true,
                text: scoreText,
                rect: target.getBoundingClientRect(),
            },
        });
    };

    tooltipHide = () => {
        this.setState({
            rowTooltipState: {
                show: false,
                text: '',
                rect: null,
            },
        });
    };

    leaderboardHeader = (): JSX.Element => {
        const isDailyTabActive = this.state.timeFrame === timeFrames.DAY;
        const isWeeklyTabActive = this.state.timeFrame === timeFrames.WEEK;
        const isMonthlyTabActive = this.state.timeFrame === timeFrames.MONTH;

        return (
            <div className={styles.leaderboardHeader} role="tablist" ref={this.leaderboardHeaderRef}>
                <Button
                    className={classNames(styles.dayTab, {
                        [styles.activeTab]: isDailyTabActive,
                    })}
                    onClick={(e) => this.headerOnClick(e, timeFrames.DAY)}
                    data-testid="daily_tab"
                    id="day-tab"
                    role="tab"
                    aria-selected={isDailyTabActive}
                    aria-controls="day-tabpanel"
                    focusable={isDailyTabActive}
                >
                    Daily
                </Button>
                <Button
                    className={classNames(styles.weekTab, {
                        [styles.activeTab]: isWeeklyTabActive,
                    })}
                    onClick={(e) => this.headerOnClick(e, timeFrames.WEEK)}
                    data-testid="weekly_tab"
                    id="week-tab"
                    role="tab"
                    aria-selected={isWeeklyTabActive}
                    aria-controls="week-tabpanel"
                    focusable={isWeeklyTabActive}
                >
                    Weekly
                </Button>
                <Button
                    className={classNames(styles.monthTab, {
                        [styles.activeTab]: isMonthlyTabActive,
                    })}
                    onClick={(e) => this.headerOnClick(e, timeFrames.MONTH)}
                    data-testid="monthly_tab"
                    id="month-tab"
                    role="tab"
                    aria-selected={isMonthlyTabActive}
                    aria-controls="month-tabpanel"
                    focusable={isMonthlyTabActive}
                >
                    Monthly
                </Button>
            </div>
        );
    };

    leaderboardGamePromo = (): JSX.Element => {
        const { game } = this.props;
        return (
            <div className={styles.gamePromoContainer}>
                <img alt={game.name} className={styles.gamePromo} src={game.promoTile280x600} />
            </div>
        );
    };

    replaceLocalDataWithNew = (data, user, score: number, timeFrame: timeFrames) => {
        let skipForcedUpdate = false;
        // to start showing local data and not wait 5 sec from backend
        if (data.public[timeFrame].length > 0) {
            // update table if user exists
            data.public[timeFrame].forEach((item, key) => {
                if (item.uid === user.uid) {
                    data.public[timeFrame].isCurrentUser = true;
                    skipForcedUpdate = true;
                    if (score > data.public[timeFrame][key].score) {
                        data.public[timeFrame][key].score = score;
                        data.public[timeFrame].splice(key, 1);
                    }
                }
            });
            // add user score if it not exists in table
            if (!skipForcedUpdate) {
                data.public[timeFrame].forEach((item, key) => {
                    if (score > item.score) {
                        data.public[timeFrame].splice(key, 0, {
                            isCurrentUser: true,
                            countryCode: user.countryId,
                            countryId: user.countryId,
                            isSubscriber: user.isSubscriber,
                            name: user.name,
                            score: score,
                            subscriberAvatar: user.subscriberAvatar,
                            uid: user.uid,
                            userId: user.uid,
                        });
                    }
                });
            }
        } else {
            // add user score if no entry exist
            data.public[timeFrame][0] = {
                isCurrentUser: true,
                countryCode: user.countryId,
                countryId: user.countryId,
                isSubscriber: user.isSubscriber,
                name: user.name,
                score: score,
                subscriberAvatar: user.subscriberAvatar,
                uid: user.uid,
                userId: user.uid,
            };
        }

        data.user[timeFrame] = data.user[timeFrame] < score ? score : data.user[timeFrame];

        return data;
    };

    leaderboardLoggedIn = (): JSX.Element => {
        const { timeFrame, bannersRightMargin } = this.state;
        let { data, user } = this.props;
        data = this.props.score > 0 ? this.replaceLocalDataWithNew(data, user, this.props.score, timeFrame) : data;
        data.public[timeFrame] = this.filterDuplicateRows(data.public[timeFrame]);
        const userRowData = getCurrentUserData(user, timeFrame, data);
        const userPlace = getCurrentUserPlace(user, timeFrame, data);
        const updatedData = data.public[timeFrame];
        const userInRange = userRowData && userPlace !== 0;
        const userHasScore = userRowData && userRowData.score > 0;

        let leaderboardScrollableBodyStyle = {};

        if (!userInRange && userHasScore) {
            // to allow view last row, just add padding equal one row height;
            leaderboardScrollableBodyStyle = { paddingBottom: `${ROW_HEIGHT}` };
        }
        const showTopBanner = this.state.showUserTopPanel && userInRange;
        const showBottomBanner = (this.state.showUserBottomPanel && userInRange) || (!userInRange && userHasScore);

        const topBanner = (
            <div
                className={classNames(styles.userTopBanner, {
                    [styles.show]: showTopBanner,
                })}
                style={{ right: `${bannersRightMargin}px` }}
            >
                <LeaderboardRow
                    onClick={(e) => {
                        this.bannerOnClick(e, userPlace);
                    }}
                    place={userPlace}
                    highlightCurrent
                    tooltipShow={this.tooltipShow}
                    tooltipHide={this.tooltipHide}
                    {...userRowData}
                    email={user.email}
                    history={this.props.history}
                />
            </div>
        );

        const bottomBanner = (
            <div
                className={classNames(styles.userBottomBanner, {
                    [styles.show]: showBottomBanner,
                })}
                style={{ right: `${bannersRightMargin}px` }}
            >
                <LeaderboardRow
                    onClick={(e) => {
                        this.bannerOnClick(e, userPlace, !userInRange);
                    }}
                    place={userPlace}
                    highlightCurrent
                    tooltipShow={this.tooltipShow}
                    tooltipHide={this.tooltipHide}
                    {...userRowData}
                    history={this.props.history}
                />
            </div>
        );

        const header = this.leaderboardHeader();
        const body =
            updatedData.length > 0 ? (
                <div
                    onScroll={({ target }) => {
                        this.renderStickyBanners(target as HTMLDivElement);
                    }}
                    className={styles.leaderboardScrollableBody}
                    style={leaderboardScrollableBodyStyle}
                    ref={this.leaderboardBodyRef}
                >
                    {updatedData.map((item, index) => {
                        const striped = index % 2 === 0;
                        return item.isCurrentUser ? (
                            <LeaderboardRow
                                place={userPlace}
                                key={`${item.uid}_${item.score}`}
                                highlightCurrent
                                ref={this.currentUserRowRef}
                                striped={false}
                                tooltipShow={this.tooltipShow}
                                tooltipHide={this.tooltipHide}
                                {...item}
                                history={this.props.history}
                            />
                        ) : (
                            <LeaderboardRow
                                place={index + 1}
                                key={`${item.uid}_${item.score}`}
                                highlightCurrent={false}
                                striped={striped}
                                tooltipShow={this.tooltipShow}
                                tooltipHide={this.tooltipHide}
                                {...item}
                                history={this.props.history}
                            />
                        );
                    })}
                </div>
            ) : (
                this.emptyDataPanel()
            );

        const gamePromo = this.leaderboardGamePromo();

        return (
            <div className={styles.leaderboardContainer}>
                <div className={styles.leaderboardContent}>
                    {header}
                    {topBanner}
                    <div
                        role="tabpanel"
                        id={`${this.state.timeFrame}-tabpanel`}
                        aria-labelledby={`${this.state.timeFrame}-tab`}
                        tabIndex={0}
                        className={styles.overflowFix}
                    >
                        {body}
                    </div>
                    {bottomBanner}
                </div>
                {gamePromo}
            </div>
        );
    };

    emptyDataPanel = (): JSX.Element => {
        return (
            <div className={styles.emptyDataPanelContainer}>
                <img
                    className={styles.emptyDataPanelAsset}
                    src={UrlService.toCDNUrl(
                        '/illustrations/ad-divider/illustrations-ad-divider-ad-divider-licening.png'
                    )}
                    alt="empty-data"
                />
                <div className={styles.emptyDataPanelTextContainer}>
                    <p
                        className={styles.emptyDataPanelText}
                        dangerouslySetInnerHTML={{
                            __html: TranslationService.translateIt('LEADERBOARD_NO_DATA_TEXT1'),
                        }}
                    />
                    <p
                        className={styles.emptyDataPanelText}
                        dangerouslySetInnerHTML={{
                            __html: TranslationService.translateIt('LEADERBOARD_NO_DATA_TEXT2'),
                        }}
                    />
                </div>
            </div>
        );
    };

    leaderboardNotLoggedIn = (): JSX.Element => {
        const { timeFrame } = this.state;
        const { data } = this.props;
        // TODO: tmp fix issue with duplicate rows from back-end
        const rowsData = this.filterDuplicateRows(data.public[timeFrame]);

        const header = this.leaderboardHeader();

        const body =
            rowsData.length > 0 ? (
                <div className={styles.leaderboardScrollableBody} ref={this.leaderboardBodyRef}>
                    {rowsData.map((item, index) => {
                        const striped = index % 2 === 0;
                        return (
                            <LeaderboardRow
                                place={index + 1}
                                key={`${item.uid}_${item.score}`}
                                highlightCurrent={false}
                                striped={striped}
                                tooltipShow={this.tooltipShow}
                                tooltipHide={this.tooltipHide}
                                {...item}
                                history={this.props.history}
                            />
                        );
                    })}
                </div>
            ) : (
                this.emptyDataPanel()
            );

        const signInPanel =
            this.props.userGameEndScore > 0 ? (
                <div className={styles.userBottomNotLoggedInBanner}>
                    <LeaderboardSignInPanel score={this.props.userGameEndScore} />
                </div>
            ) : null;

        const gamePromo = this.leaderboardGamePromo();

        return (
            <div className={styles.leaderboardContainer}>
                <div className={styles.leaderboardContent}>
                    {header}
                    {body}
                    {signInPanel}
                </div>
                {gamePromo}
            </div>
        );
    };

    render() {
        const { user } = this.props;
        const { rowTooltipState } = this.state;
        let leaderboard: JSX.Element;
        if (user) {
            leaderboard = this.leaderboardLoggedIn();
        } else {
            leaderboard = this.leaderboardNotLoggedIn();
        }
        return (
            <>
                <Tooltip
                    show={rowTooltipState.show}
                    text={rowTooltipState.text}
                    targetBoundingClientRect={rowTooltipState.rect}
                />
                <div
                    id="leaderboard-tabpanel"
                    className={styles.leaderboardTabpanel}
                    tabIndex={0}
                    role="tabpanel"
                    aria-labelledby="leaderboard-tab"
                >
                    {leaderboard}
                </div>
            </>
        );
    }
}

type LeaderboardRowProps = {
    place: number;
    history: History;
    countryId?: string;
    highlightCurrent?: boolean;
    striped?: boolean;
    onClick?: (e) => void;
    tooltipShow?: (scoreText: string, target: HTMLElement) => void;
    tooltipHide?: () => void;
    leaderboardNotificationType?: LeaderboardNotificationType;
    email?: string;
} & LeaderboardPublicItemModel;

type scoreModel = {
    delimited: string;
    short: string;
};

const cupsDefinition = {
    1: 'Gold cup',
    2: 'Silver cup',
    3: 'Bronze cup',
};

const LeaderboardRow = React.forwardRef<HTMLDivElement, LeaderboardRowProps>((props, ref) => {
    const cupLink = getCupLink(props.place);
    const countryCode = props.countryId || 'US';
    const countryFlagLink = UrlService.toCDNUrl(`/icons/flags-icons/${countryCode.toLowerCase()}.png`);
    const countryItem = useMemo(() => CountriesList.find((item) => item.id === countryCode), [countryCode]);
    const flagImageAlt = countryItem?.name;
    const cupImageAlt = cupsDefinition[props.place];

    const formatScore = (score: number): scoreModel => {
        // DOCS: https://miro.com/app/board/o9J_kuSVkDI=/

        const scoreStr = score?.toString();
        let short = '';
        // more than 999999 millions, f.e. 4014466688350289
        if (scoreStr?.length > 12) {
            const numberPart = scoreStr?.substring(0, scoreStr?.length - 10);
            const part1 = numberPart.substring(0, numberPart.length - 2);
            const part2 = numberPart.substring(part1.length);
            const part1Formatted = numberFormatter.format(Number(part1));
            short = `${part1Formatted}.${part2} MM`;
        }
        // more than 1 million
        else if (scoreStr?.length > 7) {
            const numberPart = scoreStr?.substring(0, scoreStr?.length - 4);
            const part1 = numberPart.substring(0, numberPart.length - 2);
            const part2 = numberPart.substring(part1.length);
            const part1Formatted = numberFormatter.format(Number(part1));
            short = `${part1Formatted}.${part2} M`;
        } else {
            short = numberFormatter.format(score);
        }

        const result = {
            delimited: numberFormatter.format(score),
            short,
        };
        return result;
    };
    const score = formatScore(props.score);
    const showScoreTooltip = score.short.indexOf('M') !== -1;

    const goToPublicProfile = () => {
        props.history.push(UrlService.createURL(`/profile/${props.uid}`));
    };
    return (
        <div
            className={classNames(styles.leaderboardRow, {
                [styles.rowStripe]: props.striped,
                [styles.highlightCurrent]: props.highlightCurrent,
            })}
            ref={ref}
            onClick={(e) => {
                props.onClick?.(e);
            }}
            role="group"
            aria-label="player"
        >
            <div className={styles.details}>
                <div className={styles.detailsPlaceAndCup}>
                    <div className={styles.detailsPlace}>
                        <span>{props.place !== 0 ? props.place : null}</span>
                        {props.leaderboardNotificationType?.ROW && (
                            <LeaderboardNotificationIcon place="leaderboard-row" />
                        )}
                    </div>
                    <div className={styles.detailsCup}>
                        {cupLink ? (
                            <img className={styles.cupImage} src={cupLink} alt={cupImageAlt} />
                        ) : (
                            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE}>
                                <div className={styles.cupImage} />
                            </Responsive>
                        )}
                    </div>
                </div>
                <NavLink
                    tabIndex={-1}
                    className={classNames(styles.detailsAvatar)}
                    to={UrlService.createURL(`/profile/${props.uid}`)}
                >
                    <Avatar
                        image={props.avatar || props.subscriberAvatar?.avatar}
                        size={AvatarTypes.SMALL_LEADERBOARD}
                        regularMousePointer
                        email={props.email}
                        frame={props.isSubscriber ? props.subscriberAvatar?.frame : ''}
                        background={props.avatarBackground || DEFAULT_AVATAR_BACKGROUND}
                        isLeaderBoard
                        isLeaderBoardHoverOn={props.isSubscriber}
                    />
                </NavLink>
                <div className={styles.detailsFlag}>
                    <img className={styles.countryFlag} src={countryFlagLink} alt={flagImageAlt} />
                </div>
            </div>
            <NavLink to={UrlService.createURL(`/profile/${props.uid}`)} className={styles.name}>
                <img alt={flagImageAlt} className={styles.countryFlagMobile} src={countryFlagLink} />

                <span className={styles.textEllipsis} onClick={goToPublicProfile}>
                    {props.name}
                </span>
            </NavLink>
            <div className={classNames(styles.rowScore, styles.scoreTextContainer)}>
                <span
                    className={classNames(styles.textEllipsis, styles.scoreText)}
                    onMouseOver={({ target }) => {
                        if (showScoreTooltip) {
                            props.tooltipShow?.(score.delimited, target as HTMLElement);
                        }
                    }}
                    onMouseLeave={() => {
                        props.tooltipHide?.();
                    }}
                    onFocus={({ target }) => {
                        if (showScoreTooltip) {
                            props.tooltipShow?.(score.delimited, target);
                        }
                    }}
                >
                    {score.short}
                </span>
            </div>
        </div>
    );
});

type LeaderboardSignInPanelProps = {
    score: number;
};

const LeaderboardSignInPanel = React.memo((props: LeaderboardSignInPanelProps) => {
    const dispatch = useDispatch();

    return (
        <div className={styles.leaderboardSignInRow}>
            <div className={styles.signInAvatar}>
                <Avatar image="" size={AvatarTypes.SMALL_LEADERBOARD} />
            </div>
            <div className={styles.signInButton}>
                <BorderedButton
                    filled
                    caption={TranslationService.translateIt('LEADERBOARD_LOGIN_BUTTON_TEXT')}
                    className={styles.loginButton}
                    dataElementDescription="leaderboard-login-button"
                    onClick={() => {
                        batch(() => {
                            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                            dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
                        });
                    }}
                />
            </div>
            <div className={classNames(styles.signInScore, styles.scoreTextContainer)}>
                <span>{numberFormatter.format(props.score)}</span>
            </div>
        </div>
    );
});

export default withRouter(
    connect((state) => ({
        user: state.user,
        leaderboardNotificationType: state.leaderboardNotificationType,
    }))(LeaderboardBase) as React.ComponentType<any>
);
