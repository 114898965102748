import React from 'react';

import classNames from 'classnames';

import { BorderedButton } from '../../../atoms/Buttons/BorderedButton/BorderedButton';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { UserModel } from '../../../models/User/UserModel';
import { CircleWithIcon } from '../../../molecules/CircleWIthIcon/CircleWithIcon';
import { Analytics } from '../../../services/Analytics/Analytics';
import { ProfileProcessService } from '../../../services/ProfileProcessService';
import { TranslationService } from '../../../services/TranslationService';
import { UserEffects } from '../../../store/effects/user.effects';
import { FinalScreen } from './FinalScreen/FinalScreen';
import styles from './ProgressBar.css';
import { RegistrationSource } from '../../../models/User/RegistrationSource';

export interface ProgressBarProps {
    currentStep: number;
    switchPage: () => void;
    activePagePublic: boolean;
    dispatch: any;
    user: UserModel;
    stepsOverride?: any[];
    loginRegistrationSource?: RegistrationSource;
}

const circleSize = '3.74rem';
const colorEmpty = '#ffffff';
const colorFilled = '#00a371';

type ColorEmpty = '#ffffff';
type ColorField = '#00a371';

export class ProgressBar extends React.Component<ProgressBarProps> {

    render() {

        const { currentStep, switchPage, activePagePublic, dispatch } = this.props;

        let steps = null;

        const stepsDefault = [
            {
                number: 1,
                text: TranslationService.translateIt('SELECT_AN_AVATAR_AND_SCREEN_NAME'),
                button: '',
            },
            {
                number: 2,
                text: TranslationService.translateIt('TELL_US_YOUR_NAME_AND_BIRTHDAY'),
                button: TranslationService.translateIt('TAKE_ME_THERE'),
                click: () => {
                    Analytics.trackEvent(Analytics.profile.onboardingPersonal(this.props.loginRegistrationSource));
                    switchPage();
                },
            },
            {
                number: 3,
                text: TranslationService.translateIt('GET_GAME_TIPS_AND_COUPONS'),
                button: TranslationService.translateIt('SURE'),
                click: () => {
                    Analytics.trackEvent(Analytics.profile.onboardingEmail(this.props.loginRegistrationSource));
                    dispatch(
                        UserEffects.updateUser({
                            emailRetrievalConsent: true,
                            ...ProfileProcessService.getStepForUpdate(32),
                        })
                    );
                },
            },
        ];

        steps = [...stepsDefault];

        function getButtonText(stepData, activePagePublic) {
            let res = '';
            if (
                (stepData.number === 2 && activePagePublic && currentStep === 21) ||
                (stepData.number === 3 && currentStep === 31)
            ) {
                res = stepData.button;
            }

            return res;
        }

        function getInnerObject(stepData) {
            return stepData.number >= Math.trunc(currentStep / 10) ? stepData.number : '';
        }

        function getBackgroundCircleColor(stepData) {
            return stepData.number * 10 < currentStep ? colorFilled : colorEmpty;
        }

        function getInnerObjectColor(stepData) {
            return stepData.number * 10 < currentStep ? colorEmpty : colorFilled;
        }

        return (
            <div
                className={classNames(styles.blockPlaceholder, {
                    [styles.highLightedBlock]: currentStep === 32,
                })}
            >
                <div className={styles.stepsWrapper}>
                    {currentStep !== 32 &&
                        steps.map((stepData) => (
                            <Step
                                key={stepData.text}
                                captionText={TranslationService.translateIt(stepData.text)}
                                buttonText={getButtonText(stepData, activePagePublic)}
                                innerObject={getInnerObject(stepData)}
                                backgroundCircleColor={getBackgroundCircleColor(stepData)}
                                innerObjectColor={getInnerObjectColor(stepData)}
                                onClick={() => {
                                    stepData.click();
                                }}
                                isStepCurrent={stepData.number === Math.trunc(currentStep / 10)}
                            />
                        ))}
                </div>
                <div
                    className={classNames(styles.finalScreenWrapper, {
                        [styles.visible]: currentStep === 32,
                    })}
                >
                    <FinalScreen colorFilled={colorFilled} circleSize={circleSize} colorEmpty={colorEmpty} />
                </div>
            </div>
        );
    }
}

type StepProps = {
    isStepCurrent: boolean;
    backgroundCircleColor: ColorEmpty | ColorField;
    innerObjectColor: ColorEmpty | ColorField;
    innerObject: number | '';
    captionText: string;
    buttonText: string;
    onClick: () => void;
};

const Step = React.memo(
    ({
        isStepCurrent,
        backgroundCircleColor,
        innerObjectColor,
        innerObject,
        captionText,
        buttonText,
        onClick,
    }: StepProps) => (
        <div className={styles.step} aria-current={isStepCurrent ? 'step' : null}>
            <CircleWithIcon
                backgroundColor={backgroundCircleColor}
                size={circleSize}
                innerObjectColor={innerObjectColor}
                innerObject={innerObject}
            />
            <div className={styles.captionText}>
                <I18nText keyName={captionText} />
            </div>
            <div
                className={classNames(styles.bottomButton, {
                    [styles.visible]: buttonText,
                })}
            >
                <BorderedButton caption={buttonText} onClick={onClick} />
            </div>
        </div>
    )
);
