import React, { FormEvent, useEffect } from 'react';

import classNames from 'classnames';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { chooseGemsPacksPlan, PaymentType, RecurlyPlanObject, RecurlyPlans } from '../../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import { BillingBlock } from '../../../molecules/Subscription/BillingBlock/BillingBlock';
import { ChosenPurchaseBlock } from '../../../molecules/Subscription/ChosenPurchaseBlock/ChosenPurchaseBlock';
import { PaymentBlock } from '../../../molecules/Subscription/PaymentBlock/PaymentBlock';
import { AuthForm } from '../../../organisms/AuthForm/AuthForm';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { LocalStorageService } from '../../../services/LocalStorage';
import { gemsShopLocationSelector } from '../../../store/ducks/gems/gemsSelectors';
import { LS_SHOP_LOCATION_PROP } from '../../../store/ducks/leanplum/lpAnalytics';
import { SubscriptionStep2Content } from '../../../store/ducks/subscription/common';
import styles from '../SubscriptionTemplate.css';
import { LS_SENT_AI_GEMS_NEXT_STEP } from '../SummaryInfo/SubmitButton';

interface SubscriptionStep2Props {
  giftCardCode: string;
  handleSmoothScrolling: (v: { isScrolling: boolean, id: string }) => void;
  price: any; // recurly obj
  handleSubmit: (e: FormEvent<HTMLFormElement> | Event) => void;
  paymentType: PaymentType;
  paymentGoodPlan?: RecurlyPlanObject;
  isSubscriber?: boolean;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  t: WithTranslation['t'];
}

export const SubscriptionStep2 = React.forwardRef<HTMLFormElement, SubscriptionStep2Props>(
  (
    {
      giftCardCode,
      handleSmoothScrolling,
      price,
      handleSubmit,
      paymentType,
      paymentGoodPlan,
      isSubscriber,
      gemsAnalyticsProps,
      t
    }: SubscriptionStep2Props,
    ref
  ) => {
    const userData = useSelector(({ billingInfoValues }) => billingInfoValues);
    const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
    const planCode = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan || SubscriptionPlans.ANNUAL);
    const subscriptionStep2Content = useSelector(({ subscriptionStep2Content }) => subscriptionStep2Content);
    const gemsShopLocation = useSelector(gemsShopLocationSelector);
    const shopLocation = LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || gemsShopLocation;
    const subscriptionPlanType =
      [
        ...Object.values(RecurlyPlans[PaymentType.subscription]),
        ...Object.values(chooseGemsPacksPlan(isSubscriber))
      ]
        .filter(
          (recurlyPlan: { key: string }) => recurlyPlan.key === (paymentGoodPlan ? paymentGoodPlan.key : planCode)
        )
        .map((recurlyPlan: { key: string }) => {
          let period: string;

          if (recurlyPlan.key === SubscriptionPlans.MONTHLY) {
            period = 'month';
          } else if (recurlyPlan.key === SubscriptionPlans.ANNUAL) {
            period = 'year';
          } else {
            period = '';
          }

          return ({
            label: recurlyPlan.key,
            price: price?.now?.subtotal,
            period
          });
        })[0] || // safer fallback to old values
      (planCode === SubscriptionPlans.MONTHLY
        ? {
          label: 'Monthly',
          price: price?.now?.subtotal,
          period: 'month'
        }
        : {
          label: 'Annual',
          price: price?.now?.subtotal,
          period: 'year'
        });

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const { gemsPackId, gemsInPack, priceInGem } = gemsAnalyticsProps;
    const asyncAnalytics = async () => {
      void Analytics.trackEvent(
        Analytics.gems.gemPurchaseNextStepImpression(priceInGem, gemsPackId, gemsInPack, shopLocation),
        false
      );
      window.sessionStorage.setItem(LS_SENT_AI_GEMS_NEXT_STEP, 'SENT');
    };
    const renderContent = (data: { step: number, type: SubscriptionStep2Content }) => {
      switch (data.type) {
        case SubscriptionStep2Content.PurchaseDetails:
          if (paymentType === PaymentType.gems && !window.sessionStorage.getItem(LS_SENT_AI_GEMS_NEXT_STEP)) {
            void asyncAnalytics();
          }

          return (
            <>
              <div className={styles.mainStepHeading}>{t('PAYMENT_PAGE.SUBSCRIPTION_PURCHASE_DETAILS_TITLE')}</div>
              <ChosenPurchaseBlock
                type={subscriptionPlanType}
                planCode={planCode}
                paymentType={paymentType}
                gemsAnalyticsProps={gemsAnalyticsProps}
              />
              <div className={styles.paymentRow}>
                <PaymentBlock
                  handleSmoothScrolling={handleSmoothScrolling}
                  userData={userData}
                  giftCardCode={giftCardCode}
                />
                {paymentMethod === PaymentMethod.CARD && (
                  <BillingBlock handleSmoothScrolling={handleSmoothScrolling} userData={userData}/>
                )}
              </div>
            </>
          );

        case SubscriptionStep2Content.AuthForm:
          return (
            <>
              <div
                className={classNames(
                  styles.mainStepHeading,
                  data.step === 2 && styles.registerFormTitle
                )}
              >
                {data.step === 1 && t('PAYMENT_PAGE.SUBSCRIPTION_AUTH_FORM_TITLE_FIRST_STEP')}
                {data.step === 2 && t('PAYMENT_PAGE.SUBSCRIPTION_AUTH_FORM_TITLE_SECOND_STEP')}
              </div>
              {data.step === 1 && (
                <div className={styles.subTitle}>{t('PAYMENT_PAGE.SUBSCRIPTION_AUTH_FORM_SUBTITLE')}</div>
              )}
              <AuthForm step={data.step === 1 ? 1 : 2} handleSubmit={handleSubmit} ref={ref}/>
            </>
          );

        default:
          break;
      }
    };

    return renderContent(subscriptionStep2Content);
  }
);
