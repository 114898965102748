import React from 'react';

import Box from '@mui/material/Box';

import styles from './BlogPostModularCard.css';
import { Media } from '../../../models/Strapi';
import { UrlService } from '../../../services/UrlService';

type BlogPostModularCardProps = {
    imgAlt: string;
    date: string;
    category: string;
    title: string;
    summary: string;
    image: Media;
    setUrl?: string;
};

enum MEDIA_SIZES {
    Large = 1024,
    Medium = 768,
    Small = 480,
}

export const BlogPostModularCard = React.memo(
    ({ imgAlt, date, title, summary, image, setUrl }: BlogPostModularCardProps) => {
        const { formats, url } = image;

        return (
            <a href={setUrl} className={styles.noDecoration}>
                <Box className={styles.clickableArea}>
                    <Box className={styles.imgContainer}>
                        <picture>
                            {formats.large && (
                                <source
                                    media={`(min-width: ${MEDIA_SIZES.Large}px)`}
                                    srcSet={UrlService.createStrapiMediaUrl(formats.large?.url)}
                                    type={formats.large?.mime}
                                />
                            )}
                            {formats.medium && (
                                <source
                                    media={`(min-width: ${MEDIA_SIZES.Medium}px)`}
                                    srcSet={UrlService.createStrapiMediaUrl(formats.medium?.url)}
                                    type={formats.medium?.mime}
                                />
                            )}
                            {formats.small && (
                                <source
                                    media={`(min-width: ${MEDIA_SIZES.Small}px)`}
                                    srcSet={UrlService.createStrapiMediaUrl(formats.small?.url)}
                                    type={formats.small?.mime}
                                />
                            )}
                            <img src={UrlService.createStrapiMediaUrl(url)} alt={imgAlt} />
                        </picture>
                    </Box>
                    <Box className={styles.infoRow}>
                        <Box>{date}</Box>
                        {/* <Box>{category}</Box> */}
                    </Box>
                    <Box component="h2" className={styles.title}>
                        {title}
                    </Box>
                </Box>
                <Box className={styles.summary}>{summary}</Box>
            </a>
        );
    }
);
