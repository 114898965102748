import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './BirthdayEditor.css';
import { MonthsShort } from '../../../../constants/Months';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { UserModel } from '../../../../models/User/UserModel';
import { DropdownDesktop } from '../../../../molecules/DropdownDesktop/DropdownDesktop';
import { DeviceDetector, DeviceType } from '../../../../services/DeviceDetector';
import { TranslationService } from '../../../../services/TranslationService';
import { FieldEdit } from '../../EditorTemplates/FieldEdit/FieldEdit';
import { FieldView } from '../../EditorTemplates/FieldView/FieldView';

enum TOOLTIP_TEXT {
  TOOLTIP_TOP = 'BIRTHDAY_TOOLTIP',
  TOOLTIP_BOTTOM = '',
}

export type BirthdayEditorProps = {
  inEdit: boolean;
  setInEdit: any;
  user: UserModel;
  updateUser: (updatedUser: { birthday: string }) => void;
  rowContentClassName: string;
  rowEditContentClassName: string;
  captionClassName: string;
  viewClassName?: string;
  title: string;
  openPanel: (panel: ProfileRightSideRoutes) => void;
  hover?: boolean;
};

export const BirthdayEditor = (props: BirthdayEditorProps) => {
  const { inEdit, setInEdit, user, updateUser, viewClassName, title, hover } = props;
  const d = user.birthday;
  const date = new Date(d);
  const months = MonthsShort;
  const [day, setDay] = useState<number>(d ? date.getDate() : -1);
  const [month, setMonth] = useState<string | number>(d ? months[date.getMonth()] : -1);
  const [year, setYear] = useState<number>(d ? date.getFullYear() : -1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const saveButtonDisabled = year === -1 || month === -1 || day === -1;

  useEffect(() => {
    if (inEdit || !d) {
      return;
    }

    const d1 = new Date(d);

    setDay(d1.getDate());
    setMonth(months[d1.getMonth()]);
    setYear(d1.getFullYear());
  }, [props.user]);

  function getNumberOfDaysInMonth(monthData: string, yearData: number) {
    const months30 = [3, 5, 8, 10];
    const leapYear = yearData % 4 === 0;
    const days =
      months.indexOf(monthData) === 1
        ? leapYear
          ? 29
          : 28
        : months30.includes(months.indexOf(monthData))
          ? 30
          : 31;

    return days || 31;
  }

  const updateBirthday = async () => {
    if (year === -1 || month === -1 || day === -1) {
      return;
    }

    const initDate = new Date(year, months.indexOf(String(month)), day);
    const convertDate = new Date(initDate.getTime() - initDate.getTimezoneOffset() * 60000).toISOString();
    const updatedUser = {
      birthday: convertDate
    };

    updateUser(updatedUser);
    setIsLoading(false);
  };

  function setTheMonth(monthData: string) {
    setMonth(monthData);

    if (getNumberOfDaysInMonth(monthData, year) < day) {
      setDay(1);
    }
  }

  function setTheYear(yearData: number) {
    setYear(yearData);

    if (getNumberOfDaysInMonth(month as string, yearData) < day) {
      setDay(1);
    }
  }

  function setTheDay(day: number) {
    setDay(day);
  }

  const editComponent = () => {
    const MonthMap = new Map<string, string>();

    for (let i = 0; i <= 11; i++) {
      MonthMap.set(String(months[i]), String(months[i]));
    }

    const DayMap = new Map<string, string>();

    for (let i = 1; i <= getNumberOfDaysInMonth(month as string, year); i++) {
      DayMap.set(String(i), String(i));
    }

    const currentYear = new Date().getFullYear();
    const YearMap = new Map<string, string>();

    for (let i = 0; i < 120; i++) {
      YearMap.set(String(currentYear - i), String(currentYear - i));
    }

    return (
      <div className={styles.dropdownContainer}>
        <DropdownDesktop
          id="month"
          placeholder={month !== -1 ? month.toString() : 'Month'}
          dropdownItems={MonthMap}
          onClick={(e) => setTheMonth(e)}
        />
        <DropdownDesktop
          id="day"
          placeholder={day !== -1 ? day.toString() : 'Day'}
          dropdownItems={DayMap}
          onClick={(e) => setTheDay(e)}
        />
        <DropdownDesktop
          id="year"
          placeholder={year !== -1 ? year.toString() : 'Year'}
          dropdownItems={YearMap}
          onClick={(e) => setTheYear(e)}
        />
      </div>
    );
  };
  const viewDate = `${month} ${day}, ${year}`;
  const viewComponent = () => {
    return (
      <>
        <div className={styles.hiddenCalendar}>
          <input
            tabIndex={inEdit ? 0 : -1}
            type="date"
            id="profile-bday-input"
            onChange={(e) => {
              const initDate = new Date(e.target.value);
              const convertDate = new Date(
                initDate.getTime() - initDate.getTimezoneOffset() * 60000
              ).toISOString();

              updateUser({
                ...user,
                birthday: convertDate
              });
            }}
          />
        </div>
        <span className={classNames(styles.fieldViewValue, { [styles.hovered]: hover })}>
          {user.birthday ? viewDate : undefined}
        </span>
      </>
    );
  };

  return (
    <>
      <div className={classNames(styles.fields, { [styles.visible]: !inEdit }, { [styles.notVisible]: inEdit })}>
        <FieldView
          title={title}
          titleAlwaysOn
          onMobileEditClick={() => {
            const deviceDetector = new DeviceDetector();
            const deviceType = deviceDetector.DetectDevice();

            if (deviceType === DeviceType.DESKTOP) {
              props.openPanel(ProfileRightSideRoutes.BIRTHDAY_PANEL);
            } else {
              const el = document.getElementById('profile-bday-input');

              el.focus();
              el.click();
            }
          }}
          disableTooltipOnMobile
          onEditClick={() => setInEdit(true)}
          renderViewComponent={viewComponent}
          viewComponentValue={user.birthday ? viewDate : undefined}
          classNameField={viewClassName}
          tooltipEnabled
          tooltipTopText={TranslationService.translateIt(TOOLTIP_TEXT.TOOLTIP_TOP)}
          tooltipBottomText={TranslationService.translateIt(TOOLTIP_TEXT.TOOLTIP_BOTTOM)}
          hover={hover}
        />
      </div>
      <div className={classNames(styles.fields, { [styles.notVisible]: !inEdit }, { [styles.visible]: inEdit })}>
        <FieldEdit
          isLoading={isLoading}
          title={title}
          cancelField={() => setInEdit(false)}
          renderEditComponent={editComponent}
          saveField={async () => {
            setIsLoading(true);
            await updateBirthday();
            setInEdit(false);
          }}
          tooltipEnabled
          tooltipTopText={TranslationService.translateIt(TOOLTIP_TEXT.TOOLTIP_TOP)}
          tooltipBottomText={TranslationService.translateIt(TOOLTIP_TEXT.TOOLTIP_BOTTOM)}
          classNameField="birthdayEditor"
          saveButtonDisabled={saveButtonDisabled}
        />
      </div>
    </>
  );
};
