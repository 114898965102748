import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const ApplePayIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="80"
            height="32"
            viewBox="0 0 80 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M15.0254 4.12559C14.0996 5.20647 12.6184 6.05899 11.1371 5.9372C10.952 4.47574 11.6772 2.92293 12.5258 1.96384C13.4516 0.852521 15.0717 0.0608944 16.3832 0C16.5375 1.52236 15.9358 3.01427 15.0254 4.12559ZM16.3678 6.22645C14.2231 6.10466 12.3869 7.42912 11.3686 7.42912C10.3348 7.42912 8.7764 6.28735 7.07913 6.31779C4.87269 6.34824 2.82054 7.58135 1.69417 9.5452C-0.620281 13.4729 1.09241 19.2883 3.32972 22.4853C4.42523 24.0685 5.73675 25.804 7.46488 25.7431C9.10042 25.6822 9.74847 24.6927 11.7235 24.6927C13.7139 24.6927 14.2848 25.7431 16.0129 25.7127C17.8028 25.6822 18.9291 24.1294 20.0246 22.5461C21.2744 20.7498 21.7836 18.999 21.8145 18.9077C21.7836 18.8773 18.3582 17.5833 18.3274 13.686C18.2965 10.4282 21.0276 8.87536 21.151 8.78402C19.608 6.53092 17.201 6.28735 16.3678 6.22645ZM28.7578 1.81161V25.5452H32.4918V17.431H37.6608C42.3823 17.431 45.6996 14.2341 45.6996 9.60609C45.6996 4.97812 42.444 1.81161 37.7842 1.81161H28.7578ZM32.4918 4.91722H36.7967C40.0369 4.91722 41.8885 6.62226 41.8885 9.62131C41.8885 12.6204 40.0369 14.3406 36.7813 14.3406H32.4918V4.91722ZM52.5196 25.7279C54.8649 25.7279 57.0405 24.5557 58.028 22.6984H58.1051V25.5452H61.5614V13.7317C61.5614 10.3064 58.784 8.09895 54.51 8.09895C50.5446 8.09895 47.6129 10.3368 47.5049 13.412H50.8686C51.1463 11.9505 52.5196 10.9914 54.402 10.9914C56.6856 10.9914 57.9662 12.0419 57.9662 13.9753V15.2845L53.3065 15.5585C48.9707 15.8173 46.6254 17.568 46.6254 20.6127C46.6254 23.6879 49.0479 25.7279 52.5196 25.7279ZM53.5225 22.9115C51.5321 22.9115 50.2668 21.9677 50.2668 20.5214C50.2668 19.0295 51.4858 18.1618 53.8157 18.0247L57.9662 17.7659V19.1056C57.9662 21.3283 56.053 22.9115 53.5225 22.9115ZM66.1748 32C69.8162 32 71.5289 30.6299 73.0256 26.4738L79.5832 8.32731H75.7875L71.3901 22.3482H71.3129L66.9155 8.32731H63.0118L69.3379 25.6061L68.9985 26.6565C68.4276 28.4377 67.5018 29.1227 65.8508 29.1227C65.5576 29.1227 64.9867 29.0923 64.7553 29.0618V31.9087C64.9713 31.9696 65.8971 32 66.1748 32Z"
                    fill="#231F20"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="79" height="32" fill="white" transform="translate(0.583008)" />
                </clipPath>
            </defs>
        </svg>
    );
});
