import React from 'react';

import { BorderedButton } from '../../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { TranslationService } from '../../../../../services/TranslationService';
import styles from './LastStep.css';
import { MiscUtils } from '../../../../../../utils/MiscUtils';
import { LocalStorageService } from '../../../../../services/LocalStorage';

type Props = {
    onClick: () => void;
};

export const LastStep = React.memo((props: Props) => {

    const authProvider = LocalStorageService.getItem('authProvider');

    return (
        <>
            <div className={styles.content}>
                <div className={styles.lastStepText}>Almost done...</div>
                <div className={styles.lastStepComponent}>
                    Would you like to receive game tips, coupons and specials?
                </div>
            </div>
            <div className={styles.footer}>
                <div>
                    <BorderedButton
                        caption={TranslationService.translateIt('SURE')}
                        onClick={() => {
                            props.onClick();
                            Analytics.trackEvent(
                                Analytics.profile.onboardingEmail(MiscUtils.getAuthSource(authProvider))
                            );
                        }}
                        filled
                    />
                </div>
            </div>
        </>
    );
});
