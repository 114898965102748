import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Country, isValidPhoneNumber } from 'react-phone-number-input/input';

import styles from './PhoneEditor.css';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { UserModel } from '../../../../models/User/UserModel';
import PhoneNumberInput from '../../../../molecules/PhoneNumberInput/PhoneNumberInput';
import { FieldEdit } from '../../EditorTemplates/FieldEdit/FieldEdit';
import { FieldView } from '../../EditorTemplates/FieldView/FieldView';

export type PhoneEditorProps = {
    inEdit: boolean;
    setInEdit: any;
    user: UserModel;
    updateUser: (updatedUser) => void;
    rowContentClassName: string;
    rowEditContentClassName: string;
    captionClassName: string;
    viewClassName?: string;
    title: string;
    label: string;
    openPanel: (panel: ProfileRightSideRoutes) => void;
    hover?: boolean;
};

const PhoneEditor = (props: PhoneEditorProps) => {
    const { inEdit, setInEdit, user, updateUser, viewClassName, title, label, hover } = props;

    const [phone, setPhone] = useState(user[label] || '');
    const [isFocused, setIsFocused] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    useEffect(() => {
        setPhone(user[label] || '');
    }, [user[label]]);

    const updatePhone = async () => {
        if ((phone || '').trim().length === 0) {
            return;
        }
        const updatedUser = {
            [label]: phone,
        };
        await updateUser(updatedUser);
    };

    const editComponent = () => {
        return (
            <>
                <PhoneNumberInput
                    value={phone}
                    countryCode={user.countryId as Country}
                    onChange={(v) => {
                        setPhone(v);
                        if (v) {
                            setValidNumber(isValidPhoneNumber(v) === true);
                        }
                    }}
                    onSave={async () => {
                        setInEdit(false);
                        await updatePhone();
                    }}
                    isFocused={isFocused}
                    setIsFocused={setIsFocused}
                />
            </>
        );
    };

    let text: any = '';
    const viewComponent = () => {
        if (user[label]) {
            try {
                const phoneObj = parsePhoneNumber(user[label]);
                text = phoneObj.format('NATIONAL');
            } catch (e) {
                text = user[label];
            }
        }

        return (
            <span
                className={classNames(styles.fieldViewValue, {
                    [styles.hovered]: hover,
                })}
            >
                {text}
            </span>
        );
    };

    return (
        <>
            <div className={classNames(styles.fields, { [styles.visible]: !inEdit }, { [styles.notVisible]: inEdit })}>
                <FieldView
                    title={title}
                    titleAlwaysOn
                    onEditClick={() => setInEdit(true)}
                    renderViewComponent={viewComponent}
                    viewComponentValue={text}
                    classNameField={viewClassName}
                    onMobileEditClick={() => props.openPanel(ProfileRightSideRoutes.SCREEN_NAME_PANEL)}
                    hover={hover}
                />
            </div>
            <div className={classNames(styles.fields, { [styles.notVisible]: !inEdit }, { [styles.visible]: inEdit })}>
                <FieldEdit
                    title={title}
                    cancelField={() => setInEdit(false)}
                    renderEditComponent={editComponent}
                    saveButtonDisabled={(phone || '').trim().length === 0 || !validNumber}
                    saveField={async () => {
                        setInEdit(false);
                        await updatePhone();
                    }}
                />
            </div>
        </>
    );
};

export default PhoneEditor;
