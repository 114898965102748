import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DeviceUtils } from '../../../../../utils/DeviceUtils';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { Avatar, AvatarTypes } from '../../../../atoms/Avatar/Avatar';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { ArrowIcon } from '../../../../atoms/Icons/Styleguide/ArrowIcon';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { EditIcon } from '../../../../FigmaStyleguide/Icons/EditIcon';
import { IGame } from '../../../../models/Game/Game';
import { PublicUserModel, UserModel } from '../../../../models/User/UserModel';
import { Ad, AdTypes, BackgroundToneTypes, CaptionPositionTypes } from '../../../../molecules/Ad/Ad';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { RecentlyPlayedSlider } from '../../../../molecules/RecentlyPlayedSlider/RecentlyPlayedSlider';
import { GameService } from '../../../../services/GameService';
import { HighScoreModel } from '../../../../services/HighScoreService';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import { RECENTLY_PLAYED_QUEUE_LENGTH, RecentlyPlayedModel } from '../../../../services/RecentlyPlayedService';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { setProfilePanelState } from '../../../../store/ducks/layout';
import { UserEffects } from '../../../../store/effects/user.effects';
import { CountryEditor } from '../../Editors/CountryEditor/CountryEditor';
import { ScreenNameEditor } from '../../Editors/ScreenNameEditor/ScreenNameEditor';
import { TooltipMobile } from '../../TooltipMobile/TooltipMobile';
import { Highscore } from './Highscore/Highscore';
import styles from './Public.css';

type PublicProfileProps = {
  games: IGame[];
  recentlyPlayed: RecentlyPlayedModel[];
  highScore: HighScoreModel[];
  user: UserModel;
  publicUser: PublicUserModel;
  currentLang: string;
  dispatch?: any;
  isMyProfile: boolean;
  adFree: boolean;
  isOpenedProfilePanel?: boolean;
};

export const PublicProfileTemplate = React.memo((props: PublicProfileProps) => {
  const { t } = useTranslation();
  const { user: userData, publicUser, isMyProfile, games, recentlyPlayed, currentLang, adFree } = props;
  const activeUserSubscriptions = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions);
  let user = isMyProfile
    ? { ...userData, isSubscriber: UserService.isUserSubscriber() }
    : publicUser || ({} as PublicUserModel);
  const isDesktop = !MiscUtils.isServer && window.matchMedia('(min-width: 1025px)').matches;
  const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';


  const recentlyPlayedGames: IGame[] = setupRecentlyPlayed(
    recentlyPlayed,
    games
  );
  const whyNotTryGames: IGame[] = setupWhyNotTry(
    games,
    RECENTLY_PLAYED_QUEUE_LENGTH
  );
  useEffect(() => {
    user = isMyProfile
      ? { ...userData, isSubscriber: UserService.isUserSubscriber() }
      : publicUser || ({} as PublicUserModel);
  }, [activeUserSubscriptions]);


  const playingSinceFormatted = (): string => {
    const dateString = user.registrationDate ?? '2020-10-05T12:00:00.000Z';
    const date = new Date(dateString);
    const options = { year: 'numeric' as const, month: 'short' as const, day: 'numeric' as const };
    const formatted = new Intl.DateTimeFormat(currentLang, options).format(date);

    return ` ${formatted}`;
  };

  const onAvatarPickerClick = () => {
    props.dispatch(
      setProfilePanelState({
        isOpened: true,
        caption: TranslationService.translateIt('CHOOSE_YOUR_AVATAR'),
        target: ProfileRightSideRoutes.AVATARS_PANEL
      })
    );
  };

  const renderFavoriteGamesSection = () => (
    <div className={classnames(styles.blockRecentlyPlayedBase, styles.blockFavoriteGames)}>
      <RecentlyPlayedSlider
        items={games}
        isFavoriteGamesBlock={true}
        {...(props.isMyProfile
          ? {
            caption: 'MY_FAVORITES_TITLE'
          }
          : {
            caption: 'MY_FAVORITES_PUBLIC_TITLE',
            params: { name: user.name }
          })}
      />
    </div>
  );

  const renderRecentlyPlayedGamesSection = () => {
    if (!recentlyPlayedGames.length && !isMyProfile) {
      return null;
    }
    return (
      <div className={classnames(styles.blockRecentlyPlayedBase, styles.blockRecentlyPlayed)}>
        {recentlyPlayedGames.length ? (
          <RecentlyPlayedSlider
            items={recentlyPlayedGames}
            {...(props.isMyProfile
              ? {
                caption: 'RECENTLY_PLAYED_TITLE'
              }
              : {
                caption: 'RECENTLY_PLAYED_PUBLIC_TITLE',
                params: { name: user.name }
              })}
          />
        ) : (
          <RecentlyPlayedSlider items={whyNotTryGames} caption="WHY_NOT_TRY_TITLE"/>
        )}
      </div>
    );
  };

  return (
    <div className={styles.grid}>
      <div className={styles.blockYourProfile}>
        <div className={styles.yourProfileHeader}>
          <div className={styles.captionContainer}>
            <I18nText
              as="h2"
              keyName={props.isMyProfile ? 'MY_PUBLIC_PROFILE' : 'PUBLIC_PROFILE'}
              className={styles.caption}
              {...(isMyProfile ? {} : { params: { name: user.name } })}
            />
          </div>
          <NotifyIconTooltip
            textTop={TranslationService.translateIt(
              props.isMyProfile ? 'MY_PUBLIC_PROFILE_TOOLTIP' : 'PUBLIC_PROFILE_TOOLTIP'
            )}
            tooltipNotVisible={false}
            classNameMessage="profilePage"
          />
          <div className={styles.createdProfileDate}>
            <I18nText keyName="PLAYING_SINCE"/>
            {playingSinceFormatted()}
          </div>
        </div>
        <div className={classNames(styles.blockPlaceholder, styles.profile)}>
          <div className={styles.createdProfileDateMobile}>
            <I18nText keyName="PLAYING_SINCE"/> {playingSinceFormatted()}
          </div>
          <div className={styles.avatarPanel}>
            <Avatar
              image={userAvatar}
              selectable={false}
              selected={false}
              size={isDesktop ? AvatarTypes.REGULAR : AvatarTypes.MEDIUM}
              onSelect={() => null}
              highlight={isMyProfile && ProfileProcessService.getCurrentStep() === 11}
              email={props.user?.email}
              frame={user?.isSubscriber ? user?.subscriberAvatar?.frame : ''}
              background={user?.avatarBackground}
              disabled
              isPublicProfile={!props.isMyProfile}
            />
            {isMyProfile && (
              <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <Button
                  className={styles.editButton}
                  outlined
                  modalOpens={true}
                  onClick={isMyProfile ? onAvatarPickerClick : null}
                >
                  Edit <EditIcon className={styles.editButtonInner}/>
                </Button>
              </Responsive>
            )}
            {isMyProfile && (
              <Responsive maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}>
                <div className={styles.arrowIconWrapper}>
                  <Button
                    noPadding
                    className={styles.arrowIcon}
                    onClick={onAvatarPickerClick}
                    ariaLabel="avatar-picker"
                  >
                    <ArrowIcon className={styles.editIcon}/>
                  </Button>
                </div>
              </Responsive>
            )}
          </div>
          <div className={styles.fieldsGroup}>
            <ProfilePanelRow highLighted={isMyProfile && ProfileProcessService.getCurrentStep() === 12}>
              {({ inEdit, setInEdit, hover }) => {
                return (
                  <ScreenNameEditor
                    name="screenName"
                    inEdit={inEdit}
                    setInEdit={setInEdit}
                    user={user}
                    boldValue
                    showTitleAsValue
                    onboardingProgress={ProfileProcessService.getStepForUpdate(21)}
                    openPanel={() =>
                      props.dispatch(
                        setProfilePanelState({
                          isOpened: true,
                          target: ProfileRightSideRoutes.SCREEN_NAME_PANEL,
                          caption: TranslationService.translateIt('ARKADIUM_SCREEN_NAME')
                        })
                      )
                    }
                    rowContentClassName={styles.rowContent}
                    rowEditContentClassName={styles.rowEditContent}
                    captionClassName={styles.fieldLabel}
                    title={TranslationService.translateIt(
                      props.isMyProfile ? 'MY_ARKADIUM_SCREEN_NAME' : 'ARKADIUM_SCREEN_NAME'
                    )}
                    label="name"
                    hover={hover}
                    className="public"
                    placeholder={t('SCREEN_NAME_PLACEHOLDER')}
                  />
                );
              }}
            </ProfilePanelRow>
            <ProfilePanelRow>
              {({ inEdit, setInEdit, hover }) => {
                return (
                  <CountryEditor
                    inEdit={inEdit}
                    setInEdit={setInEdit}
                    user={user}
                    updateUser={(updatedUser) => {
                      props.dispatch(UserEffects.updateUser(updatedUser));
                    }}
                    showTitleAsValue
                    openPanel={() =>
                      props.dispatch(
                        setProfilePanelState({
                          isOpened: true,
                          target: ProfileRightSideRoutes.COUNTRY_PICKER_PANEL,
                          caption: TranslationService.translateIt('CHOOSE_YOUR_COUNTRY')
                        })
                      )
                    }
                    rowContentClassName={styles.rowContent}
                    rowEditContentClassName={styles.rowEditContent}
                    captionClassName={styles.fieldLabel}
                    hover={hover}
                    viewTitle={
                      props.isMyProfile
                        ? 'COUNTRY_EDITOR_CAPTION'
                        : 'NOT_MY_COUNTRY_EDITOR_CAPTION'
                    }
                  />
                );
              }}
            </ProfilePanelRow>
          </div>
        </div>
        <TooltipMobile
          tooltipContent={TranslationService.translateIt(
            props.isMyProfile ? 'MY_PUBLIC_PROFILE_TOOLTIP' : 'PUBLIC_PROFILE_TOOLTIP'
          )}
        />
      </div>

      {renderFavoriteGamesSection()}
      {renderRecentlyPlayedGamesSection()}

      {!adFree && (
        <div className={styles.blockLeaderboardMiddleAds}>
          <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE_BELOW}>
            <Ad
              adType={[AdTypes.AD_320x50]}
              backgroundTone={BackgroundToneTypes.LIGHT}
              keepSize
              captionPosition={CaptionPositionTypes.CENTER}
              id="public-profile-m1"
              useDefaultBidTimeout
            />
          </Responsive>
          <Responsive
            minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}
            maxWidth={ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}
          >
            <Ad
              adType={[AdTypes.AD_728x90, AdTypes.AD_320x50]}
              backgroundTone={BackgroundToneTypes.LIGHT}
              keepSize
              captionPosition={CaptionPositionTypes.CENTER}
              id="public-profile-m1"
              useDefaultBidTimeout
            />
          </Responsive>
          <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <Ad
              adType={[AdTypes.AD_970x90, AdTypes.AD_728x90, AdTypes.AD_320x50]}
              backgroundTone={BackgroundToneTypes.LIGHT}
              keepSize
              captionPosition={CaptionPositionTypes.CENTER}
              id="public-profile-m1"
              useDefaultBidTimeout
            />
          </Responsive>
        </div>
      )}
      {Boolean(recentlyPlayedGames.length) && (
        <div className={styles.blockLeaderboard}>
          <Highscore
            highScore={props.highScore}
            games={props.games}
            rowPerPage={10}
            isMyProfile={props.isMyProfile}
            name={user.name}
            adFree={adFree}
          />
        </div>
      )}
      {!adFree && (
        <Responsive
          minWidth={ArkCssBreakpoints.ARK_MEDIUM_LG_DESKTOP}
          maxWidth={ArkCssBreakpoints.ARK_LARGE_DESKTOP_BELOW}
        >
          <div className={styles.blockLeaderboardBottomRightAds}>
            <Ad hasBackground id="public-profile-m2" adType={[AdTypes.AD_300x600]}/>
          </div>
        </Responsive>
      )}
    </div>
  );
});
PublicProfileTemplate.displayName = 'PublicProfileTemplate';

const ProfilePanelRow = (props: any) => {
  const [inEdit, setInEdit] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div
      className={classNames(styles.row, {
        [styles.rowInEdit]: inEdit,
        [styles.highLighted]: props.highLighted && !inEdit,
        [styles.hovered]: hover && !inEdit
      })}
      onMouseMove={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
    >
      {props.children({ inEdit, setInEdit, hover })}
    </div>
  );
};

function setupRecentlyPlayed(recentlyPlayed: RecentlyPlayedModel[], games: IGame[]): IGame[] {
  const bag: IGame[] = [];
  recentlyPlayed.forEach((item) => {
    const game = GameService.findGameByArena5Slug(games, item.slug);
    if (game) {
      bag.push(game);
    }
  });
  return bag;
}

function setupWhyNotTry(games: IGame[], length: number) {
  let bag: IGame[] = [];
  if (MiscUtils.isServer || DeviceUtils.isDesktopDevice()) {
    bag = games.sort((a, b) => (a.desktopRank > b.desktopRank ? 1 : -1)).slice(0, length);
  } else if (DeviceUtils.isTabletDevice()) {
    bag = games.sort((a, b) => (a.tabletRank > b.tabletRank ? 1 : -1)).slice(0, length);
  } else if (DeviceUtils.isPhoneDevice()) {
    bag = games.sort((a, b) => (a.mobileRank > b.mobileRank ? 1 : -1)).slice(0, length);
  }
  return bag;
}
