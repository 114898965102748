import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Button } from '../../../../atoms/Buttons/Base/Base';
import { ArrowIcon } from '../../../../atoms/Icons/Styleguide/ArrowIcon';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { EditIcon } from '../../../../FigmaStyleguide/Icons/EditIcon';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import styles from './FieldView.css';

type FieldViewProps = {
    title: string;
    onEditClick: () => void;
    onMobileEditClick?: () => void;
    renderViewComponent: () => JSX.Element;
    viewComponentValue: string | undefined;
    classNameField?: string;
    tooltipEnabled?: boolean;
    titleAlwaysOn?: boolean;
    disableTooltipOnMobile?: boolean;
    tooltipTopText?: string;
    tooltipBottomText?: string;
    hover?: boolean;
};

function handleOnClick(props: FieldViewProps) {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        props.onMobileEditClick();
    }
    if (window.matchMedia('(min-width: 1025px)').matches) {
        props.onEditClick();
    }
}

export const FieldView = (props: FieldViewProps) => {
    const { isMyProfile } = useSelector((state) => state.publicProfile);

    return (
        <>
            <div
                tabIndex={0}
                className={classNames(
                    styles.fieldViewContainer,
                    styles[props.classNameField],
                    {
                        [styles.titleAlwaysOn]: props.titleAlwaysOn,
                    },
                    {
                        [styles.hovered]: props.hover,
                    }
                )}
                onClick={isMyProfile ? () => handleOnClick(props) : null}
                onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                        handleOnClick(props);
                    }
                }}
            >
                <div className={styles.fieldViewTitleContainer}>
                    <span
                        className={classNames(styles.fieldViewTitle, {
                            [styles.titleAlwaysOn]: props.titleAlwaysOn,
                        })}
                    >
                        {props.title}
                    </span>
                    {props.tooltipEnabled && (
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div
                                className={classNames({
                                    [styles.disableTooltip]: props.disableTooltipOnMobile,
                                })}
                            >
                                <NotifyIconTooltip
                                    textTop={props.tooltipTopText}
                                    textBottom={props.tooltipBottomText}
                                    tooltipNotVisible={false}
                                    classNameMessage="profilePage"
                                />
                            </div>
                        </Responsive>
                    )}
                </div>

                <Button
                    focusable={false}
                    className={styles.stretchHeight}
                    onClick={isMyProfile ? () => handleOnClick(props) : null}
                    ariaLabel={Boolean(props.viewComponentValue) ? null : props.title}
                >
                    {props.renderViewComponent()}
                </Button>

                {isMyProfile && (
                    <>
                        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                            <div className={styles.editIconContainer}>
                                <EditIcon className={styles.editIcon} />
                            </div>
                        </Responsive>
                        <Responsive maxWidth={1024}>
                            <Button className={styles.arrowIcon} onClick={() => handleOnClick(props)} noPadding>
                                <ArrowIcon className={styles.editIcon} />
                            </Button>
                        </Responsive>
                    </>
                )}
            </div>
        </>
    );
};
