import React from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { I18nText } from '../../../atoms/i18nText/i18nText';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import { setGiftCardStep } from '../../../store/ducks/giftCard';
import styles from '../GiftCardTemplate.css';

dayjs.extend(utc);

const GiftCardStep3 = React.memo(() => {
    const giftCardStep = useSelector((state) => state.giftCardStep);
    const isStep3 = giftCardStep === 2;

    return (
        <div className={classNames(styles.promo, isStep3 && styles.promo_step3)}>
            <PromoBottomTop />
        </div>
    );
});
const PromoBottomTop = React.memo(() => {
    const recipientName = useSelector((state) => state.giftCardPurchase.recipientName);
    const deliveryDate = useSelector((state) => state.giftCardPurchase.deliveryDate);
    const dispatch = useDispatch();
    const convertedDateLabel = deliveryDate
        ? dayjs(deliveryDate).utc().format('MMM DD, YYYY')
        : '';
    const subscriptionSource = useSelector((state) => state.subscriptionSource || '');
    const handleSendAnother = () => {
        Analytics.trackEvent(Analytics.giftCard.gifterSendAgain(subscriptionSource));
        dispatch(setGiftCardStep(0));
    };
    const handleReturnHome = () => (document.location.href = UrlService.createURL('/'));

    return (
        <div className={classNames(styles.promo__top)}>
            <div className={classNames(styles.promo__text, styles.promo__text_step3)}>
                <I18nText className={styles.promo__heading} as="h1" keyName={'GIFT_CARD_PROMO_HEADING_FINAL'} />
                <div className={styles.promo__subheading}>
                    <I18nText keyName="GIFT_CARD_FINAL_SUBHEADING_P1" />
                    <strong>{recipientName}</strong>
                    <I18nText keyName="GIFT_CARD_FINAL_SUBHEADING_P2" />
                    <strong>{convertedDateLabel}</strong>
                </div>
                <I18nText
                    as="div"
                    html
                    className={styles.promo__subheading_bottom}
                    keyName="GIFT_CARD_FINAL_BOTTOM_SUBHEADING"
                />
                <div className={styles.promo__row_btns}>
                    <Button className={styles.promo__btn} onClick={handleSendAnother}>
                        <I18nText keyName="GIFT_CARD_PROMO_BTN_SEND_LABEL" />
                    </Button>
                    <Button
                        outlined
                        white
                        className={classNames(styles.promo__btn, styles.promo__btn_return)}
                        onClick={handleReturnHome}
                    >
                        <I18nText keyName="GIFT_CARD_PROMO_BTN_RETURN_LABEL" />
                    </Button>
                </div>
            </div>
            <div className={classNames(styles.promo_images, styles.step3)}></div>
        </div>
    );
});

export default GiftCardStep3;
