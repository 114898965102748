import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { I18nText } from '../../../atoms/i18nText/i18nText';
import { GiftCardAdFree } from '../../../atoms/Icons/Styleguide/GiftCardAdFree';
import { GiftCardApps } from '../../../atoms/Icons/Styleguide/GiftCardApps';
import { GiftCardCommunity } from '../../../atoms/Icons/Styleguide/GiftCardCommunity';
import { Analytics } from '../../../services/Analytics/Analytics';
import styles from '../GiftCardTemplate.css';
import { GiftCardForm } from './GiftCardForm/GiftCardForm';

const ENJOY_BLOCKS = [
    {
        icon: <GiftCardAdFree />,
        textKey: 'GIFT_CARD_PROMO_ENJOY_AD_FREE',
    },
    {
        icon: <GiftCardApps />,
        textKey: 'GIFT_CARD_PROMO_ENJOY_APPS',
    },
    {
        icon: <GiftCardCommunity />,
        textKey: 'GIFT_CARD_PROMO_ENJOY_COMMUNITY',
    },
];

/*TODO: Maybe don`t need memo() at all, check in future updates*/
const GiftCardStep1 = React.memo(() => {
    const formRef = useRef<HTMLFormElement>(null);

    const offsetTopForScroll = 250;
    const subscriptionSource = useSelector((state) => state.subscriptionSource);

    const [sourceState, _setSourceState] = useState(null);
    const sourceStateRef = React.useRef(sourceState);
    const setSourceState = (data) => {
        sourceStateRef.current = data;
        _setSourceState(data);
    };

    let alreadyTracked;

    useEffect(() => {
        setSourceState(subscriptionSource);
    }, [subscriptionSource]);

    useEffect(() => {
        window.addEventListener('scroll', trackVisibleElements);
        return () => {
            window.removeEventListener('scroll', trackVisibleElements);
        };
    }, []);

    const trackVisibleElements = () => {
        const html = document.body.parentElement;
        if (!alreadyTracked) {
            const el = formRef.current;
            if (el) {
                const elR = el.getBoundingClientRect();
                if (elR.y + elR.height <= html.clientHeight) {
                    alreadyTracked = true;
                    Analytics.trackEvent(Analytics.giftCard.gifterFormViewable(sourceStateRef.current));
                }
            }
        }
    };

    const scrollToForm = () =>
        window.scrollTo({
            top: formRef?.current?.offsetTop - offsetTopForScroll,
            behavior: 'smooth',
        });

    return (
        <div className={styles.container}>
            <div className={styles.promo}>
                <PromoBottomTop scrollToHandle={scrollToForm} />
                <PromoBottomBlock />
            </div>
            <GiftCardForm ref={formRef} />
        </div>
    );
});

const PromoBottomTop = React.memo(({ scrollToHandle }: any) => {
    return (
        <div className={classNames(styles.promo__top)}>
            <div className={styles.promo__text}>
                <I18nText as="h1" className={styles.promo__heading} keyName={'GIFT_CARD_PROMO_HEADING'} />
                <I18nText as="div" className={styles.promo__subheading} keyName="GIFT_CARD_PROMO_SUBHEADING" />
                {/* <div className={styles.promo__row_btns}>
                    <Button className={styles.promo__btn} onClick={scrollToHandle}>
                        <I18nText keyName="GIFT_CARD_PROMO_BTN_ACTION_LABEL" />
                    </Button>
                </div> */}
            </div>
            <div className={styles.promo_images}></div>
        </div>
    );
});

const PromoBottomBlock = React.memo(() => {
    return (
        <div className={styles.promo__bottom}>
            <I18nText as="h2" className={styles.enjoy_title} keyName="GIFT_CARD_PROMO_ENJOY_TITLE" />
            <div className={styles.enjoy__blocksRow}>
                {ENJOY_BLOCKS.map(({ icon, textKey }) => (
                    <EnjoyBlock key={textKey} icon={icon} textKey={textKey} />
                ))}
            </div>
        </div>
    );
});

type EnjoyBlockProps = {
    icon: React.ReactNode;
    textKey: string;
};
const EnjoyBlock = React.memo(({ icon, textKey }: EnjoyBlockProps) => {
    return (
        <div className={styles.enjoyBlock}>
            <div className={styles.enjoyBlock__circle}>{icon}</div>
            <I18nText html className={styles.enjoyBlock__text} keyName={textKey} />
        </div>
    );
});

export default GiftCardStep1;
