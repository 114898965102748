import React from 'react';

import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './Account.css';
import { Button } from '../../../../atoms/Buttons/Base/Base';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { ArrowIcon } from '../../../../atoms/Icons/Styleguide/ArrowIcon';
import { FlagEn } from "../../../../atoms/Icons/Styleguide/Flags/FlagEn";
import { FlagFr } from "../../../../atoms/Icons/Styleguide/Flags/FlagFr";
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { UserModel } from '../../../../models/User/UserModel';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { TranslationService } from '../../../../services/TranslationService';
import UserService from '../../../../services/UserService';
import { setProfilePanelState } from '../../../../store/ducks/layout';
import { TooltipMobile } from '../../TooltipMobile/TooltipMobile';

interface AccountProfileProps extends RouteComponentProps {
  user: UserModel;
  dispatch?: any;
  currentLang: string;
}

type LanguageSwitcherMenuItem = {
  key: string;
  title: string;
  alt: string;
  flag: any;
  noTranslationText: string;
  noTranslationLink: string;
  url?: string;
  fallbackUrl?: string;
};

const menuItems: LanguageSwitcherMenuItem[] = [
  {
    key: 'fr',
    title: 'Français (FR)',
    alt: 'French (FR)',
    flag: <FlagFr />,
    noTranslationText: "Cette page n'est pas encore traduite en français.",
    noTranslationLink: 'Voir tous les jeux',
  },
  {
    key: 'en',
    title: 'English (US)',
    alt: 'English (US)',
    flag: <FlagEn />,
    noTranslationText: '',
    noTranslationLink: '',
  },
];

export const AccountProfileTemplate = withRouter(
  React.memo((props: AccountProfileProps) => {
    const currentLocaleData = menuItems.find((item) => item.key === props.currentLang);
    const flag = currentLocaleData ? currentLocaleData.flag : '';
    const title = currentLocaleData ? currentLocaleData.title : 'Language not defined';

    return (
      <div className={styles.grid}>
        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
          <div className={styles.blockAccountProfile}>
            <div className={classNames(styles.blockPlaceholder, styles.account)}>
              <Button
                fullWidth
                className={styles.blockContainer}
                onClick={() => {
                  UserService.userLogout();
                  props.history.push('/');
                }}
              >
                <div className={styles.logoutText}>
                  <I18nText keyName="LOG_OUT"/>
                </div>
              </Button>
            </div>
          </div>
        </Responsive>
        <div className={styles.blockAccountProfile}>
          <div className={styles.accountProfileHeader}>
            <div className={styles.captionContainer}>
              <I18nText as="h2" keyName="MY_LANGUAGE" className={styles.caption}/>
            </div>
            <NotifyIconTooltip
              textTop={TranslationService.translateIt('THIS_INFORMATION_IS_NOT_VISIBLE')}
              tooltipNotVisible={false}
              classNameMessage="profilePage"
            />
          </div>
          <div className={classNames(styles.blockPlaceholder, styles.account)}>
            <Responsive maxWidth={1024}>
              <div className={styles.mobileCaptionContainer}>
                <I18nText as="h2" keyName="MY_LANGUAGE" className={styles.caption}/>
              </div>
            </Responsive>
            <Button
              noPadding
              fullWidth
              className={styles.blockContainer}
              onClick={() =>
                props.dispatch(
                  setProfilePanelState({
                    isOpened: true,
                    caption: TranslationService.translateIt('SELECT_YOUR_LANGUAGE'),
                    target: ProfileRightSideRoutes.MY_LANGUAGE_PANEL
                  })
                )
              }
            >
              <div className={styles.blockContent}>
                <div className={styles.languageFlag}>{flag}</div>
                <div className={styles.labelTitle}>{title}</div>
              </div>
              <div className={styles.blockArrow}>
                <ArrowIcon/>
              </div>
            </Button>
          </div>
          <TooltipMobile tooltipContent={TranslationService.translateIt('THIS_INFORMATION_IS_NOT_VISIBLE')}/>
        </div>
      </div>
    );
  })
);
