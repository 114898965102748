const SITE_MAP_PAGES = [
    { name: 'HOME_PAGE', link: '' },
    { name: 'ALL_CAT', link: 'ALL_GAMES_SLUG' },
    { name: 'ALL_CATEGORIES_PAGE', link: 'ALL_CATEGORIES_SLUG' },
    { name: 'Profile', link: 'profile' },
    { name: 'Privacy Policy', link: 'privacy-policy' },
    { name: 'ABOUT_US_PAGE', link: 'ABOUT_US_SLUG' },
    { name: 'Blog', link: 'blog' },
];

export default SITE_MAP_PAGES;
