import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './GiftCardForm.css';
import { GiftCardInput } from './GiftCardInput';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { setGiftCardPurchase, setGiftCardStep } from '../../../../store/ducks/giftCard';

export const GiftCardForm = React.forwardRef<HTMLFormElement>((_, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [field, setField] = useState<{ deliveryDate: Date }>({ deliveryDate: new Date() });
  const [valid, setValid] = useState<{ date: boolean }>({ date: true });
  const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource);
  const selectedDateForDatePicker = new Date();
  const maxDateForDatePicker = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const setFormValue = (fieldName: string, value: string) => {
    setField({ ...field, [fieldName]: value });
  };
  const setFormValidity = (fieldName: string, value: boolean) => {
    setValid((prevValidState) => ({ ...prevValidState, [fieldName]: value }));
  };
  const onSubmit = () => {
    void Analytics.trackEvent(Analytics.giftCard.gifterNextStep(subscriptionSource));
    batch(() => {
      dispatch(
        setGiftCardPurchase({
          recipientName: field['name'],
          recipientEmail: field['email'],
          senderName: field['name2'],
          senderEmail: field['email2'],
          customMessage: field['message'],
          deliveryDate: field['deliveryDate']
        })
      );
      dispatch(setGiftCardStep(1));
    });
  };
  const checkAnalytics = (field: string) => {
    const ANALYTICS_EVENTS_MAP: Record<string, string> = {
      name: 'giftRecipientName',
      email: 'giftRecipientEmail',
      emailConfirm: 'giftConfirmEmail',
      date: 'giftDeliveryDate',
      message: 'giftCustomMessage',
      name2: 'giftSenderName',
      email2: 'giftSenderEmail'
    };
    const eventName = ANALYTICS_EVENTS_MAP[field];

    void Analytics.trackEvent(Analytics.giftCard[eventName](subscriptionSource));
  };

  return (
    <div className={styles.formContainer}>
      <I18nText as="h2" className={styles.formHeading} keyName="GIFT_CARD_FORM_HEADING"/>
      <form ref={ref}>
        <GiftCardInput
          label={t('GIFT_CARD.RECIPIENTS_NAME')}
          placeholder="Jane Smith"
          name="name"
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.RECIPIENTS_EMAIL')}
          placeholder="jane.smith@example.com"
          name="email"
          type="email"
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.CONFIRM_RECIPIENTS_EMAIL')}
          placeholder="jane.smith@example.com"
          name="emailConfirm"
          type="email"
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          firstEmail={field['email']}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.DELIVERY_DATE')}
          name="date"
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          datepicker={true}
          selectedDate={selectedDateForDatePicker}
          maxDate={maxDateForDatePicker}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.YOUR_GIFT_MESSAGE')}
          placeholder="Enjoy your favorite games!"
          name="message"
          maxlength={240}
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.SENDERS_NAME')}
          placeholder="Jack Smith"
          name="name2"
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          onBlur={checkAnalytics}
        />
        <GiftCardInput
          label={t('GIFT_CARD.SENDERS_EMAIL')}
          placeholder="jack.smith@email.com"
          name="email2"
          type="email"
          firstEmail={field['email']}
          setFormValue={setFormValue}
          setFormValidity={setFormValidity}
          onBlur={checkAnalytics}
        />
        <div className={styles.form__btnRow}>
          <Button
            type="button"
            className={styles.promo__btn}
            disabled={
              !(
                valid['name'] &&
                valid['name2'] &&
                valid['email'] &&
                valid['email2'] &&
                valid['message'] &&
                valid['date'] &&
                field['email'] === field['emailConfirm']
              )
            }
            onClick={onSubmit}
          >
            <I18nText keyName="NEXT_STEP"/>
          </Button>
        </div>
      </form>
    </div>
  );
});
