import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const VisaCardIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="138"
            height="45"
            viewBox="0 0 138 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M59.8243 44.024H48.5638L55.6015 0.772836H66.8631L59.8243 44.024ZM39.0907 0.772836L28.3555 30.5213L27.0852 24.1153L27.0863 24.1176L23.2973 4.6671C23.2973 4.6671 22.8392 0.772836 17.9557 0.772836H0.20825L0 1.50518C0 1.50518 5.42721 2.63435 11.7788 6.44879L21.5619 44.0251H33.2945L51.2097 0.772836H39.0907ZM127.66 44.024H138L128.985 0.77168H119.933C115.753 0.77168 114.735 3.99492 114.735 3.99492L97.9409 44.024H109.679L112.027 37.5994H126.341L127.66 44.024ZM115.27 28.7246L121.186 12.5389L124.515 28.7246H115.27ZM98.8213 11.1737L100.428 1.88581C100.428 1.88581 95.4697 0 90.3005 0C84.7124 0 71.4423 2.4423 71.4423 14.3183C71.4423 25.4921 87.0171 25.6309 87.0171 31.5001C87.0171 37.3692 73.047 36.3176 68.4366 32.6165L66.7625 42.3279C66.7625 42.3279 71.7905 44.7702 79.4726 44.7702C87.157 44.7702 98.7496 40.7915 98.7496 29.9625C98.7496 18.717 83.0349 17.67 83.0349 12.7807C83.036 7.89034 94.0027 8.51856 98.8213 11.1737Z"
                fill="#2566AF"
            />
            <path
                d="M27.0863 24.117L23.2973 4.66647C23.2973 4.66647 22.8392 0.772217 17.9557 0.772217H0.20825L0 1.50456C0 1.50456 8.53012 3.27236 16.712 9.89584C24.5352 16.2266 27.0863 24.117 27.0863 24.117Z"
                fill="#E6A540"
            />
        </svg>
    );
});
