import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const FlagEn = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="28"
            height="20"
            viewBox="0 0 28 20"
        >
            <defs>
                <rect id="prefix__a" width="28" height="20" x="0" y="0" rx="2" />
                <rect id="prefix__c" width="28" height="20" x="0" y="0" rx="2" />
                <rect id="prefix__e" width="28" height="20" x="0" y="0" rx="2" />
                <linearGradient id="prefix__g" x1="50%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#FFF" />
                    <stop offset="100%" stopColor="#F0F0F0" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <rect width="28" height="20" x="-.5" y="-.5" fill="#FFF" fillRule="nonzero" stroke="#D9DADC" rx="2" />
                <mask id="prefix__b" fill="#fff">
                    <use xlinkHref="#prefix__a" />
                </mask>
                <path
                    fill="#D02F44"
                    fillRule="nonzero"
                    d="M28 18.667V20H0v-1.333h28zM28 16v1.333H0V16h28zm0-2.667v1.334H0v-1.334h28zm0-2.666V12H0v-1.333h28zM28 8v1.333H0V8h28zm0-2.667v1.334H0V5.333h28zm0-2.666V4H0V2.667h28zM28 0v1.333H0V0h28z"
                    mask="url(#prefix__b)"
                />
                <g>
                    <mask id="prefix__d" fill="#fff">
                        <use xlinkHref="#prefix__c" />
                    </mask>
                    <path fill="#46467F" fillRule="nonzero" d="M0 0H12V9.333H0z" mask="url(#prefix__d)" />
                </g>
                <g>
                    <mask id="prefix__f" fill="#fff">
                        <use xlinkHref="#prefix__e" />
                    </mask>
                    <g mask="url(#prefix__f)">
                        <g>
                            <path
                                fill="#000"
                                fillRule="nonzero"
                                d="M9 5.667c.368 0 .667.298.667.666C9.667 6.702 9.368 7 9 7c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm-2.667 0c.369 0 .667.298.667.666C7 6.702 6.702 7 6.333 7c-.368 0-.666-.298-.666-.667 0-.368.298-.666.666-.666zm-2.666 0c.368 0 .666.298.666.666 0 .369-.298.667-.666.667C3.298 7 3 6.702 3 6.333c0-.368.298-.666.667-.666zM1 5.667c.368 0 .667.298.667.666C1.667 6.702 1.368 7 1 7c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm1.333-1.334c.369 0 .667.299.667.667 0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667 0-.368.298-.667.666-.667zm2.667 0c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667 0-.368.299-.667.667-.667zm2.667 0c.368 0 .666.299.666.667 0 .368-.298.667-.666.667C7.298 5.667 7 5.368 7 5c0-.368.298-.667.667-.667zM9 3c.368 0 .667.298.667.667 0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666C8.333 3.298 8.632 3 9 3zM6.333 3c.369 0 .667.298.667.667 0 .368-.298.666-.667.666-.368 0-.666-.298-.666-.666 0-.369.298-.667.666-.667zM3.667 3c.368 0 .666.298.666.667 0 .368-.298.666-.666.666-.369 0-.667-.298-.667-.666C3 3.298 3.298 3 3.667 3zM1 3c.368 0 .667.298.667.667 0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666C.333 3.298.632 3 1 3zm1.333-1.333c.369 0 .667.298.667.666C3 2.702 2.702 3 2.333 3c-.368 0-.666-.298-.666-.667 0-.368.298-.666.666-.666zm2.667 0c.368 0 .667.298.667.666C5.667 2.702 5.368 3 5 3c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm2.667 0c.368 0 .666.298.666.666 0 .369-.298.667-.666.667C7.298 3 7 2.702 7 2.333c0-.368.298-.666.667-.666zM1 .333c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667C.333.632.632.333 1 .333zm2.667 0c.368 0 .666.299.666.667 0 .368-.298.667-.666.667C3.298 1.667 3 1.368 3 1c0-.368.298-.667.667-.667zm2.666 0C6.702.333 7 .632 7 1c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667 0-.368.298-.667.666-.667zM9 .333c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667 0-.368.299-.667.667-.667z"
                                transform="translate(1 1)"
                            />
                            <path
                                fill="url(#prefix__g)"
                                d="M9 5.667c.368 0 .667.298.667.666C9.667 6.702 9.368 7 9 7c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm-2.667 0c.369 0 .667.298.667.666C7 6.702 6.702 7 6.333 7c-.368 0-.666-.298-.666-.667 0-.368.298-.666.666-.666zm-2.666 0c.368 0 .666.298.666.666 0 .369-.298.667-.666.667C3.298 7 3 6.702 3 6.333c0-.368.298-.666.667-.666zM1 5.667c.368 0 .667.298.667.666C1.667 6.702 1.368 7 1 7c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm1.333-1.334c.369 0 .667.299.667.667 0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667 0-.368.298-.667.666-.667zm2.667 0c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667 0-.368.299-.667.667-.667zm2.667 0c.368 0 .666.299.666.667 0 .368-.298.667-.666.667C7.298 5.667 7 5.368 7 5c0-.368.298-.667.667-.667zM9 3c.368 0 .667.298.667.667 0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666C8.333 3.298 8.632 3 9 3zM6.333 3c.369 0 .667.298.667.667 0 .368-.298.666-.667.666-.368 0-.666-.298-.666-.666 0-.369.298-.667.666-.667zM3.667 3c.368 0 .666.298.666.667 0 .368-.298.666-.666.666-.369 0-.667-.298-.667-.666C3 3.298 3.298 3 3.667 3zM1 3c.368 0 .667.298.667.667 0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666C.333 3.298.632 3 1 3zm1.333-1.333c.369 0 .667.298.667.666C3 2.702 2.702 3 2.333 3c-.368 0-.666-.298-.666-.667 0-.368.298-.666.666-.666zm2.667 0c.368 0 .667.298.667.666C5.667 2.702 5.368 3 5 3c-.368 0-.667-.298-.667-.667 0-.368.299-.666.667-.666zm2.667 0c.368 0 .666.298.666.666 0 .369-.298.667-.666.667C7.298 3 7 2.702 7 2.333c0-.368.298-.666.667-.666zM1 .333c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667C.333.632.632.333 1 .333zm2.667 0c.368 0 .666.299.666.667 0 .368-.298.667-.666.667C3.298 1.667 3 1.368 3 1c0-.368.298-.667.667-.667zm2.666 0C6.702.333 7 .632 7 1c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667 0-.368.298-.667.666-.667zM9 .333c.368 0 .667.299.667.667 0 .368-.299.667-.667.667-.368 0-.667-.299-.667-.667 0-.368.299-.667.667-.667z"
                                transform="translate(1 1)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
});
