import React, { useCallback, useEffect, useState } from 'react';

import {
  UserVirtualCurrencyTransactionListDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/virtual-currency';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './GemsTransactionTable.css';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { LazyImage } from '../../atoms/ProgressiveImage/LazyImage';
import { environment } from '../../config/environment';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { AppInsightService } from '../../services/AppInsight';
import GemsService from '../../services/GemsService';
import PaymentService from '../../services/PaymentService';
import { UrlService } from '../../services/UrlService';
import { gemsAmountSelector } from '../../store/ducks/gems/gemsSelectors';
import { setSideMenuActivePage } from '../../store/ducks/layout';
import { setHostedLoginToken } from '../../store/ducks/user';

const DEFAULT_TAKE = 10;
const GemsTransactionTable = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hostedLoginToken = useSelector(({ hostedLoginToken }) => hostedLoginToken);
  const gemsAmount = useSelector(gemsAmountSelector);
  const [transactionList, setTransactionList] = useState<UserVirtualCurrencyTransactionListDto>({
    items: [],
    total: 0
  });
  const [tableState, setTableState] = useState({ skip: 0, loading: true, error: false });
  const buyMoreGems = () => {
    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
  };
  const showMore = () => {
    void getTransactions(tableState.skip + DEFAULT_TAKE);
  };
  const getTransactions = useCallback(async (skip: number) => {
    setTableState((t) => ({ ...t, loading: true }));

    try {
      const transactionPage = await GemsService.getVirtualCurrencyTransactionHistory(skip, DEFAULT_TAKE);

      setTransactionList((t) => ({
        items: [...t.items, ...transactionPage.items],
        total: transactionPage.total
      }));
      setTableState({ skip, loading: false, error: false });
    } catch (err) {
      console.error(err);
      setTableState((t) => ({ ...t, loading: false, error: true }));
    }
  }, []);
  const getDescription = (gameName: string, purchasableItemName: string, comment: string) => {
    //If gameName exists, gems were spent in a game
    if (gameName) {
      return `${gameName} ${purchasableItemName}`;
    }

    //If purchasableItemName exists, this bundle was bought and gems were added
    if (purchasableItemName) {
      return purchasableItemName;
    }

    //If comment exists, show it
    if (comment) {
      return comment;
    }

    return '';
  };
  const retry = () => {
    setTransactionList({ items: [], total: 0 });
    void getTransactions(0);
  };
  const openRecurlyAccount = () => {
    setTimeout(() => window.open(`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`, '_blank'), 300);
  };

  useEffect(() => {
    void getTransactions(0);
  }, [getTransactions]);

  useEffect(() => {
    if (!hostedLoginToken) {
      PaymentService.getRecurlyData()
        .then((res) => {
          dispatch(setHostedLoginToken(res.hostedLoginToken));
        })
        .catch((err) => {
          console.log(' PaymentService.getRecurlyData()', err);
          AppInsightService.trackAppError(err, { data: 'getRecurlyData()' });
        });
    }
  }, []);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <LazyImage
          className={styles.gemsImage}
          img={UrlService.createURL('/images/gems/gems_promo_block.png', true)}
          alt={'Gems'}
        />
        <span className={styles.gemBalance}>
          {t('GEMS_TRANSACTION.MY_GEM_BALANCE')}
          <span className={styles.gemTotal}>{gemsAmount?.toLocaleString('en', { useGrouping: true })}</span>
        </span>
        <div className={styles.actions}>
          <Button className={styles.button} onClick={buyMoreGems}>
            {t('GEMS_TRANSACTION.BUY_MORE_GEMS')}
          </Button>
          <Button
            transparent={true}
            white={true}
            outlined={true}
            disabled={!hostedLoginToken}
            className={styles.button}
            onClick={openRecurlyAccount}
            iconLink={true}
            iconColor={'#FFF'}
          >
            {t('GEMS_TRANSACTION.PURCHASE_HISTORY')}
          </Button>
        </div>
      </div>
      {tableState.loading && !transactionList.items.length && <AppLoader/>}
      {!tableState.loading && (
        <table aria-label="Gem transactions table" className={styles.table}>
          <thead>
          <tr className={styles.headerRow}>
            <th>{t('GEMS_TRANSACTION.DATE')}</th>
            <th>{t('GEMS_TRANSACTION.DESCRIPTION')}</th>
            <th>{t('GEMS_TRANSACTION.ACTIVITY')}</th>
          </tr>
          </thead>
          {!tableState.error && transactionList.items.length > 0 && (
            <tbody>
            {transactionList.items.map(
              ({ id, amount, timestamp, gameName, purchasableItemName, comment }) => (
                <tr className={styles.row} key={id}>
                  <td>
                    <span className={styles.desktop}>
                      {dayjs(timestamp).format('MMMM D, YYYY')}
                    </span>
                    <span className={styles.mobile}>
                      {dayjs(timestamp).format('MMM D')}
                    </span>
                  </td>
                  <td>{getDescription(gameName, purchasableItemName, comment)}</td>
                  <td
                    className={classNames(styles.value, { [styles.negativeValue]: amount < 0 })}
                  >
                    {`${amount < 0 ? '-' : '+'} ${Math.abs(amount)}`}&nbsp;
                    <span className={styles.desktop}>{t('GEMS_TRANSACTION.GEMS')}</span>
                  </td>
                </tr>
              )
            )}
            </tbody>
          )}
        </table>
      )}
      {!tableState.error && transactionList.items.length === 0 && (
        <div>
          <p className={styles.noPurchases}>There are no purchases yet</p>
          <Button className={styles.button} onClick={buyMoreGems}>
            {t('GEMS_TRANSACTION.GO_TO_THE_SHOP')}
          </Button>
        </div>
      )}
      {!tableState.error && transactionList.total > transactionList.items.length && (
        <Button loading={tableState.loading} className={styles.button} onClick={showMore}>
          {t('GEMS_TRANSACTION.SHOW_MORE')}
        </Button>
      )}
      {tableState.error && (
        <div>
          <p className={styles.error}>{t('GEMS_TRANSACTION.ERROR_TEXT')}</p>
          <Button className={styles.button} onClick={retry} loading={tableState.loading}>
            {t('GEMS_TRANSACTION.TRY_AGAIN')}
          </Button>
        </div>
      )}
    </div>
  );
});

GemsTransactionTable.displayName = 'GemsTransactionTable';
export default GemsTransactionTable;
