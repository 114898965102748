import React, { FC, useEffect } from 'react';

type ScrollToProps = {
    scrollTopValue?: number;
};

export const withScrollTo =
    <T extends object>(Component: FC<T>, scrollTopValue = 0): FC<T & ScrollToProps> =>
    ({ ...props }: ScrollToProps) => {
        useEffect(() => {
            window.scrollTo(0, scrollTopValue);
        }, []);
        return <Component {...(props as T)} />;
    };
