import React, { Suspense } from 'react';

import classNames from 'classnames';

import styles from './ModuleBuilder.css';
import { Section } from '../../../models/Strapi';

// Lazy load modules
const MainModule = React.lazy(() => import('../MainModule/MainModule'));
const ColumnsModule = React.lazy(() => import('../ColumnsModule/ColumnsModule'));
const AccordionModule = React.lazy(() => import('../AccordionModule/AccordionModule'));
const GameInfoModule = React.lazy(() => import('../GameInfoModule/GameInfoModule'));
const InfoModule = React.lazy(() => import('../InfoModule/InfoModule'));
const ImageListModule = React.lazy(() => import('../ImageListModule/ImageListModule'));
const LinkListModule = React.lazy(() => import('../LinkListModule/LinkListModule'));
const ComingSoonGameModule = React.lazy(() => import('../ComingSoonGameModule/ComingSoonGameModule'));
// Constructs the Strapi modules
const ModuleBuilder = React.memo((props: { section: Section<unknown> }) => {
    const { section } = props;
    let result;

    switch (section.uid) {
        case 'MainModule':
            result = <MainModule  title={section.title}
                                  background={section.background}
                                  backgroundFallback={section.backgroundFallback}
                                  component={section.component as any}
            />;
            break;
        case 'Columns':
            result = <ColumnsModule title={section.title}
                                    background={section.background}
                                    backgroundFallback={section.backgroundFallback}
                                    component={section.component as any}
            />;
            break;
        case 'AccordionModule':
            result = <AccordionModule title={section.title}
                                      background={section.background}
                                      backgroundFallback={section.backgroundFallback}
                                      component={section.component as any}
            />;
            break;
        case 'GameInfoModule':
            result = <GameInfoModule title={section.title}
                                     background={section.background}
                                     backgroundFallback={section.backgroundFallback}
                                     component={section.component as any}
            />;
            break;
        case 'InfoModule':
            result = <InfoModule title={section.title}
                                 background={section.background}
                                 backgroundFallback={section.backgroundFallback}
                                 component={section.component as any}
            />;
            break;
        case 'LinkListModule':
            result = <LinkListModule title={section.title}
                                     background={section.background}
                                     backgroundFallback={section.backgroundFallback}
                                     component={section.component as any}
            />;
            break;
        case 'ImageListModule':
            result = <ImageListModule title={section.title}
                                      background={section.background}
                                      backgroundFallback={section.backgroundFallback}
                                      component={section.component as any}
                                      backgroundGradient={null}
            />;
            break;
        case 'ComingSoonGameModule':
            result = <ComingSoonGameModule title={section.title}
                                           background={section.background}
                                           backgroundFallback={section.backgroundFallback}
                                           component={section.component as any}
            />;
            break;
        default:
            return <></>;
    }

    return (
        <div className={classNames(styles.container)}>
            <Suspense fallback={<div>Loading...</div>}>
                {result}
            </Suspense>
        </div>
    );
});

export default ModuleBuilder;