import React from 'react';

import Box from '@mui/material/Box';

import styles from './BlogPostCard.css';

type BlogPostCardProps = {
    imgAlt: string;
    date: string;
    category: string;
    title: string;
    summary: string;
    images: any[];
    setUrl?: string;
};

export const BlogPostCard = React.memo(({ imgAlt, date, title, summary, images, setUrl }: BlogPostCardProps) => {
    const img = images.find((img) => img.mime === 'image/jpeg' && img.name.includes('preview'));
    return (
        <a href={setUrl} className={styles.noDecoration}>
            <Box className={styles.clickableArea}>
                <Box className={styles.imgContainer}>
                    <picture>
                        {images.map((img) => (
                            <source
                                key={`${img.name}-${img.id}`}
                                media={img.name.includes('mobile') ? '(max-width: 380px)' : '(min-width: 380px)'}
                                srcSet={img.url}
                                type={img.mime}
                            />
                        ))}
                        <img src={img?.url} alt={imgAlt} />
                    </picture>
                </Box>
                <Box className={styles.infoRow}>
                    <Box>{date}</Box>
                    {/* <Box>{category}</Box> */}
                </Box>
                <Box component="h2" className={styles.title}>
                    {title}
                </Box>
            </Box>
            <Box className={styles.summary}>{summary}</Box>
        </a>
    );
});
