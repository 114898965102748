import React from 'react';

import classNames from 'classnames';

import { Button } from '../../atoms/Buttons/Base/Base';
import { ArrowIcon } from '../../atoms/Icons/Styleguide/ArrowIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import styles from './Dropdown.css';

type DropdownProps = {
    activeItem?: string;
    headerItem: string;
    dropdownItems: Map<string, string>;
    onClick: (e) => void;
    close: () => void;
    className: string;
    closeIconArrow?: boolean;
};

export const Dropdown = React.memo((props: DropdownProps) => {
    return (
        <div className={classNames(styles.dropdownContainer, styles[props.className])}>
            <div className={styles.dropdownWrapper}>
                <div className={styles.primaryItem}>{props.headerItem}</div>
                <Button noPadding className={styles.closeIcon} onClick={() => props.close()}>
                    {props.closeIconArrow && <ArrowIcon className={styles.dropdownIcon} />}
                    {!props.closeIconArrow && <CloseIcon className={styles.dropdownIcon} />}
                </Button>
                <ul>
                    {Array.from(props.dropdownItems).map(([key, value]) => (
                        <li key={key}>
                            <Button
                                className={classNames(styles.dropdownItem, {
                                    [styles.activeItem]: props.activeItem === key,
                                })}
                                onClick={() => props.onClick(key)}
                            >
                                {value}
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});
