import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IBlogPost, IModularBlogPost } from '../../models/Strapi';
import { filterBlogArchiveData } from '../ducks/blog/blogArchieve';
import { AppState } from '../index';

type IPost = IBlogPost | IModularBlogPost;

export class BlogEffects {
    static setBlogCategoryFilters = () => (dispatch: ThunkDispatch<AppState, void, Action<any>>, getState) => {
        const blogData = getState().blogArchiveData;
        const category = getState().blogCurrentCategory;

        const filteredData = {
            ...blogData,
            filteredPosts: BlogEffects.filterPostsByCategory(blogData.posts, category),
            filteredPostsModular: BlogEffects.filterPostsByCategory(blogData.postsModular, category),
        };

        dispatch(filterBlogArchiveData(filteredData));
    };

    static filterPostsByCategory = (posts: IPost[], category: string): IPost[] => {
        if (!category) {
            return posts;
        }
        return category === 'All' ? posts : posts.filter((post) => post.blogCategory?.name === category);
    };
}
