import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './InputWrapper.css';
import { CreditInput } from '../../../atoms/CreditInput/CreditInput';
import { ErrorMessage } from '../../../atoms/ErrorMessage/ErrorMessage';
import { Input } from '../../../atoms/HookFormInput/Input';
import { SearchSelect } from '../../../FigmaStyleguide/Select/SearchSelect';
import { NotifyIconTooltip } from '../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { Media } from '../../../services/MediaService';

type InputWrapperProps = {
  register?: any; // need to check ts support for react-hook-form
  errors: any; // need to check ts support for react-hook-form
  errorMessage: string;
  name?: string;
  ariaInvalid?: string;
  type?: string;
  label: string;
  description?: string;
  descriptionId?: string;
  placeholder?: string;
  creditInput?: boolean;
  dataRecurly?: string;
  fullWidth?: boolean;
  setValue?: (name: string, value: string) => void;
  setValidation?: (isValid: boolean, name: string, message?: string) => void;
  isValid?: boolean;
  select?: boolean;
  value?: string;
};

export const InputWrapper = ({
  register,
  errors,
  errorMessage,
  name,
  ariaInvalid,
  type,
  label,
  description,
  descriptionId,
  placeholder,
  creditInput,
  dataRecurly,
  fullWidth = true,
  setValue,
  setValidation,
  isValid,
  select,
  value
}: InputWrapperProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.labelWrapper} style={{ textAlign: 'end' }}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>

        {/* mobile tooltip */}
        {description && (
          <Media lessThan="ARK_SMALL_DESKTOP_BELOW">
            <NotifyIconTooltip
              textTop={description}
              tooltipNotVisible={false}
              classNameMessage="profilePage"
              messageBackgroundColor="rgba(0, 66, 187, 1)"
            />
          </Media>
        )}
      </div>
      <div className={classNames(styles.inputBlock, !fullWidth && styles.minWidth)}>
        {select && (
          <SearchSelect
            isValid={isValid}
            value={value}
            onChange={(value) => {
              setValue('country', value);
              // here we need to reset state field
              // for example, behavior without reset: the state of Washington can be selected, with the country of the USA,
              // and after changing the country to France the state will not change and will remain Washington
              setValue('state', '');
              setValidation(true, 'country');
            }}
            placeholder={'Select your country'}
            fullWidth
            className={styles.select}
            name={'country'}
            validate={() => {
            }}
          />
        )}
        {creditInput && (
          <CreditInput
            setValidation={setValidation}
            setValue={setValue}
            placeholder={placeholder}
            dataRecurly={dataRecurly}
            errors={errors}
          />
        )}
        {!select && !creditInput && (
          <Input
            className={styles.input}
            isValid={isValid}
            ariaInvalid={ariaInvalid}
            register={register}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            dataRecurly={dataRecurly}
          />
        )}

        {errors[name] && <ErrorMessage id={`${name}Error`} message={errorMessage}/>}
        {dataRecurly === 'expirationDate' && (errors.year || errors.month) && (
          <ErrorMessage id={`${name}Error`} message={errorMessage}/>
        )}
      </div>

      {/*  */}
      {description && dataRecurly !== 'cvv' && (
        <Media greaterThanOrEqual="ARK_SMALL_DESKTOP_BELOW">
          <span className={styles.description} aria-atomic="true" aria-live="polite" id={descriptionId}>
            {description}
          </span>
        </Media>
      )}

      {/* cvv tooltip for desktop */}
      {dataRecurly === 'cvv' && (
        <Media greaterThanOrEqual="ARK_SMALL_DESKTOP_BELOW">
          <div className={styles.notifyWrapper}>
            <p className={styles.description} style={{ fontSize: 16, fontWeight: 'bold' }}>
              Where do i find this?
            </p>

            <NotifyIconTooltip
              textTop={t('PAYMENT_PAGE.SECURITY_CODE_DESCRIPTION')}
              tooltipNotVisible={false}
              classNameMessage="profilePage"
              messageBackgroundColor="rgba(0, 66, 187, 1)"
            />
          </div>
        </Media>
      )}
    </div>
  );
};
