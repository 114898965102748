import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './FieldEdit.css';
import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';

type FieldEditProps = {
  title: string;
  renderEditComponent: () => ReactNode;
  cancelField: () => void;
  saveField: () => void;
  saveButtonDisabled?: boolean;
  lastRowStyle?: boolean;
  tooltipEnabled?: boolean;
  tooltipTopText?: string;
  tooltipBottomText?: string;
  classNameField?: string;
  htmlFor?: string;
  isLoading?: boolean;
};

export const FieldEdit = (props: FieldEditProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.fieldEditContainer, styles[props.classNameField], {
        [styles.lastRowStyle]: props.lastRowStyle
      })}
    >
      <div className={styles.fieldEditTitleContainer}>
        <label htmlFor={props.htmlFor} className={styles.fieldEditTitle}>
          {props.title}
        </label>
        {props.tooltipEnabled && (
          <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
            <div className={styles.tooltipWrapper}>
              <NotifyIconTooltip
                textTop={props.tooltipTopText}
                textBottom={props.tooltipBottomText}
                tooltipNotVisible={false}
                classNameMessage="profilePage"
              />
            </div>
          </Responsive>
        )}
      </div>
      <div className={styles.fieldEditComponent}>{props.renderEditComponent()}</div>
      <div className={styles.fieldEditButtons}>
        <BorderedButton onClick={() => props.cancelField()} caption={t('CANCEL_BUTTON')}/>
        <BorderedButton
          isLoading={props.isLoading}
          disabled={props.saveButtonDisabled}
          onClick={async () => props.saveField()}
          caption={t('SAVE_BTN')}
        />
      </div>
    </div>
  );
};
