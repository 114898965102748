import React, { useState } from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './ProfileHeader.css';
import { Button } from '../../../atoms/Buttons/Base/Base';
import { PageBackground } from '../../../atoms/PageBackground/PageBackground';
import { PageTypes } from '../../../constants/Pages';
import { Dropdown } from '../../../molecules/Dropdown/Dropdown';
import { HeaderBackgroundText } from '../../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import UserService from '../../../services/UserService';

interface ProfileHeaderProps extends RouteComponentProps {
    currentLang: string;
    activePage: string;
    activePageText: string;
    profilePages: Map<string, string>;
    setActivePage: (e) => void;
    isMyProfile?: boolean;
    publicUsername?: string;
}

export const ProfileHeader = withRouter(
    withTranslation()(
        React.memo((props: ProfileHeaderProps & WithTranslation) => {
            const [userOpenedDropdown, setUserOpenedDropdown] = useState(false);

            const menuItems = props.profilePages;
            menuItems.set('LOG_OUT', props.t('LOG_OUT'));

            const logout = () => {
                UserService.userLogout();
                props.history.push('/');
            };

            const setActivePage = (page: string) => {
                props.setActivePage(page);
            };

            const onDropDownClick = (page: string) => {
                page === 'LOG_OUT' ? logout() : setActivePage(page);
                setUserOpenedDropdown(false);
            };

            return (
                <>
                    <div className={styles.myProfileTextHeader}>
                        <HeaderBackgroundText
                            currentLang={props.currentLang}
                            pageType={PageTypes.Profile}
                            isMyProfile={props.isMyProfile}
                            publicUsername={props.publicUsername}
                        />
                    </div>
                    {props.isMyProfile && (
                        <div className={styles.myProfileHeader}>
                            <PageBackground
                                pageType={PageTypes.Profile}
                                isMyProfile={props.isMyProfile}
                                topAsset={() => ''}
                                gameHeaderStyles={{
                                    backgroundImage: `linear-gradient(258deg, rgb(13, 111, 221), rgb(0, 66, 187))`,
                                    boxShadow: `0 4px 20px -5px rgba(31, 105, 216, 0.5)`,
                                }}
                            />
                            <div className={styles.pageHeaderDropdownContainer}>
                                <Dropdown
                                    className={userOpenedDropdown ? 'showDropdown' : ''}
                                    headerItem={props.activePage}
                                    dropdownItems={props.profilePages}
                                    onClick={(page) => onDropDownClick(page)}
                                    close={() => setUserOpenedDropdown(false)}
                                    closeIconArrow
                                />
                                <div className={styles.pageHeaderDropdownWrapper}>
                                    <Button
                                        noPadding
                                        className={styles.pageHeaderDropdown}
                                        onClick={() => setUserOpenedDropdown(true)}
                                    >
                                        <h2>{props.activePage}</h2>
                                    </Button>
                                    <div className={styles.pageHeaderText}>
                                        <p>{props.activePageText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        })
    )
);
