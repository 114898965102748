import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const GiftCardCommunity = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="52"
            height="28"
            viewBox="0 0 52 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="26" cy="8.42311" r="6.13459" stroke="#F9F9F9" strokeWidth="3" />
            <circle cx="41.6153" cy="8.42311" r="5.01921" stroke="#F9F9F9" strokeWidth="3" />
            <circle r="5.01921" transform="matrix(-1 0 0 1 10.3847 8.42311)" stroke="#F9F9F9" strokeWidth="3" />
            <path
                d="M36.5961 24.1181C36.5961 24.1181 31.8169 25.7115 26 25.7115C20.1831 25.7115 15.4039 24.1181 15.4039 24.1181C15.4039 18.0257 21.5608 14.5577 26 14.5577C30.4393 14.5577 36.5961 18.0257 36.5961 24.1181Z"
                stroke="#F9F9F9"
                strokeWidth="3"
            />
            <path
                d="M33.25 17.5939C34.936 14.913 38.4041 13.4423 41.1099 13.4423C44.8263 13.4423 49.9807 16.2167 49.9807 21.0906C49.9807 21.0906 45.9797 22.3653 41.1099 22.3653C39.4017 22.3653 37.8003 22.2085 36.4411 22.0048"
                stroke="#F9F9F9"
                strokeWidth="3"
            />
            <path
                d="M18.75 17.5939C17.064 14.913 13.5959 13.4423 10.8901 13.4423C7.17368 13.4423 2.01933 16.2167 2.01933 21.0906C2.01933 21.0906 6.02036 22.3653 10.8901 22.3653C12.5984 22.3653 14.1997 22.2085 15.5589 22.0048"
                stroke="#F9F9F9"
                strokeWidth="3"
            />
        </svg>
    );
});
