import React, { useState, memo, ChangeEvent } from 'react';

import classNames from 'classnames';

import styles from './Input.css';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { WarningIcon } from '../Icons/WarningIcon';

type InputProps = {
  type?: string;
  maxWidth?: number;
  fullWidth?: boolean;
  placeholder?: string;
  dataRecurly?: string;
  name?: string;
  onFocus?: () => void;
  onChange?: (v: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  value?: string;
  clickOnCloseIcon?: () => void;
  isValid?: boolean;
  errorMessage?: string;
  onBlur?: (name: string, value: string) => void;
  className?: string;
  closeIconClassName?: string;
  maxLength?: number;
  disabled?: boolean;
  label?: string;
  wrapperClassname?: string;
  ref?: unknown;
  calendar?: boolean;
};

// INPUT MAX LENGTHS MAP
const INPUT_MAX_LENGTHS = {
  name: 50,
  email: 50
};

export const Input = memo((props: InputProps) => {
  const {
    maxWidth,
    fullWidth,
    placeholder,
    dataRecurly,
    name,
    onFocus,
    onChange,
    onClick,
    value,
    clickOnCloseIcon,
    isValid,
    errorMessage,
    onBlur,
    className,
    closeIconClassName,
    maxLength,
    disabled,
    type,
    label,
    wrapperClassname,
    calendar
  } = props;
  const [focused, setFocused] = useState<boolean>(false);
  const getMaxLength = (nameValue: string) => {
    let inputType = nameValue;
    let isName: boolean, isEmail: boolean;

    if (nameValue) {
      isName = nameValue.includes('name');
      isEmail = nameValue.includes('email');
    }

    if (isName) {
      inputType = 'name';
    }

    if (isEmail) {
      inputType = 'email';
    }

    return INPUT_MAX_LENGTHS[inputType] ?? maxLength;
  };
  const renderInput = (name: string) => {
    switch (name) {
      default:
        return (
          <div className={wrapperClassname}>
            {label && (
              <label htmlFor={name} className={styles.label}>
                {label}
              </label>
            )}
            <input
              value={value}
              onChange={onChange}
              onFocus={() => {
                onFocus && onFocus();
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
                onBlur && onBlur(name, value);
              }}
              onClick={onClick && onClick}
              className={classNames(
                className,
                styles.input,
                value?.length > 0 && isValid && styles.successful,
                !isValid && styles.error
              )}
              type={type || 'text'}
              placeholder={placeholder}
              data-recurly={dataRecurly}
              name={name}
              maxLength={name ? getMaxLength(name) : null}
              disabled={disabled}
            />

            {!isValid && (
              <div className={styles.validationBlock}>
                <WarningIcon/>
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div className={classNames(styles.inputWrapper, fullWidth && styles.fullWidth)} style={{ maxWidth: maxWidth }}>
      {renderInput(name)}
      {!calendar && focused && value?.length > 0 && (
        <CloseIcon
          onMouseDown={() => {
            if (clickOnCloseIcon) {
              clickOnCloseIcon();
            }
          }}
          className={classNames(styles.closeIcon, closeIconClassName && closeIconClassName)}
        />
      )}
      {calendar && (
        <CalendarIcon
          onMouseDown={() => {
            if (clickOnCloseIcon) {
              clickOnCloseIcon();
            }
          }}
          className={classNames(styles.calendarIcon)}
        />
      )}
    </div>
  );
});
