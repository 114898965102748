import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { IGame } from '../models/Game/Game';
import { PublicUserModel, UserModel } from '../models/User/UserModel';
import { GameService } from '../services/GameService';
import HighScoreService from '../services/HighScoreService';
import RecentlyPlayedService from '../services/RecentlyPlayedService';
import { UrlService } from '../services/UrlService';
import UserService from '../services/UserService';
import { setPageType } from '../store/ducks/pages';
import { setIsMyProfile } from '../store/ducks/user';
import { P404Template } from '../templates/p404/404Template';
import ProfileTemplate from '../templates/Profile/ProfileTemplate';


const mapStateToProps = (state) => ({
    games: GameService.gameModelToGame(state.games),
    currentLang: state.currentLang,
    user: state.user,
    loginLoader: state.loginLoader,
    profileProgress: state.profileProgress,
    pageType: state.pageType,
    publicUser: state.publicProfile.publicUser,
    isMyProfile: state.publicProfile.isMyProfile,
    isOpenedProfilePanel: state.profilePanel.isOpened,
    adFree: state.preLoadData.adFree,
    subscription: state.preLoadData.subscription,
});

type ProfilePageProps = {
    games: IGame[];
    currentLang: string;
    user: UserModel;
    dispatch: any;
    loginLoader: boolean;
    profileProgress: number;
    pageType: PageTypes;
    publicUser: PublicUserModel;
    isMyProfile: boolean;
    isOpenedProfilePanel: boolean;
    adFree: boolean;
    subscription: boolean;
};

export default connect(mapStateToProps)(
    React.memo(
        ({
            games,
            currentLang,
            user,
            dispatch,
            loginLoader,
            profileProgress,
            pageType,
            publicUser,
            isMyProfile,
            isOpenedProfilePanel,
            adFree,
            subscription,
        }: ProfilePageProps) => {
            const [recentlyPlayed, setRecentlyPlayed] = useState([]);
            const [highScore, setHighScore] = useState([]);
            const [dataFetched, setDataFetched] = useState(false);
            const [notFound, setNotFound] = useState(false);
            const [currentUserId, setCurrentUserId] = useState(user?.uid);
            const routerFromRedux = useSelector((state) => state.router);
            const url = UrlService.buildAbsoluteUrl(routerFromRedux);
            const { userId } = useParams<{ userId: string }>();
            const fetchRecentlyPlayed = async (uId) => {
                const result = await RecentlyPlayedService.recentlyPlayedFetch(uId);

                setRecentlyPlayed(result);
            };
            const fetchHighScore = async (uId) => {
                const result = await HighScoreService.scoreFetch(uId);

                setHighScore(result);
            };

            useEffect(() => {
                if (!userId || user?.uid === userId) {
                    dispatch(setIsMyProfile(true));
                } else {
                    dispatch(setIsMyProfile(false));
                }

                setCurrentUserId(userId || user?.uid);
            }, [user, userId]);

            useEffect(() => {
                if (!isMyProfile && currentUserId) {
                    UserService.getPublicUserProfile(currentUserId);
                }

                if (!dataFetched && currentUserId) {
                    // do not await this calls
                    fetchRecentlyPlayed(currentUserId);
                    fetchHighScore(currentUserId);

                    setDataFetched(true);
                }
            }, [currentUserId]);

            useEffect(() => {
                if (pageType === PageTypes.NotFound) {
                    setNotFound(true);
                }
            }, [pageType]);

            useEffect(() => {
                dispatch(setPageType(PageTypes.Profile));
            }, []);

            if (notFound) {
                return <P404Template />;
            }

            const seoTitle = isMyProfile ? 'My Arkadium Profile' : 'Arkadium Profile';
            const adFreeValue = adFree || subscription;

            return (
                <>
                    <Helmet
                        title={seoTitle}
                        link={[{ rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` }]}
                    />
                    <ProfileTemplate
                        loginLoader={loginLoader && !user}
                        games={games}
                        recentlyPlayed={recentlyPlayed}
                        highScore={highScore}
                        currentLang={currentLang}
                        user={user}
                        dispatch={dispatch}
                        profileProgress={profileProgress}
                        isMyProfile={!userId || isMyProfile}
                        publicUser={publicUser}
                        isOpenedProfilePanel={isOpenedProfilePanel}
                        userId={userId}
                        adFree={adFreeValue}
                    />
                </>
            );
        }
    )
);
