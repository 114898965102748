import React from 'react';

import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import styles from './ProfileTemplate.skeleton.css';

type Props = {
    visible: boolean;
    asPlaceholder: boolean;
    forPublic: boolean;
};

export const ProfileTemplateSkeleton = React.memo((props: Props) => {
    return (
        <CSSTransition
            in={props.visible}
            timeout={200}
            classNames={{
                exit: styles.animationExit,
                exitActive: classNames(styles.animationExitActive, {
                    [styles.disableTransition]: props.asPlaceholder,
                }),
                exitDone: styles.animationExitDone,
            }}
        >
            <div className={styles.container}>
                <div className={classNames(styles.mobileHeader, { [styles.forPublicProfile]: props.forPublic })} />
                <div className={classNames(styles.topContent)}>
                    <div className={styles.shadow} />
                    <div className={classNames(styles.blockContent, styles.nav)} />
                    <div className={classNames(styles.contentGrid)}>
                        <ProfileBlock />
                        <RecentlyPlayedBlock />
                        <div className={classNames(styles.mobileAd, styles.recentlyPlayed)} />
                        <div className={styles.leaderboardGrid}>
                            <LeaderboardBlock />
                            <div className={classNames(styles.blockContent, styles.ad)} />
                        </div>
                    </div>
                    <div className={classNames(styles.blockContent, styles.ad, styles.wideScreen)} />
                </div>
            </div>
        </CSSTransition>
    );
});

const ProfileBlock = React.memo(() => {
    return (
        <div className={styles.skeletonBlock}>
            <div className={styles.blockHeader} />
            <div className={classNames(styles.blockContent, styles.profile)}>
                <div className={styles.avatar} />
            </div>
            <div className={classNames(styles.mobileAd, styles.profile)} />
        </div>
    );
});

const RecentlyPlayedBlock = React.memo(() => {
    return (
        <div className={styles.skeletonBlock}>
            <div className={styles.blockHeader} />
            <div className={classNames(styles.blockContent, styles.recentlyPlayed)} />
        </div>
    );
});

const LeaderboardBlock = React.memo(() => {
    return (
        <div className={styles.skeletonBlock}>
            <div className={styles.heading}>
                <div className={styles.blockHeader} />
                <div className={styles.dropdown} />
            </div>
            <div className={classNames(styles.blockContent, styles.leaderboard)} />
            <div className={classNames(styles.mobileAd, styles.leaderboard)} />
        </div>
    );
});
