import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './HeroBlock.css';

type Props = {
    isPostPage?: boolean;
    promoPost?: any; // add type when json file (archive_data) is ready
    showSubtitle?: boolean;
    onClick?: () => void;
    setUrl?: string;
};

const HeroBlock = React.memo(({ isPostPage, promoPost, showSubtitle = true, setUrl }: Props) => {
    const [mainImageUrl, setMainImageUrl] = useState('');

    useEffect(() => {
        if (promoPost?.images) {
            const image = promoPost.images.find((img) => img.ext === '.jpg' && img.name.includes('desktop'));

            setMainImageUrl(image?.UrlService);
        }
    }, [promoPost]);

    if (!promoPost) {
        return <div>No promo post found</div>;
    }

    return (
        <a href={setUrl} className={styles.noDecoration}>
            <div className={classNames(styles.wrapper)}>
                <picture className={styles.heroImg}>
                    {promoPost?.images &&
                        promoPost.images.map((img) => (
                            <source
                                key={`${img.name}-${img.id}`}
                                media={img.name.includes('desktop') ? '(min-width: 600px)' : '(max-width: 599px)'}
                                srcSet={img.url}
                                type={img.mime}
                            />
                        ))}
                    <img src={mainImageUrl} alt={promoPost.mainImgAlt} />
                </picture>
                <div className={styles.gradient} />
                {/* <div className={styles.badge}>Category</div> */}
                <p className={styles.date}>{promoPost.date}</p>
                {isPostPage ? (
                    // if post page
                    <h1 className={styles.title}>{promoPost.h1Title}</h1>
                ) : (
                    // if archive page
                    <p className={styles.title}>{promoPost.h1Title}</p>
                )}
                {promoPost?.summary && showSubtitle && <p className={styles.subTitle}>{promoPost.summary}</p>}
            </div>
        </a>
    );
});

export default HeroBlock;
