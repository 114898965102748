import React, { memo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './SiteMapTemplate.css';
import { filterPages, getOrderedCategories, getOrderedGames, ISiteMapPage } from './utils';
import { GameModel } from '../../models/Game/GameModel';
import { CategoryLocalizedRoute, GeneralPageSEO, CategoryPageSEO } from '../../models/PagesData';
import { newRouteAllowedGames, UrlService } from '../../services/UrlService';

enum SITEMAP_TYPES {
    Pages = 'Pages',
    Categories = 'Categories',
    Games = 'Games',
}

interface ISiteMapItem {
    title: SITEMAP_TYPES;
    items: ISiteMapPage[] | (GeneralPageSEO | CategoryPageSEO)[] | GameModel[];
}

const SiteMapTemplate = memo(() => {
    SiteMapTemplate.displayName = 'SiteMapTemplate';

    const currentLang = useSelector((state) => state.currentLang);
    const pages = useSelector((state) => state.pages);
    const games = useSelector((state) => state.games);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const { t } = useTranslation();

    const siteMapItems: ISiteMapItem[] = [
        {
            title: SITEMAP_TYPES.Pages,
            items: filterPages(currentLang, t),
        },
        {
            title: SITEMAP_TYPES.Categories,
            items: getOrderedCategories(pages, games, currentLang, categoryPromoData),
        },
        {
            title: SITEMAP_TYPES.Games,
            items: getOrderedGames(games),
        },
    ];

    const getLink = ({ category, meta, link, slug }, title): string => {
        if (title === 'Categories' && category) {
            return UrlService.createURL(
                `/${CategoryLocalizedRoute[currentLang]}/${Object.keys(slug).length !== 0 ? slug[currentLang] : ''}/`,
                false,
                false,
                false
            );
        }

        if (title === 'Games' && meta) {
            return newRouteAllowedGames.includes(meta.alias)
                ? UrlService.createURL(
                      `/${t('ALL_GAMES_SLUG')}/${t('JIGSAWS_CATEGORY_SLUG')}/${meta.alias}/`,
                      false,
                      false,
                      false
                  )
                : UrlService.createURL(`/games/${meta.alias}/`, false, false, false);
        }

        return UrlService.createURL(link, false, false, false) + (link === '/' ? '' : '/');
    };

    const renderCategory = ({ title, items }, index: number): JSX.Element => (
        <div key={index} className={styles.categoryRow}>
            {title && <h1 className={styles.categoryHeading}>{title}</h1>}
            <ul className={classNames(styles.categoriesList, styles[`categoriesList-${title || 'Pages'}`])}>
                {items.map((item, index) => renderItems(item, title, index))}
            </ul>
        </div>
    );

    const renderItems = (item, title, index: number): JSX.Element => (
        <li key={index} className={styles.itemLink}>
            <a href={getLink(item, title)}>{item.name[currentLang] || item.name}</a>
        </li>
    );

    return <main className={styles.container}>{siteMapItems.map(renderCategory)}</main>;
});

export default SiteMapTemplate;
