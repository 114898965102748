import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './PrivacyPolicyTemplate.css';
import { PageTypes } from '../../constants/Pages';
import { GeneralPageSEO } from '../../models/PagesData';
import { HeaderBackgroundText } from '../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { DeviceType } from '../../services/DeviceDetector';

export type PrivacyPolicyProps = {
    page: GeneralPageSEO;
    currentLang: string;
    pageType: PageTypes;
    deviceType: DeviceType;
};

export default function PrivacyPolicyTemplate({ page, pageType, currentLang, deviceType }: PrivacyPolicyProps) {
    return (
        <main className={styles.pageContainer}>
            <div className={styles.headerContainer}>
                <HeaderBackgroundText
                    currentLang={currentLang}
                    pageData={page}
                    pageType={pageType}
                    deviceType={deviceType}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.pageContentContainer}>
                  {page.pageName === PageTypes.PrivacyPolicyCalifornia && <PrivacyPolicyCaliforniaContentEn />}
                  {page.pageName === PageTypes.PrivacyPolicy && <PrivacyPolicyContentEn />}
                </div>
            </div>
        </main>
    );
}

const PrivacyPolicyCaliforniaContentEn = React.memo(() => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        setEmail('d' + 'p' + 'o' + '@' + 'a' + 'r' + 'k' + 'a' + 'd' + 'i' + 'u' + 'm' + '.' + 'c' + 'o' + 'm');
    }, []);

    return (
        // tslint:disable-next-line: jsx-wrap-multiline
        <div>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>PRIVACY NOTICE – CALIFORNIA</h2>
            <p>
                This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS (“California Privacy Notice”) supplements the information
                contained in the general &nbsp;
                <a href="https://www.arkadium.com/privacy-policy/">Privacy Policy</a> (“Privacy Policy”) of Arkadium,
                Inc. (“Arkadium”) and its subsidiaries and applies solely to visitors, users, and others who reside in
                the State of California (“consumers” or “you”), in connection with information collected by Arkadium
                through our arena gaming platform (the “Service“), Arkadium games or mobile apps that you download, and
                the various sites on which such Services are provided, whether located on a wholly-owned website or on a
                third-party publisher site (the “Sites”). We adopt this supplemental notice to comply with the
                California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any terms defined in
                the CCPA have the same meaning when used in this notice.
            </p>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>INFORMATION WE COLLECT</h2>
            <p className={styles.dividedItemBottom}>
                We collect the following categories of personal information that may identify, relate to, describe,
                reference, be capable of being associated with, or reasonably be linked, directly or indirectly, with a
                particular consumer or device (“personal information”). Specifically, we have collected the following
                categories of personal information from some consumers within the last twelve (12) months:
            </p>
            <ul>
                <li>
                    <strong>Category A</strong> – Identifiers
                    <p>
                        Examples: Name, alias, online identifier, Internet Protocol address, email address, account
                        name, or other similar identifiers.
                    </p>
                </li>
                <li>
                    <strong>Category B</strong> – Personal information categories listed in the California Customer
                    Records statute (Cal. Civ. Code § 1798.80(e))
                    <p>Examples: Name, alias, address (city, state, and/or country), telephone number. </p>
                </li>
                <li>
                    <strong>Category C</strong> – Protected classification characteristics under California or federal
                    law
                    <p>Examples: Age, birth date, gender</p>
                </li>
                <li>
                    <strong>Category D</strong> – Commercial Information
                    <p>Examples: Records of products or Services downloaded or purchased</p>
                </li>
                <li>
                    <strong>Category F</strong> – Internet or other similar network activity
                    <p>
                        Examples: Consumers’ interactions with websites or applications, browsing history, device
                        information; information provided through cookies, or web logs
                    </p>
                </li>
                <li>
                    <strong>Category G</strong> – Geolocation data
                    <p>
                        Examples: Your physical location at the time you register, or when you interact with the
                        Services
                    </p>
                </li>
                <li>
                    <strong>Category K</strong> – Inferences drawn from other personal information
                    <p>Examples: Game preferences based on users’ game activities</p>
                </li>
            </ul>
            <p className={styles.dividedItem}>
                We obtain the categories of personal information listed above from the following categories of sources:
            </p>
            <ul>
                <li>
                    Directly from you. For example, through information we ask from you when you subscribe to or engage
                    our Services.
                </li>
                <li>
                    From social networks. For example, you may share certain personal information from your social
                    network account (e.g. Facebook), such as your name, nickname/alias, age, email address, or other
                    information, when you connect your social media account to our Services. The specific information
                    transferred depends on your security settings and the privacy policy of your social media network.
                </li>
                <li>
                    Directly and indirectly from your activity when you use our Services or visit a Site. For example,
                    usage details collected automatically in the course of your interaction with our platform or
                    website, or from our games played on Sites that you visit.
                </li>
                <li>
                    From Service Providers, partners or third-parties that interact with us in connection with the
                    Services.
                </li>
            </ul>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>USE OF PERSONAL INFORMATION</h2>
            <p className={styles.dividedItemBottom}>
                We may use the personal information we collect for one or more of the following business purposes:
            </p>
            <ul>
                <li>To create your account and post your scores on the leaderboard;</li>
                <li>
                    To respond to requests you may make of us; including requests regarding technical support or other
                    inquiries;
                </li>
                <li>
                    To prevent fraud or potentially illegal activities, resolve disputes, and enforce our Terms of
                    Service;
                </li>
                <li>
                    To solicit input and feedback to improve our products and services and to customize your user
                    experience;
                </li>
                <li>
                    We may also send promotional e-mail messages (“Promotional Communications”), directly or in
                    partnership with parties other than Arkadium, to communicate about new and current games and to
                    inform users about new products; and
                </li>
                <li>
                    Information about your use of Services or Sites (“Usage Information”) may be included in databases
                    owned and maintained by Arkadium or its agents. Arkadium retains all rights to these databases and
                    the information contained in them. For example, anonymized Usage Information is used to better
                    understand users’ needs, how we can improve our websites, products and services, to analyze trends
                    and user traffic and gather statistical information, and to administer games and improve game play.
                </li>
                <li>For testing, research, analysis and product development.</li>
                <li>
                    As necessary or appropriate to protect the rights, property or safety of us, our clients or others.
                </li>
                <li>
                    To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.
                </li>
                <li>
                    To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other
                    sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal information held by us is among the assets
                    transferred.
                </li>
            </ul>
            <p className={styles.dividedItemTop}>
                We will not collect additional categories of personal information or use the personal information we
                collected for materially different, unrelated, or incompatible purposes without providing you notice.
            </p>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>SHARING PERSONAL INFORMATION</h2>
            <p>
                We may disclose your personal information to a partner, service provider or third-party for a business
                purpose, as described further in our Privacy Policy. When we disclose personal information for a
                business purpose, we enter into a contract that describes the purpose and requires the recipient to keep
                that personal information confidential and secure, and not use it for any purpose except to perform its
                obligations under the contract.
            </p>
            <p className={styles.dividedItem}>
                In the last twelve (12) months, we have disclosed the following categories of personal information for a
                business purpose:
            </p>
            <ul>
                <li>Category A: Identifiers.</li>
                <li>Category B: California Customer Records personal information categories.</li>
                <li>Category C: Protected classification characteristics under California or federal law.</li>
                <li>Category D: Commercial information.</li>
                <li>Category F: Internet or other similar network activity.</li>
                <li>Category G: Geolocation data.</li>
                <li>Category K: Inferences drawn from other personal information.</li>
            </ul>
            <p className={styles.dividedItem}>
                We may from time to time disclose your personal information for a business purpose to the following
                categories of third parties upon their request:
            </p>
            <ul>
                <li>
                    Service providers, to enable them to provide their services (e.g., PayPal, for payment services)
                </li>
                <li>
                    Third party partners or service providers to whom you or your agents authorize us to disclose your
                    personal information in connection with products or services we provide to you.
                </li>
            </ul>
            <p className={styles.dividedItemTop}>
                In the last twelve (12) months, we have not sold (as understood in common usage) any personal
                information such as names, addresses, telephone numbers or e-mail addresses.
            </p>
            <p className={styles.dividedItem}>
                We may have shared aggregated and/or anonymized information regarding Your usage of our Services with
                third parties to help us develop and improve the Services and provide our customers with more relevant
                content and service offerings, as detailed in our Customer agreements.
            </p>
            <p>
                As described in our Privacy Policy, when allowed by you, we may use and share your personal information
                with service providers and partners for services that they provide to us. Some partners may also use
                this information for their own purposes. The transfer of your information to such partners may be
                considered a “sale” under the CCPA, and in the last twelve (12) months we may have sold information for
                some consumers under the CCPA’s definition of “sale.” Information on how you can opt out of sharing your
                information with these partners for purposes beyond services they provide us is provided below.
            </p>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>YOUR RIGHTS AND CHOICES</h2>
            <p>
                The CCPA provides California residents with specific rights regarding their personal information. This
                section describes your CCPA rights and explains how to exercise those rights.
            </p>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>
                Access to Specific Information and Data Portability Rights
            </h3>
            <p>
                You have the right to request that we disclose to you (i) the categories of personal information we
                collected about you and the categories of sources from which we collected such information; (ii) the
                specific pieces of personal information we collected about you; (iii) the business or commercial purpose
                for collecting personal information about you; and (iv) the categories of personal information about you
                that we shared or disclosed and the categories of third parties with whom we shared or to whom we
                disclosed such information in the last 12 months.
                <br />
                Once we receive and confirm your verifiable consumer request, we will disclose to you the information
                referenced in this subsection.
            </p>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>Deletion Request Rights</h3>
            <p>
                You have the right to request that we delete any of your personal information that we collected from you
                and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer
                request, we will delete (and direct our service providers to delete) your personal information from our
                records, unless an exception applies.
            </p>
            <p className={styles.dividedItem}>
                We may deny your deletion request if retaining the information is necessary for us or our service
                providers to:
            </p>
            <ul>
                <li>
                    Complete the transaction for which we collected the personal information, provide a good or service
                    that you requested, take actions reasonably anticipated within the context of our ongoing business
                    relationship with you, or otherwise perform our contract with you.
                </li>
                <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
                    prosecute those responsible for such activities.
                </li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>
                    Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or
                    exercise another right provided for by law.
                </li>
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
                <li>
                    Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                    interest that adheres to all other applicable ethics and privacy laws, when the information’s
                    deletion may likely render impossible or seriously impair the research’s achievement, if you
                    previously provided informed consent.
                </li>
                <li>
                    Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                    relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                    Make other internal and lawful uses of that information that are compatible with the context in
                    which you provided it.
                </li>
            </ul>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>
                Exercising Access, Data Portability and Deletion Rights
            </h3>
            <p>
                To exercise the access, data portability, and deletion rights described above, please submit a
                verifiable consumer request to us by either:
            </p>
            <ul className={styles.dividedItem}>
                <li>Calling us at </li>
                <li>
                    Emailing
                    <span>
                        <a href={`mailto:${email}`}>{email}</a>
                    </span>
                </li>
                <li>Visiting our CCPA request page </li>
            </ul>
            <p>
                Only you or a person registered with the California Secretary of State that you authorize to act on your
                behalf, may make a verifiable consumer request related to your personal information. You may also make a
                verifiable consumer request on behalf of your minor child.
            </p>
            <p>
                You may only make a verifiable consumer request for access or data portability twice within a 12-month
                period. The verifiable consumer request must:
            </p>
            <ul className={styles.dividedItem}>
                <li>
                    Provide sufficient information that allows us to reasonably verify you are the person about whom we
                    collected personal information or an authorized representative.
                </li>
                <li>
                    Describe your request with sufficient detail that allows us to properly understand, evaluate, and
                    respond to it.
                </li>
            </ul>
            <p>
                We cannot respond to your request or provide you with personal information if we cannot verify your
                identity or authority to make the request and confirm the personal information relates to you. Making a
                verifiable consumer request does not require you to create an account with us. We will only use personal
                information provided in a verifiable consumer request to verify the requestor’s identity or authority to
                make the request.
            </p>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>Response Timing and Format</h3>
            <p>
                We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require
                more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have
                an account with us, we will deliver our written response to the email address associated with that
                account. If you do not have an account with us, we will deliver our written response by mail or to an
                email address that you provide us, at your option.
            </p>
            <p>
                Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer
                request’s receipt. The response we provide will also explain the reasons we cannot comply with a
                request, if applicable. For data portability requests, we will select a format to provide your personal
                information that is readily useable and should allow you to transmit the information from one entity to
                another entity without hindrance.
            </p>
            <p>
                We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive,
                repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you
                why we made that decision and provide you with a cost estimate before completing your request.
            </p>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>Non-Discrimination</h3>
            <p className={styles.dividedItemBottom}>
                We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the
                CCPA, we will not:
            </p>
            <ul>
                <li>Deny you goods or services.</li>
                <li>
                    Charge you different prices or rates for goods or services, including through granting discounts or
                    other benefits, or imposing penalties.
                </li>
                <li>Provide you a different level or quality of goods or services.</li>
                <li>
                    Suggest that you may receive a different price or rate for goods or services or a different level or
                    quality of goods or services.
                </li>
            </ul>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>CHANGES TO OUR PRIVACY NOTICE</h2>
            <p>
                We reserve the right to amend this California Privacy Notice at our discretion and at any time. When we
                make changes to this California Privacy Notice, we will notify you by email or through a notice on our
                website homepage.
            </p>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>CONTACT INFORMATION</h2>
            <p>
                If you have any questions or comments about this California Privacy Notice, our Privacy Policy, the ways
                in which we collect and use your personal information, your choices and rights regarding such use, or
                wish to exercise your rights under California law, please do not hesitate to contact us at:
            </p>
            <p className={styles.dividedItem}>
                Phone:
                <br />
                Website: <a href="https://www.arkadium.com/">arkadium.com</a>
                <br />
                Email:
                <span>
                    <a href={`mailto:${email}`}>{email}</a>
                </span>
                <br />
                Postal Address: Arkadium, Inc., 40 West 25th St, 4th Floor, New York, NY 10010, Attn: Data Privacy
                Officer, Legal Department.
            </p>
            <p className={classNames(styles.dividedItem, styles.italicText)}>Updated: January 8, 2020</p>
        </div>
    );
});
const PrivacyPolicyContentEn = React.memo(() => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        setEmail('d' + 'p' + 'o' + '@' + 'a' + 'r' + 'k' + 'a' + 'd' + 'i' + 'u' + 'm' + '.' + 'c' + 'o' + 'm');
    }, []);

    // workaround for the FireFox issue with anchor tags
    useEffect(() => {
        const scrollToTarget = (targetId) => {
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        };
        const handleClick = (event) => {
            event.preventDefault();

            const targetId = event.currentTarget.getAttribute('data-target-id');

            if (targetId) {
                scrollToTarget(targetId);
            }
        };
        const links = document.querySelectorAll('[data-target-id]');

        links.forEach((link) => {
            link.addEventListener('click', handleClick);
        });

        return () => {
            links.forEach((link) => {
                link.removeEventListener('click', handleClick);
            });
        };
    }, []);

    return (
        // tslint:disable-next-line: jsx-wrap-multiline
        <div>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>Last updated: March 20th, 2024</h2>
            <p className={styles.dividedItemBottom}>
                Arkadium, Inc. (<strong>“Arkadium”</strong>, <strong>“Company”</strong>, “we”, “our”, or “us”)
                understands that privacy is important to our users. This Privacy Policy describes our practices
                regarding the collection, use, disclosure, and protection of your personal information for the website
                located at Arkadium.com (the <strong>“Website”</strong>) and in connection with the games available on
                the Website; all games published by Arkadium available on the Apple iOS App Store or Google Play (the
                <strong> “Apps”</strong>); all Arkadium games available on our affiliate publishers’ websites through
                our Arena service (the <strong>“Arena Games”</strong>); any custom games that we develop for affiliates
                and partners; and any other services that we provide that are not covered by a separate privacy policy
                (the <strong>“Services”</strong>) (collectively, the <strong>“Properties”</strong>). This Privacy Policy
                also tells you about the rights and choices you have with respect to your personal information, how you
                can assert those rights, and how you can contact us to get answers to your questions.
            </p>
            <p className={styles.dividedItemBottom}>
                Please note that our privacy practices are subject to the applicable laws of the regions in which we
                operate. Accordingly, some additional region-specific terms will only apply to residents of those
                locations, or as required by applicable laws.
            </p>
            <p className={styles.dividedItemBottom}>
                For more information about how users with disabilities can access this Privacy Policy in an alternative
                format, please contact us at (212) 337-3796.
            </p>
            <p className={styles.dividedItemBottom}>
                We may enter into written agreements with companies and publishers who make our Arena Games available on
                their own websites (<strong>“Arena Partners”</strong>). Please note that, where permitted by law and
                directed by particular Arena Partners, we may process the personal information that we collect from
                Arena Partners in connection with the Arena Games (<strong>“Arena Data”</strong>) only as a “processor”
                or “service provider” (as those terms are defined under applicable data protection laws) on behalf of
                such Arena Partners, who act as the “controller” or “business” (as those terms are defined under
                applicable data protection laws). In such cases, those Arena Partners are the parties that control the
                use of the Arena Data and determine the purposes for which we may process such information. In addition
                to describing our practices regarding the personal information that we collect through the Services,
                this Privacy Policy also describes how we process Arena Data on behalf of such controller Arena Partner,
                who are responsible for their own practices in collecting, using, and disclosing your personal
                information. To learn more about particular Arena Partners’ use of your information and your rights that
                you may have over such information, please consult the privacy policy of the applicable company or
                publisher.
            </p>

            <p className={styles.dividedItemBottom}>
                BY USING OR ACCESSING THE PROPERTIES, YOU AGREE TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH OUR
                POLICIES OR PRACTICES, YOU SHOULD NOT USE OR ACCESS THE PROPERTIES OR PROVIDE US WITH ANY PERSONAL
                INFORMATION.
            </p>

            <h2 className={classNames(styles.title1, styles.dividedItem)}>WHAT OUR PRIVACY POLICY COVERS</h2>

            <p className={styles.dividedItemBottom}>
                This privacy policy applies to the personal information that we obtain in various contexts, both online
                and offline, including when you:
            </p>
            <ul>
                <li>Access or use our Properties;</li>
                <li>Communicate with us over email or text message, or otherwise via the Properties;</li>
                <li>Participate in our programs or events, including sweepstakes, contests or surveys; or</li>
                <li>Engage with us on social media.</li>
            </ul>

            <p className={styles.dividedItem}>
                We may provide different or additional privacy notices in connection with certain activities, programs,
                and offerings. Additionally, we may provide additional “just-in-time” notices or notices at collection
                that may supplement or clarify our privacy practices or provide you with additional choices regarding
                your personal information.
            </p>

            <p className={styles.dividedItemBottom}>Links to the contents of this Privacy Policy are provided below:</p>

            <strong>
                <ul className={styles.listStyleNone}>
                    <li>
                        <a href="#" data-target-id="Personal-Information-We-Collect-from-You">
                            Personal Information We Collect from You
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="How-We-Collect-Your-Personal-Information">
                            How We Collect Your Personal Information
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="How-We-Use-Your-Personal-Information">
                            How We Use Your Personal Information
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Arena-Data">
                            Arena Data
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Non-personal-Information">
                            Non-personal Information
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="How-We-Disclose-or-Share-Your-Personal-Information">
                            How We Disclose or Share Your Personal Information
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Links-to-Other-Sites-and-Social-Media-Services">
                            Links to Other Sites and Social Media Services
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Interest-based-Advertising">
                            Interest-based Advertising
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="How-We-Protect-Your-Personal-Information">
                            How We Protect Your Personal Information
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Data-Retention">
                            Data Retention
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Your-Choices">
                            Your Choices
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Nevada-Privacy-Rights">
                            Nevada Privacy Rights
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Cross-Border-Data-Transfers">
                            Cross-Border Data Transfers
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Children-s-Privacy">
                            Children’s Privacy
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Changes-to-This-Policy">
                            Changes to This Policy
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Contacting-Us">
                            Contacting Us
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="California-Privacy-Rights">
                            California Privacy Rights
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="Other-State-Privacy-Rights">
                            Other State Privacy Rights
                        </a>
                    </li>
                    <li>
                        <a href="#" data-target-id="European-Privacy-Rights">
                            European Privacy Rights
                        </a>
                    </li>
                </ul>
            </strong>

            <div className={styles.anchorTarget} id="Personal-Information-We-Collect-from-You">
                <h2 className={classNames(styles.title1, styles.dividedItem)}>
                    PERSONAL INFORMATION WE COLLECT FROM YOU
                </h2>
            </div>
            <p className={styles.dividedItemBottom}>
                We use the term “personal information” – sometimes called “personal data” or “personally identifiable
                information” in the laws of some jurisdictions – to refer to information that reasonably identifies,
                relates to, describes, or can be associated with you. Data that has been deidentified, aggregated or
                that otherwise cannot reasonably be related back to a specific person is not considered personal
                information.
            </p>
            <p className={styles.dividedItemBottom}>
                The following are the categories and types of personal information we may collect from or about you,
                depending on how you interact with the Properties:
            </p>
            <ul>
                <li>
                    Identifiers, such as your name, email address, telephone number, and Arkadium Advantage account
                    information;
                </li>

                <li>Additional characteristics that you provide, such as gender and date of birth;</li>

                <li>Country of origin or other information that permits us to determine your approximate location;</li>

                <li>
                    Device and online identifiers and related information, including internet protocol (IP) address,
                    mobile ad identifiers, data collected from cookies, beacons, and pixel tags, and similar unique
                    identifiers;
                </li>

                <li>
                    Internet or other electronic network activity information, including, but not limited to browsing
                    history, search history, and information regarding your interaction with a website, application,
                    mobile app, or advertisement;
                </li>

                <li>Any other personal information that you voluntarily provide us.</li>
            </ul>

            <div id="How-We-Collect-Your-Personal-Information" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>
                    HOW WE COLLECT YOUR PERSONAL INFORMATION
                </h2>
            </div>
            <h3 className={classNames(styles.title1, styles.dividedItemBottom)}>Personal Information You Provide </h3>
            <p className={styles.dividedItemBottom}>
                We collect personal information that you provide to us directly. This may include, but is not limited
                to:
            </p>
            <ul>
                <li>
                    Information you provide when you contact us via email, text message, or through other
                    Internet-enabled communications;
                </li>

                <li>Information you provide to us over the telephone or via regular mail;</li>

                <li>
                    Information you provide when you create a profile or account on one of our Properties or subscribe
                    to one of our Services;
                </li>

                <li>Content that you post to the Properties;</li>

                <li>Your responses to surveys that you choose to complete for us;</li>

                <li>Information you provide when you enter or win a contest or other promotion sponsored by us;</li>

                <li>
                    Details relating to transactions that you carry out through our Properties, including any orders
                    that you ask us to fulfill, and any payment or other financial information you provide to us
                    relating to such orders;
                </li>

                <li>Your search queries on the Properties;</li>

                <li>Your interactions with our support providers;</li>

                <li>Any other information that you provide us on or through the Properties.</li>
            </ul>
            <h3 className={classNames(styles.title1, styles.dividedItem)}>
                Personal Information We Collect Automatically
            </h3>
            <p className={styles.dividedItemBottom}>
                When you visit our Properties, open or click on emails we send you, or interact with our advertisements,
                we or third parties we work with may automatically collect certain information using some of the
                tracking technologies described below.
            </p>
            <ul>
                <li>
                    Cookies and Similar Technology
                    <p className={styles.dividedItemBottom}>
                        "Cookies" are pieces of information that may be placed on your computer by a website for the
                        purpose of collecting data to facilitate and enhance your communication and interaction with
                        that website. Such data may include, for example, the address of the websites you visited before
                        and after you visited our Properties, the type of browser you are using, your Internet Protocol
                        (IP) address, what pages in the Properties you visited and what links you clicked on, the region
                        where your device is located, and geographic information based on your IP data. We may store
                        some information on your device or device hard drive as a cookie or similar type of file (such
                        as clear gifs, web beacons, tags, and similar technologies that work on mobile devices) to
                        collect data related to usage of the Properties. We may also use cookies to customize your visit
                        to the Properties and for other purposes to make your visit more convenient or to enable us to
                        enhance the Properties and Services.
                    </p>
                </li>
                <li>
                    Clickstream Data
                    <p className={styles.dividedItemBottom}>
                        As you use the Internet, a trail of electronic information is left at each website you visit.
                        This information, which is sometimes referred to as "clickstream data," can be collected and
                        stored by a website's server. Clickstream data can tell us the type of computer and browsing
                        software you use and the address of the website from which you linked to the Properties. We may
                        collect and use clickstream data as a form of aggregate information to anonymously determine how
                        much time visitors spend on each page of our Website or App, how visitors navigate throughout
                        the Properties, and how we may tailor our web pages to better meet the needs of visitors. This
                        information will be used to improve our Properties and our Services.
                    </p>
                </li>
                <li>
                    Analytics
                    <p className={styles.dividedItemBottom}>
                        We may work with third-party vendors who use the technologies described in this section to
                        conduct website analytics to help us track and understand how visitors use our Properties and
                        other Services. One such provider is Google Analytics, a web analytics service provided by
                        Google, Inc. (<strong>“Google”</strong>). Google Analytics uses cookies to help analyze how
                        users use the Website. The information generated by these cookies about your use (including your
                        IP address) will be transmitted to and stored by Google on servers in the United States. Google
                        will use this information for the purpose of evaluating your use of the Website, interacting
                        with advertisements, and providing other services relating to web page activity and internet
                        usage. Google may also transfer this information to third parties where required to do so by
                        law, or where such third parties process the information on Google's behalf. You may refuse the
                        use of cookies by selecting the appropriate settings in your browser. By using the Website and
                        accepting cookies, you consent to the processing of data about you by Google in the manner and
                        for the purposes set out above. Please refer to the currently available opt-outs for Google
                        Analytics by visiting &nbsp;
                        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                    </p>
                </li>
                <li>
                    Social Media Platforms and Networks
                    <p className={styles.dividedItemBottom}>
                        If you interact with us on social media or use features, such as plugins, widgets, or other
                        tools made available by social media platforms or networks (such as Instagram, Facebook,
                        Twitter, or Google) in connection with our Properties, we may collect information that you share
                        with us on social media or that such platforms share with us. Please review the privacy policies
                        and settings of the social media platforms and networks that you use for more information about
                        their privacy practices.
                    </p>
                </li>
                <li>
                    Arena Data
                    <p className={styles.dividedItemBottom}>
                        We may obtain Arena Data from certain Arena Partners and their vendors, which we process on
                        behalf of such Arena Partners in accordance with our contracts with them.
                    </p>
                </li>
                <li>
                    Apps
                    <p className={styles.dividedItemBottom}>
                        We may obtain data through your interactions with our Apps available through Apple iOS and
                        Google Play. Such data may include your approximate location and device ID. When such data is
                        collected, it is used only on a transient basis and therefore not retained.
                    </p>
                </li>
                <li>
                    From Other Sources
                    <p className={styles.dividedItemBottom}>
                        We may obtain information about you from other sources, such as data analytics providers,
                        marketing or advertising vendors, fraud prevention vendors, vendors that provide other services
                        on our behalf, or publicly available sources.
                    </p>
                </li>
            </ul>

            <div id="How-We-Use-Your-Personal-Information" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>HOW WE USE YOUR PERSONAL INFORMATION</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                We collect and use personal information for the following purposes:
            </p>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        To communicate with you, which may include:
                        <ul className={styles.circleBullet}>
                            <li>Contacting you about our Properties;</li>
                            <li>
                                Responding to your direct inquiries, requests, issues or feedback, and providing
                                customer service;
                            </li>
                            <li>Adding you to our mailing lists and sending you emails from time to time;</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        To provide our products and Services, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Operating the Properties, and providing you with any specific Services that you have
                                requested;
                            </li>
                            <li>
                                Creating, maintaining, and otherwise managing your account, profile, or subscription;
                            </li>
                            <li>Maintaining our leaderboard statistics;</li>
                            <li>
                                Facilitating your interactions with any games provided by third-party developers hosted
                                on, linked to or otherwise available through the Website (the{' '}
                                <strong>“Third-Party Games”</strong>);
                            </li>
                            <li>Delivering content and product and Service offerings relevant to your interests;</li>
                            <li>
                                Fulfilling your orders and/or completing the transactions you have requested, processing
                                your payments through our third-party payment processor(s), and providing you receipts
                                and order updates;
                            </li>
                            <li>Providing a forum for discussion, questions, and sharing experiences;</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        For marketing and promotional purposes, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Marketing our Services or goods and services of our affiliates, business partners, and
                                other third parties;
                            </li>
                            <li>Providing you advertising for products and services that interest you;</li>
                            <li>Administering sweepstakes, contests, and other similar promotions;</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        For analytics and personalization, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Identifying trends and drawing inferences from your interactions with us or our
                                affiliates or our business partners;
                            </li>
                            <li>
                                Conducting research and analytics to improve our Services and product offerings or those
                                of our affiliates and business partners;
                            </li>
                            <li>
                                Understanding how you interact with our Properties, advertisements, and communications
                                with you to determine which of our Services are the most popular, and to improve
                                Properties and marketing campaigns;
                            </li>
                            <li>Personalizing your experience to save you time when you visit our Properties;</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        For security and fraud prevention, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Helping maintain the safety, security, and integrity of our Properties, databases and
                                other technology assets, and business;
                            </li>
                            <li>
                                Internal research; technological development and demonstration; and improving,
                                upgrading, or enhancing our Properties;
                            </li>
                            <li>
                                Detecting security incidents; protecting against malicious, deceptive, fraudulent, or
                                illegal activity; and prosecuting those responsible for that activity;
                            </li>
                            <li>
                                Investigating suspected fraud, harassment, or other violations of any law, rule, or
                                regulation, or the policies related to our Properties;
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        To comply with legal obligations, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Compliance with legal or regulatory obligations, establishing or exercising our rights,
                                and defending against legal claims;
                            </li>
                            <li>
                                Responding to law enforcement requests and, as required by applicable law, court order,
                                legal process, or governmental regulation;
                            </li>
                            <li>
                                Acting in connection with a bankruptcy proceeding or the sale, merger, or change of
                                control of the Company or the division responsible for the Services with which your
                                information is associated;
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        To support core business functions, which may include:
                        <ul className={styles.circleBullet}>
                            <li>
                                Maintaining records related to business process management, loss and fraud prevention,
                                and to collect amounts owing to us;
                            </li>
                            <li>
                                Providing and maintaining the functionality of our Properties, including identifying and
                                repairing errors or problems; and
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>For any additional purposes to which you specifically consent.</li>
                </ul>
            </div>

            <div id="Arena-Data" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>ARENA DATA</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                With respect to our Services as they apply to the Arena Data that certain Arena Partners direct us to
                collect, in some instances we do not process or use such Arena Data on our own behalf but rather at the
                direction and for the benefit of those Arena Partners who act as controllers. In other instances, we may
                act as the controller of such data. Some of our Arena Partners find that advertising is more effective
                and relevant when it is targeted to your interests and behaviors. In connection with our processing of
                Arena Data relevant to your possible interests and behaviors, some of our Arena Partners may employ
                various technologies to determine whether you have seen their advertisements or to create a profile to
                determine likely associations or connections between your devices. One use of such information is to
                provide more tailored advertising, marketing, measurement, analytics, and research about goods and
                services of interest to you across those various devices. Such information may form part of the Arena
                Data that we process on behalf of those Arena Partners.
            </p>
            <p className={styles.dividedItemBottom}>
                If you would like more information about this practice and to know your choices with respect to it,
                please refer to the relevant Arena Partners’ privacy policies and the <strong>Your Choices</strong>{' '}
                section of this Privacy Policy.
            </p>

            <div id="Non-personal-Information" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItemBottom)}>NON-PERSONAL INFORMATION </h2>
            </div>
            <p>
                We may collect information that is not personal information (“Non-personal Information”). Non-personal
                Information may include anonymous or aggregate data, or information lawfully made available from
                federal, state, or local government records. Because Non-personal information does not personally
                identify you, we may collect, use, and disclose such information for any purpose permitted by law. For
                example, we may aggregate or de-identify Personal Information so that the information does not
                personally identify you or anyone else. We reserve the right to develop and derive aggregate data
                (meaning information that relates to a group or category of individuals, from which individual
                identities have been removed) from personal information in order to enhance and maintain the Properties,
                and such aggregate data will be treated as non-personal information.
            </p>

            <div id="How-We-Disclose-or-Share-Your-Personal-Information" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>
                    HOW WE DISCLOSE OR SHARE YOUR INFORMATION
                </h2>
            </div>
            <p className={styles.dividedItemBottom}>
                In addition to using your personal information ourselves for the reasons stated above (and as otherwise
                mentioned in this Privacy Policy), we may disclose your personal information to other affiliates and
                entities in the following instances:
            </p>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Vendors</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share your personal information with third-party vendors who may use your information to
                    provide us with services including, but not limited to, website hosting, data analysis,
                    infrastructure provision, information technology services, customer service, email delivery
                    services, payment processing, auditing, and anti-fraud monitoring. These vendors may have access to
                    personal information that is necessary to perform their functions, but they are only permitted to do
                    so in connection with performing services for us. They are not authorized by us to use your personal
                    information for their own benefit.
                </p>
            </div>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Third-Party Developers </li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share administrative information, including limited personal information, with third-party
                    developers in connection with your accessing Third-Party Games. Such information may include your
                    username, a hashed user ID, your avatar, any virtual items associated with your user account, and
                    your subscription status with Arkadium.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Corporate Affiliates</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share personal information with our corporate affiliates and subsidiaries, who process
                    personal information on our behalf, where necessary to provide a product or service that you have
                    requested, or in other circumstances with your consent or as permitted or required by law.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Business and Advertising Partners</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share your personal information with third parties who partner with us to promote products
                    and services, provide marketing and advertisements, conduct data analytics, or use the data for
                    other commercial purposes. We do not control how these third parties use and share your personal
                    information once they receive it. You will need to contact such third parties directly for
                    information about their privacy practices or to exercise any rights you may have (including if you
                    would like to opt-out of marketing messages).
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Legal Compliance and to Defend Our Rights</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may disclose personal information and other information as we believe necessary or appropriate:
                    (a) under applicable law, including laws outside your country of residence; (b) to comply with legal
                    process; (c) to respond to requests from public and government authorities including public and
                    government authorities outside your country of residence; (d) to enforce our Terms and Conditions;
                    (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy,
                    safety or property, and/or that of our affiliates, you, or others; and (g) to allow us to pursue
                    available remedies or limit the damages that we may sustain.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Business Transfers</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share your personal information and other information with third parties in connection with a
                    merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
                    or all of the assets of the Company, whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which the assets transferred may include information about
                    the users of our Properties.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Non-Personal Information</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may share non-personal information with unaffiliated third parties, such as business and
                    advertising partners, manufacturers, distributors, and retailers, to improve and enhance your
                    experience using the Properties, and for our own market research activities.
                </p>
            </div>

            <p className={styles.dividedItemBottom}>
                Please note that if you specifically consent to additional uses of your personal information, we may use
                your personal information in a manner consistent with that consent.
            </p>

            <p className={styles.dividedItemBottom}>
                With respect to Arena Data, certain Arena Partners provide us with instructions on what to do with such
                information. For example, an Arena Partner may request that Arkadium compile reporting of aggregated
                user metrics and data associated with Arena performance, such as average player session duration, number
                of game plays, number of users, and scores. These choices and instructions may result in the access,
                use, disclosure, modification, or deletion of Arena Data. Our Arena Partners and their affiliates
                determine their own policies for the sharing and disclosure of Arena Data. We may transfer Arena Data to
                third parties on our Arena Partners’ behalf, and under such circumstances, we do so strictly according
                to our Arena Partners’ instructions.
            </p>

            <div id="Links-to-Other-Sites-and-Social-Media-Services" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>
                    LINKS TO OTHER SITES AND SOCIAL MEDIA SERVICES
                </h2>
            </div>
            <p className={styles.dividedItemBottom}>
                We may create links to other websites that we think may be of interest to you, such as providers of
                various products and services. We may also provide links that allow you to send or forward a post from
                our Properties through or to third-party social media sites, or other means of electronic communication,
                such as an email or text messaging service. We do not endorse any other websites, providers, or services
                by providing such links, and this Privacy Policy applies only to your use of our Properties. We are not
                responsible for the privacy policies of any websites and services we link to on our Properties, and you
                should read the privacy policies of each site you visit to determine what data that site may collect
                about you.
            </p>
            <p className={styles.dividedItemBottom}>
                The Properties may also integrate with social media services. We do not control such services and are
                not liable for the manner in which they operate. While we may provide you with the ability to use such
                services in connection with our Properties, we are doing so merely as an accommodation and, like you,
                are relying upon those third-party services to operate properly and fairly.
            </p>
            <p className={styles.dividedItemBottom}>
                You should be aware that any personal information which you voluntarily include and transmit online in a
                publicly accessible blog, forum, social network, or otherwise may be viewed and used by others. By using
                such features, you assume the risk that the personal information provided by you may be viewed and used
                by third parties.
            </p>

            <div id="Interest-based-Advertising" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>INTEREST BASED ADVERTISING</h2>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Targeted Advertising / Behavioral Advertising</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    Our third-party partners or vendors may use your information to deliver targeted advertising to you
                    when you visit our Properties or other websites. Cookies, clickstream data, and other similar
                    technologies described below may be used in this process. For example, if you are searching for
                    information on a particular product, our partner or vendor may cause an advertisement to appear on
                    other websites you view with information on that product. This form of advertising, sometimes called
                    “targeted advertising,” “behavioral advertising,” or “cross-context behavioral advertising,” enables
                    our partners and vendors to know about your interests in connection with the delivery of a specific
                    ad. We believe that such advertising is helpful because it allows you to see advertisements that are
                    relevant to your interests.
                </p>
                <p className={styles.dividedItemTop}>
                    If you would like to opt out of these interest-based advertisements, please follow the opt-out
                    process described in the section titled <strong>Your Choices</strong> below.
                </p>

                <p className={styles.dividedItemTop}>
                    Please note that if you opt in and consent to receive in-game SMS messages, we will not share your
                    mobile telephone number or other mobile identifier with any third parties or affiliates for their
                    marketing or promotional purposes.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Cross-Device Matching</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may use your information to help us, or our third-party vendors, determine if you have interacted
                    with our Properties across multiple devices and to match such devices. To accomplish this, we may
                    rely on information (including demographic, geographic and interest-based data) from third parties
                    such as data vendors, pursuant to their own privacy policies, or we may use information we collect
                    in conjunction with such third-party data. Based on this data, we may then display targeted
                    advertisements across devices that we believe are associated with each other and may further provide
                    services to our advertisers to better enable cross-device targeting and analysis. To opt-out of or
                    restrict our use of certain cross-device data, please see the section titled{' '}
                    <strong>Your Choices</strong> below.
                </p>
            </div>

            <div id="How-We-Protect-Your-Personal-Information" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>
                    HOW WE PROTECT YOUR PERSONAL INFORMATION
                </h2>
            </div>
            <p className={styles.dividedItemBottom}>
                Personal information is maintained on our servers or those of our vendors, and is accessible by
                authorized employees, independent contractors, representatives, and agents as necessary for the purposes
                described in this privacy policy. We use reasonable and appropriate physical, technical, and
                organizational safeguards designed to promote the security of our systems and protect the
                confidentiality, integrity, availability, and resilience of personal information. However, no method of
                safeguarding information is completely secure, and we cannot guarantee that our safeguards will be
                effective or sufficient. If you have reason to believe that your interaction with us is no longer
                secure, please immediately notify us of the problem by contacting us at{' '}
                <a href={`mailto:${email}`}>{email}</a>.
            </p>

            <div id="Data-Retention" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>DATA RETENTION</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                We will retain your personal information for as long as your account is active or as needed to provide
                you with the Properties, and for a reasonable time thereafter in accordance with our standard procedures
                or as necessary to comply with our legal obligations, to resolve disputes, and to enforce our
                agreements. If you have an account with us, we will retain your account, marketing and user-provided
                data until you can cancel your account or request deletion of your personal information. Even if we
                delete some or all of your information, we may continue to retain and use anonymous or aggregate data,
                or any other data that constitutes non-personal information. Please note that we will not be liable for
                disclosures of your data due to errors or unauthorized acts of third parties.
            </p>

            <div id="Your-Choices" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>YOUR CHOICES</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                Consistent with applicable law, you may exercise the choices described in this section regarding your
                personal information and communications from us. You may also have certain additional rights available
                depending on laws in your state or country of residence, as described further below in the Privacy
                Policy.
            </p>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Opting Out of Messages or Services</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    We may send you marketing messages via email, or by SMS message if you consent to receive such text
                    messages. We may also send you in-game SMS messages if you opt in and consent to receive such text
                    messages. If you receive a marketing or in-game message from us, you may unsubscribe from future
                    messages in accordance with our standard unsubscribe process (such as by using the unsubscribe link
                    included in an email or replying STOP to a text message), or by sending an unsubscribe request to us
                    at <a href={`mailto:${email}`}>{email}</a>. We will process your request within a reasonable time
                    after receipt. Please note that if you opt out in this manner, certain aspects of our services may
                    no longer be available to you.
                </p>
            </div>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Cookies and Tracking Technologies</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    If you would like to stop or restrict the placement of cookies or flush any cookies that may already
                    be on your computer or device, please refer to and adjust your web browser preferences. Further
                    information on cookies is available at &nbsp;
                    <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
                        www.allaboutcookies.org
                    </a>
                    . By deleting our cookies or disabling future cookies, you may not be able to access certain areas
                    or features of our Properties or some of its functionality may be affected.
                </p>
            </div>

            <p className={styles.dividedItemBottom}>
                Note that cookie-based opt-outs must be performed on each device and browser that you wish to have opted
                out. For example, if you have opted out on your device browser, that opt-out will not be effective on
                your mobile device. Additionally, if you opt out on one of your devices, that opt out may not be
                effective on all of your devices. However, please be advised that cookie-based opt-outs are not
                effective on some mobile services.
            </p>

            <p className={styles.dividedItemBottom}>
                Some browsers have a Do Not Track (“DNT”) feature that lets users signal to websites that they do not
                want to have their online activities tracked. Please note that we do not respond to or honor DNT signals
                or similar mechanisms transmitted by web browsers. However, if you are a resident of California,
                Virginia or Colorado, we treat Global Privacy Control signals or other accepted universal opt out
                signals, when such signals are available and readable, as a means of opting out of the sale or sharing
                of personal information, or of opting out of the processing of personal information for targeted
                advertising, as applicable. Please see the sections titled California Privacy Rights and Other State
                Privacy Rights below for more information.
            </p>

            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>Interest-Based Advertising</li>
                </ul>
                <p className={styles.dividedItemTop}>
                    You may opt out of receiving targeted ads from certain data and advertising partners that
                    participate in certain industry self-regulatory programs. The DAA provides a website at&nbsp;
                    <a href="https://www.aboutads.info/consumers" target="_blank" rel="noopener noreferrer">
                        www.aboutads.info/consumers
                    </a>{' '}
                    with information about how to opt out of targeted advertising from some or all of the DAA’s
                    participating companies. Additionally, the Network Advertising Initiative (“NAI”) offers a website
                    at{' '}
                    <a href="https://optout.networkadvertising.org" target="_blank" rel="noopener noreferrer">
                        {' '}
                        http://optout.networkadvertising.org
                    </a>{' '}
                    where you can opt out of interest-based advertising from some or all of the NAI’s members. Please
                    note that by opting out, you will continue to see generic advertising that is not tailored to your
                    specific interests and activities. In the event that we perform cross-device matching (as described
                    above), once you have opted out on one device (“Opted-Out Device”), we will not use any new data
                    from the Opted-Out Device to identify you on another device for interest-based advertising purposes,
                    and we will not use data from another device for interest-based advertising purposes on the
                    Opted-Out Device.
                </p>
            </div>

            <p className={styles.dividedItemBottom}>
                For targeted advertisements delivered through mobile apps, users may opt out of certain ads or reset
                advertising identifiers via their device settings. To learn how to limit ad tracking or to reset the
                advertising identifier on your iOS and Android device, visit the following links:
            </p>
            <ul>
                <li>
                    {' '}
                    iOS - &nbsp;
                    <a href="https://support.apple.com/en-us/HT202074" target="_blank" rel="noopener noreferrer">
                        https://support.apple.com/en-us/HT202074
                    </a>
                </li>
                <li>
                    {' '}
                    Android - &nbsp;
                    <a
                        href="https://support.google.com/ads/answer/2662922?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://support.google.com/ads/answer/2662922?hl=en
                    </a>
                </li>
            </ul>

            <p className={styles.dividedItem}>
                You can also install the DAA’s AppChoices app on your device to opt out of targeted advertising by
                certain providers, and to select system-level advertising preferences on your device (such as “Limit Ad
                Tracking” on Apple devices, or “Opt-out of Interest-based ads” on Android devices).
            </p>

            <p className={styles.dividedItemBottom}>
                Finally, to learn more from the NAI about how to opt out of targeted advertising on mobile devices, you
                can also visit the following link: &nbsp;
                <a href="https://thenai.org/opt-out/mobile-opt-out" target="_blank" rel="noopener noreferrer">
                    https://thenai.org/opt-out/mobile-opt-out/
                </a>
                .
            </p>

            <div id="Nevada-Privacy-Rights" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>NEVADA PRIVACY RIGHTS</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                If you are a resident of Nevada, you have the right to opt out of the sale of certain personal
                information that we have collected (or may collect) from you to data brokers or other third parties. You
                can exercise this right by emailing us at <a href={`mailto:${email}`}>{email}</a> with the subject line
                “Nevada Do Not Sell Request.”
            </p>

            <div id="Cross-Border-Data-Transfers" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>CROSS-BORDER DATA TRANSFERS</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                If you submit personal information to us, that information may be processed in a jurisdiction where
                privacy laws may be less stringent than those in your country of residence. By submitting your personal
                information to us, you agree to the transfer, storage, and processing of such information in foreign
                jurisdictions including, but not limited to, the United States. Please note that personal information
                transferred to the United States is subject to access by law enforcement. Where applicable, we may use
                model clauses approved by the laws of your jurisdiction (such as Standard Contractual Clauses approved
                by the European Commission) for cross-border data transfers.
            </p>

            <div id="Children-s-Privacy" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>CHILDREN'S PRIVACY</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                Our Properties are intended for users ages 18 and over, and we do not knowingly collect personal
                information from children under the age of 16. If we become aware that personal information (or other
                information that is protected under applicable law) from a child under 16 has been collected, we will
                use all reasonable efforts to delete such information from our databases. If you believe we might have
                any personal information from or about a child under 16, please contact us by using the information the
                section below titled <strong>Contacting Us</strong>.
            </p>

            <div id="Changes-to-This-Policy" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>CHANGES TO THIS POLICY</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                We reserve the right, at our discretion, to update this Privacy Policy at any time and will notify you
                of any material changes to the way in which we treat personal information by posting an updated Privacy
                Policy or other notice on relevant areas of our Properties. Any updated version of this Privacy Policy
                will be effective immediately upon posting unless otherwise specified. Your continued use of the
                Properties after the effective date of the revised Privacy Policy will constitute your consent to those
                changes. However, we will not, without your consent, use your personal information in a manner
                materially different from what was stated at the time your personal information was collected. We
                encourage you to periodically review the Website for the latest information on our privacy practices.
            </p>

            <div id="Contacting-Us" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>CONTACTING US</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                If you have any questions about our privacy or security practices, you can contact us at:
            </p>
            <p className={styles.dividedItemBottom}>
                Email: <a href={`mailto:${email}`}>{email}</a>
            </p>
            <p className={styles.dividedItemBottom}>
                Mailing Address: 30 W. 21st Street, 7th Floor, New York, NY 10010
            </p>
            <p className={styles.dividedItemBottom}>Telephone: (212) 337-3796</p>

            <div id="California-Privacy-Rights" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>CALIFORNIA PRIVACY RIGHTS</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                The California Consumer Privacy Act of 2018 (“CCPA”), as amended by the California Privacy Rights Act of
                2020, provides you with specific rights regarding your personal information. This section describes the
                rights that California consumers have and explains how to exercise those rights. For the purposes of
                this section, personal information does not include: (i) information that is lawfully made available
                from federal, state or local government records; (ii) de-identified or aggregated data; or (iii)
                information excluded from the scope of the CCPA. These rights are granted only to the extent that you
                are a California consumer and we are acting as a “business” under the CCPA with respect to your personal
                information. The rights in this section are not intended to grant you additional rights, but only your
                rights under the CCPA.
            </p>
            <p className={styles.dividedItemBottom}>
                General information regarding our collection, use, and disclosure of personal information is set forth
                in the Privacy Policy above. To help consumers make informed privacy decisions, the CCPA defines
                personal information by discrete categories. Information about our data practices pertaining to these
                categories of personal information can be found below (some types of personal information may apply to
                multiple categories).
            </p>

            <p className={styles.dividedItemBottom}>
                In order to provide our Services to our Arena Partners, we may process Arena Data that we receive from
                or on behalf of them. If you are a California resident, you may have certain CCPA rights pertaining to
                such information. Under the CCPA, when we act as a “service provider” (not as a “business” or a “third
                party”) over such information, we only process your information through our Services as necessary to
                provide our services to our Arena Partners, at their direction. If you wish to exercise any of your
                rights under CCPA with respect to such information, please contact the relevant business that collected
                the information from you, who controls and determines how your information is processed.
            </p>

            <p className={classNames(styles.dividedItemBottom, styles.underlined)}> Information We Collect</p>

            <p className={styles.dividedItemBottom}>
                In the past 12 months, we have collected the categories of personal information described below for the
                business or commercial purpose(s) indicated. Depending on your level of interaction with us, we may not
                have collected your personal information from every specified category.
            </p>

            <table>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Examples</th>
                        <th>Business or Commercial Purpose(s)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Identifiers</td>
                        <td>
                            Name, alias, postal address, unique personal identifier, online identifier, internet
                            protocol (IP) address, device, browser, email address, account name, or other similar
                            identifiers
                        </td>
                        <td>
                            Account creation; communications with users; provide user support; consent and preferences
                            management; to personalize your experience with us; analytics to create new game features;
                            marketing activities; and as otherwise described in the section titled{' '}
                            <strong>How We Disclose or Share Your Personal Information in the Privacy Policy</strong>{' '}
                            above.
                        </td>
                    </tr>
                    <tr>
                        <td>Customer records information</td>
                        <td>
                            Name, signature, address, telephone number, or other similar information. Some personal
                            information included in this category may overlap with other categories
                        </td>
                        <td>
                            Account creation; communications with users; provide user support; to personalize your
                            experience with us; and as otherwise described in the section titled{' '}
                            <strong>How We Disclose or Share Your Personal Information</strong> in the Privacy Policy
                            above.
                        </td>
                    </tr>
                    <tr>
                        <td>Characteristics of protected classifications under California or federal law</td>
                        <td>Age, date of birth, gender</td>
                        <td>Age verification; analytics</td>
                    </tr>
                    <tr>
                        <td>Commercial information</td>
                        <td>
                            Name, email, address, telephone number, payment information or any other financial
                            information; records of products or services purchased; purchasing or consuming histories or
                            tendencies
                        </td>
                        <td>
                            If you provide information to purchase a service, we will use that information to process
                            your payment and facilitate your receipt of the product or service; we may also use your
                            information to contact you about our products and services, to personalize your experience
                            with us including by presenting products or offers tailored to you, to administer our
                            sweepstakes or other Programs; to respond to your direct inquiries; and for analytics.
                        </td>
                    </tr>
                    <tr>
                        <td>Internet or other similar network activity information</td>
                        <td>
                            Browsing history, search history, information regarding consumer’s interaction with a
                            website, application, or advertisement
                        </td>
                        <td>
                            To personalize your experience with us, including by presenting products or offers tailored
                            to you; marketing and advertising; analytics to create new game features; and as otherwise
                            described in this Privacy Policy.
                        </td>
                    </tr>
                    <tr>
                        <td>Geolocation data</td>
                        <td>Non-precise location information</td>
                        <td>User support to distinguish users from different locations; analytics</td>
                    </tr>
                    <tr>
                        <td>Sensitive personal information ("SPI")</td>
                        <td>Tax information, including social security number, solely for sweepstakes winners</td>
                        <td>Processing tax information for sweepstakes winners</td>
                    </tr>
                    <tr>
                        <td>Inferences</td>
                        <td>
                            Inferences drawn from any of the categories described above to create a profile about a
                            consumer reflecting the consumer’s preferences and characteristics
                        </td>
                        <td>
                            To personalize your experience with us, including by presenting products or offers tailored
                            to you; analytics to create new game features; marketing and advertising; game preferences
                            based on users’ activities
                        </td>
                    </tr>
                </tbody>
            </table>

            <p className={classNames(styles.dividedItem, styles.underlined)}> Information We Disclose</p>

            <p className={styles.dividedItemBottom}>
                Within the past twelve (12) months, we may have (i) disclosed your personal information; (ii) sold your
                personal information; or (iii) shared your personal information for cross-context behavioral advertising
                with the following entities:
            </p>

            <table>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Source</th>
                        <th>Disclosed in Prior Twelve (12) Months for the Following Business Purposes</th>
                        <th>Sold or Shared in the Prior Twelve (12) Months for the Following Purposes</th>
                        <th>Categories of Third Parties With Which We Disclose, Sell, or Share Personal Information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Identifiers</td>
                        <td>Consumers; cookies Website analytics providers</td>
                        <td>
                            To help ensure the security and integrity of our Services; to verify or maintain the quality
                            or safety of our services, and to identify and repair errors; Undertaking internal research
                            for service optimization; Providing advertising and marketing services; Auditing related to
                            counting ad impressions to unique visitors; For other business services performed on our
                            behalf, including maintaining or servicing accounts, facilitating consumer access to
                            Third-Party Games, providing customer service, processing or fulfilling orders and
                            transactions, verifying customer information, processing payments, providing analytic
                            services, providing storage, or providing similar services; and as otherwise described in
                            the section titled <strong>How We Disclose or Share Your Personal Information</strong> in
                            the Privacy Policy above.
                        </td>
                        <td>
                            To personalize your experience with us, including by presenting products or offers tailored
                            to you; for analytics, marketing and advertising, including targeted advertising.
                        </td>
                        <td>
                            Service providers who perform business services for us; Marketing and advertising partners;
                            Social media platforms; third-party developers whose games are made available via the
                            Properties
                        </td>
                    </tr>
                    <tr>
                        <td>Customer records information</td>
                        <td>Consumers</td>
                        <td>
                            To help ensure the security and integrity of our Services; to verify or maintain the quality
                            or safety of our services, and to identify and repair errors; Undertaking internal research
                            for service optimization; Providing advertising and marketing services; Auditing related to
                            counting ad impressions to unique visitors; For other business services performed on our
                            behalf, including maintaining or servicing accounts, providing customer service, processing
                            or fulfilling orders and transactions, verifying customer information, processing payments,
                            providing analytic services, providing storage, or providing similar services; and as
                            otherwise described in the section titled{' '}
                            <strong>How We Disclose or Share Your Personal Information</strong>
                            in the Privacy Policy above.
                        </td>
                        <td>N/A</td>
                        <td>
                            Service providers who perform business services for us; Marketing and advertising partners;
                            third-party developers whose games are made available via the Properties
                        </td>
                    </tr>
                    <tr>
                        <td>Characteristics of protected classifications under California or federal law</td>
                        <td>Consumers</td>
                        <td>
                            Verifying customer information; analytics; and as otherwise described in the section titled
                            &nbsp;
                            <strong>How We Disclose or Share Your Personal Information</strong> in the Privacy Policy
                            above.
                        </td>
                        <td>N/A</td>
                        <td>Service providers who perform business services for us</td>
                    </tr>
                    <tr>
                        <td>Commercial information</td>
                        <td>Consumers</td>
                        <td>
                            To help ensure the security and integrity of our Services; to verify or maintain the quality
                            or safety of our services, and to identify and repair errors; Undertaking internal research
                            for service optimization; Providing advertising and marketing services; Auditing related to
                            counting ad impressions to unique visitors; For other business services performed on our
                            behalf, including maintaining or servicing accounts, facilitating consumer access to
                            Third-Party Games, providing customer service, processing or fulfilling orders and
                            transactions, verifying customer information, processing payments, providing analytic
                            services, providing storage, or providing similar services; and as otherwise described in
                            the section titled <strong>How We Disclose or Share Your Personal Information </strong>in
                            the Privacy Policy above.
                        </td>
                        <td>
                            To personalize your experience with us including by presenting products or offers tailored
                            to you; for analytics, marketing and advertising, including targeted advertising
                        </td>
                        <td>
                            Service providers and other third parties, including providers of the following services:
                            website hosting, data analysis, payment processing, order fulfillment, infrastructure
                            provision, information technology services, third-party developers whose games are made
                            available via the Properties, customer service, e-mail delivery, credit card processing,
                            auditing, and other similar services, including services that deliver targeted advertising
                            about goods and services that may be of interest to you.
                        </td>
                    </tr>
                    <tr>
                        <td>Internet or other similar network activity information</td>
                        <td>Consumer interactions with Properties through tracking technologies</td>
                        <td>
                            Helping to ensure the security and integrity of our services, to verify or maintain the
                            quality or safety of our services, and to identify and repair errors Undertaking internal
                            research for service optimization Providing advertising and marketing services For other
                            business services performed on our behalf, including maintaining or servicing accounts,
                            facilitating consumer access to Third-Party Games, providing customer service, processing or
                            fulfilling orders and transactions, verifying customer information, processing payments,
                            providing analytic services, providing storage, or providing similar services, and as
                            otherwise described in this Privacy Policy.
                        </td>
                        <td>
                            To personalize your experience with us including by presenting products or offers tailored
                            to you; for analytics, marketing and advertising, including targeted advertising
                        </td>
                        <td>
                            Service providers and other third parties, including providers of the following services:
                            website hosting, data analysis, payment processing, order fulfillment, infrastructure
                            provision, information technology services, third-party developers whose games are made
                            available via the Properties, customer service, e-mail delivery, credit card processing,
                            auditing, and other similar services, including services that deliver targeted advertising
                            about goods and services that may be of interest to you.
                        </td>
                    </tr>
                    <tr>
                        <td>Geolocation data</td>
                        <td>Consumers; consumer interactions with Properties</td>
                        <td>
                            Undertaking internal research for service optimization Providing advertising and marketing
                            services For other business services performed on our behalf, including maintaining or
                            servicing accounts, providing customer service, processing or fulfilling orders and
                            transactions, verifying customer information, processing payments, providing analytic
                            services, providing storage, or providing similar services, and as otherwise described in
                            this Privacy Policy.
                        </td>
                        <td>
                            To personalize your experience with us including by presenting products or offers tailored
                            to you; for analytics, marketing and advertising, including targeted advertising
                        </td>
                        <td>
                            Service providers and other third parties, including analytics providers and services that
                            deliver targeted advertising about goods and services that may be of interest to you.
                        </td>
                    </tr>
                    <tr>
                        <td>Sensitive personal information ("SPI")</td>
                        <td>Consumers</td>
                        <td>Federal and state tax authorities</td>
                        <td>N/A</td>
                        <td>Government tax authorities</td>
                    </tr>
                    <tr>
                        <td>Inferences</td>
                        <td>Consumer interactions with Properties</td>
                        <td>
                            To personalize your experience with us, including by presenting products or offers tailored
                            to you, for advertising and marketing, and as otherwise described herein.
                        </td>
                        <td>
                            To personalize your experience with us including by presenting products or offers tailored
                            to you; for analytics, marketing and advertising, including targeted advertising
                        </td>
                        <td>
                            Service providers who perform business services for us Marketing and advertising partners
                        </td>
                    </tr>
                </tbody>
            </table>

            <p className={classNames(styles.dividedItem, styles.underlined)}> Rights to Your Information</p>

            <ol className={styles.numbered}>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>Right to Know</span>
                </li>
                <p className={styles.dividedItem}>
                    As a California consumer, you have the right to request that we disclose certain information to you
                    about our collection, use, disclosure or sale of your personal information over the past 12 months.
                    Once we receive and confirm your verifiable consumer request (see Exercising Your Rights, below),
                    and subject to certain limitations that we describe below, we will disclose such information. You
                    have the right to request any or all of the following:
                </p>
                <ul>
                    <li>The categories of personal information we collected about you.</li>
                    <li>The categories of sources from which the personal information is collected.</li>
                    <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                    <li>The categories of third parties with whom we share that personal information.</li>
                    <li>
                        The specific pieces of personal information we collected about you (see Data Portability Rights
                        below).
                    </li>
                </ul>
                <li className={classNames(styles.dividedItemTop, styles.numberedLi)}>
                    <span className={styles.margined}>Right to Delete</span>{' '}
                </li>
                <p className={styles.dividedItem}>
                    You have the right to request that we delete any of your personal information that we collected from
                    you and retained, subject to certain exceptions. Once we receive and confirm your verifiable
                    consumer request (see Exercising Your Rights, below), we will delete (and direct our service
                    providers to delete) your personal information from our records, unless an exception applies.
                    However, we maybe retain personal information that has been de-identified or aggregated.
                    Furthermore, we may deny your deletion request if retaining the information is necessary for us or
                    our service provider(s) in order to perform certain actions set forth under CCPA, such as detecting
                    security incidents and protecting against fraudulent or illegal activity.
                </p>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>Right to Data Portability</span>
                </li>
                <p className={styles.dividedItem}>
                    You have the right to request a copy of personal information we have collected and maintained about
                    you in the past 12 months. The CCPA allows you to request your information from us up to twice
                    during a 12-month period. We will provide our response in a readily usable (and usually electronic)
                    format.
                </p>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>Right to Correct</span>
                </li>
                <p className={styles.dividedItem}>
                    You have the right to request the correction of any personal information we maintain about you.
                </p>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>
                        Right to Opt Out of Selling or Sharing Your Personal Information
                    </span>
                </li>
                <p className={styles.dividedItem}>
                    You have the right to opt out of the sale or sharing of your personal information, along with the
                    right to opt in to the sale of such information. We do not knowingly sell or share the personal
                    information of consumers under 16 years of age, unless we receive affirmative authorization (the
                    "right to opt-in") from either the consumer who is less than 16 (but greater than 13) years of age,
                    or the parent or guardian of a consumer less than 13 years of age. To our knowledge, we do not sell
                    or share the personal information of minors under 16 years of age.
                </p>
                <p className={styles.dividedItemBottom}>
                    To exercise the right to opt out, you (or your authorized representative) may submit a request to us
                    by visiting the following: "Do Not Sell or Share My Personal Information." Alternatively, you may
                    submit an opt-out request by emailing us at <a href={`mailto:${email}`}>{email}</a>.
                </p>
                <p className={styles.dividedItemBottom}>
                    You may change your mind and opt back in to personal information sales at any time by contacting us
                    at <a href={`mailto:${email}`}>{email}</a>.
                </p>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>
                        Right to Limit the Use of Your Sensitive Personal Information
                    </span>
                </li>
                <p className={styles.dividedItem}>
                    You have the right to limit the use or disclosure of your sensitive personal information (“SPI”) if
                    we are using your SPI beyond what is reasonable and proportionate to provide the requested goods or
                    services. We currently do not collect or process any SPI except for consumers who have won a cash
                    prize in a sweepstakes that we have administered. Consumers who have provided us requested materials
                    offline that contain SPI may contact us directly at <a href={`mailto:${email}`}>{email}</a> to
                    assert any applicable privacy rights.
                </p>
                <li className={styles.numberedLi}>
                    <span className={styles.margined}>Right to Non-Discrimination</span>
                </li>
                <p className={styles.dividedItem}>
                    We will not discriminate against you for exercising any of your CCPA rights, including but not
                    limited to, by:
                    <ul className={styles.dividedItemTop}>
                        <li>Denying you goods or services.</li>
                        <li>
                            Charging you different prices or rates for goods or services, including through granting
                            discounts or other benefits, or imposing penalties.
                        </li>
                        <li>Providing you a different level or quality of goods or services.</li>
                        <li>
                            Suggesting that you may receive a different price or rate for goods or services or a
                            different level or quality of goods or services.
                        </li>
                    </ul>
                </p>
                <li className={classNames(styles.numberedLi, styles.dividedItemBottom)}>
                    <span className={styles.margined}>Exercising Your Rights</span>
                </li>
                <p className={styles.dividedItem}>
                    To exercise the rights described above, please contact us by using the following methods:
                </p>
                <div className={styles.dividedItem}>
                    <ul>
                        <li>Calling us toll-free at (212) 337-3796</li>
                        <li>
                            Emailing us at <a href={`mailto:${email}`}>{email}</a>{' '}
                        </li>
                    </ul>
                </div>
                <p className={styles.dividedItemBottom}>
                    After submitting a request, we will take steps, where required, to verify your identity so that we
                    may properly respond and confirm that it is not a fraudulent request. In order to verify your
                    identity, we will ask, at a minimum, that you provide your name and email address so that we may
                    attempt to match this information with the information existing in our systems. In some instances,
                    we may request additional information to help us identify you. When providing us this information,
                    you represent and affirm that all information provided is true and accurate. If we are unable to
                    verify that the consumer submitting the request is the same individual about whom we have collected
                    personal information, we may contact you for more information, or we may not be able to meet your
                    request.
                </p>
                <p className={styles.dividedItemBottom}>
                    Only you, or an agent legally authorized to act on your behalf, may make a verifiable request
                    related to your personal information. If you are making a request as the authorized agent of a
                    California consumer, we will ask you also submit reliable proof that you have been authorized in
                    writing by the consumer to act on such consumer’s behalf.
                </p>
                <p className={styles.dividedItemBottom}>
                    We will make every effort to respond to your request within 45 days from when you contacted us. If
                    you have a complex request, the CCPA allows us up to 90 days to respond. We will still contact you
                    within 45 days from when you contacted us to let you know we need more time to respond.
                </p>
            </ol>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}> Notice of Financial Incentive</p>
            <p className={styles.dividedItemBottom}>
                We may offer you financial incentives, including sweepstakes, discounts, coupons, gift cards, or other
                rewards, for sharing personal information through programs that we may provide from time to time
                (collectively, our “Programs”). For example, we may reward you with a gift card if you win a
                sweepstakes, or in connection with other offers. When we offer our Programs to you, we believe that the
                value of the different pricing or services you receive exceeds the cost of providing your personal
                information. Your participation in these Programs is completely optional and subject to the terms
                provided to you at the time you sign up.
            </p>
            <p className={styles.dividedItemBottom}>
                You may withdraw from participation in a Program at any time by contacting us using the designated
                method set forth in the applicable Program rules. Visit the terms and conditions of each Program to view
                full details, including how to join. You may also withdraw from or opt out of our Programs at any time
                by contacting us at <a href={`mailto:${email}`}>{email}</a>.
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}> California “Shine the Light”</p>
            <p className={styles.dividedItemBottom}>
                In addition to the above rights, under California Civil Code Section 1798.83 (“Shine the Light”),
                California residents may have the right to request in writing from businesses with whom they have an
                established business relationship: (a) a list of the categories of personal information, as defined
                under Shine the Light, such as name, email address, and mailing address, and the type of services
                provided to the customer that a business has disclosed to third parties (including affiliates that are
                separate legal entities) during the immediately preceding calendar year for the third parties’ direct
                marketing purposes; and (b) the names and addresses of all such third parties. To request the above
                information, please contact us by email at <a href={`mailto:${email}`}>{email}</a>. If you do not want
                your personal information shared with any third party who may use such information for direct marketing
                purposes, then you may opt out of such disclosures by sending an email to us at{' '}
                <a href={`mailto:${email}`}>{email}</a>.
            </p>

            <div id="Other-State-Privacy-Rights" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}>OTHER STATE PRIVACY RIGHTS</h2>
            </div>
            <p className={styles.dividedItemBottom}>
                Residents of Virginia, Colorado, Connecticut, and Utah (“Applicable States”) may have certain rights
                with respect to their personal information. These rights are established through the Virginia Consumer
                Data Protection Act (“VCDPA”), Colorado Privacy Act (“CPA”), the Connecticut Data Privacy Act (“CTDPA”)
                and the Utah Consumer Privacy Act (“UCPA”) (collectively, the “Applicable State Laws”). The rights
                available to residents of these Applicable States are explained below.
            </p>
            <p className={styles.dividedItemBottom}>
                The Applicable State Laws provide consumers who are residents of Applicable States with specific rights
                regarding their personal information. To the extent that you are a resident of an Applicable State, this
                section describes your rights under your Applicable State’s privacy laws and explains how you may
                exercise these rights.
            </p>

            <p className={styles.dividedItemBottom}>
                These rights are granted only to the extent that you are a resident of an Applicable State and we are
                obligated to grant the rights stated below under Applicable State Laws with respect to your personal
                information. The rights in this section are not intended to grant you additional rights, but only your
                rights under Applicable State Laws.
            </p>

            <p className={styles.dividedItemBottom}>
                The categories of personal information we process, our purposes for processing your personal
                information, the categories of personal information that we share with third parties, and the categories
                of third parties with whom we share it are set forth in the details of our Privacy Policy above.
            </p>

            <p className={classNames(styles.dividedItemBottom, styles.underlined)}> Rights to Your Information</p>

            <p className={styles.dividedItemBottom}>
                In addition to the rights set forth in our Privacy Policy, the Applicable State Laws provides you with
                the following rights, except where indicated otherwise below:
            </p>

            <ul>
                <li>
                    Right to know. You have the right to know whether we process your personal information and to access
                    such personal information.
                </li>
                <li>
                    Right to data portability. You have the right to obtain a copy of your personal information that you
                    previously provided to us or that we have obtained in a portable and, to the extent technically
                    feasible, readily usable format that allows you to transmit the data to another business without
                    hindrance, where the processing is carried out by automated means. Subject to certain exceptions,
                    you may request such personal information free of charge up to once annually, except for Virginia
                    residents, who may request such personal information up to twice annually.
                </li>
                <li>
                    Right to delete. Residents of Virginia, Colorado, and Utah have the right to delete personal
                    information that you have provided to us or that we have obtained about you; requests from Utah
                    residents cover only personal information that you have provided directly to us. Please note that we
                    may deny such requests to delete if the requested deletion falls under an exception as set forth in
                    the Applicable State Laws. Additionally, if you request deletion of your personal information and we
                    have obtained such information from a third-party source, we may retain such data by (a) keeping a
                    record of the deletion request and the minimum data necessary to ensure that your personal
                    information remains deleted from our records and that such retained data is not used for any other
                    purpose, or (b) we may opt you out of the processing of such personal information for any purpose
                    except for those allowed under the Applicable State Laws.
                </li>
                <li>
                    Right to opt out. Residents of Virginia, Colorado, and Connecticut have the right to opt out of the
                    processing of the personal information for purposes of: (i) targeted advertising; (ii) the sale of
                    personal information; or (iii) profiling in furtherance of decisions that produce legal or similarly
                    significant effects concerning you. Utah residents may opt out of the processing for the purposes
                    described in (i) and (ii) of this paragraph.
                    <p className={classNames(styles.dividedItem, styles.margined)}>
                        {' '}
                        As of the latest date of the Privacy Policy:{' '}
                    </p>
                    <ul>
                        <li>We process personal information for the purposes of targeted advertising;</li>
                        <li>We do not sell your personal information in exchange for monetary consideration; and</li>
                        <li>
                            We do not engage in profiling decisions based on your personal information that produce
                            legal or similarly significant effects concerning you.
                        </li>
                    </ul>
                    <p className={styles.dividedItemTop}>
                        If you wish to opt out of the processing of your personal information for any of the above
                        purposes, and your state of residence provides for such opt-out right, please email us at &nbsp;
                        <a href={`mailto:${email}`}>{email}</a>.
                    </p>
                </li>
                <li>
                    Right to correct. Residents of Virginia, Colorado and Connecticut have the right to correct
                    inaccuracies in your personal information, taking into account the nature of the personal
                    information and the purposes for which we process it.
                </li>
                <li>
                    Right to nondiscrimination. You have the right not to receive discriminatory treatment by us for the
                    exercise of your privacy rights under Applicable State Laws. Unless permitted by the Applicable
                    State Law, we will not:
                    <ul>
                        <li>Deny you goods or services;</li>

                        <li>
                            Charge you different prices or rates for goods or services, including through granting
                            discounts or other benefits, or imposing penalties;
                        </li>

                        <li>Provide you a different level or quality of goods or services; or</li>

                        <li>
                            Suggest that you may receive a different price or rate for goods or services or a different
                            level or quality of goods or services.
                        </li>
                    </ul>
                </li>
            </ul>

            <p className={classNames(styles.dividedItem, styles.underlined)}>
                How to Exercise Your Rights; Verifying Your Identity
            </p>

            <p className={styles.dividedItemBottom}>
                To exercise any of your Applicable State Law privacy rights, or if you have any questions about your
                privacy rights, you may contact us by:
            </p>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        Emailing us at <a href={`mailto:${email}`}>{email}</a>{' '}
                    </li>
                    <li>Calling us at toll-free at (212) 337-3796</li>
                </ul>
            </div>
            <p className={styles.dividedItemBottom}>
                After submitting a request, we will take steps to verify your identity so that we may properly respond
                and/or confirm that your request is not fraudulent. We may contact you for additional information as
                reasonably necessary to authenticate your request, but if we are ultimately unable to authenticate your
                request using reasonably commercial efforts, then we may not be able to comply with it.
            </p>
            <p className={styles.dividedItemBottom}>
                Only you may make a verifiable request related to your personal information. If you are making a request
                as the parent or legal guardian of a known child regarding the processing of that child’s personal
                information, we may ask you to submit reliable proof of your identity.
            </p>

            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>
                {' '}
                Response Time; Your Right to Appeal{' '}
            </p>

            <p className={styles.dividedItemBottom}>
                We will make every effort to respond to your request within 45 days from when you contacted us. If you
                have a complex request, certain Applicable State Laws allow us an extension of our time to respond. We
                will contact you within 45 days from when you contacted us to inform you of the need for additional time
                and the reason for such extension. We may charge you a reasonable fee to cover administrative costs if
                your requests are manifestly unfounded, excessive, or repetitive.
            </p>
            <p className={styles.dividedItemBottom}>
                If we decline to take action on a request that you have submitted, we will inform you of our reasons for
                doing so and, for Virginia, Colorado and Connecticut residents, provide instructions for how to appeal
                the decision. Residents of those states will have the right to appeal within a reasonable period of time
                after you have received our decision. Within 60 days of the time provided by your Applicable State Law
                of our receipt of your appeal, we will inform you in writing of any action taken or not taken in
                response to the appeal, including a written explanation of the reasons for the decisions. If we deny
                your appeal, we will provide you with a method for contacting your state’s regulatory authority to
                submit a complaint.
            </p>

            <p className={classNames(styles.dividedItemBottom, styles.underlined)}> Sensitive Data</p>

            <p className={styles.dividedItemBottom}>
                The State Laws of Virginia, Colorado and Connecticut require companies to obtain a consumer’s
                affirmative consent before processing “sensitive data,” which may include, depending on the specific
                statute, information that reveals, among other things, precise geolocation data; citizenship or
                citizenship status; biometric data; personal data regarding a known child; and Social Security number or
                other government-issued ID. We will not process any such sensitive data from Virginia, Colorado, or
                Connecticut residents without first obtaining your consent. Additionally, for Colorado residents, we
                will not process any sensitive data inferences with first obtaining your consent.
            </p>

            <div id="European-Privacy-Rights" className={styles.anchorTarget}>
                <h2 className={classNames(styles.title1, styles.dividedItem)}> EUROPEAN PRIVACY RIGHTS </h2>
            </div>
            <p className={styles.dividedItemBottom}>
                IF YOU ARE SITUATED IN THE EUROPEAN ECONOMIC AREA, SWITZERLAND, OR THE UNITED KINGDOM, THIS SECTION
                APPLIES TO OUR COLLECTION, USE, AND DISCLOSURE OF YOUR PERSONAL DATA AND ADDITIONAL RIGHTS YOU HAVE
                UNDER APPLICABLE LAW.{' '}
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>Legal Basis </p>
            <p className={styles.dividedItemBottom}>
                We will only use your personal data, as that term is defined under the General Data Protection
                Regulation (“GDPR”), when the law allows us to. Most commonly, we will use your personal data in the
                following circumstances:{' '}
            </p>
            <ul>
                <li>
                    Where we need to perform the contract we are about to enter into or have entered into with you.{' '}
                </li>
                <li>
                    Where it is necessary for our legitimate interests (or those of a third party) and your interests
                    and fundamental rights do not override those interests.{' '}
                </li>
                <li>Where you have consented to a certain use of your personal data.</li>
                <li>
                    Where we need to comply with a legal or regulatory obligation. To the extent permitted under
                    applicable laws, we will also process, transfer, disclose, and preserve personal data when we have a
                    good faith belief that doing so is necessary.{' '}
                </li>
            </ul>
            <p className={classNames(styles.dividedItem, styles.underlined)}>Data Controller </p>
            <p className={styles.dividedItemBottom}>
                Arkadium is the data controller of all personal data collected through our Properties, with the
                exception of Arena Data, for which Arkadium acts as a data processor on behalf of its Arena Partners in
                the EEA. To contact us, please see the section below titled Contacting Us. With regard to Arena data,
                you may consult the privacy policy and contact information of the applicable Arena Partner that acts as
                the data controller of your Arena data for additional information about their data processing practices.{' '}
            </p>
            <p className={styles.dividedItemBottom}>
                If you are situated in the EEA, Switzerland, or the UK and have any complaints regarding our privacy
                practices, you have the right to make a complaint at any time to your local supervisory authority. We
                would, however, appreciate the chance to deal with your concerns before you approach your supervisory
                authority, so please contact us in the first instance. If you have a complaint, please contact our
                privacy manager here: <a href={`mailto:${email}`}>{email}</a>.
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>
                Provision of Personal data and Failure to Provide Personal Data{' '}
            </p>
            <p className={styles.dividedItemBottom}>
                Where we need to collect personal data by law or under the terms of a contract we have with you, and you
                fail to provide that data when requested, we may not be able to perform the contract we have or are
                trying to enter into with you (for example, to provide you with our Services). In this case, we may not
                be able to provide certain Services to you.{' '}
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>
                Collection of Personal Data from Third-party Sources{' '}
            </p>
            <p className={styles.dividedItemBottom}>
                We may obtain personal data and other information about you through public sources and through our
                third-party partners who help us provide our products and services to you.
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>Withdrawing Your Consent</p>
            <p className={styles.dividedItemBottom}>
                If we are relying on your consent to process your Personal Data, you have the right to withdraw your
                consent at any time by contacting us at <a href={`mailto:${email}`}>{email}</a>.{' '}
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>Data Transfer </p>
            <p className={styles.dividedItemBottom}>
                We may transfer personal data from the EEA, Switzerland, and the UK to the USA and other countries, some
                of which have not been determined by the European Commission or the UK Secretary of State to have an
                adequate level of data protection. Where we use certain vendors, we may use specific contracts approved
                by the European Commission or the UK Secretary of State which give personal data the same protection it
                has in Europe. For more information about how we transfer your data, please contact us at{' '}
                <a href={`mailto:${email}`}>{email}</a>.{' '}
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>
                Use of Your Personal Data for Marketing Purposes{' '}
            </p>
            <p className={styles.dividedItemBottom}>
                We strive to provide you with choices regarding certain personal data uses, particularly around
                marketing and advertising. We may use your personal data to form a view on what we think you may want or
                need, or what may be of interest to you. This is how we decide which products, services and offers may
                be relevant for you (generally called marketing). You will receive marketing communications from us if
                you have requested information from us or used our Services and, in each case, you have consented to our
                use of your personal data for marketing purposes.{' '}
            </p>
            <p className={classNames(styles.dividedItemBottom, styles.underlined)}>Data Subject Rights </p>
            <p className={styles.dividedItemBottom}>
                If you are situated in the European Union, Switzerland, or the UK, under the GDPR, as a data subject,
                you have the right to:{' '}
            </p>
            <div className={styles.dividedItemBottom}>
                <ul>
                    <li>
                        Request access to your personal data (commonly known as a "data subject access request"). This
                        enables you to receive a copy of the personal data we hold about you and to check that we are
                        lawfully processing it.
                    </li>
                    <li>
                        Request correction of the personal data that we hold about you. This enables you to have any
                        incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                        accuracy of the new data you provide to us.
                    </li>
                    <li>
                        Request erasure of your personal data. This enables you to ask us to delete or remove personal
                        data where there is no good reason for us continuing to process it. You also have the right to
                        ask us to delete or remove your personal data where you have successfully exercised your right
                        to object to processing (see below), where we may have processed your information unlawfully, or
                        where we are required to erase your personal data to comply with local law. Note, however, that
                        we may not always be able to comply with your request of erasure for specific legal reasons
                        which will be notified to you, if applicable, at the time of your request.
                    </li>
                    <li>
                        Object to processing of your personal data where we are relying on a legitimate interest (or
                        those of a third party) and there is something about your particular situation which makes you
                        want to object to processing on this ground as you feel it impacts on your fundamental rights
                        and freedoms. You also have the right to object where we are processing your personal data for
                        direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
                        grounds to process your information which override your rights and freedoms.
                    </li>
                    <li>
                        Request restriction of processing of your personal data. This enables you to ask us to suspend
                        the processing of your personal data in the following scenarios: (a) if you want us to establish
                        the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase
                        it; (c) where you need us to hold the data even if we no longer require it as you need it to
                        establish, exercise, or defend legal claims; or (d) you have objected to our use of your data
                        but we need to verify whether we have overriding legitimate grounds to use it.
                    </li>
                    <li>
                        Request the transfer of your personal data to you or to a third party. We will provide to you,
                        or a third party you have chosen, your personal data in a structured, commonly used,
                        machine-readable format. Note that this right only applies to automated information which you
                        initially provided consent for us to use or where we used the information to perform a contract
                        with you.{' '}
                    </li>
                    <li>
                        Withdraw consent at any time where we are relying on consent to process your personal data.
                        However, this will not affect the lawfulness of any processing carried out before you withdraw
                        your consent. If you withdraw your consent, we may not be able to provide certain products or
                        services to you. We will advise you if this is the case at the time you withdraw your consent.
                    </li>
                </ul>
            </div>
            <p className={styles.dividedItemBottom}>
                To exercise your rights under the GDPR, please contact us at <a href={`mailto:${email}`}>{email}</a>.
                Please note that for you to assert these rights, we may need to verify your identity to confirm your
                right to access your personal data. This is a security measure to ensure that personal data is not
                disclosed to any person who has no right to receive it. To verify your identity, we may need to gather
                more personal data from you than we currently have.{' '}
            </p>
        </div>
    );
});
