import React from 'react';

import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { PageTypes } from '../constants/Pages';
import { setPageType } from '../store/ducks/pages';
import { P404Template } from '../templates/p404/404Template';

type P404Props = {
    dispatch?: Dispatch<Action>;
};

const P404PageBase = React.memo(({ dispatch }: P404Props) => {
    dispatch(setPageType(PageTypes.NotFound));

    return <P404Template />;
});

export default connect()(P404PageBase);
