import React, { useState } from 'react';

import classNames from 'classnames';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { StringUtils } from '../../../../../../utils/StringUtils';
import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { IGame } from '../../../../../models/Game/Game';
import { DropdownDesktop } from '../../../../../molecules/DropdownDesktop/DropdownDesktop';
import { NotifyIconTooltip } from '../../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { GameService } from '../../../../../services/GameService';
import { HighScoreModel } from '../../../../../services/HighScoreService';
import { SessionStorageService } from '../../../../../services/SessionStorage';
import { TranslationService } from '../../../../../services/TranslationService';
import { TooltipMobile } from '../../../TooltipMobile/TooltipMobile';
import styles from './Highscore.css';
import HighscoreTable from './HighscoreTable/Table';

enum TOOLTIP_HIGHSCORE_MESSAGE {
    TOP = `MY_HIGHSCORE_TOOLTIP`,
    BOTTOM = ``,
    PUBLIC = 'HIGHSCORE_TOOLTIP',
}

const SortingsMap = new Map<string, string>();
// first sorting can not be implemented right now, will use in in the future
// SortingsMap.set('sort1', TranslationService.translateIt('MY_MOST_PLAYED_GAMES'));
SortingsMap.set('sort2', TranslationService.translateIt('NEWEST_FIRST'));
SortingsMap.set('sort3', TranslationService.translateIt('HIGH_TO_LOW'));
SortingsMap.set('sort4', TranslationService.translateIt('LOW_TO_HIGH'));

interface HighscoreProps extends RouteComponentProps {
    games: IGame[];
    highScore: HighScoreModel[];
    rowPerPage: number;
    isMyProfile: boolean;
    name: string;
    adFree: boolean;
}

type LeaderboardRow = {
    arkadiumSlug: string;
    name: string;
    imageUrl: string;
    score: number;
    scoreTeaser: string;
    date: number;
};

export const Highscore = withRouter(
    React.memo((props: HighscoreProps) => {
        const [activeSorting, setActiveSorting] = useState(SessionStorageService.getItem('sorting_highscores'));
        const [userOpenedDropdown, setUserOpenedDropdown] = useState(false);
        const keyName = props.isMyProfile ? 'MY_HIGH_SCORES' : 'PUBLIC_HIGH_SCORES';
        const tableData = setupLeaderBoardData(
            props.highScore,
            props.games,
            props.rowPerPage,
            activeSorting
        );

        const handleDropDownDesktopClick = (e) => {
            setActiveSorting(e);
        };

        const handleDropDownMobileClick = (e: string) => {
            setUserOpenedDropdown(false);
            setActiveSorting(e);
            SessionStorageService.setItem('sorting_highscores', e);
        };

        const NotificationTextTop = (
            <I18nText
                keyName={props.isMyProfile ? TOOLTIP_HIGHSCORE_MESSAGE.TOP : TOOLTIP_HIGHSCORE_MESSAGE.PUBLIC}
                {...(props.isMyProfile ? {} : { params: { name: props.name } })}
            />
        );

        return (
            <div
                className={classNames(styles.highscoreContainer, {
                    [styles.adFree]: props.adFree,
                })}
            >
                <div className={classNames(styles.highscoreHeader)}>
                    <div className={styles.highscoreCaptionContainer}>
                        <I18nText
                            as="h2"
                            className={styles.highscoreCaption}
                            keyName={keyName}
                            {...(props.isMyProfile ? {} : { params: { name: props.name } })}
                        />
                    </div>
                    <NotifyIconTooltip
                        textTop={NotificationTextTop}
                        textBottom={TranslationService.translateIt(TOOLTIP_HIGHSCORE_MESSAGE.BOTTOM)}
                        tooltipNotVisible={false}
                        classNameMessage="profilePage"
                    />
                    <div className={styles.highscoreDropdownContainer}>
                        <DropdownDesktop
                            id="high score sorting"
                            dropdownItems={SortingsMap}
                            onClick={handleDropDownDesktopClick}
                            placeholder={
                                activeSorting
                                    ? SortingsMap.get(activeSorting)!
                                    : TranslationService.translateIt('SORT_BY')
                            }
                        />
                    </div>
                </div>

                <HighscoreTable
                    tableData={tableData}
                    handleDropDownMobileClick={handleDropDownMobileClick}
                    userOpenedDropdown={userOpenedDropdown}
                    setUserOpenedDropdown={setUserOpenedDropdown}
                    activeSorting={activeSorting}
                    dropdownItems={SortingsMap}
                    isMyProfile={props.isMyProfile}
                />
                <TooltipMobile tooltipContent={NotificationTextTop} />
            </div>
        );
    })
);

function setupLeaderBoardData(
    score: HighScoreModel[],
    games: IGame[],
    length: number,
    activeSorting: string
): LeaderboardRow[] {
    let result: LeaderboardRow[] = [];
    const numberFormatter = new Intl.NumberFormat();
    score.forEach((s) => {
        const game =
            GameService.findGameByArena5Slug(games, s.slug) || GameService.findGameByArkadiumSlug(games, s.slug);
        if (game) {
            const row: LeaderboardRow = {
                arkadiumSlug: game.alias,
                name: game.name,
                imageUrl: game.promoTile280x280,
                score: s.score,
                scoreTeaser: numberFormatter.format(s.score),
                date: Date.parse(s?.timestamp || new Date(0).toISOString()),
            };
            result.push(row);
        }
    });

    // put some sort logic here

    // by default sort high to low
    if (!activeSorting || StringUtils.equalIgnoreCase(activeSorting, 'sort3')) {
        result = result.sort((a, b) => (a.score > b.score ? -1 : 1));
    } else if (StringUtils.equalIgnoreCase(activeSorting, 'sort4')) {
        result = result.sort((a, b) => (a.score < b.score ? -1 : 1));
    } else if (StringUtils.equalIgnoreCase(activeSorting, 'sort2')) {
        result = result.sort((a, b) => (a.date > b.date ? -1 : 1));
    }

    result = result.slice(0, length);
    return result;
}
