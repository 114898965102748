import React, { useEffect, useState } from 'react';

import styles from './ScrollBackToTopButton.css';

export const ScrollBackToTopButton = () => {
    // The back-to-top button is hidden at the beginning
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth', // for smoothly scrolling
        });
    };

    return (
        <>
            {showButton && (
                <button onClick={scrollToTop} className={styles.button}>
                    <svg
                        role="img"
                        aria-label="scroll-back-icon"
                        width="29"
                        height="17"
                        viewBox="0 0 29 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 16L14.4029 0.999999L28 16"
                            stroke="#2A7AD3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            )}
        </>
    );
};
