import React, { FC, ElementType, RefObject, useEffect, useRef, useState } from "react";

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import gameAreaStyles from './GameArea.css';
import { DeviceUtils } from '../../../../utils/DeviceUtils';
import { MiscUtils } from '../../../../utils/MiscUtils';
import { ConnectedHelmet } from "../../../atoms/ConnectedHelmet";
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { GameState, WIDTH_QUERY } from '../../../models/Enums';
import { IGame } from '../../../models/Game/Game';
import { Ad, AdTypes, CaptionPositionTypes } from '../../../molecules/Ad/Ad';
import { TabRouter } from '../../../molecules/TabRouter/TabRouter';
import GameAreaBottomAd from '../../../organisms/DisplayAd/GamePageAd/GameAreaBottomAd';
import { GameNavigation } from '../../../organisms/GameNavigation/GameNavigation';
import Leaderboard from'../../../organisms/Leaderboard/Leaderboard';
import { LeaderboardSkeleton } from '../../../organisms/Leaderboard/Leaderboard.skeleton';
import { Analytics } from '../../../services/Analytics/Analytics';
import { LeaderboardDataModel } from '../../../services/HighScoreService';
import { Media } from '../../../services/MediaService';
import UserService from '../../../services/UserService';
import { setActiveGameView } from '../../../store/ducks/games';
import { setLeaderBoardNotificationType } from '../../../store/ducks/layout';
import styles from '../GameTemplate.css';
import { getAdBackgroundTone } from '../utils';

interface IGameArea {
    game: IGame;
    routes: Map<string, ElementType>;
    leaderboardData: LeaderboardDataModel;
    pageDescriptionRef: RefObject<HTMLElement>;
    abVariant: string;
    leaderboardDataLoaded: boolean;
    isAdFree: boolean;
}

declare global {
    interface Window {
        risePlayerInstances: any;
    }
}

const RIGHT_AD_TYPES = [AdTypes.AD_300x250, AdTypes.AD_250x250];
const ORIGINALS_CATEGORY = 'originals';
const RISE_PLAYER_INSTANCE_ID = '61503af3e68faf0001f2262e';
const GameArea: FC<IGameArea> = (props) => {
    const { game, routes, leaderboardData, pageDescriptionRef, abVariant, leaderboardDataLoaded, isAdFree } = props;
    const [score, setScore] = useState<number>(0);
    const [oldScore, setOldScore] = useState<number>(0);
    const [actualVh, setActualVh] = useState<number>(MiscUtils.isServer ? 0 : window.innerHeight);
    const [isVisibleMobileSlider, setIsVisibleMobileSlider] = useState<boolean>(false);
    const [gameAreaContainerClass, setGameAreaContainerClass] = useState<string>('');
    const user = useSelector(({ user }) => user);
    const currentLang = useSelector(({ currentLang }) => currentLang);
    const gameState = useSelector(({ gameState }) => gameState);
    const activeGameNavigationTab = useSelector(({ gamePageViewState }) => gamePageViewState.activeTab);
    const activeGameNavigationTabFullScreen = useSelector(({ gamePageViewState }) => gamePageViewState.activeTab2);
    const userFavoritesList = useSelector(({ userFavoritesList }) => userFavoritesList);
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>();
    const fullscreenModal = useRef<any>();
    const fullscreenGame = useRef<HTMLDivElement>();
    const adDividerRef = useRef<any>();
    let trackedImpression = false;

    game.isFavorite = userFavoritesList?.includes?.(game.alias);
    const leaderboardOptions = {
        user,
        game,
        userGameEndScore: score,
        data: leaderboardData,
    };

    useEffect(() => {
        let timerVideoAd;

        if (isAdFree) {
            console.log('T3', isAdFree, window.risePlayerInstances);
            timerVideoAd = setInterval(() => {
                if (window.risePlayerInstances?.[RISE_PLAYER_INSTANCE_ID]) {
                    console.log('T33', isAdFree, window.risePlayerInstances);
                    window.risePlayerInstances[RISE_PLAYER_INSTANCE_ID].dispose();
                    clearInterval(timerVideoAd);
                }

            }, 1000);
        }

        window.addEventListener('scroll', trackIfVisible);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', onResize);
        window.addEventListener('fullscreenchange', exitFullscreenListener);
        window.addEventListener('mozfullscreenchange', exitFullscreenListener);
        window.addEventListener('webkitfullscreenchange', exitFullscreenListener);
        window.addEventListener('msfullscreenchange', exitFullscreenListener);
        onResize();
        UserService.gameSignListenerAdd();

        getGameAreaContainerClassNameClass();
        setActualVh(window.innerHeight);

        return () => {
            timerVideoAd && clearInterval(timerVideoAd);
            window.removeEventListener('scroll', trackIfVisible);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', onResize);
            window.removeEventListener('fullscreenchange', exitFullscreenListener, false);
            window.removeEventListener('mozfullscreenchange', exitFullscreenListener, false);
            window.removeEventListener('webkitfullscreenchange', exitFullscreenListener, false);
            window.removeEventListener('msfullscreenchange', exitFullscreenListener, false);
            UserService.gameSignListenerRemove();
        };
    }, []);

    useEffect(() => {
        const isScoreHigher = score > oldScore;

        if (isScoreHigher && leaderboardDataLoaded && UserService.isUserLoggedIn()) {
            checkForNewHighScore();
        }

        setActualVh(window.innerHeight);
    }, [score, oldScore, UserService.isUserLoggedIn()]);

    useEffect(() => {
        if (isVisibleMobileSlider) {
            window.removeEventListener('scroll', handleScroll);
        }

        setActualVh(window.innerHeight);
    }, [isVisibleMobileSlider]);

    const openContentFullscreen = (): void => {
        const elem = fullscreenModal.current;
        const gameElem = fullscreenGame.current;

        gameElem.focus();

        if (elem.requestFullscreen) {
            elem.requestFullscreen({ navigationUI: 'hide' });
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen({ navigationUI: 'hide' });
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else {
            console.log('Your browser cannot use fullscreen right now');
        }
    };
    const exitFullscreen = (): void => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document['mozCancelFullScreen']) {
            document['mozCancelFullScreen']();
        } else if (document['webkitCancelFullScreen']) {
            document['webkitCancelFullScreen']();
        } else if (document['msExitFullscreen']) {
            document['msExitFullscreen']();
        }

        if (fullscreenModal.current) {
            (window as any).scrollTo(0, 120);
        }
    };
    const exitFullscreenListener = (): void => {
        if (!document.fullscreenElement && !document['webkitFullscreenElement']) {
            Analytics.trackEvent(Analytics.games.fullScreenExitButtonClick(game));
            dispatch(setActiveGameView({ activeTab: 'game', activeTab2: '' }));
        }
    };
    const trackIfVisible = (): void => {
        if (trackedImpression) {
            return;
        }

        const el = adDividerRef.current;
        const html = document.body.parentElement;

        if (el) {
            const elR = el.getBoundingClientRect();

            if (elR.y + elR.height * 0.5 <= Math.abs(html.clientHeight)) {
                trackedImpression = true;
                Analytics.trackEvent(Analytics.games.gamePageAdDividerImpression(game));
            }
        }
    };
    const isElementInViewport = (el): boolean => {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    const handleScroll = (): void => {
        if (!isVisibleMobileSlider && ref.current && isElementInViewport(ref.current)) {
            setIsVisibleMobileSlider(true);
        }
    };
    const updateScore = (newScore: number) => {
        setOldScore(score);
        setScore(newScore);
        routes.set('leaderboard', Leaderboard);
    };
    const setSkeletonLeaderboard = (): void => {
        routes.set('leaderboard', LeaderboardSkeleton);
    };
    const onResize = (): void => {
        setActualVh(window.innerHeight);
    };
    const calculateGameWrapperHeight = (): string => {
        const BOTTOM_MENU_SIZE = 60;
        const MOBILE_MENU_SIZE = 64;
        const isDesktopWidth = MiscUtils.matchWidth(
            WIDTH_QUERY.MIN_WIDTH,
            `${ArkCssBreakpoints.ARK_SMALL_DESKTOP}`,
            true
        );
        const isPortraitOrientation = DeviceUtils.isOrientationPortrait();

        if (!isDesktopWidth && gameState === GameState.PREROLL && activeGameNavigationTab === 'game') {
            return isPortraitOrientation ? `${actualVh - BOTTOM_MENU_SIZE}px` : `${actualVh - MOBILE_MENU_SIZE}px`;
        }

        return `auto`;
    };
    const checkForNewHighScore = (): void => {
        if (user) {
            const scores = leaderboardData.public.day.map((user) => user.score);
            const myBestResult = leaderboardData.public.day.find((user) => user.isCurrentUser)?.score;
            const lastScoreInLeaderboard = scores[scores.length - 1] || 0;
            const firstTimeInLeaderBoard = !myBestResult && score > lastScoreInLeaderboard;
            const beatMyBestResult = myBestResult && score > myBestResult;

            if (firstTimeInLeaderBoard || beatMyBestResult) {
                dispatch(setLeaderBoardNotificationType({ ICON: true, ROW: true }));
            }

            setOldScore(score);
        }
    };
    const getGameAreaContainerClassNameClass = (): void => {
        const isDesktopWidth = MiscUtils.matchWidth(
            WIDTH_QUERY.MIN_WIDTH,
            `${ArkCssBreakpoints.ARK_SMALL_DESKTOP}`,
            true
        );
        const isOrientationPortrait = DeviceUtils.isOrientationPortraitByJS();
        let gameAreaContainerClassName = classNames(
            styles.gameAreaContainerFullScreen,
            activeGameNavigationTabFullScreen === 'fullscreen' && gameAreaStyles.noPadding,
            gameState === GameState.GAME_END && isOrientationPortrait
                ? styles.gameAreaContainerFullScreenNotFullHeight
                : styles.gameAreaContainerFullScreenFullHeight
        );

        if (isDesktopWidth || (gameState === GameState.PREROLL && activeGameNavigationTab === 'game')) {
            gameAreaContainerClassName = classNames(
                gameAreaStyles.gameAreaContainer,
                activeGameNavigationTabFullScreen === 'fullscreen' && gameAreaStyles.noPadding,
                activeGameNavigationTabFullScreen === 'fullscreen' && gameAreaStyles.gameAreaContainerFullScreen
            );
        }

        setGameAreaContainerClass(gameAreaContainerClassName);
    };
    const gameAreaWrapperStyle = {
        height: calculateGameWrapperHeight(),
        backgroundColor: game.backgroundColor,
    };

    return (
        <>
            {game.primaryCategory?.toLowerCase() !== ORIGINALS_CATEGORY &&
                <ConnectedHelmet>
                    <script src="https://sdk.streamrail.com/wrapper/hb.loader.js?wrapper_id=61503de49bbbfe00016ebc3e&org=60d9ab656a43ad00010a56ed" />
                </ConnectedHelmet>
            }
            <div className={styles.gameAreaWrapper} style={gameAreaWrapperStyle}>
                <div className={classNames(gameAreaStyles.gameAreaContent, { [gameAreaStyles.adFree]: isAdFree })}>
                    {/* bookmark buttons, top, bottom, right ad near game, game itself */}
                    <div
                        className={classNames(
                            gameAreaContainerClass !== '' ? gameAreaContainerClass : styles.initialGameAreaContainer, //#157305 display grid needs to be applied from the start in order to avoid the container moving down and the user sees the style change
                            { [gameAreaStyles.fullWidthGameContainer]: isAdFree },
                            { [gameAreaStyles.fullScreen]: activeGameNavigationTabFullScreen === 'fullscreen' }
                        )}
                        ref={fullscreenModal}
                    >
                        <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                            <div className={gameAreaStyles.mobileHidden}>
                                <GameNavigation
                                    game={game}
                                    adFree={isAdFree}
                                    background={game.backgroundColor}
                                    openFullScreen={openContentFullscreen}
                                    exitFullScreen={exitFullscreen}
                                    pageDescriptionRef={pageDescriptionRef}
                                />
                            </div>
                        </Media>
                        <div
                            className={classNames(
                                gameAreaStyles.gameAreaGameContainer,
                                activeGameNavigationTabFullScreen === 'fullscreen' && styles.noPadding,
                                {[gameAreaStyles.adFree]: isAdFree }
                            )}
                            style={game.isAdsFree ? { maxHeight: 'initial' } : {}}
                        >
                            <div
                                className={classNames(
                                    gameAreaStyles.gameAreaGameWrapper,
                                    activeGameNavigationTabFullScreen === 'fullscreen' && gameAreaStyles.fullHeight
                                )}
                                style={{}}
                            >
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <div
                                    tabIndex={gameState === GameState.GAME ? 0 : -1}
                                    className={styles.gameAreaGame}
                                    ref={fullscreenGame}
                                >
                                    <TabRouter
                                        activeState={activeGameNavigationTab}
                                        componentsMap={routes}
                                        keepAlive="game"
                                        currentLang={currentLang}
                                        updateScore={(score) => updateScore(score)}
                                        setSkeletonLeaderboard={setSkeletonLeaderboard}
                                        key={game.alias}
                                        score={score}
                                        {...leaderboardOptions}
                                    />
                                </div>
                            </div>
                        </div>

                        {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                            <div className={classNames(gameAreaStyles.gameAreaRightAdContainer, styles.mobileHidden)}>
                                <Ad
                                    adType={RIGHT_AD_TYPES}
                                    backgroundTone={getAdBackgroundTone(game)}
                                    keepSize={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    id="ark_display_r1"
                                    useDefaultBidTimeout
                                    keyValues={[['abtest', `laptop-${abVariant}`]]}
                                    className={gameAreaStyles.rightAd320}
                                    isContainerDisabled
                                />
                                <Ad
                                    adType={RIGHT_AD_TYPES}
                                    backgroundTone={getAdBackgroundTone(game)}
                                    keepSize={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    id="ark_display_r2"
                                    useDefaultBidTimeout
                                    keyValues={[['abtest', `laptop-${abVariant}`]]}
                                    className={gameAreaStyles.rightAd320}
                                    isContainerDisabled
                                />
                                <Ad
                                    adType={RIGHT_AD_TYPES}
                                    backgroundTone={getAdBackgroundTone(game)}
                                    keepSize={false}
                                    captionPosition={CaptionPositionTypes.CENTER}
                                    id="ark_display_r3"
                                    useDefaultBidTimeout
                                    keyValues={[['abtest', `laptop-${abVariant}`]]}
                                    className={gameAreaStyles.rightAd320}
                                    isContainerDisabled
                                />
                            </div>
                        )}
                    </div>
                    {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                        <GameAreaBottomAd
                            className={classNames(styles.gameAreaBottomAdContainer, styles.mobileHidden)}
                            adBackgroundTone={getAdBackgroundTone(game)}
                            bgColor={game.backgroundColor}
                            abVariant={abVariant}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default GameArea;
