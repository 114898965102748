import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const GiftCardApps = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="44"
            height="48"
            viewBox="0 0 44 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.04904"
                y="13.8824"
                width="23.6667"
                height="37"
                rx="2.5"
                transform="rotate(-30 2.04904 13.8824)"
                stroke="#F9F9F9"
                strokeWidth="3"
            />
            <rect
                x="7.97978"
                y="10.2658"
                width="9.77778"
                height="2.66667"
                rx="1.33333"
                transform="rotate(-30 7.97978 10.2658)"
                fill="#F9F9F9"
                stroke="#F9F9F9"
                strokeWidth="2.66667"
            />
            <path
                d="M26.5524 27.1407C26.3062 26.7579 25.9503 26.4026 25.5477 26.3368C24.6756 26.2199 23.8767 27.1226 23.8773 27.8858C23.8885 28.798 24.5249 29.051 25.5037 29.3092C26.7066 29.6288 26.0512 30.6276 25.5577 31.0795C23.9978 32.4932 22.9053 32.9092 22.9053 32.9092C22.9053 32.9092 22.1624 31.274 21.1702 30.2959C20.9854 30.1281 19.8768 28.8831 19.3466 31.6231C19.2387 32.1984 18.2788 32.8004 16.9998 31.7611C15.7209 30.7217 16.3058 29.6443 16.7294 29.5071C19.7045 28.5411 17.6522 27.4472 17.7717 27.5452C16.604 26.7638 14.8045 26.3472 14.8045 26.3472C14.8045 26.3472 15.0314 25.2378 16.2081 23.5085C16.5777 22.973 17.4867 22.1738 18.0251 23.2588C18.4594 24.1416 18.838 24.7104 19.7577 24.5612C20.5289 24.4381 21.3227 23.5264 21.0499 22.7056C20.9102 22.3329 20.4943 22.048 20.0494 21.8873C17.6055 21.5086 19.2854 19.9541 19.3273 19.918C20.2729 18.9426 21.9198 18.1587 21.9198 18.1587C21.9198 18.1587 22.7227 19.7235 23.7149 20.7016C23.665 20.6588 25.192 22.1277 25.3996 19.896C25.5147 18.6364 26.755 18.3022 27.8947 19.231C28.9544 20.0866 28.4282 21.3092 27.7454 21.4767C25.0049 22.1521 27.446 23.7017 27.1134 23.4523C28.2811 24.2337 30.0207 24.7207 30.0207 24.7207C30.0207 24.7207 29.5082 26.4244 28.6676 27.4944C28.6642 27.5321 27.3678 29.4022 26.5524 27.1407Z"
                fill="#F9F9F9"
            />
        </svg>
    );
});
