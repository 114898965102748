import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { ScrollBackToTopButton } from '../FigmaStyleguide/ScrollBackToTopButton/ScrollBackToTopButton';
import { MetaInfoService } from '../services/MetaInfoService';
import { UrlService } from '../services/UrlService';
import { setPageType } from '../store/ducks/pages';
import BlogPostModularTemplate from '../templates/Blog/BlogPostModularTemplate/BlogPostModularTemplate';
import BlogPostTemplate from '../templates/Blog/BlogPostTemplate/BlogPostTemplate';
import { P404Template } from '../templates/p404/404Template';

const BlogPostPage = React.memo(() => {
    const currentLang = useSelector((state) => state.currentLang);
    const routerFromRedux = useSelector((state) => state.router);
    const postData = useSelector((state) => state.blogPostData);
    const dispatch = useDispatch();

    const [canonicalHref, setCanonicalHref] = useState('');
    const [linksInfo, setLinksInfo] = useState([]);

    useEffect(() => {
        dispatch(setPageType(PageTypes.BlogPost));
    }, []);

    useEffect(() => {
        try {
            if (routerFromRedux) {
                const url = UrlService.buildAbsoluteUrl(routerFromRedux);
                setCanonicalHref(`${environment.SITE_BASE_URL}${url}`);
                setLinksInfo(MetaInfoService.getLinksInfo(url));
            }
        } catch (err) {
            console.error(err);
        }
    }, [routerFromRedux]);

    // Check page exist
    if (!postData) {
        dispatch(setPageType(PageTypes.NotFound));
        return <P404Template />;
    }

    const isPostModular = postData?.modularBlogTitle;

    // TODO this is to cover both the posts with non-US locales and not, to be simplified after the old blog structure is migrated
    function resolveDataValue(item, lang) {
        if (typeof item === 'object' && item !== null) {
            return item[lang];
        }
        return item;
    }

    return (
        <>
            <Helmet
                title={resolveDataValue(postData.metaHTMLTitle, currentLang)}
                link={[{ rel: 'canonical', href: canonicalHref }, ...linksInfo]}
                meta={[
                    {
                        name: 'description',
                        content: resolveDataValue(postData.metaHTMLDescription, currentLang),
                    },
                    {
                        name: 'keywords',
                        content: resolveDataValue(postData.metaKeywords, currentLang),
                    },
                    { name: 'twitter:title', content: resolveDataValue(postData.metaHTMLTitle, currentLang) },
                    { property: 'og:title', content: resolveDataValue(postData.metaHTMLTitle, currentLang) },
                    {
                        property: 'og:description',
                        content: resolveDataValue(postData.metaHTMLDescription, currentLang),
                    },
                    {
                        name: 'twitter:description',
                        content: resolveDataValue(postData.metaHTMLDescription, currentLang),
                    },
                ]}
            />
            <ErrorBoundary>
                <ScrollBackToTopButton />
                {isPostModular ? <BlogPostModularTemplate /> : <BlogPostTemplate />}
            </ErrorBoundary>
        </>
    );
});

export default BlogPostPage;
