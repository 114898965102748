import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';

import styles from './GiftCardForm.css';
import { Input } from '../../../../FigmaStyleguide/Input/Input';


import 'react-datepicker/dist/react-datepicker.css';

type GiftCardInputProps = {
  label: string;
  placeholder?: string;
  name: string;
  maxlength?: number;
  value?: string;
  type?: string;
  datepicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  selectedDate?: Date;
  firstEmail?: string;
  onClick?: () => void;
  setFormValue: (field: string, value: Date | string) => void;
  setFormValidity: (field: string, value: boolean) => void;
  onBlur?: (field: string, value: string) => void;
};

export const GiftCardInput = (props: GiftCardInputProps) => {
  const {
    label,
    placeholder,
    name,
    type,
    maxlength,
    datepicker,
    firstEmail,
    maxDate,
    selectedDate,
    setFormValue,
    setFormValidity,
    onBlur
  } = props;
  const [startDate, setStartDate] = useState(selectedDate);
  const [value, setValue] = useState<Date | string | ChangeEvent<HTMLInputElement>>('');
  const [valid, setValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (firstEmail && value !== '') {
      if (name === 'emailConfirm' && value !== firstEmail) {
        setValidGeneral(false);
        setErrorMessage('Emails should be equal'); //@todo ask for copy
      }

      if (name === 'email2' && value == firstEmail) {
        setValidGeneral(false);
        setErrorMessage(`Sorry, the sender and recipient emails can't be the same.`);
      }
    }
  }, [firstEmail, value]);

  function onChange(v: string | Date) {
    setValue(v);
    setFormValue(name, v);
    isValid(name, v);
  }

  const isValid = (_: string, value: Date | string) => {
    if (value === '') {
      setValidGeneral(false);
      setErrorMessage('This field is required');
    } else {
      if (name === 'email' || name === 'email2' || name === 'emailConfirm') {
        if (validateEmail(value as string)) {
          if (
            name === 'email' ||
            (name === 'email2' && value !== firstEmail) ||
            (name === 'emailConfirm' && value === firstEmail)
          ) {
            setValidGeneral(true);
            setErrorMessage('');
          }
        } else {
          setValidGeneral(false);
          setErrorMessage('Please enter a valid email address');
        }
      } else {
        //     if (isProfanityValid(field, value)) {
        setValidGeneral(true);
        setErrorMessage('');
        // }
      }
    }
  };
  const setValidGeneral = (v: boolean) => {
    setValid(v);
    setFormValidity(name, v);
  };

  function validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return re.test(String(email).toLowerCase());
  }


  //@ts-ignore
  const CustomInput = forwardRef(({ value, onClick, name, onBlur }, ref) => (
    <Input
      wrapperClassname={styles.formInput}
      label={label}
      name={name}
      onClick={onClick}
      clickOnCloseIcon={onClick}
      ref={ref}
      value={value}
      onChange={(v) => onChange(v.target.value)}
      isValid={valid}
      onFocus={onClick}
      onBlur={onBlur}
      calendar
    />
  ));

  return (
    <>
      {datepicker && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label>
          <DatePicker
            dateFormat="MMM-dd-yy"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              onChange(date);
            }}
            minDate={selectedDate}
            maxDate={maxDate}
            tabIndex={0}
            // @ts-ignore-next-line
            onBlur={(_: string, value: string) => {
              const newField = 'date';

              return onBlur(newField, value);
            }}

            customInput={<CustomInput/>}
            showDisabledMonthNavigation
          />
        </label>
      )}
      {!datepicker && (
        <Input
          wrapperClassname={styles.formInput}
          label={label}
          placeholder={placeholder}
          value={value as string}
          name={name}
          type={type}
          maxLength={maxlength || 255}
          onChange={(v) => onChange(v.target.value)}
          isValid={valid}
          onFocus={() => setValue(value)}
          onBlur={(field, value) => {
            isValid(field, value);
            onBlur(field, value);
          }}
          errorMessage={errorMessage}
          clickOnCloseIcon={() => onChange('')}
          closeIconClassName={styles.closeAdjust}
        />
      )}
    </>
  );
};
