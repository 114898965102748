import React from 'react';

import classNames from 'classnames';

import styles from './ChallengeCaptchaModal.css';
import { CloseIcon } from '../../../FigmaStyleguide/Icons/CloseIcon';


export const CloseButton = ({ onClose, mobile, className, closeIconColor, tabIndex = -1 }) => (
  <div
    className={classNames(styles.closeIcon, className, { [styles.mobile]: mobile })}
    onClick={onClose}
    role="button"
    tabIndex={tabIndex}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        onClose();
      }
    }}
    style={{ ...(closeIconColor && { color: closeIconColor }) }}
  >
    <CloseIcon onClick={onClose}/>
  </div>
);
