import React, { forwardRef, memo, useEffect } from 'react';

import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LS_SENT_AI_GEMS_IMPRESSION } from '../../../constants/GemsConstants';
import { PageTypes } from '../../../constants/Pages';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { PayPalIcon } from '../../../FigmaStyleguide/Icons/PayPalIcon';
import { QUERY_STRING_CONSTS } from '../../../models/Enums';
import { PaymentMethod } from '../../../models/Subscription/PaymentForm';
import { PAYPAL_TAXES_TEXT } from '../../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { TabsComponent } from '../../../molecules/TabsComponent/TabsComponent';
import { GiftCardForm } from '../../../organisms/PaymentForm/GiftCardForm';
import { PaymentForm } from '../../../organisms/PaymentForm/PaymentForm';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import { gemsShopLocationSelector } from '../../../store/ducks/gems/gemsSelectors';
import { setIsValidForm, setPaymentMethod } from '../../../store/ducks/subscription/common';
import { PurchaseNewTabHelper } from '../PurchaseNewTabHelper';
import styles from '../SubscriptionTemplate.css';

interface SubscriptionStep1Props {
  isStepVisible: boolean;
  giftCardCode: string;
  setGiftCardCode: (v: string) => void;
  recurlyPricingError: any; // recurly obj
  setCheckoutPricing: any; // recurly func
  paymentMethodsApplied: PaymentMethod[]; // COMMON - to limit payment methods list
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  t: WithTranslation['t'];
}

export const SubscriptionStep1 = memo(
  forwardRef<HTMLFormElement, SubscriptionStep1Props>(
    (
      {
        isStepVisible,
        giftCardCode,
        setGiftCardCode,
        recurlyPricingError,
        setCheckoutPricing,
        paymentMethodsApplied,
        paymentType,
        gemsAnalyticsProps,
        t
      }: SubscriptionStep1Props,
      ref
    ) => {
      const dispatch = useDispatch();
      const pageTypeIsGiftCard = useSelector(({ pageType }) => pageType) === PageTypes.GiftCard;
      const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource || '');
      const planCode = useSelector(({ activeSubscriptionPlan }) => activeSubscriptionPlan || SubscriptionPlans.ANNUAL);
      const paymentMethod = useSelector(({ paymentMethod }) => paymentMethod);
      const shopLocation = useSelector(gemsShopLocationSelector);
      const isPassedStateProcessed = !PurchaseNewTabHelper.isTabData();

      useEffect(() => {
        if (
          UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE) &&
          paymentMethods.some((method) => method.key === PaymentMethod.GIFT_CARD)
        ) {
          dispatch(setPaymentMethod(PaymentMethod.GIFT_CARD));
        }
      }, [UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE)]);

      useEffect(() => {
        setIsValidFormByMethod(paymentMethod);
      }, [paymentMethod, giftCardCode]);

      const asyncEffect = async () => {
        if (
          paymentType === PaymentType.gems &&
          isStepVisible &&
          window !== undefined &&
          !window.sessionStorage.getItem(LS_SENT_AI_GEMS_IMPRESSION) &&
          isPassedStateProcessed &&
          gemsAnalyticsProps.priceInGem !== 0
          // it is ok for gems purchase in new tab, also we check it is gems purchase
        ) {
          void Analytics.trackEvent(
            Analytics.gems.gemPurchaseClickImpression(
              gemsAnalyticsProps.priceInGem,
              gemsAnalyticsProps.gemsPackId,
              gemsAnalyticsProps.gemsInPack,
              shopLocation
            ),
            false
          );
          window.sessionStorage.setItem(LS_SENT_AI_GEMS_IMPRESSION, 'SENT');
        }
      };

      useEffect(() => {
        void asyncEffect();
      }, [isStepVisible, paymentType, isPassedStateProcessed, gemsAnalyticsProps.priceInGem]);

      const setIsValidFormByMethod = (method: PaymentMethod) => {
        switch (method) {
          case PaymentMethod.GIFT_CARD:
            dispatch(setIsValidForm(giftCardCode.length === 16));
            break;
          case PaymentMethod.PAYPAL:
          case PaymentMethod.APPLE_PAY:
            dispatch(setIsValidForm(true));
            break;
          default:
            break;
        }
      };
      const paymentMethods = paymentMethodsApplied
        .map((payMethod) => {
          const card = {
            key: PaymentMethod.CARD,
            tabComponent: <span>{t('PAYMENT_PAGE.CREDIT_OR_DEBIT_CARD')}</span>,
            content: <PaymentForm ref={paymentMethod === PaymentMethod.CARD ? ref : null}/>,
            onClick: () => dispatch(setPaymentMethod(PaymentMethod.CARD))
          };
          const giftCard = {
            key: PaymentMethod.GIFT_CARD,
            tabComponent: <span>{t('PAYMENT_PAGE.GIFT_CARD')}</span>,
            content: (
              <>
                <div className={styles.mainStepHeading}>
                  <h2>
                    <strong>2. </strong>
                    {t('PAYMENT_PAGE.ENTER_GIFT_CARD_CODE')}
                  </h2>
                </div>
                <GiftCardForm
                  recurlyPricingError={recurlyPricingError}
                  giftCardCode={giftCardCode}
                  setGiftCardCode={setGiftCardCode}
                  ref={paymentMethod === PaymentMethod.GIFT_CARD ? ref : null}
                  setCheckoutPricing={setCheckoutPricing}
                />
              </>
            ),
            onClick: () => dispatch(setPaymentMethod(PaymentMethod.GIFT_CARD))
          };
          const paypal = {
            key: PaymentMethod.PAYPAL,
            tabComponent: <PayPalIcon/>,
            content: (
              <div className={styles.stepHeading}>
                <p>
                  {`${t('PAYMENT_PAGE.PAYPAL_REDIRECT_TEXT')} ${paymentType !== PaymentType.subscription ?
                    PAYPAL_TAXES_TEXT :
                    ''}`}
                </p>
              </div>
            ),
            onClick: () => {
              dispatch(setPaymentMethod(PaymentMethod.PAYPAL));

              if (paymentType === PaymentType.subscription) {
                void Analytics.trackEvent(Analytics.subscription.giftPayPal(subscriptionSource, planCode));
              }
            }
          };

          switch (payMethod) {
            case card.key:
              return card;
            case giftCard.key:
              return giftCard;
            case paypal.key:
              return paypal;
            default:
              return null;
          }
        })
        .filter((el) => Boolean(el));

      return (
        <div style={{ display: isStepVisible ? 'inherit' : 'none' }}>
          <div className={styles.mainStepHeading}>
            <h2>
              <strong>1. </strong>Select your payment method
            </h2>
          </div>
          <TabsComponent
            defaultTab={
              UrlService.getQSParam(window.location.search, QUERY_STRING_CONSTS.REDEMPTION_CODE)
                ? PaymentMethod.GIFT_CARD
                : PaymentMethod.CARD
            }
            content={
              pageTypeIsGiftCard
                ? paymentMethods.filter((method) => method.key !== PaymentMethod.GIFT_CARD)
                : paymentMethods
            }
          />
        </div>
      );
    }
  )
);
