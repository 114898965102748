import React from 'react';

import { Avatar, AvatarTypes } from '../../../../../atoms/Avatar/Avatar';
import { BorderedButton } from '../../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { UserModel } from '../../../../../models/User/UserModel';
import styles from './AvatarField.css';

type Props = {
    onClick: () => void;
    user: UserModel;
};

export const AvatarField = React.memo((props: Props) => {
    const userAvatar = props.user?.avatar || props.user?.subscriberAvatar?.avatar || '';
    return (
        <>
            <div
                role="button"
                tabIndex={0}
                onClick={() => props.onClick()}
                onKeyDown={({ key }) => {
                    if (key === 'Enter') {
                        props.onClick();
                    }
                }}
            >
                <Avatar
                    image={userAvatar}
                    selectable={false}
                    selected={false}
                    size={AvatarTypes.MEDIUM}
                    onSelect={() => null}
                    email={props.user.email}
                />
            </div>
            <div className={styles.footer}>
                <div>
                    <BorderedButton caption="Choose your avatar" onClick={() => props.onClick()} filled />
                </div>
            </div>
        </>
    );
});
