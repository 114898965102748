import React from 'react';

import { batch, useDispatch } from 'react-redux';

import { BorderedButton } from '../../../atoms/Buttons/BorderedButton/BorderedButton';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import { SignInUpComponents } from '../../../constants/SignInUpComponents';
import { TranslationService } from '../../../services/TranslationService';
import { UrlService } from '../../../services/UrlService';
import { setSideMenuActivePage, setSignInUpState } from '../../../store/ducks/layout';
import {
    GemsAnalyticsRegistrationLocations,
    setSocialRegistrationLocation,
} from '../../../store/ducks/leanplum/lpAnalytics';
import styles from './NotLoggedInPanelDesktop.css';

const imgUrl = UrlService.toCDNUrl('/illustrations/profile/promo-not-logged-in.png');

export const NotLoggedInPanelDesktop = React.memo(() => {
    const dispatch = useDispatch();
    const goToSignIn = () => {
        batch(() => {
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.PROFILE_PAGE));
            dispatch(setSignInUpState(SignInUpComponents.SIGN_IN));
        });
    };
    return (
        <div className={styles.notLoggedInPanelContainer}>
            <div className={styles.leftPanel}>
                <div className={styles.leftAsset} style={{ backgroundImage: `url(${imgUrl})` }} />
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.rightPanelBody}>
                    <I18nText as="h2" keyName="NOT_LOGGED_IN_PANEL_TITLE1" className={styles.title1} />
                    <I18nText as="p" keyName="NOT_LOGGED_IN_PANEL_TITLE2" className={styles.title2} />
                    <I18nText as="p" keyName="NOT_LOGGED_IN_PANEL_TITLE3" className={styles.title3} />
                    <div className={styles.loginButtonShadowWrapper}>
                        <BorderedButton
                            filled
                            caption={TranslationService.translateIt('NOT_LOGGED_IN_PANEL_LOGIN_BUTTON_TEXT')}
                            className={styles.loginButton}
                            dataElementDescription="not-logged-in-panel-login-button"
                            onClick={goToSignIn}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
