import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppLoader } from '../atoms/AppLoader/AppLoader';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { PagesData } from '../models/PagesData';
import { MetaInfoService } from '../services/MetaInfoService';
import { PageService } from '../services/PageService';
import { UrlService } from '../services/UrlService';
import { setFullScreenLoading } from '../store/ducks/layout';
import { setPageType } from '../store/ducks/pages';
import GiftCardTemplate from '../templates/GiftCard/GiftCardTemplate';

const GiftCardPage = React.memo(() => {
    GiftCardPage.displayName = 'GiftCardPage';

    const currentLang = useSelector((state) => state.currentLang);
    const pagesFromStore = useSelector((state) => state.pages);
    const routerFromRedux = useSelector((state) => state.router);
    const loading = useSelector((state) => state.fullScreenLoading);

    const pages = PagesData.getPages(pagesFromStore);
    const pageSEO = PageService.getPageSEOByPageName(pages, 'Give the Gift of Games');
    const url = UrlService.buildAbsoluteUrl(routerFromRedux);
    const enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.GiftCard)}/`;
    const addonLocales = PageService.getNonEnLocales(pageSEO);
    const localesPath = addonLocales.map((item) => ({
        lang: item,
        path: `/${pageSEO.slug[item]}/`,
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageType(PageTypes.GiftCard));
        dispatch(setFullScreenLoading(false));
    }, []);

    return (
        <>
            <ConnectedHelmet
                title={pageSEO.metaHTMLTitle[currentLang]}
                link={[
                    { rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` },
                    ...MetaInfoService.getLinksInfo(enPathname, localesPath),
                ]}
                meta={[
                    { name: 'twitter:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    { property: 'og:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    {
                        name: 'description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        property: 'og:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        name: 'twitter:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                ]}
            />
            <ErrorBoundary>
                {/* TODO: check init loader (move apploader with fullscreen on the highest lvl) and Helmet */}
                {loading && <AppLoader fullPage />}
                <GiftCardTemplate />
            </ErrorBoundary>
        </>
    );
});
export default GiftCardPage;
