import React, { FC } from 'react';

import { useDispatch } from 'react-redux';

import styles from './BillingBlock.css';
import CountriesList from '../../../constants/CountriesList';
import { BillingInfoValues } from '../../../models/Subscription/PaymentForm';
import { setStepIndex } from '../../../store/ducks/subscription/common';
import subsStyles from '../SubscriptionStyles.css';

type BillingBlockProps = {
  userData: BillingInfoValues;
  handleSmoothScrolling: (v: { isScrolling: boolean, id: string }) => void;
};

export const BillingBlock: FC<BillingBlockProps> = ({ userData, handleSmoothScrolling }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <div>
        <span className={subsStyles.heading}>Billing Address </span>
        <span
          role="presentation"
          className={subsStyles.link}
          onClick={() => {
            handleSmoothScrolling({ isScrolling: true, id: 'billingFieldsRef' });
            dispatch(setStepIndex(0));
          }}
        >
          Edit
        </span>
      </div>
      <div className={subsStyles.infoBlock}>
        <div className={subsStyles.paymentHeading}>
          {userData.country &&
            CountriesList.filter((i) => {
              return i.id === userData.country;
            })[0].name}
        </div>
        <div className={styles.description}>
          <p>{userData.address1}</p>
          <p>
            {userData.state}, {userData.city} {userData.postal_code}
          </p>
        </div>
      </div>
    </div>
  );
};
