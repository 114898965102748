import React from 'react';

import classNames from 'classnames';

import styles from './CheckboxSwitcher.css';

type CheckboxSwitcherProps = {
    turnedOn: boolean;
};

export const CheckboxSwitcher = React.memo(({ turnedOn }: CheckboxSwitcherProps) => {
    return (
        <div className={styles.checkboxSwitcherContainer}>
            <div
                className={classNames(styles.checkboxSwitcherBackground, {
                    [styles.turnedOn]: turnedOn,
                })}
                aria-checked={turnedOn}
                role="switch"
            >
                <div className={styles.checkboxSwitcherButton} />
            </div>
        </div>
    );
});
