import React, { ReactNode, useEffect } from 'react';

import classNames from 'classnames';


import styles from './ChallengeCaptchaModal.css';
import { CloseButton } from './CloseButton';
import { DeviceUtils } from '../../../../utils/DeviceUtils';
import { setBodyOverflowAuto, setBodyOverflowHidden } from '../../../../utils/IframeUtils';

type TModalProps = {
  modalClassName?: string;
  onClose?: () => void;
  children: ReactNode;
  isVisible: boolean;
};

export const ChallengeCaptchaModal = ({
  onClose,
  modalClassName,
  children,
  isVisible
}: TModalProps) => {
  const isDesktop = DeviceUtils.isDesktopDevice();
  const closeModal = (event: React.MouseEvent<Element, MouseEvent>) => {
    const eventTarget = event.target as Element;
    const currentTarget = event.currentTarget;
    const isClickedOnOverlay = currentTarget === event.target;
    const isClickedOnModalWrapper = eventTarget.classList.contains('Modal__modalWrapper');

    if ((isClickedOnOverlay || isClickedOnModalWrapper)) {
      onClose?.();
    }
  };

  useEffect(() => {
    isVisible ? setBodyOverflowHidden() : setBodyOverflowAuto();
  }, [isVisible]);

  return (
    <div
      className={classNames({
        [styles.overlay]: isVisible,
        [styles.mobile]: !isDesktop
      })}
      onClick={closeModal}
      role="presentation"
    >
      <div
        className={classNames({
          [styles.mobile]: !isDesktop
        })}
      >
        <div
          className={classNames(
            modalClassName,
            {
              [styles.modal]: isVisible,
              [styles.mobile]: !isDesktop
            }
          )}
        >
          {isVisible && (
            <CloseButton
              className={styles.closeIcon}
              onClose={onClose}
              mobile={!isDesktop}
              closeIconColor="black"
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
