export enum ProfilePages {
    PUBLIC = 'PUBLIC',
    PERSONAL = 'PERSONAL',
    PAYMENT = 'PAYMENT',
    EMAIL = 'EMAIL',
    ACCOUNT = 'ACCOUNT',
    PAYMENT_AND_SUBSCRIPTION = 'PAYMENT_AND_SUBSCRIPTION',
    TRANSACTIONS_HISTORY = 'TRANSACTIONS_HISTORY',
    COLLECTIONS = 'COLLECTIONS',
}
