import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import styles from './LogInTab.css';
import { setSideMenuOpened } from '../../../store/ducks/layout';
import { SignInUpContainer } from '../../SignInUpContainer/SignInUpContainer';

const LogInTab = React.memo(() => {
  const dispatch = useDispatch();
  const onSignInUpFinished = useCallback(() => {
    dispatch(setSideMenuOpened(false));
  }, [dispatch]);

  return (
    <div role="region" aria-label="shop" className={styles.wrapper}>
      <SignInUpContainer onSignInUpFinished={onSignInUpFinished}/>
    </div>
  );
});

LogInTab.displayName = 'LogInTab';
export default LogInTab;
