import orderBy from 'lodash/orderBy';

import SITE_MAP_PAGES from '../../../constants/SiteMapPages';
import { GameModel } from '../../../models/Game/GameModel';
import { CategoryPageSEO, GeneralPageSEO } from '../../../models/PagesData';
import { CategoryPromoType } from '../../../store/ducks/categoryPromo/categoryPromo';

export interface ISiteMapPage {
    name: string;
    link: string;
}

export const filterPages = (currentLang: string, t): ISiteMapPage[] => {
    const filteredPages = SITE_MAP_PAGES.filter((siteMapPage) => currentLang !== 'fr' || siteMapPage.name !== 'Blog');

    return filteredPages.map(({ name, link }) => ({ name: t(name), link: `/${t(link)}` }));
};

export const getOrderedCategories = (
    pages: (CategoryPageSEO | GeneralPageSEO)[],
    games: GameModel[],
    currentLang: string,
    categoryPromo: CategoryPromoType
): (CategoryPageSEO | GeneralPageSEO)[] => {
    const ALL_CATEGORY = 'All';

    const categoryName = categoryPromo?.categoryName || null;

    const filteredCategories = pages.filter(
        ({ name, pageName, category }: CategoryPageSEO) =>
            category &&
            pageName !== ALL_CATEGORY &&
            (currentLang === 'en' || pageName !== categoryName) &&
            !!games.find(
                ({ meta: { primaryCategory, categories } }) =>
                    primaryCategory === name[currentLang].toLowerCase() ||
                    categories.includes(name[currentLang]) ||
                    categories.includes(name[currentLang].toLowerCase())
            )
    );
    return orderBy(filteredCategories, [({ name }) => name[currentLang].toLowerCase()], ['asc']);
};

export const getOrderedGames = (games: GameModel[]): GameModel[] =>
    orderBy(games, [({ name }) => name.toLowerCase()], ['asc']);
