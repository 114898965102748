import React from 'react';

import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import GameArea from './GameAreaNew/GameArea';
import GameFooter from './GameFooter/GameFooter';
import styles from './GameTemplate.css';
import { getAdBackgroundTone, getTitleWithCountry } from './utils';
import { DeviceUtils } from '../../../utils/DeviceUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { ConnectedHelmet } from '../../atoms/ConnectedHelmet';
import { environment } from '../../config/environment';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { PageTypes } from '../../constants/Pages';
import { GameState, WIDTH_QUERY } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { UserModel } from '../../models/User/UserModel';
import { HeaderBackgroundText } from '../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { CollectionsTab } from '../../organisms/CollectionsTab/CollectionsTab';
import GameAreaBottomAd from '../../organisms/DisplayAd/GamePageAd/GameAreaBottomAd';
import { GameAreaRightAdMobile } from '../../organisms/DisplayAd/GamePageAd/GameAreaRightAdMobile';
import { GameAreaRightAdNewLayoutTest } from '../../organisms/DisplayAd/GamePageAd/GameAreaRightAdNewLayoutTest/GameAreaRightAdNewLayoutTest';
import { GameAreaTopAdNewLayoutTest } from '../../organisms/DisplayAd/GamePageAd/GameAreaRightAdNewLayoutTest/GameAreaTopAdNewLayoutTest';
import { GameAreaTopAdMobileLandscape } from '../../organisms/DisplayAd/GamePageAd/GameAreaTopAdMobileLandscape';
import { GameAreaTopAdMobilePortrait } from '../../organisms/DisplayAd/GamePageAd/GameAreaTopAdMobilePortrait';
import { GameContainer } from '../../organisms/Game/GameContainer';
import { GameNavigation } from '../../organisms/GameNavigation/GameNavigation';
import Leaderboard from '../../organisms/Leaderboard/Leaderboard';
import { LeaderboardSkeleton } from '../../organisms/Leaderboard/Leaderboard.skeleton';
import { Analytics } from '../../services/Analytics/Analytics';
import HighScoreService, { LeaderboardDataModel } from '../../services/HighScoreService';
import { Media } from '../../services/MediaService';
import UserService from '../../services/UserService';
import { setActiveGameView } from '../../store/ducks/games';
import { setLeaderBoardNotificationType } from '../../store/ducks/layout';

const RISE_PLAYER_INSTANCE_ID = '61503af3e68faf0001f2262e';

type GameTemplateProps = {
    dispatch?: Dispatch<Action>;
    game: IGame;
    userFavoritesList?: string[];
    user: UserModel;
    recommendedGames: IGame[];
    gameState: GameState;
    currentLang: string;
    url: string;
    activeGameNavigationTab?: string;
    activeGameNavigationTabFullScreen?: string;
    adFree: boolean;
    abVariant?: string;
} & WithTranslation;

type GameTemplateState = {
    oldScore: number;
    score: number;
    leaderboardData: LeaderboardDataModel;
    iSLBLoading: boolean;
    leaderboardDataLoaded: boolean;
    routes: Map<string, React.ElementType>;
    actualVh: number;
    isVisibleMobileSlider: boolean;
    rehydratation: number;
    gameAreaContainerClass?: string;
};

export enum TabNames {
    HOW_TO_PLAY = 'HOW_TO_PLAY',
    ABOUT = 'ABOUT_CAP',
    COMMENTS = 'COMMENTS',
}

class GameTemplateBase extends React.PureComponent<GameTemplateProps, GameTemplateState> {
    readonly state = {
        oldScore: 0,
        score: 0,
        iSLBLoading: false,
        leaderboardData: {
            public: { day: [], week: [], month: [] },
            user: { day: 0, week: 0, month: 0 },
        },
        leaderboardDataLoaded: false,
        routes: new Map<string, React.ElementType>([
            ['game', GameContainer],
            ['leaderboard', LeaderboardSkeleton],
            ['collections', CollectionsTab],
        ]),
        actualVh: MiscUtils.isServer ? 0 : window.innerHeight,
        isVisibleMobileSlider: false,
        rehydratation: 1,
        gameAreaContainerClass: '',
    };

    ref = React.createRef<HTMLDivElement>();
    fullscreenModal = React.createRef<any>();
    fullscreenGame = React.createRef<HTMLDivElement>();
    adDividerRef = React.createRef<any>();
    pageDescriptionRef = React.createRef<HTMLDivElement>();

    trackedImpression = false;

    componentDidMount() {
        const { game } = this.props;

        window.addEventListener('scroll', this.trackIfVisible);
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.onResize);
        window.addEventListener('fullscreenchange', this.exitFullscreenListener);
        window.addEventListener('mozfullscreenchange', this.exitFullscreenListener);
        window.addEventListener('webkitfullscreenchange', this.exitFullscreenListener);
        window.addEventListener('msfullscreenchange', this.exitFullscreenListener);
        this.onResize();
        UserService.gameSignListenerAdd();

        if (game.slug === 'hurdle') {
            this.setState({ rehydratation: 2 });
        }
    }

    componentDidUpdate(prevProps: GameTemplateProps) {
        const { gameState, activeGameNavigationTab, url } = this.props;
        const { leaderboardDataLoaded, score, oldScore, iSLBLoading, isVisibleMobileSlider } = this.state;
        const isGameEnd = gameState === GameState.GAME_END;
        const isLeaderboardTab = activeGameNavigationTab === 'leaderboard';
        const isScoreHigher = score > oldScore;
        const isAdFree = this.props.adFree || this.props.game.isAdsFree;

        // Remove video ad
        if (isAdFree && window.risePlayerInstances?.[RISE_PLAYER_INSTANCE_ID]) {
            window.risePlayerInstances[RISE_PLAYER_INSTANCE_ID].dispose();
        }

        // handle leaderboard data fetching
        if (((isGameEnd || isLeaderboardTab) && !iSLBLoading && !leaderboardDataLoaded) || prevProps.url !== url) {
            this.fetchLeaderboardData();
        }

        if (isScoreHigher && leaderboardDataLoaded && UserService.isUserLoggedIn()) {
            this.checkForNewHighScore();
        }

        if (isVisibleMobileSlider) {
            window.removeEventListener('scroll', this.handleScroll);
        }

        this.setState({ actualVh: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.trackIfVisible);
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('fullscreenchange', this.exitFullscreenListener, false);
        window.removeEventListener('mozfullscreenchange', this.exitFullscreenListener, false);
        window.removeEventListener('webkitfullscreenchange', this.exitFullscreenListener, false);
        window.removeEventListener('msfullscreenchange', this.exitFullscreenListener, false);
        UserService.gameSignListenerRemove();
    }

    openContentFullscreen = () => {
        const elem = this.fullscreenModal.current;
        const gameElem = this.fullscreenGame.current;

        gameElem.focus();

        if (elem.requestFullscreen) {
            elem.requestFullscreen({ navigationUI: 'hide' });
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen({ navigationUI: 'hide' });
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else {
            console.log('Your browser cannot use fullscreen right now');
        }
    };

    exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document['mozCancelFullScreen']) {
            document['mozCancelFullScreen']();
        } else if (document['webkitCancelFullScreen']) {
            document['webkitCancelFullScreen']();
        } else if (document['msExitFullscreen']) {
            document['msExitFullscreen']();
        }

        if (this.fullscreenModal.current) {
            (window as any).scrollTo(0, 120);
        }
    };

    exitFullscreenListener = () => {
        if (!document.fullscreenElement && !document['webkitFullscreenElement']) {
            Analytics.trackEvent(Analytics.games.fullScreenExitButtonClick(this.props.game));
            this.props.dispatch(setActiveGameView({ activeTab: 'game', activeTab2: '' }));
        }
    };

    trackIfVisible = () => {
        if (this.trackedImpression) {
            return;
        }

        const el = this.adDividerRef.current;
        const html = document.body.parentElement;

        if (el) {
            const elR = el.getBoundingClientRect();

            if (elR.y + elR.height * 0.5 <= Math.abs(html.clientHeight)) {
                this.trackedImpression = true;
                Analytics.trackEvent(Analytics.games.gamePageAdDividerImpression(this.props.game));
            }
        }
    };

    isElementInViewport(el) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    handleScroll = () => {
        if (!this.state.isVisibleMobileSlider && this.ref.current && this.isElementInViewport(this.ref.current)) {
            this.setState({ isVisibleMobileSlider: true });
        }
    };

    updateScore = (score) => {
        this.setState(
            (prevState) => ({
                oldScore: prevState.score,
                score,
            }),
            () => {
                this.state.routes.set('leaderboard', Leaderboard);
            }
        );
    };

    setSkeletonLeaderboard = () => {
        this.state.routes.set('leaderboard', LeaderboardSkeleton);
    };

    onResize = () => {
        this.setState({ actualVh: window.innerHeight });
    };

    calculateGameWrapperHeight = (): string => {
        const BOTTOM_MENU_SIZE = 60;
        const { gameState, activeGameNavigationTab } = this.props;
        const { actualVh } = this.state;
        const isDesktopWidth = MiscUtils.matchWidth(
            WIDTH_QUERY.MIN_WIDTH,
            `${ArkCssBreakpoints.ARK_SMALL_DESKTOP}`,
            true
        );
        const isPortraitOrientation = DeviceUtils.isOrientationPortrait();

        if (!isDesktopWidth && gameState === GameState.PREROLL && activeGameNavigationTab === 'game') {
            if (isPortraitOrientation) {
                return `${actualVh - BOTTOM_MENU_SIZE}px`;
            }

            return `${actualVh}px`;
        }

        return `auto`;
    };

    checkForNewHighScore = () => {
        if (this.props.user) {
            const { leaderboardData, score } = this.state;
            const { dispatch } = this.props;
            const scores = leaderboardData.public.day.map((user) => user.score);
            const myBestResult = leaderboardData.public.day.find((user) => user.isCurrentUser)?.score;
            const lastScoreInLeaderboard = scores[scores.length - 1] || 0;
            const firstTimeInLeaderBoard = !myBestResult && score > lastScoreInLeaderboard;
            const beatMyBestResult = myBestResult && score > myBestResult;

            if (firstTimeInLeaderBoard || beatMyBestResult) {
                dispatch(setLeaderBoardNotificationType({ ICON: true, ROW: true }));
            }

            this.setState({ oldScore: score });
        }
    };

    getGameAreaContainerClassNameClass = (
        activeGameNavigationTabFullScreen,
        gameState,
        activeGameNavigationTab,
        isDesktopWidth
    ) => {
        const isOrientationPortrait = DeviceUtils.isOrientationPortraitByJS();
        let gameAreaContainerClassName = classNames(
            styles.gameAreaContainerFullScreen,
            activeGameNavigationTabFullScreen === 'fullscreen' && styles.noPadding,
            gameState === GameState.GAME_END && isOrientationPortrait
                ? styles.gameAreaContainerFullScreenNotFullHeight
                : styles.gameAreaContainerFullScreenFullHeight
        );

        if (isDesktopWidth || (gameState === GameState.PREROLL && activeGameNavigationTab === 'game')) {
            gameAreaContainerClassName = classNames(
                styles.gameAreaContainer,
                activeGameNavigationTabFullScreen === 'fullscreen' && styles.noPadding,
                activeGameNavigationTabFullScreen === 'fullscreen' && styles.gameAreaContainerFullScreen
            );
        }

        this.setState({ gameAreaContainerClass: gameAreaContainerClassName });
    };

    render() {
        const {
            game,
            url,
            currentLang,
            gameState,
            activeGameNavigationTab,
            activeGameNavigationTabFullScreen,
            adFree,
            abVariant,
            t,
            userFavoritesList,
        } = this.props;

        game.isFavorite = userFavoritesList?.includes?.(game.alias);
        const leaderboardOptions = this.getLeaderboardProps();
        const isDesktopWidth = MiscUtils.matchWidth(
            WIDTH_QUERY.MIN_WIDTH,
            `${ArkCssBreakpoints.ARK_SMALL_DESKTOP}`,
            true
        );
        const gameAreaWrapperStyle = {
            height: this.calculateGameWrapperHeight(),
            backgroundColor: game.backgroundColor,
        };
        const hurdleStyleFix = {};
        const isAdFree = adFree || game.isAdsFree;

        this.getGameAreaContainerClassNameClass(
            activeGameNavigationTabFullScreen,
            gameState,
            activeGameNavigationTab,
            isDesktopWidth
        );

        return (
            <>
                <ConnectedHelmet
                    title={getTitleWithCountry(game ? game.metaHTMLTitle : game.name, this.props.currentLang)}
                    meta={[
                        {
                            name: 'description',
                            content: game.metaHTMLDescription,
                        },
                    ]}
                    link={[{ rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` }]}
                />
                <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                    <HeaderBackgroundText game={game} pageType={PageTypes.Game} currentLang={currentLang} />
                </Media>
                <main key={this.state.rehydratation}>
                    <Media lessThan="ARK_LARGEST_DESKTOP">
                        <div className={styles.gameAreaWrapper} style={gameAreaWrapperStyle}>
                            <div className={styles.gameAreaContent}>
                                {/* bookmark buttons, top, bottom, right ad near game, game itself */}
                                <div
                                    className={classNames(
                                        this.state.gameAreaContainerClass !== ''
                                            ? this.state.gameAreaContainerClass
                                            : styles.initialGameAreaContainer, //#157305 display grid needs to be applied from the start in order to avoid the container moving down and the user sees the style change
                                        { [styles.fullWidthGameContainer]: isAdFree },
                                        { [styles.fullScreen]: activeGameNavigationTabFullScreen === 'fullscreen' }
                                    )}
                                    ref={this.fullscreenModal}
                                >
                                    <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                                        <div
                                            className={classNames(styles.gameAreaLeftSideButtons, styles.mobileHidden)}
                                        >
                                            <GameNavigation
                                                game={game}
                                                adFree={isAdFree}
                                                background={game.backgroundColor}
                                                openFullScreen={this.openContentFullscreen}
                                                exitFullScreen={this.exitFullscreen}
                                                pageDescriptionRef={this.pageDescriptionRef}
                                            />
                                        </div>
                                    </Media>
                                    {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                                        <>
                                            <GameAreaTopAdMobileLandscape
                                                className={styles.gameAreaTopAdContainerMobileLandscape}
                                                adBackgroundTone={getAdBackgroundTone(game)}
                                            />
                                            <Media lessThan="ARK_SMALL_DESKTOP">
                                                <div className={styles.topAdMobilePortraitWrapper}>
                                                    <GameAreaTopAdMobilePortrait
                                                        className={styles.gameAreaTopAdContainerMobilePortrait}
                                                        adBackgroundTone={getAdBackgroundTone(game)}
                                                    />
                                                </div>
                                            </Media>
                                        </>
                                    )}
                                    <div
                                        className={classNames(
                                            styles.gameAreaGameContainer,
                                            activeGameNavigationTabFullScreen === 'fullscreen' && styles.noPadding
                                        )}
                                        style={game.isAdsFree ? { maxHeight: 'initial' } : {}}
                                    >
                                        {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                                            <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                                                <GameAreaTopAdNewLayoutTest
                                                    keyValues={[['abtest', `laptop-${abVariant}`]]}
                                                    className={classNames(
                                                        styles.gameAreaTopAdContainer,
                                                        styles.mobileHidden
                                                    )}
                                                    adBackgroundTone={getAdBackgroundTone(game)}
                                                />
                                            </Media>
                                        )}
                                        <div
                                            className={classNames(
                                                styles.gameAreaGameWrapper,
                                                activeGameNavigationTabFullScreen === 'fullscreen' && styles.fullHeight
                                            )}
                                            style={hurdleStyleFix}
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                            <div
                                                tabIndex={gameState === GameState.GAME ? 0 : -1}
                                                className={styles.gameAreaGame}
                                                ref={this.fullscreenGame}
                                            >
                                                <TabRouter
                                                    activeState={activeGameNavigationTab}
                                                    componentsMap={this.state.routes}
                                                    keepAlive="game"
                                                    currentLang={currentLang}
                                                    updateScore={(score) => this.updateScore(score)}
                                                    setSkeletonLeaderboard={this.setSkeletonLeaderboard}
                                                    key={game.alias}
                                                    score={this.state.score}
                                                    {...leaderboardOptions}
                                                />
                                            </div>
                                        </div>
                                        {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                                            <GameAreaBottomAd
                                                className={classNames(
                                                    styles.gameAreaBottomAdContainer,
                                                    styles.mobileHidden
                                                )}
                                                adBackgroundTone={getAdBackgroundTone(game)}
                                                bgColor={game.backgroundColor}
                                                abVariant={abVariant}
                                            />
                                        )}
                                    </div>

                                    {!isAdFree && activeGameNavigationTabFullScreen !== 'fullscreen' && (
                                        <>
                                            <GameAreaRightAdNewLayoutTest
                                                className={classNames(
                                                    styles.gameAreaRightAdContainer,
                                                    styles.mobileHidden
                                                )}
                                                adBackgroundTone={getAdBackgroundTone(game)}
                                                game={game}
                                                t={t}
                                                keyValues={[['abtest', `laptop-${abVariant}`]]}
                                            />
                                            <GameAreaRightAdMobile
                                                className={styles.gameAreaRightAdContainerMobile}
                                                supportCardClassName={styles.supportCardMobile}
                                                adBackgroundTone={getAdBackgroundTone(game)}
                                                isFlexOnMobile={false}
                                                t={t}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Media>
                    <Media greaterThan="ARK_LARGEST_DESKTOP_BELOW">
                        <GameArea
                            game={game}
                            routes={this.state.routes}
                            leaderboardData={this.state.leaderboardData}
                            pageDescriptionRef={this.pageDescriptionRef}
                            abVariant={abVariant}
                            leaderboardDataLoaded={this.state.leaderboardDataLoaded}
                            isAdFree={isAdFree}
                        />
                    </Media>
                    <Media lessThan="ARK_SMALL_DESKTOP">
                        {gameState === GameState.PREROLL && activeGameNavigationTab === 'game' && (
                            <GameFooter
                                game={game}
                                recommendedGames={this.props.recommendedGames}
                                recommendedGamesRef={this.ref}
                                adDividerRef={this.adDividerRef}
                                pageDescriptionRef={this.pageDescriptionRef}
                                isVisibleMobileSlider={this.state.isVisibleMobileSlider}
                                isAdFree={isAdFree}
                            />
                        )}
                    </Media>
                    <Media greaterThan="ARK_SMALL_DESKTOP_BELOW">
                        <GameFooter
                            game={game}
                            recommendedGames={this.props.recommendedGames}
                            recommendedGamesRef={this.ref}
                            adDividerRef={this.adDividerRef}
                            pageDescriptionRef={this.pageDescriptionRef}
                            isVisibleMobileSlider={this.state.isVisibleMobileSlider}
                            isAdFree={isAdFree}
                        />
                    </Media>
                </main>
            </>
        );
    }

    private getLeaderboardProps = () => {
        const { user, game } = this.props;
        const { score, leaderboardData } = this.state;

        return {
            user: user,
            game: game,
            userGameEndScore: score,
            data: leaderboardData,
        };
    };

    private fetchLeaderboardData = async () => {
        const { routes } = this.state;
        const { game, user } = this.props;

        routes.set('leaderboard', Leaderboard);
        const leaderboardData = await HighScoreService.leaderboardFetch(game.slug, user?.uid, UserService.getToken);

        this.setState({
            leaderboardData,
            leaderboardDataLoaded: true,
            routes,
        });
    };
}
export default connect(({ gameState, gamePageViewState, userFavoritesList }) => ({
    //gameState,
    activeGameNavigationTab: gamePageViewState.activeTab,
    activeGameNavigationTabFullScreen: gamePageViewState.activeTab2,
    userFavoritesList,
}))(withTranslation()(GameTemplateBase));
