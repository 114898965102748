import React from 'react';

import { iconProps } from '../../../../models/Icon/IconPropsModel';

export const FlagFr = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="28"
            height="19"
        >
            <defs>
                <rect id="A" width="28" height="19" rx="2" />
                <rect id="B" width="28" height="19" rx="2" />
            </defs>
            <rect width="28" height="19" x="-.5" y="-.5" fill="#fff" stroke="#d9dadc" rx="2" />
            <mask id="C" fill="#fff">
                <use xlinkHref="#A" />
            </mask>
            <path fill="#f44653" d="M19 0h9v20h-9z" mask="url(#C)" />
            <mask id="D" fill="#fff">
                <use xlinkHref="#B" />
            </mask>
            <path fill="#1035bb" d="M0 0H9V20H0z" mask="url(#D)" />
        </svg>
    );
});
