import React, { useState } from 'react';

import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import './PhoneNumberInput.css';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import PhoneInput, {
    Country,
    getCountries,
    getCountryCallingCode,
    parsePhoneNumber,
} from 'react-phone-number-input/input';

import styles from './PhoneInput.styles.css';
import { ButtonWhileTyping } from '../../atoms/Buttons/ButtonWhileTyping/ButtonWhileTyping';
import { ArrowIcon } from '../../atoms/Icons/Styleguide/ArrowIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { TranslationService } from '../../services/TranslationService';
import { UrlService } from '../../services/UrlService';
import { en } from './i18n/en';

export type PhoneNumberInputProps = {
    value?: string;
    countryCode?: Country;
    onChange?: (v: string) => void;
    onSave?: () => void;
    isFocused?: boolean;
    setIsFocused?: (v: boolean) => void;
    isValid?: boolean;
};

const PhoneNumberInput = React.memo(
    ({ value, countryCode, onChange, onSave, isFocused, setIsFocused, isValid }: PhoneNumberInputProps) => {
        let defaultCountry = countryCode || '';
        if (value) {
            const parsed = parsePhoneNumber(value);
            defaultCountry =
                parsed && (parsed as any).getMetadata()?.country_calling_codes[parsed.countryCallingCode as string]
                    ? (parsed as any).getMetadata()?.country_calling_codes[parsed.countryCallingCode as string][0]
                    : countryCode || '';
        }
        const [country, setCountry] = useState(defaultCountry || '');

        function getCountryCallingCodeHandler(country: Country) {
            let res: Country | string = '1';
            try {
                res = getCountryCallingCode(country) as Country;
            } catch (error) {
                console.log(error);
            }
            return res;
        }

        return (
            <>
                <Responsive maxWidth={575}>
                    <div className={styles.mobileInput}>
                        <div>
                            <div className={styles.selectCaption}>Country code</div>
                            <div className={styles.selectContainer}>
                                <Select value={country} onChange={(v) => setCountry(v)} />
                                <div className={styles.countryCode}>
                                    {country ? `+${getCountryCallingCodeHandler(country as Country)}` : '+1'}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.selectCaption}>Phone number</div>
                            <div
                                className={classNames(styles.inputContainer, {
                                    [styles.inputContainerFocused]: isFocused,
                                })}
                            >
                                <PhoneInput
                                    country={country as Country}
                                    className={classNames(styles.phoneInput, styles.inputField, {
                                        [styles.withValue]: (value || '').length > 0,
                                    })}
                                    placeholder="1 262 785 6895"
                                    value={value}
                                    onChange={onChange}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    withCountryCallingCode
                                />
                                {(value || '').length >= 3 && isFocused && (
                                    <button
                                        className={classNames(styles.clearButton, {
                                            [styles.clearIconFocused]: isFocused,
                                        })}
                                        onMouseDown={() => {
                                            if (onChange) {
                                                onChange('');
                                            }
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                )}
                                <ButtonWhileTyping
                                    isValid={(value || '').length >= 3 && isValid}
                                    focus={isFocused}
                                    onMouseDown={async () => {
                                        if ((value || '').length >= 3 && onSave && isValid) onSave();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Responsive>
                <Responsive minWidth={576}>
                    <div className={styles.mobileInput}>
                        <Select value={country} onChange={(v) => setCountry(v)} />
                        <div className={styles.inputContainer}>
                            <PhoneInput
                                className={classNames(styles.phoneInput, styles.inputField, {
                                    [styles.withValue]: (value || '').length > 0,
                                })}
                                country={country as Country}
                                placeholder="1 262 785 6895"
                                value={value}
                                onChange={onChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                withCountryCallingCode
                            />
                            {(value || '').length >= 3 && isFocused && (
                                <button
                                    className={styles.clearButton}
                                    onMouseDown={() => {
                                        if (onChange) {
                                            onChange?.('');
                                        }
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            )}
                        </div>
                    </div>
                </Responsive>
            </>
        );
    }
);

type SelectProps = {
    value: string;
    onChange: (v: string) => void;
};

const countries =
    getCountries &&
    getCountries()
        .slice(1)
        .sort((a, b) => {
            return en[a].trim().toLowerCase().localeCompare(en[b].trim().toLowerCase());
        });

const Select = React.memo(({ value, onChange }: SelectProps) => {
    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(countries.length);
    return (
        <div className={styles.selectContainer}>
            <button
                {...buttonProps}
                className={classNames(styles.countrySelectValue, {
                    [styles.opened]: isOpen,
                    [styles.notSelectedInput]: !value,
                })}
            >
                {value && (
                    <img
                        className={styles.countryIcon}
                        src={UrlService.toCDNUrl(`/icons/flags-icons/${value.toLowerCase()}.png`)}
                        alt="flag-icon"
                    />
                )}
                <span className={classNames(!value && styles.notSelected)}>
                    {value ? en[value] : TranslationService.translateIt('SELECT_COUNTRY_CODE')}
                </span>
            </button>
            <div
                role="menu"
                className={classNames(styles.countrySelectList, {
                    [styles.visible]: isOpen,
                })}
            >
                {countries.map((country, index) => (
                    <a
                        {...itemProps[index]}
                        //Have to add href here
                        key={country}
                        onClick={() => {
                            onChange(country || '');
                            setIsOpen(false);
                        }}
                    >
                        <img
                            className={styles.countryIcon}
                            src={UrlService.toCDNUrl(`/icons/flags-icons/${country.toLowerCase()}.png`)}
                            alt="flag-icon"
                        />
                        {en[country]}
                    </a>
                ))}
            </div>
            <ArrowIcon
                className={classNames(styles.arrowIcon, {
                    [styles.opened]: isOpen,
                })}
            />
        </div>
    );
});

export default PhoneNumberInput;
