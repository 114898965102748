import React, { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Personal.css';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { IGame } from '../../../../models/Game/Game';
import { UserModel } from '../../../../models/User/UserModel';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import { TranslationService } from '../../../../services/TranslationService';
import { setProfilePanelState } from '../../../../store/ducks/layout';
import { UserEffects } from '../../../../store/effects/user.effects';
import { BirthdayEditor } from '../../Editors/BirthdayEditor/BirthdayEditor';
import { GenderEditor } from '../../Editors/GenderEditor/GenderEditor';
import PhoneEditor from '../../Editors/PhoneEditor/PhoneEditor';
import { ScreenNameEditor } from '../../Editors/ScreenNameEditor/ScreenNameEditor';
import { TooltipMobile } from '../../TooltipMobile/TooltipMobile';


type PersonalProfileProps = {
  games: IGame[];
  recentlyPlayed: string[];
  user: UserModel;
  dispatch?: any;
};

export const PersonalProfileTemplate = React.memo((props: PersonalProfileProps) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.grid}>
      <div className={styles.blockPersonalProfile}>
        <div className={styles.personalProfileHeader}>
          <div className={styles.captionContainer}>
            <I18nText as="h2" keyName="MY_PERSONAL_INFORMATION" className={styles.caption}/>
          </div>
          <NotifyIconTooltip
            textTop={TranslationService.translateIt('THIS_INFORMATION_IS_NOT_VISIBLE')}
            tooltipNotVisible={false}
            classNameMessage="profilePage"
          />
        </div>
        <div className={classNames(styles.blockPlaceholder, styles.personal)}>
          <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <div className={styles.mobileCaptionContainer}>
              <I18nText as="h2" keyName="MY_PERSONAL_INFORMATION" className={styles.caption}/>
            </div>
          </Responsive>
          <ProfilePanelRow highLighted={ProfileProcessService.getCurrentStep() === 21}>
            {({ inEdit, setInEdit, hover }) => {
              return (
                <ScreenNameEditor
                  name="firstName"
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  user={user}
                  openPanel={() =>
                    props.dispatch(
                      setProfilePanelState({
                        isOpened: true,
                        target: ProfileRightSideRoutes.FIRST_NAME_PANEL,
                        caption: TranslationService.translateIt('FIRST_NAME')
                      })
                    )
                  }
                  onboardingProgress={ProfileProcessService.getStepForUpdate(22)}
                  rowContentClassName={styles.rowContent}
                  rowEditContentClassName={styles.rowEditContent}
                  captionClassName={styles.fieldLabel}
                  viewClassName="fullWidth"
                  titleAlwaysOn
                  title={TranslationService.translateIt('FIRST_NAME')}
                  label="firstName"
                  minLength={1}
                  maxLength={255}
                  hover={hover}
                  placeholder={t('FIRST_NAME_PLACEHOLDER')}
                />
              );
            }}
          </ProfilePanelRow>

          <ProfilePanelRow>
            {({ inEdit, setInEdit, hover }) => {
              return (
                <ScreenNameEditor
                  name="lastName"
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  user={user}
                  openPanel={() =>
                    props.dispatch(
                      setProfilePanelState({
                        isOpened: true,
                        target: ProfileRightSideRoutes.LAST_NAME_PANEL,
                        caption: TranslationService.translateIt('LAST_NAME')
                      })
                    )
                  }
                  onboardingProgress={undefined}
                  rowContentClassName={styles.rowContent}
                  rowEditContentClassName={styles.rowEditContent}
                  captionClassName={styles.fieldLabel}
                  viewClassName="fullWidth"
                  title={TranslationService.translateIt('LAST_NAME')}
                  titleAlwaysOn
                  label="lastName"
                  minLength={1}
                  maxLength={255}
                  hover={hover}
                  placeholder={t('LAST_NAME_PLACEHOLDER')}
                />
              );
            }}
          </ProfilePanelRow>

          <ProfilePanelRow highLighted={ProfileProcessService.getCurrentStep() === 22}>
            {({ inEdit, setInEdit, hover }) => {
              return (
                <BirthdayEditor
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  user={user}
                  updateUser={(updatedUser) => {
                    props.dispatch(
                      UserEffects.updateUser({
                        ...updatedUser,
                        ...ProfileProcessService.getStepForUpdate(31)
                      })
                    );
                  }}
                  openPanel={() => {
                    props.dispatch(
                      setProfilePanelState({
                        isOpened: true,
                        target: ProfileRightSideRoutes.BIRTHDAY_PANEL,
                        caption: TranslationService.translateIt('BIRTHDAY')
                      })
                    );
                  }}
                  rowContentClassName={styles.rowContent}
                  rowEditContentClassName={styles.rowEditContent}
                  captionClassName={styles.fieldLabel}
                  viewClassName="fullWidth"
                  title={TranslationService.translateIt('BIRTHDAY')}
                  hover={hover}
                />
              );
            }}
          </ProfilePanelRow>
          <TooltipMobile tooltipContent={TranslationService.translateIt('BIRTHDAY_TOOLTIP')}/>
          <ProfilePanelRow>
            {({ inEdit, setInEdit, hover }) => {
              return (
                <GenderEditor
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  user={user}
                  openPanel={() =>
                    props.dispatch(
                      setProfilePanelState({
                        isOpened: true,
                        target: ProfileRightSideRoutes.GENDER_PANEL,
                        caption: TranslationService.translateIt('SELECT_YOUR_GENDER')
                      })
                    )
                  }
                  updateUser={(updatedUser) => {
                    props.dispatch(UserEffects.updateUser(updatedUser));
                  }}
                  rowContentClassName={styles.rowContent}
                  rowEditContentClassName={styles.rowEditContent}
                  captionClassName={styles.fieldLabel}
                  viewClassName="fullWidth"
                  title={TranslationService.translateIt('GENDER')}
                  label="gender"
                  hover={hover}
                />
              );
            }}
          </ProfilePanelRow>
        </div>
      </div>
      <div className={styles.blockContactProfile}>
        <div className={styles.contactProfileHeader}>
          <div className={styles.captionContainer}>
            <I18nText as="h2" keyName="MY_CONTACT_INFORMATION" className={styles.caption}/>
          </div>
          <NotifyIconTooltip
            textTop={`${TranslationService.translateIt(
              'THIS_INFORMATION_IS_NOT_VISIBLE'
            )} ${TranslationService.translateIt('LEARN_MORE_ABOUT_ARKADIUM_PRIVACY')}`}
            tooltipNotVisible={false}
            classNameMessage="profilePage"
          />
        </div>
        <div className={classNames(styles.blockPlaceholder, styles.contact)}>
          <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP_BELOW}>
            <div className={styles.mobileCaptionContainer}>
              <I18nText as="h2" keyName="MY_CONTACT_INFORMATION" className={styles.caption}/>
            </div>
          </Responsive>
          <ProfilePanelRow>
            {({ inEdit, setInEdit, hover }) => {
              return (
                <PhoneEditor
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  user={user}
                  updateUser={(updatedUser: Partial<UserModel>) => {
                    props.dispatch(UserEffects.updateUser(updatedUser));
                  }}
                  openPanel={() =>
                    props.dispatch(
                      setProfilePanelState({
                        isOpened: true,
                        target: ProfileRightSideRoutes.PHONE_NUMBER_PANEL,
                        caption: TranslationService.translateIt('PHONE_NUMBER')
                      })
                    )
                  }
                  rowContentClassName={styles.rowContent}
                  rowEditContentClassName={styles.rowEditContent}
                  captionClassName={styles.fieldLabel}
                  viewClassName="fullWidth"
                  title={TranslationService.translateIt('PHONE_NUMBER')}
                  label="phone"
                  hover={hover}
                />
              );
            }}
          </ProfilePanelRow>
          <TooltipMobile tooltipContent={TranslationService.translateIt('THIS_INFORMATION_IS_NOT_VISIBLE')}/>
        </div>
      </div>
    </div>
  );
});

const ProfilePanelRow = (props: any) => {
  const [inEdit, setInEdit] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <div
      className={classNames(styles.row, {
        [styles.rowInEdit]: inEdit,
        [styles.hightLighted]: props.highLighted && !inEdit,
        [styles.hovered]: hover && !inEdit
      })}
      onMouseMove={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
    >
      {props.children({ inEdit, setInEdit, hover })}
    </div>
  );
};
