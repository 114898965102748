import React, { FC } from 'react';

import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { MiscUtils } from '../../../../utils/MiscUtils';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { ListCheckCircleIcon } from '../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { LocalStorageService } from '../../../services/LocalStorage';
import { setActiveSubscriptionPlan } from '../../../store/ducks/subscription/common';
import subsStyles from '../SubscriptionStyles.css';
import styles from './ChosenPurchaseBlock.css';

export type SubscriptionPlanProps = {
    type: SubscriptionType;
    planCode: string;
    paymentType?: PaymentType;
    gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
};

export type SubscriptionType = {
    label: string;
    price: number;
    period: string;
};

const DetailsList = {
    [PaymentType.subscription]: [
        {
            infoText: (
                <>
                    <ListCheckCircleIcon /> <p>Play games and apps ad-free</p>
                </>
            ),
        },
        {
            infoText: (
                <>
                    <ListCheckCircleIcon />{' '}
                    <p>
                        <span className={styles.highlight}>15%</span>&nbsp;off gem purchases
                    </p>
                </>
            ),
        },
        {
            infoText: (
                <>
                    <ListCheckCircleIcon />
                    <p>
                        Show your personality with premium <span className={styles.highlight}>avatars</span> and{' '}
                        <span className={styles.highlight}>frames</span>
                    </p>
                </>
            ),
        },
    ],
    [PaymentType.gems]: [
        {
            infoText: (
                <>
                    <ListCheckCircleIcon />{' '}
                    <p>Use gems to skip ads or buy boosts, themes, and more to use in your favorite games</p>
                </>
            ),
        },
    ],
};

export const ChosenPurchaseBlock: FC<SubscriptionPlanProps> = ({
    type,
    planCode,
    paymentType = PaymentType.subscription,
    gemsAnalyticsProps,
}) => {
    const dispatch = useDispatch();
    const { label: typeLabel, price: typePrice, period: typePeriod } = type;
    const isAdvantageSubscription = paymentType === PaymentType.subscription;
    const isGemsPurchase = paymentType === PaymentType.gems;
    const switchText = isAdvantageSubscription
        ? ` Switch to ${planCode === SubscriptionPlans.MONTHLY ? 'an annual' : 'a monthly'} plan`
        : 'Update my gem purchase';
    const switchCallback = isAdvantageSubscription
        ? () =>
              dispatch(
                  setActiveSubscriptionPlan(
                      planCode === SubscriptionPlans.MONTHLY ? SubscriptionPlans.ANNUAL : SubscriptionPlans.MONTHLY
                  )
              )
        : isGemsPurchase
        ? async () => {
              Analytics.trackEvent(
                  Analytics.gems.gemPurchaseChangeGems(
                      gemsAnalyticsProps.shopLocation,
                      gemsAnalyticsProps.priceInGem,
                      gemsAnalyticsProps.gemsPackId,
                      gemsAnalyticsProps.gemsInPack
                  )
              );
              if (LocalStorageService.checkStorageListening()) {
                  window.close();
              } else {
                  dispatch(push('/?menuTab=Shop'));
              }
          }
        : () => null;

    return (
        <>
            <div>
                <span className={subsStyles.heading}>
                    {isAdvantageSubscription && !isGemsPurchase ? 'Subscription Plan⠀' : 'Gems⠀'}
                </span>
                <span
                    aria-hidden="true"
                    className={classNames(subsStyles.link, styles.switchLink, { [styles.__gems]: isGemsPurchase })}
                    onClick={switchCallback}
                >
                    {switchText}
                </span>
            </div>
            <div className={styles.subPlanWrapper}>
                <div
                    className={classNames(
                        styles.typeBlock,
                        isAdvantageSubscription && planCode === SubscriptionPlans.ANNUAL && styles.annualTypeBlock
                    )}
                >
                    <div className={styles.typeName}>
                        {updateLabel(typeLabel, 'Gem', gemsAnalyticsProps, paymentType)}
                    </div>
                    <div className={styles.typePrice}>
                        ${typePrice} {typePeriod ? <span className={styles.typePeriod}>/{typePeriod}</span> : null}
                    </div>
                </div>
                <div className={styles.typeDescription}>
                    <ul className={styles.planDetailsList}>
                        {DetailsList[paymentType].map((item, index) => (
                            <li key={index.toString()}>{item.infoText}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export const updateLabel = (
    label,
    itemName,
    gemsAnalyticsProps: GemsAnalyticsCustomDimensions,
    paymentType
): string => {
    const gemsAmount = gemsAnalyticsProps && gemsAnalyticsProps?.gemsInPack;
    if (gemsAmount && paymentType === PaymentType.gems) {
        return `${MiscUtils.numberWithCommas(gemsAmount)} ${itemName}`;
    } else {
        return label;
    }
};
