import React from 'react';

import {
  EnumIframeMessageTypes,
  GamesWithOwnIframe,
  TypeGameEvent,
  TypeGRMProps,
  TypeLocaleType,
  TypeUserModel,
  GameRenderingModule
} from '@arkadium/modules';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { batch, connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import styles from './Game.css';
import { ComponentOwnProps as GameUnitProps, GameUnit } from './GameUnit';
import { devConsole } from '../../../utils/DevConsole';
import { isSafari } from '../../../utils/IsSafariUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { environment } from '../../config/environment';
import { EnvironmentName } from '../../constants/Environment';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { ARK_MESSAGES, GameState, IframeMessageTypes, LOCALSTORAGE_ITEMS, LS_COOKIE_CONSTS } from '../../models/Enums';
import { IGame } from '../../models/Game/Game';
import { GameUserEventsNames, GameUserEventsResults } from '../../models/Game/GameEventsUserEvents';
import { GameEvents, SS_HURDLE_BOOST_PROPS } from '../../models/Game/GameObservable';
import { IframeGamesList } from '../../models/Game/IframeGamesList';
import { UserTopScores } from '../../models/HighScores';
import { UserModel } from '../../models/User/UserModel';
import { AdBlockScreen } from '../../molecules/AdBlockScreen/AdBlockScreen';
import { Preroll, PrerollType } from '../../molecules/Preroll/Preroll';
import PrerollPreloader from '../../molecules/Preroll/PrerollPreloader';
import { SubscriberOnlyPreroll } from '../../molecules/Preroll/SubscriberOnlyPreroll';
import { adBlockDetector } from '../../services/AdBlockerService';
import AdsService from '../../services/AdsService';
import { setLastPlayedGame } from '../../services/Analytics/AI/SubscriptionAnalyticsAi';
import { Analytics, trackGameEvent } from '../../services/Analytics/Analytics';
import { GDPRMediatorService } from '../../services/Analytics/GDPR';
import { LEANPLUM_EVENTS, LeanplumAnalytics, LeanplumGameTrack } from '../../services/Analytics/LeanplumAnalytics';
import { handleLeanplumMarketingActions } from '../../services/Analytics/LeanplumAnalyticsMarketingActions';
import CollectionsService, { CollectionPrize } from '../../services/CollectionsService';
import { CookieService } from '../../services/CookieService';
import GemsService from '../../services/GemsService';
import HighScoreService from '../../services/HighScoreService';
import { LocalStorageService } from '../../services/LocalStorage';
import { LocalStorageListenedProps } from '../../services/LocalStorageListenerLogic';
import { MicrosoftAdvertisingService } from '../../services/MicrosoftAdvertisingService';
import PaymentService from '../../services/PaymentService';
import RecentlyPlayedService from '../../services/RecentlyPlayedService';
import { SessionStorageService } from '../../services/SessionStorage';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { arkConfigType } from '../../store/ducks/arkConfig/arkConfig';
import { setUserHasCollectionNotification } from '../../store/ducks/collections/collections';
import { setActiveGameView, setGameObservable, setGameState } from '../../store/ducks/games';
import { setSideMenuActivePage, setSideMenuOpened } from '../../store/ducks/layout';
import { GemsAnalyticsShopLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { GemsEffects } from '../../store/effects/gems.effects';
import { GameEnd } from '../GameEnd/GameEnd';

const IFRAME_SOURCE_CODE_URL =
  environment.CDN_BASE_URL_OVERWRITES || `${!MiscUtils.isServer ? window.location.origin : ''}/iframe-rendering.html`;
const TIMEOUT_ONE_MIN = 60000;
const addQSPtoURL = (url: string, gameId: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  let queryParams = urlParams.toString();

  if (Boolean(queryParams)) {
    queryParams = `?gameId=${gameId}&${queryParams}`;
  }

  return `${url}${queryParams}`;
};
const hasWithoutDelayQSP = () => Boolean(UrlService.getQSParam(window.location.search, '__ark_no_delay'));

type StoreProps = {
  gameState: GameState;
  iframeGamesList: IframeGamesList;
  grmGamesList: string[];
  adFree: boolean;
  subscription: boolean;
  dispatch: Dispatch<Action>;
  userFavoritesList: string[];
  shopLocation?: GemsAnalyticsShopLocations; // ? because from store connection
  arkConfig: arkConfigType;
};

type ComponentOwnProps = {
  game: IGame;
  user: UserModel;
  currentLang: string;
  keepAliveStatus: boolean;
  updateScore: (score: number) => void;
  setSkeletonLeaderboard: () => void;
};

type GameContainerProps =
  StoreProps
  & ComponentOwnProps;

type GRMProps = TypeGRMProps;

type GameContainerState = {
  score: number;
  topScores: UserTopScores | null;
  isPrerollReady: boolean;
  isIframeGame: boolean;
  iframeSourceCode: string | null;
  collectionPrize: CollectionPrize | null;
  isCollectionPrizeLoading: boolean;
  isGenerationPrizeDelayPassed: boolean;
  isHeavyAdDetected: boolean;
};

class GameContainerBase extends React.PureComponent<GameContainerProps, GameContainerState> {

  readonly state = {
    score: 0,
    topScores: null,
    isPrerollReady: false,
    isIframeGame: true, // if default false duplicate render can occur like for Hurdle in #157967
    iframeSourceCode: null,
    collectionPrize: null,
    isCollectionPrizeLoading: false,
    isGenerationPrizeDelayPassed: false,
    isHeavyAdDetected: false
  };

  private prerollContainerRef = React.createRef<HTMLDivElement>();
  private iframeRef = React.createRef<HTMLIFrameElement>();
  private timerId: number | null = null;
  private gameStates = [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL];
  private gameStartTime: Date;
  private rewardPayload: any;
  private interstitialPayload: any;

  private get isAdsFreeGame(): boolean {
    return this.props?.game?.isAdsFree;
  }

  privacyPolicyLabelHandler = () => {
    const { gameState } = this.props;
    const privacyPolicyLabel = document.getElementById('qc-cmp2-persistent-link');

    if (!privacyPolicyLabel) {
      return;
    } else if (gameState === GameState.GAME) {
      privacyPolicyLabel.setAttribute('style', 'z-index: 0 !important');
    } else {
      privacyPolicyLabel.removeAttribute('style');
    }
  };

  componentDidMount() {
    const { user, dispatch } = this.props;

    devConsole('Version check - Originals + GRM');

    if (!MiscUtils.isServer) {
      // for QA-ing game states
      const urlParams = new URLSearchParams(window.location.search);
      const qpGameState = urlParams.get('__gamestates');

      if (qpGameState !== null) {
        (window as any).gameState = (gameStateName: string) => {
          dispatch(setGameState(GameState[gameStateName]));
        };

        Object.keys(GameState).includes(qpGameState.toUpperCase()) &&
        dispatch(setGameState(GameState[qpGameState.toUpperCase()]));
      }
    }

    if (this.isGameInIframe()) {
      switch (this.props.game.renderingType) {
        case 'Html':
          this.setState({
            isIframeGame: true,
            iframeSourceCode: this.props.game.gameAssetOriginUrl + 'index.html'
          });
          break;
        case 'External':
          this.setState({
            isIframeGame: true,
            iframeSourceCode: this.props.game.version
          });
          break;
        default:
          this.setState({
            isIframeGame: true,
            iframeSourceCode: addQSPtoURL(IFRAME_SOURCE_CODE_URL, this.props.game.meta.alias)
          });
          break;
      }
    } else {
      this.setState({ isIframeGame: false });
    }

    window.addEventListener('message', this.onMessage);
    window.addEventListener('click', this.navButtonListener);
    window.addEventListener('videoStarted', this.setStateToPrerollPlaying);

    const isAdfree = CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true';
    const isSubscriber = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';

    if (user) {
      this.getUserTopScores();
    }

    if (isAdfree && !this.isAdsFreeGame) {
      dispatch(setGameState(GameState.PREROLL));
    }

    if (isSubscriber || this.isAdsFreeGame) {
      dispatch(setGameState(GameState.PENDING));
    }

    this.gameStartTime = new Date(); // for users without preroll
    this.applySetPrerollIfNeeded();

    if (adBlockDetector.adsShown) {
      adBlockDetector.check();
    }
  }

  private checkIfNewUserShouldSkipPreroll() {
    // below the logic related https://arkadium.atlassian.net/browse/PDO-1198
    let skipPreroll = false;
    const firstVisit = LocalStorageService.getItem(LOCALSTORAGE_ITEMS.ARK_USER_FIRST_VISIT);
    const env = EnvironmentName[environment.Name].toLowerCase();

    if (!MiscUtils.isServer && firstVisit) {
      const disablePreroll = this.props.arkConfig?.disablePreroll;

      if (!disablePreroll?.env?.includes(env)) {
        return false;
      }

      const startDate = new Date(disablePreroll?.starDate);
      const endDate = new Date(disablePreroll?.endDate);
      const currentDate = new Date();

      if (currentDate >= startDate && currentDate <= endDate) {
        const currentDate = new Date(); // Current date and time
        const firstVisitDate = new Date(firstVisit); // Specific date
        const timeDifference = currentDate.getTime() - firstVisitDate.getTime();
        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        skipPreroll = daysLeft <= (disablePreroll?.days || -1);

        console.log('Days Left: ', daysLeft, 'Skip preroll: ', skipPreroll); // Rounded down whole days
      }
    }

    return skipPreroll;
  }

  componentDidUpdate(prevProps: GameContainerProps, prevState: GameContainerState) {
    const lastGamePlayed = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_PC_LAST_GAME_PLAYED);
    const { adFree, subscription, user, gameState, dispatch, game, userFavoritesList } = this.props;

    game.isFavorite = userFavoritesList?.includes?.(game?.alias);
    const isSubscriber = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true';
    const gameStateChanged = prevProps.gameState !== gameState;

    if (prevState.isHeavyAdDetected !== this.state.isHeavyAdDetected) {
      const gameCanvas = document.getElementById('canvas-box') as HTMLImageElement | HTMLVideoElement;

      // We should to do that for iframe reload in case if we faced with "heavy ad" error
      if (gameCanvas) {
        gameCanvas.src = gameCanvas.src;
      }

    }

    if (prevProps.gameState === GameState.ADBLOCK) {
      return gameState !== GameState.ADBLOCK && dispatch(setGameState(GameState.ADBLOCK));
    }

    if (
      (isSubscriber ||
        (this.isAdsFreeGame &&
          (gameState === GameState.PREROLL ||
            gameState === GameState.PENDING ||
            gameState === GameState.PREROLL_PLAYING))) &&
      gameState !== GameState.GAME_END
    ) {
      dispatch(setGameState(GameState.GAME));
    }

    const gameStateChangedFromBreak =
      prevProps.gameState === GameState.REWARD || prevProps.gameState === GameState.INTERSTITIAL;

    if (
      gameStateChanged &&
      gameState === GameState.GAME &&
      !this.isNotAllowedForUnsubscribed() &&
      !gameStateChangedFromBreak
    ) {
      setLastPlayedGame(game.slug);
      void Analytics.trackEvent(Analytics.games.gameStart(game));
      LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.FUNNEL_CONTENT_START, { game: game.name });
      Analytics.gamePlayNumTrack(game);
      LeanplumAnalytics.setUserAttributesCustom({ last_game_played: lastGamePlayed });
      Analytics.gamePlayVisitTrack(game);
      this.saveGameSequence(game);
      this.saveRecentlyPlayed(game.slug);
      MicrosoftAdvertisingService.trackGamePlay();
    }

    if (user !== prevProps.user) {
      this.getUserTopScores();
    }

    if (adFree || subscription) {
      switch (gameState) {
        case GameState.GAME_END:
          break;
        case GameState.REWARD:
          break;
        case GameState.INTERSTITIAL:
          break;

        default: {
          dispatch(setGameState(GameState.GAME));
          break;
        }
      }
    }

    this.privacyPolicyLabelHandler();
    this.applySetPrerollIfNeeded();
  }

  isGameInIframe() {
    const {
      iframeGamesList,
      game: { slug, renderingType, isIframe }
    } = this.props;

    if (MiscUtils.isServer) {
      return false;
    }

    if (['External', 'Html'].includes(renderingType)) {
      return true;
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('__iframe') === 'false') {
      return false;
    }

    // JSON check for arkadiumSlug (part of URL)
    return (
      (urlParams.get('__iframe') === 'true' ||
        urlParams.get('__arkver') === 'iframe' ||
        iframeGamesList.includes(slug) ||
        isIframe) &&
      !isSafari &&
      !GamesWithOwnIframe.includes(slug)
    );
  }

  getUserTopScores() {
    const {
      game: { slug }
    } = this.props;

    HighScoreService.getTopScores(slug, UserService.getToken).then((topScores) => this.setState({ topScores }));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    window.removeEventListener('message', this.onMessage);
    window.removeEventListener('click', this.navButtonListener);
    window.addEventListener('videoStarted', this.setStateToPrerollPlaying);
    dispatch(setGameState(GameState.PREROLL));
    dispatch(setActiveGameView({ activeTab: 'game' }));
    this.clearCollectionTimer();
  }

  onHeavyAdHandler(event: { data: string }) {
    let data: { type: string };

    if (typeof event.data === 'string') {
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        return;
      }
    }

    if (data?.type === 'intervention') {
      this.setState({ isHeavyAdDetected: true });
    }
  }

  saveGameSequence(game: IGame) {
    let gameSequence = SessionStorageService.getItem('GameSequence');

    if (gameSequence === null) {
      SessionStorageService.setItem('GameSequence', game.slug);
    } else if (game !== null && gameSequence.indexOf(game.slug) === -1) {
      gameSequence = `${gameSequence}|${game.slug}`;
      SessionStorageService.setItem('GameSequence', gameSequence);
    }
  }

  saveRecentlyPlayed = (slug: string) => {
    const recentlyPlayedLocal = RecentlyPlayedService.recentlyPlayedAddLocal(slug);

    if (UserService.isUserLoggedIn()) {
      RecentlyPlayedService.recentlyPlayedSave(recentlyPlayedLocal, UserService.getToken).then((res) => {
        noop(res);
      });
    }
  };

  // This feature used by QA stuff to skip preroll
  onMessage = (event: MessageEvent) => {
    const { dispatch } = this.props;
    const { type, payload } = event.data || {};

    try {
      if (event.data && event.data.actionName === ARK_MESSAGES.END_PREROLL) {
        dispatch(setGameState(GameState.GAME));
      }


      if (type === GameEvents.CHANGE_SCORE) {
        payload !== undefined &&
        this.onScoreChange({
          type,
          payload
        });
      }

      this.onHeavyAdHandler(event);
    } catch (ex) {
      console.log(ex);
    }
  };

  onPrerollEnd = () => {
    const { dispatch } = this.props;

    dispatch(setGameState(GameState.GAME));
    this.gameStartTime = new Date();
  };

  onGameEnd = () => {
    const { game, dispatch } = this.props;

    Analytics.trackEvent(
      Analytics.games.gameEnd(game, Math.floor((Date.now() - this.gameStartTime.getTime()) / 1000)) // , this.state.score
    ).then(() => noop());

    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.FUNNEL_CONTENT_END, { game: game.name });
    const hasCollection = game.meta.hasCollection;

    if (
      (BUILD_ENV === 'prod' && hasCollection === 'prod') ||
      (BUILD_ENV !== 'prod' && (hasCollection === 'dev' || hasCollection === 'prod'))
    ) {
      this.generateCollectionPrize().then(() => noop());
      this.clearCollectionTimer();
    }

    dispatch(setGameState(GameState.GAME_END));
  };

  onScoreChange = async (event: TypeGameEvent) => {
    const {
      setSkeletonLeaderboard,
      updateScore,
      game: { slug }
    } = this.props;
    let score: number;
    const gameSlug = (event as any)?.slug ? (event as any).slug : slug;
    const timeOffset = new Date().getTimezoneOffset();
    const dateTime = this.gameStartTime.toISOString();
    const queryUrl = UrlService.getQSParam(window.location.search, 'testScore');

    if (queryUrl && environment.Name !== EnvironmentName.PRODUCTION) {
      score = Number(queryUrl);
    } else {
      score = event.payload as number;
    }

    this.setState({ score });

    // save high score to local storage and database (if user logged in);
    const highScore = {
      slug: gameSlug,
      score,
      dateTime,
      timeOffset
    };

    devConsole('highScore UPD', highScore);
    const highScores = HighScoreService.scoreAddLocal(highScore);

    setSkeletonLeaderboard();

    if (UserService.isUserLoggedIn()) {
      await HighScoreService.scoreSave(highScores, UserService.getToken);
    }

    updateScore(score);
  };

  onRewardStart = (d: any) => {
    const { dispatch, game } = this.props;

    this.rewardPayload = d;
    dispatch(setGameState(GameState.REWARD));
    LeanplumGameTrack.funnelRewardStart(game.slug);
  };

  onRewardEnd = () => {
    const { isIframeGame } = this.state;
    const { dispatch } = this.props;

    setTimeout(
      function () {
        console.log('onRewardEnd', isIframeGame, this.rewardPayload, this.rewardPayload?.callback);

        if (isIframeGame) {
          this.postMessageToIframe({
            type: IframeMessageTypes.REWARD_CALLBACK,
            payload: { hasReward: true }
          });
        } else {
          this.rewardPayload?.callback?.({ hasReward: true });
        }

        dispatch(setGameState(GameState.GAME));
      }.bind(this),
      1000
    );
  };

  onInterstitialStart = (eventPayload: { data?; callback }) => {
    const { isIframeGame } = this.state;
    const { adFree, subscription, dispatch } = this.props;

    console.log('onInterstitialStart', eventPayload, eventPayload?.callback, !adFree && !subscription);

    if (!adFree && !subscription) {
      this.interstitialPayload = eventPayload;
      dispatch(setGameState(GameState.INTERSTITIAL));
    } else {
      setTimeout(
        function () {
          console.log(
            'onInterstitialStart subscriber call (in 1 second)',
            isIframeGame,
            isIframeGame ? this.interstitialPayload?.callback : eventPayload?.callback
          );

          if (isIframeGame) {
            this.postMessageToIframe({
              type: IframeMessageTypes.INTERSTITIAL_CALLBACK,
              payload: this.interstitialPayload?.callback ? this.interstitialPayload : {}
            });
          } else {
            eventPayload?.callback?.();
          }
        }.bind(this),
        1000
      );
    }
  };

  onInterstitialEnd = () => {
    const { isIframeGame } = this.state;
    const { dispatch } = this.props;

    dispatch(setGameState(GameState.GAME));

    if (isIframeGame) {
      this.postMessageToIframe({
        type: IframeMessageTypes.INTERSTITIAL_CALLBACK,
        payload: this.interstitialPayload?.callback ? { callback: this.interstitialPayload.callback } : {}
      });
    } else {
      this.interstitialPayload?.callback?.();
    }
  };

  postMessageToIframe = (data: { type: IframeMessageTypes; payload: object }) => {
    const iframeRef = this.iframeRef.current;
    const serializedData = JSON.parse(JSON.stringify(data));
    const isGrm = this.grmIsUsed();
    // this is for container level events (like onRewardEnd and onInterstitialEnd) still using postMessageToIframe
    const iframe: any = !isGrm
      ? iframeRef
      : document.body.querySelector?.('.__GRM iframe') ||
      document.body.querySelector?.('[class*="Game-gameContainer"] iframe') || // fallback for GRM before #157967 fix
      null;

    iframe &&
    iframe?.contentWindow?.postMessage?.({ ...serializedData }, iframe?.src?.replace?.(/\?.*/, '') || '*');
  };

  navButtonListener = (e: any) => {
    const { game } = this.props;
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { target } = e;
    const targetClassList = target.classList;

    // TODO: this is a template for future A/B test, when we will track user click on preroll area and 'play button' itself
    // if (target === prerollContainer || prerollContainer?.contains(target)) {
    //     const buttonEl = document.getElementsByClassName('ctaButton')[0] as HTMLElement;
    //     buttonEl?.click();
    // }

    if (targetClassList.contains('ctaButton') || targetClassList.contains('ctaButtonText')) {
      void Analytics.trackEvent(Analytics.games.funnelZero(game));
      void Analytics.trackEvent(Analytics.games.playNow(game));
      this.props.dispatch(setGameState(GameState.PREROLL_PLAYING));

      if (adBlockDetector.adsShown) {
        adBlockDetector.check();
      }
    }
  };

  setStateToPrerollPlaying = () => {
    const { gameState, dispatch } = this.props;

    if (gameState === GameState.PREROLL) {
      dispatch(setGameState(GameState.PREROLL_PLAYING));
    }
  };

  applySetPrerollIfNeeded() {
    const { gameState } = this.props;
    const isGameState = this.gameStates.indexOf(gameState) !== -1;
    const isGameEndState = gameState === GameState.GAME_END;

    if (!isGameState && !isGameEndState && !this.state.isPrerollReady) {
      this.setPreroll();
    }
  }

  setPreroll() {
    const { adFree, subscription, dispatch } = this.props;
    let prerollState = GameState.PREROLL;
    const skipPreroll = this.checkIfNewUserShouldSkipPreroll();

    if (
      adFree ||
      CookieService.getArkCookie(LS_COOKIE_CONSTS.AD_FREE_VER) === 'true' ||
      subscription ||
      CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION) === 'true' ||
      this.isAdsFreeGame ||
      skipPreroll
    ) {
      prerollState = GameState.GAME;
    }

    dispatch(setGameState(prerollState));
  }

  isNotAllowedForUnsubscribed() {
    const {
      game: { subscriberOnlyGame }
    } = this.props;

    if (subscriberOnlyGame === undefined || MiscUtils.isServer) {
      return false;
    }

    const todayDate = dayjs();
    const isSubscriber = UserService.isUserSubscriber();
    const subscriberId = CookieService.getArkCookie(LS_COOKIE_CONSTS.SUBSCRIPTION_ID);
    const isAfter = dayjs(subscriberOnlyGame).isAfter(todayDate);


    if (!isSubscriber && isAfter && !subscriberId) {
      this.setState({ isPrerollReady: true });
      return true;
    }

    return false;
  }

  async generateCollectionPrize() {
    const { game, dispatch } = this.props;
    const { isGenerationPrizeDelayPassed } = this.state;
    let result: CollectionPrize | null = null;

    // hasWithoutDelayQSP used by QA to disable prize generation delay
    if (hasWithoutDelayQSP() || isGenerationPrizeDelayPassed) {
      try {
        this.setState({ isCollectionPrizeLoading: true });

        if (UserService.isUserLoggedIn()) {
          result = await CollectionsService.generateCollectionPrize(game.slug);
        } else {
          result = await CollectionsService.generateAnonymousCollectionPrize(game.slug);
        }

        if (result) {
          this.setState({ collectionPrize: result });
          Analytics.trackEvent(Analytics.games.receivedCollectible(game)).then(() => noop());
          dispatch(setUserHasCollectionNotification(true));
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        this.setState({ isCollectionPrizeLoading: false });
      }
    }
  }

  setCollectionTimer = () => {
    const { game } = this.props;
    const hasCollection = game.meta.hasCollection;

    if (hasCollection) {
      this.setState({
        isGenerationPrizeDelayPassed: false,
        collectionPrize: null
      });
      this.clearCollectionTimer();
      this.timerId = window.setTimeout(() => {
        this.setState({ isGenerationPrizeDelayPassed: true });
      }, TIMEOUT_ONE_MIN);
    }
  };

  clearCollectionTimer = () => {
    if (this.timerId !== null) {
      window.clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  render() {
    const { gameState, game, user, subscription, adFree, dispatch } = this.props;
    const { isPrerollReady, score, topScores, collectionPrize, isCollectionPrizeLoading } = this.state;
    const isAdBlockState = gameState === GameState.ADBLOCK;
    const isSubscriberPlaying = subscription && gameState !== GameState.GAME_END;
    const isGameState = this.gameStates.indexOf(gameState) !== -1 || isSubscriberPlaying;
    const isPrerollState = gameState === GameState.PREROLL || gameState === GameState.PREROLL_PLAYING;
    const isRewardState = gameState === GameState.REWARD;
    const isInterstitialState = gameState === GameState.INTERSTITIAL;
    const isGameEndState = gameState === GameState.GAME_END;
    const adFreeValue = adFree || subscription;

    return (
      <div
        data-element-description="game"
        className={classNames(styles.gameContainer, {
          [styles.hidden]: !this.props.keepAliveStatus,
          __GRM: this.grmIsUsed()
        })}
        role="tabpanel"
        aria-labelledby="game-tab"
        id="game-tabpanel"
      >
        {!isPrerollReady && !isAdBlockState && !isGameState && !isGameEndState && <PrerollPreloader />}
        {isAdBlockState && <AdBlockScreen game={game} />}
        {this.grmRenderWrapper()}
        {isPrerollState &&
          (this.isNotAllowedForUnsubscribed() ? (
            <SubscriberOnlyPreroll game={game} />
          ) : (
            <Preroll
              innerRef={this.prerollContainerRef}
              prerollType={PrerollType.PREROLL}
              game={game}
              onEnd={this.onPrerollEnd}
              setIsPrerollReady={() => this.setState({ isPrerollReady: true })}
            />
          ))}
        {isGameState && this.isNotAllowedForUnsubscribed() && <SubscriberOnlyPreroll game={game} />}
        {isRewardState && (
          <Preroll
            innerRef={this.prerollContainerRef}
            prerollType={PrerollType.REWARD}
            game={game}
            onEnd={this.onRewardEnd}
            setIsPrerollReady={() => this.setState({ isPrerollReady: true })}
          />
        )}
        {isInterstitialState && (
          <Preroll
            innerRef={this.prerollContainerRef}
            prerollType={PrerollType.INTERSTITIAL}
            game={game}
            onEnd={this.onInterstitialEnd}
            setIsPrerollReady={() => this.setState({ isPrerollReady: true })}
          />
        )}
        {isGameEndState &&
          (isCollectionPrizeLoading ? (
            <AppLoader inWrapper />
          ) : (
            <GameEnd
              game={game}
              score={score}
              topScores={topScores}
              user={user}
              adFree={adFreeValue}
              subscription={subscription}
              collectionPrize={collectionPrize}
              onPageReloadHandler={this.setCollectionTimer}
            />
          ))}
        <button
          className={classNames(styles.testingGoToGameEndButton, 'testing__game-end-button')}
          data-game-status="end"
          onClick={() => dispatch(setGameState(GameState.GAME_END))}
        >
          game end button
        </button>
      </div>
    );
  }

  private doGetBackToGameTab() {
    this.props?.dispatch?.(
      setActiveGameView({
        activeTab: 'game',
        activeTab2: ''
      })
    );
  }

  private doSetGameObservable(gameObservable: any) {
    this.props?.dispatch?.(setGameObservable(gameObservable));
  }

  private doCloseMenu() {
    this.props?.dispatch?.(setSideMenuOpened(false));
  }

  private doOpenMenuLogin() {
    batch(() => {
      this?.props?.dispatch(setSideMenuOpened(true));
      this?.props?.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
    });
  }

  private doOpenMenuPurchase() {
    LocalStorageService.setItem('shopOpenRequest', LocalStorageListenedProps.GAME_PURCHASE_REQUEST);
    batch(() => {
      this?.props?.dispatch(setSideMenuOpened(true));
      this?.props?.dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
    });
  }

  private doUpdateGemsAmount() {
    // @ts-ignore
    this.props.dispatch(GemsEffects.UpdateGemsAmount());
  }

  private doAdsRefresh() {
    AdsService.refresh();
  }

  private onGameTestReady() {
    this.setCollectionTimer();
  }

  private onGameStart() {
  }

  private onResize() {
  }

  private onPlatformSpecificEvent = handlePlatformSpecificEvents;
  private marketingActionHandlerFunc = null;

  private onUnitMount(): void {
    this.marketingActionHandlerFunc = handleLeanplumMarketingActions.bind(this)(undefined, true);
  }

  private onUnitUnmount(): void {
    // All game pages use real <a> links to navigate for reloading to clear game data => no removing listener for LP is needed... But to be sure
    handleLeanplumMarketingActions.bind(this)(undefined, this.marketingActionHandlerFunc);
    this.marketingActionHandlerFunc = null;
  }

  private onUnitUpdate(prevUnitProps: GRMProps, currUnitProps: GRMProps): void {
    console.log('onUnitUpdate', prevUnitProps, currUnitProps);
  }

  private getGameUnitProps = (grmIsUsed: boolean): GameUnitProps | GRMProps => {
    const { gameState, game, currentLang, keepAliveStatus, adFree, subscription, user } = this.props;
    const { isIframeGame, iframeSourceCode } = this.state;
    const adFreeValue = adFree || subscription;
    const isGRM = typeof grmIsUsed !== 'undefined' ? grmIsUsed : this.grmIsUsed();
    let gameUnitProps: GameUnitProps | GRMProps;

    if (isGRM) {
      const currentSubscription = UserService.getCurrentSubscription();
      const subscriptionPlan = UserService.getSubscriptionType(currentSubscription);
      const subscriptionId = UserService.getSubscriptionId();
      const userGameDataApiService = UserService.authApiService.getUserGameDataApi({
        apiRoot: environment.EAGLE_USER_GAME_DATA_API
      });

      // @ts-ignore
      gameUnitProps = {
        game: game.rawGameData,
        isStateGame: this.gameStates.includes(gameState),
        isStateGamePlaying: gameState === GameState.GAME,
        arenaDomain: environment.ARENA_DOMAIN,
        isServer: MiscUtils.isServer,
        isIframeGame,
        user: user as unknown as TypeUserModel,
        subscriptionPlan,
        subscriptionId,
        authApiService: UserService.authApiService,
        userGameDataApiService: userGameDataApiService,
        paymentApiService: PaymentService.paymentApiService,
        virtualItemsApiService: GemsService.gemsApiService,
        isUserSubscriber: !!currentSubscription,
        currentLang: currentLang as TypeLocaleType,
        keepAliveStatus,
        adFree: adFreeValue,
        addEventHandlers: {},
        doGetBackToGameTab: this.doGetBackToGameTab.bind(this),
        doSetGameObservable: this.doSetGameObservable.bind(this),
        doCloseMenu: this.doCloseMenu.bind(this),
        doOpenMenuLogin: this.doOpenMenuLogin.bind(this),
        doOpenMenuPurchase: this.doOpenMenuPurchase.bind(this),
        doUpdateGemsAmount: this.doUpdateGemsAmount.bind(this),
        doAdsRefresh: this.doAdsRefresh.bind(this),
        onGameTestReady: this.onGameTestReady.bind(this),
        onGameStart: this.onGameStart.bind(this),
        onGameEnd: this.onGameEnd.bind(this),
        onScoreChange: this.onScoreChange.bind(this),
        onRewardStart: this.onRewardStart.bind(this),
        onInterstitialStart: this.onInterstitialStart.bind(this),
        onPlatformSpecificEvent: this.onPlatformSpecificEvent.bind(this),
        onResize: this.onResize.bind(this),
        onUnitMount: this.onUnitMount.bind(this),
        onUnitUpdate: this.onUnitUpdate.bind(this),
        onUnitUnmount: this.onUnitUnmount.bind(this),
        isPromo: false,
        nestEnvironment: environment.NEST_ENVIRONMENT,
        arenaEnvironment: MiscUtils.adaptEnvNameToMatchArenaEnvs(environment.Name),
        customCssClassesUnitContainer: [],
        customCssClassesCanvasContainer: [],
        customCssClassesCanvasBoxWrapper: ['game', styles.canvasBoxWrapper],
        customCssClassesCanvasBox__iframe: ['game', styles.canvasBox],
        customCssClassesCanvasBox__common: ['game', styles.canvasBox]
      } as GRMProps;
    } else {
      gameUnitProps = {
        game,
        gameState,
        currentLang,
        keepAliveStatus,
        adFree: adFreeValue,
        isIframeGame: isIframeGame,
        iframeRef: this.iframeRef,
        iframeSourceCode: iframeSourceCode,
        postMessageToIframe: this.postMessageToIframe,
        onGameEnd: this.onGameEnd,
        onScoreChange: this.onScoreChange,
        onRewardStart: this.onRewardStart,
        onInterstitialStart: this.onInterstitialStart,
        onGameTestReady: this.setCollectionTimer,
        getAnalyticsProvidersState: () => GDPRMediatorService.consentBy3rdPartyProvider(),
        user: this.props.user
      } as unknown as GameUnitProps;
    }

    /// ToDo: Update when better props/model typing provided in module
    return gameUnitProps;
  };

  grmIsUsed(): boolean {
    const { grmGamesList, game } = this.props;

    if (MiscUtils.isServer) {
      return false;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const grmParam = urlParams.get('__grm');

    if (grmParam === 'true') {
      return true;
    }

    if (grmParam === 'false') {
      return false;
    }

    if (game.isGrm != undefined) {
      return game.isGrm;
    }

    if (['External', 'Html'].includes(game?.renderingType)) {
      return true;
    }

    const gameSlug = game?.slug;
    const gameAlias = game?.meta.alias;

    return (gameSlug && grmGamesList?.includes?.(gameSlug)) || (gameAlias && grmGamesList?.includes?.(gameAlias));
  }

  grmRenderWrapper() {
    const { gameState } = this.props;
    const isGameState: boolean = this.gameStates.indexOf(gameState) !== -1;
    const grmIsUsed: boolean = this.grmIsUsed();
    const gameUnitProps: GameUnitProps | GRMProps = this.getGameUnitProps(grmIsUsed);

    console.log('GRM IS USED: ', grmIsUsed);
    console.log('IS IFRAME GAME: ', gameUnitProps.isIframeGame);
    console.log('IFRAME SOURCE: ', (gameUnitProps as GameUnitProps)?.iframeSourceCode);

    return grmIsUsed && isGameState ? ( // @ts-ignore
      <GameRenderingModule {...(gameUnitProps as TGRMProps)} />
    ) : isGameState ? (
      <GameUnit {...(gameUnitProps as GameUnitProps)} />
    ) : null;
  }
}

export const GameContainer = connect((state) => ({
  gameState: state.gameState,
  iframeGamesList: state.iframeGamesList,
  grmGamesList: state.grmGamesList,
  adFree: state.preLoadData.adFree,
  subscription: state.preLoadData.subscription,
  userFavoritesList: state.userFavoritesList,
  shopLocation: state.gemsShopLocation,
  arkConfig: state.arkConfig
}))(GameContainerBase);

function handlePlatformSpecificEvents(baseEvent: MessageEvent) {
  const event: MessageEvent | TypeGameEvent =
    this.state.isIframeGame && IFRAME_SOURCE_CODE_URL?.startsWith(baseEvent.origin) ? baseEvent.data : baseEvent;
  const { type, payload } = (event as TypeGameEvent) || {};

  if (!type) {
    return;
  }

  const platformSpecificHandlersDict = platformSpecificHandlers();

  if (platformSpecificHandlersDict.hasOwnProperty(type)) {
    try {
      platformSpecificHandlersDict[type](payload);
    } catch (err) {
      console.warn('ERROR in Game Rendering (handlePlatformSpecificEvents)', err);
    }
  }
}

function platformSpecificHandlers() {
  return {
    [EnumIframeMessageTypes.USER_EVENT]: handleGameEventUser,
    [EnumIframeMessageTypes.ANALYTICS_EVENT]: handleGameEventAnalytics
  };
}

function handleGameEventUser(event: any): void {
  const eventPayload = event?.payload;

  try {
    const { event_name, payload } = eventPayload;

    // AI analytics on arena side
    switch (event_name) {
      case 'VirtualItemSpend':
        const { id, internalPrice } = payload;

        if (id && internalPrice) {
          Analytics.trackEvent(Analytics.gems.gemSpendingPowerUp(id, internalPrice), true).then(() => noop());

          if (id === (SS_HURDLE_BOOST_PROPS.HINT_NAME as string)) {
            window.sessionStorage.setItem(SS_HURDLE_BOOST_PROPS.USED_hurdle_boost_clue, 'true'); // to not send if user already seen / used hint
          }
        } else {
          console.log(`AI ${event_name} not sent - no id or internalPrice in payload: `, payload);
        }

        this.props.dispatch(GemsEffects.UpdateGemsAmount());
        break;
      case GameUserEventsNames.HURDLE_ENTER_TRY as string: // continuing watching this to track successful tries
        const { Reason, greenGuessed } = payload;

        // reset of used hint flag
        if (Reason === (GameUserEventsResults.SUCCESS as string) && greenGuessed === 5) {
          // new hurdle, reset hint usage option
          window.sessionStorage.removeItem(SS_HURDLE_BOOST_PROPS.USED_hurdle_boost_clue);
        }

        break;
      default:
        break;
    }

    const leanplumPayload = { ...payload };
    // HURDLE - EnterTry / EnterSecondInvalidTry
    const isHurdle = (window as any)?.STORE?.getState()?.gameArena5Slug === 'hurdle';
    // old event still sent by game, but we don't want to send it to leanplum
    const hurdleTrackedNameOld = GameUserEventsNames.HURDLE_ENTER_TRY;

    // new event name 'EnterSecondInvalidTry' - fired after 2nd try and calculated on game side
    if (isHurdle && event_name === hurdleTrackedNameOld) {
      return; // not send old event 'hurdle_boost_clue' still provided by game
    }

    // main LP event
    LeanplumAnalytics.trackEvent(
      event_name,
      {
        ...leanplumPayload,
        game: this?.props?.game?.arena5Slug
      },
      true
    );
  } catch (err) {
    console.warn(`Game events error (could not send ${GameEvents.USER_EVENT}) to leanplum`, err);
  }
}

function handleGameEventAnalytics(eventPayload: any): void {
  const { event_name, payload } = eventPayload || {};
  const gameAnalyticsSlug = this.props?.game?.slug || this?.props?.game?.arkadiumSlug;

  trackGameEvent(event_name, payload, gameAnalyticsSlug).then(() => noop());

  if (event_name?.toLowerCase?.() === 'Boost'.toLowerCase()) {
    this.props.dispatch(GemsEffects.UpdateGemsAmount());
  }
}

function noop(...args: any[]) {
  console.log('GameContainer noop()', args);
}
