import React, { useState } from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { CheckboxSwitcher } from '../../../../atoms/CheckboxSwitcher/CheckboxSwitcher';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { UserModel } from '../../../../models/User/UserModel';
import { NotifyIconTooltip } from '../../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { lpAsyncSetEmailConsent } from '../../../../services/Analytics/LeanplumAnalyticsHelpers';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import { TranslationService } from '../../../../services/TranslationService';
import { UserEffects } from '../../../../store/effects/user.effects';
import { TooltipMobile } from '../../TooltipMobile/TooltipMobile';
import styles from './Email.css';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { LocalStorageService } from '../../../../services/LocalStorage';

type EmailProfileProps = {
    user: UserModel;
    currentLang: string;
    dispatch?: ThunkDispatch<any, any, Action>;
};

export const EmailProfileTemplate = React.memo(({ user, dispatch }: EmailProfileProps) => {
    const [switcher, setSwitcher] = useState(user.emailRetrievalConsent);

    const authProvider = LocalStorageService.getItem('authProvider');
    
    const updateUserData = async (val: boolean) => {
        const updatedUser = {
            emailRetrievalConsent: val,
        };

        dispatch?.(
            UserEffects.updateUser({
                ...updatedUser,
                ...ProfileProcessService.getStepForUpdate(32),
            })
        );

        lpAsyncSetEmailConsent(val);
    };

    return (
        <div className={styles.grid}>
            <div className={styles.blockEmailProfile}>
                <div className={styles.emailProfileHeader}>
                    <div className={styles.captionContainer}>
                        <I18nText as="h2" keyName="GAME_NEWS" className={styles.caption} />
                    </div>
                    <NotifyIconTooltip
                        textTop={TranslationService.translateIt('TOOLTIP_EMAIL')}
                        classNameMessage="profilePage"
                    />
                </div>
                <div className={classNames(styles.blockPlaceholder, styles.account)}>
                    <Responsive maxWidth={1024}>
                        <div className={styles.mobileCaptionContainer}>
                            <I18nText as="h2" keyName="GAME_NEWS" className={styles.caption} />
                        </div>
                    </Responsive>
                    <ProfilePanelRow
                        onClick={() => {
                            updateUserData(!switcher);
                            setSwitcher(!switcher);
                            if (!switcher) {
                                Analytics.trackEvent(
                                    Analytics.profile.profileEmail(MiscUtils.getAuthSource(authProvider))
                                );
                            }
                        }}
                        switcher={switcher}
                        isCheckbox
                    >
                        <div className={classNames(styles.blockContainer, styles.checkboxContainer)}>
                            <I18nText
                                keyName={TranslationService.translateIt('YES_SEND_ME_THE_NEWSLETTER')}
                                className={styles.fieldLabel}
                            />
                            <div className={styles.blockArrow}>
                                <CheckboxSwitcher turnedOn={switcher} />
                            </div>
                        </div>
                    </ProfilePanelRow>
                    {/*  TODO: review this code, remove if no need*/}
                    {/* <ProfilePanelRow> */}
                    {/*    {({ inEdit, setInEdit, hover }) => { */}
                    {/*        return ( */}
                    {/*            <ScreenNameEditor */}
                    {/*                name="email" */}
                    {/*                inEdit={inEdit} */}
                    {/*                titleAlwaysOn */}
                    {/*                setInEdit={setInEdit} */}
                    {/*                user={user} */}
                    {/*                updateUser={(updatedUser) => { */}
                    {/*                    props.dispatch(UserEffects.updateUser(updatedUser)); */}
                    {/*                }} */}
                    {/*                openPanel={(p) => */}
                    {/*                    props.dispatch( */}
                    {/*                        setProfilePanelState({ */}
                    {/*                            isOpened: true, */}
                    {/*                            target: ProfileRightSideRoutes.EMAIL_PANEL, */}
                    {/*                            caption: TranslationService.translateIt('EMAIL'), */}
                    {/*                        }) */}
                    {/*                    ) */}
                    {/*                } */}
                    {/*                rowContentClassName={styles.rowContent} */}
                    {/*                rowEditContentClassName={styles.rowEditContent} */}
                    {/*                captionClassName={styles.fieldLabel} */}
                    {/*                viewClassName="fullWidth" */}
                    {/*                title={TranslationService.translateIt('EMAIL_ADDRESS')} */}
                    {/*                label="email" */}
                    {/*                minLength={4} */}
                    {/*                maxLength={75} */}
                    {/*                hover={hover} */}
                    {/*            /> */}
                    {/*        ); */}
                    {/*    }} */}
                    {/* </ProfilePanelRow> */}
                </div>
                <TooltipMobile tooltipContent={TranslationService.translateIt('THIS_INFORMATION_IS_NOT_VISIBLE')} />
            </div>
        </div>
    );
});

type ProfilePanelRowProps = {
    onClick: (...args: unknown[]) => void;
    children: React.ReactNode;
    switcher: boolean;
    isCheckbox?: boolean;
};

//TODO: This should be refactored - create one component ProfilePanelRow
const ProfilePanelRow = ({ onClick, children, isCheckbox, switcher }: ProfilePanelRowProps) => {
    const [inEdit, setInEdit] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <div
            tabIndex={0}
            className={classNames(styles.row, {
                [styles.rowInEdit]: inEdit,
                [styles.hovered]: hover && !inEdit,
            })}
            role="checkbox"
            aria-checked={switcher}
            onMouseMove={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            onBlur={() => setHover(false)}
            onClick={onClick}
            onKeyDown={(event) => {
                const { key } = event;
                if (key === ' ' || key === 'Spacebar') {
                    event.preventDefault();
                    isCheckbox && onClick?.();
                }
            }}
        >
            {/*  TODO: review this code, remove if no need */}
            {/*{children({ inEdit, setInEdit, hover })}*/}
            {children}
        </div>
    );
};
