/* eslint-disable no-useless-concat */
// tslint:disable: max-line-length

import React from 'react';

import classNames from 'classnames';

import styles from './GameSubmissionDisclaimerTemplate.css';
import { PageTypes } from '../../constants/Pages';
import { GeneralPageSEO } from '../../models/PagesData';
import { HeaderBackgroundText } from '../../molecules/HeaderBackgroundText/HeaderBackgroundText';
import { DeviceType } from '../../services/DeviceDetector';

export type GameSubmissionDisclaimerProps = {
    page: GeneralPageSEO;
    currentLang: string;
    pageType: PageTypes;
    deviceType: DeviceType;
};

export default function GameSubmissionDisclaimerTemplate({
    page,
    pageType,
    currentLang,
    deviceType,
}: GameSubmissionDisclaimerProps) {

    const Disclaimers = disclaimersContent;

    return (
        <main className={styles.pageContainer}>
            <div className={styles.headerContainer}>
                <HeaderBackgroundText
                    currentLang={currentLang}
                    pageData={page}
                    pageType={pageType}
                    deviceType={deviceType}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.pageContentContainer}>
                    <Disclaimers />
                </div>
            </div>
        </main>
    );
}

const disclaimersContent = () => {
    return (
        <div>
            <h2 className={classNames(styles.title1, styles.dividedItem)}>
                Arkadium for Developers — Game Submission Disclaimers{' '}
            </h2>
            <p className={styles.dividedItem}>
                1. SUBMISSION: The Developer agrees to submit a playable link to their original game. No file uploads
                will be accepted.
            </p>
            <p className={styles.dividedItem}>
                2. INTELLECTUAL PROPERTY: The Developer represents and warrants that they are the rightful owner of the
                game and all related intellectual property rights, or that they have the necessary licenses, rights,
                consents, and permissions to use and authorize Arkadium Inc. to use all patent, trademark, trade secret,
                copyright or other proprietary rights in and to any and all games submitted.
            </p>
            <p className={styles.dividedItem}>
                3. MALICIOUS FILES: In the interest of maintaining a safe and secure environment for all parties
                involved, we hereby stipulate that all developers submitting their games to our platform are strictly
                prohibited from providing us with URLs or files that may potentially be malicious in nature. This
                includes, but is not limited to, URLs or files that may contain viruses, malware, spyware, ransomware,
                or any other form of harmful software or code.
            </p>
            <p className={styles.dividedItem}>
                4. LEGAL ACTION: By submitting your game to Arkadium Inc., you, as the developer, herein acknowledge and
                agree not to undertake any legal action or proceeding against Arkadium Inc., its subsidiaries,
                affiliates, officers, employees, agents, partners, and licensors if Arkadium Inc. develops or
                distributes a game in the future that mirrors, resembles or bears any reasonable similarity to the game
                you submit. This encompasses all game mechanics, aesthetics, and concepts but is not limited to these
                aspects.
            </p>
        </div>
    );
};
