import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './GenderEditor.css';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { UserModel } from '../../../../models/User/UserModel';
import { DropdownDesktop } from '../../../../molecules/DropdownDesktop/DropdownDesktop';
import { FieldEdit } from '../../EditorTemplates/FieldEdit/FieldEdit';
import { FieldView } from '../../EditorTemplates/FieldView/FieldView';

export type GenderEditorProps = {
  inEdit: boolean;
  setInEdit: any;
  user: UserModel;
  updateUser: (updatedUser: { [x: string]: string }) => void;
  rowContentClassName: string;
  rowEditContentClassName: string;
  captionClassName: string;
  viewClassName?: string;
  title: string;
  label: string;
  openPanel: (p: any) => any;
  hover?: boolean;
};

export const GenderEditor = (props: GenderEditorProps) => {
  const { inEdit, setInEdit, user, updateUser, viewClassName, title, label, hover } = props;
  const [activeDropdown, setActiveDropdown] = useState(user[label] || '');
  const handleScreenNameChange = (event: string) => {
    setActiveDropdown(event);
  };
  const updateScreenName = async () => {
    const updatedUser = {
      [label]: activeDropdown
    };

    updateUser(updatedUser);
  };
  const GenderMap = new Map<string, string>();

  GenderMap.set('male', 'Male');
  GenderMap.set('female', 'Female');
  GenderMap.set('none', 'Prefer not to say');

  const editComponent = () => {
    return (
      <DropdownDesktop
        id="gender"
        placeholder={activeDropdown ? GenderMap.get(activeDropdown) : 'Gender'}
        dropdownItems={GenderMap}
        onClick={(e) => handleScreenNameChange(e)}
      />
    );
  };
  const viewComponent = () => {
    return (
      <span
        className={classNames(styles.fieldViewValue, {
          [styles.hovered]: hover
        })}
      >
                {GenderMap.get(user.gender)}
            </span>
    );
  };

  return (
    <>
      <div className={classNames(styles.fields, { [styles.visible]: !inEdit }, { [styles.notVisible]: inEdit })}>
        <FieldView
          title={title}
          titleAlwaysOn
          onMobileEditClick={() => props.openPanel(ProfileRightSideRoutes.GENDER_PANEL)}
          onEditClick={() => setInEdit(true)}
          renderViewComponent={viewComponent}
          viewComponentValue={GenderMap.get(user.gender)}
          classNameField={viewClassName}
          hover={hover}
        />
      </div>
      <div className={classNames(styles.fields, { [styles.notVisible]: !inEdit }, { [styles.visible]: inEdit })}>
        <FieldEdit
          title={title}
          cancelField={() => setInEdit(false)}
          renderEditComponent={editComponent}
          saveField={async () => {
            setInEdit(false);
            await updateScreenName();
          }}
          saveButtonDisabled={activeDropdown === ''}
        />
      </div>
    </>
  );
};
