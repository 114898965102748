import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './CardDetails.css';
import { PadLockIcon } from '../../../FigmaStyleguide/Icons/PadLockIcon';
import { InputWrapper } from '../InputWrapper/InputWrapper';

type CardDetailsProps = {
  register?: any; // need to check ts support for react-hook-form
  errors: any; // need to check ts support for react-hook-form
  setValue: (name: string, value: string) => void;
  setValidation: (isValid: boolean, name: string, message?: string) => void;
  values: any; // need to check ts support for react-hook-form (just provide types with values)
};

export const CardDetails = ({ errors, register, setValue, setValidation, values }: CardDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {/* id added for smooth scrolling */}
      <div className={styles.mainStepHeading} id="paymentFieldsRef">
        <h2>
          <strong>2. </strong>
          {t('PAYMENT_PAGE.CARD_DETAILS_TITLE')}
        </h2>
        <PadLockIcon className={styles.padlockIcon}/>
      </div>

      <InputWrapper
        ariaInvalid={errors.first_name ? 'true' : 'false'}
        register={register}
        name="first_name"
        type="text"
        label={t('PAYMENT_PAGE.FIRST_NAME')}
        errors={errors}
        isValid={!errors?.first_name}
        errorMessage={errors?.first_name?.message}
        description={t('PAYMENT_PAGE.FIRST_NAME_DESCRIPTION')}
        descriptionId="firstNameDescription"
        placeholder="JANE"
        dataRecurly="first_name"
        value={values['first_name']}
      />

      <InputWrapper
        ariaInvalid={errors.last_name ? 'true' : 'false'}
        register={register}
        name="last_name"
        type="text"
        label={t('PAYMENT_PAGE.LAST_NAME')}
        errors={errors}
        isValid={!errors?.last_name}
        errorMessage={errors?.last_name?.message}
        placeholder="SMITH"
        dataRecurly="last_name"
        value={values['last_name']}
      />

      {/* TODO: accessibility for recurly (iframe) inputs (currently not possible due to the implementation of recurly) */}
      <InputWrapper
        name="cardNumber"
        label={t('PAYMENT_PAGE.CARD_NUMBER')}
        errors={errors}
        errorMessage="Please enter a valid credit card number"
        description={t('PAYMENT_PAGE.CARD_NUMBER_DESCRIPTION')}
        placeholder="0000 0000 0000 0000"
        creditInput
        dataRecurly="cardNumber"
        setValue={setValue}
        setValidation={setValidation}
      />

      <InputWrapper
        name="expiration date"
        label={t('PAYMENT_PAGE.EXPIRATION_DATE')}
        errors={errors}
        errorMessage={'Please enter a valid date'}
        description={t('PAYMENT_PAGE.EXPIRATION_DATE_DESCRIPTION')}
        placeholder="YY"
        creditInput
        dataRecurly="expirationDate"
        setValue={setValue}
        setValidation={setValidation}
      />

      <InputWrapper
        name="cvv"
        label={t('PAYMENT_PAGE.SECURITY_CODE')}
        errors={errors}
        errorMessage={errors?.cvv?.message}
        placeholder="CVC/CVV"
        creditInput
        dataRecurly="cvv"
        setValue={setValue}
        setValidation={setValidation}
        description={t('PAYMENT_PAGE.SECURITY_CODE_DESCRIPTION')}
      />
    </>
  );
};
