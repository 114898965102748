import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const EditIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.104 9.63345L24.8665 8.39601C23.8413 7.37079 22.0607 7.4895 20.8891 8.66118L8.86824 20.682L8.16113 26.3388L13.818 25.6317L25.8388 13.6109C27.0105 12.4392 27.1292 10.6587 26.104 9.63345Z"
                stroke="#0042BB"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                d="M18.1564 11.3939C18.1564 11.3939 19.4822 11.3055 21.3384 13.1617C23.1946 15.0179 23.1062 16.3437 23.1062 16.3437"
                stroke="#0042BB"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </svg>
    );
});
