import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';


import styles from './ScreenNameEditor.css';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { ProfanityUtils } from '../../../../../utils/ProfanityUtils';
import { Input } from '../../../../atoms/Input/Input';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { SPECIAL_AVATARS } from '../../../../constants/AvatarsImagesList';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { LS_COOKIE_CONSTS } from '../../../../models/Enums';
import { PublicUserModel, UserModel } from '../../../../models/User/UserModel';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LocalStorageService } from '../../../../services/LocalStorage';
import UserService from '../../../../services/UserService';
import { setUser } from '../../../../store/ducks/user';
import { FieldEdit } from '../../EditorTemplates/FieldEdit/FieldEdit';
import { FieldView } from '../../EditorTemplates/FieldView/FieldView';

export type ScreenNameEditorProps = {
  inEdit: boolean;
  setInEdit: any;
  user: UserModel | PublicUserModel;
  rowContentClassName: string;
  rowEditContentClassName: string;
  captionClassName: string;
  name: string;
  title: string;
  label: string;
  viewClassName?: string;
  titleAlwaysOn?: boolean;
  boldValue?: boolean;
  hover?: boolean;
  placeholder?: string;

  showTitleAsValue?: boolean;
  openPanel: (panel: ProfileRightSideRoutes) => void;
  minLength?: number;
  maxLength?: number;
  className?: string;
  onboardingProgress: Partial<UserModel> | undefined,
};

const fieldsForProfanity = ['screenName', 'firstName', 'lastName'];
const SCREEN_NAME_EDITOR_INPUT_ID = 'screen-name-input';

export const ScreenNameEditor = (props: ScreenNameEditorProps) => {
  const {
    inEdit,
    setInEdit,
    user,
    viewClassName,
    title,
    label,
    hover,
    name,
    maxLength,
    className,
    placeholder,
    showTitleAsValue,
    boldValue,
    titleAlwaysOn,
    openPanel,
    onboardingProgress
  } = props;
  const dispatch = useDispatch();
  const authProvider = LocalStorageService.getItem('authProvider');
  const INPUT_MAX_LENGTH = maxLength ? maxLength : 30;
  const profanityFilterNeeded = fieldsForProfanity.includes(name);
  const [screenName, setScreenName] = useState<string>(user[label] || '');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isProfanity, setIsProfanity] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userUpdateState, setUserUpdateState] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userUpdateState !== 'error') {
      setInEdit(false);
    }
  }, [userUpdateState]);

  useEffect(() => {
    setErrorMessage('');
    setScreenName(user[label] || '');
  }, [inEdit]);

  const updateScreenName = async () => {
    // Delete all symbols inside '<...>' for preventing XSS
    const safetyScreenName = screenName.replace(/<\/?[^>]+(>|$)/g, '').trim();
    const updatedUser = {
      [label]: safetyScreenName,
      ...onboardingProgress
    };

    await UserService.userUpdate({ ...updatedUser })
      .then((res) => {
        if (res === 200) {
          dispatch(setUser({ ...user, ...updatedUser } as UserModel));
          sendAnalytics(label);
          LocalStorageService.setItem(LS_COOKIE_CONSTS.USER_CHANGED_NAME, '1');
          setUserUpdateState('ok');
          setInEdit(false);
          setIsLoading(false);
          // openWeb hack to update user on openWeb load
          LocalStorageService.removeItem('SPOTIM_ACCESS_TOKEN', false);
        } else {
          setIsLoading(false);
          setErrorMessage(UserService.errorCodeToText(res));
          setUserUpdateState('error');
        }

        return res;
      });
  };
  const sendAnalytics = (fieldLabel: string) => {
    switch (fieldLabel) {
      case 'name':
        void Analytics.trackEvent(Analytics.profile.publicProfileName(MiscUtils.getAuthSource(authProvider)));
        break;
      case 'email':
        void Analytics.trackEvent(Analytics.profile.profileEmail(MiscUtils.getAuthSource(authProvider)));
        break;
      case 'firstName':
      case 'lastName':
    }
  };
  const editComponent = () => {
    return (
      <Input
        id={SCREEN_NAME_EDITOR_INPUT_ID}
        setIsValid={setIsValid}
        isValid={isValid && !errorMessage}
        placeholder={placeholder}
        value={screenName}
        onValueChange={setScreenName}
        maxlength={INPUT_MAX_LENGTH}
        name={name}
        isProfanity={isProfanity}
        errorMessageOverride={errorMessage}
      />
    );
  };
  const viewComponent = () => {
    return (
      <>
        <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
          <span className={classNames(styles.fieldViewValue, { [styles.hovered]: hover })}>
            {user[label]}
          </span>
        </Responsive>
        <Responsive maxWidth={1024}>
          <span
            className={classNames(styles.fieldViewValue, {
              [styles.hovered]: hover,
              [styles.titleAsValue]: !user[label] && showTitleAsValue,
              [styles.boldValue]: Boolean(boldValue)
            })}
          >
            {showTitleAsValue ? user[label] || title : user[label]}
          </span>
        </Responsive>
      </>
    );
  };
  const checkProfanityIfNeeded = async (): Promise<boolean> => {
    setIsProfanity(false);

    if (profanityFilterNeeded) {
      return ProfanityUtils.checkWord(screenName);
    }

    return Promise.resolve(true);
  };
  const isSaveButtonDisabled = (): boolean => {
    const trimmedName = screenName.trim();
    const isKennyOrJessEmail = Boolean(SPECIAL_AVATARS[trimmedName]);

    return !isValid || isKennyOrJessEmail || screenName.length === 0 || isLoading;
  };
  const onSaveHandler = async () => {
    setIsLoading(true);
    const isClean = await checkProfanityIfNeeded();

    if (isClean) {
      await updateScreenName();
    } else {
      setIsProfanity(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          styles.fields,
          styles[className],
          { [styles.visible]: !inEdit },
          { [styles.notVisible]: inEdit }
        )}
      >
        <FieldView
          title={title}
          titleAlwaysOn={titleAlwaysOn}
          onEditClick={() => setInEdit(true)}
          onMobileEditClick={() => openPanel(ProfileRightSideRoutes.SCREEN_NAME_PANEL)}
          renderViewComponent={viewComponent}
          viewComponentValue={showTitleAsValue ? user[label] || title : user[label]}
          classNameField={viewClassName}
          hover={hover}
        />
      </div>
      <div
        className={classNames(
          styles.fields,
          styles[className],
          { [styles.notVisible]: !inEdit },
          { [styles.visible]: inEdit }
        )}
      >
        <FieldEdit
          htmlFor={SCREEN_NAME_EDITOR_INPUT_ID}
          title={title}
          cancelField={() => setInEdit(false)}
          renderEditComponent={editComponent}
          saveField={onSaveHandler}
          saveButtonDisabled={isSaveButtonDisabled()}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
