import React from 'react';

import { Avatar, AvatarTypes } from '../../../../../atoms/Avatar/Avatar';
import { BorderedButton } from '../../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { UserModel } from '../../../../../models/User/UserModel';
import { TranslationService } from '../../../../../services/TranslationService';
import styles from './FinalScreen.css';

type Props = {
    onClick: () => void;
    user: UserModel;
};

export const FinalScreen = React.memo((props: Props) => {
    const userAvatar = props.user?.avatar || props.user?.subscriberAvatar?.avatar || '';
    return (
        <>
            <div className={styles.content}>
                <div className={styles.finalScreenText}>Well done, {props.user.name}!</div>
                <div className={styles.finalScreenComponent}>
                    <Avatar
                        image={userAvatar}
                        selectable={false}
                        selected={false}
                        size={AvatarTypes.MEDIUM}
                        onSelect={() => null}
                        email={props.user.email}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <div>
                    <BorderedButton
                        caption={TranslationService.translateIt('START_PLAYING')}
                        onClick={() => props.onClick()}
                        filled
                    />
                </div>
            </div>
        </>
    );
});
