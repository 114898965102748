import React, { Suspense, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { MiscUtils } from '../../utils/MiscUtils';
import { AppLoader } from '../atoms/AppLoader/AppLoader';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { PagesData } from '../models/PagesData';
import { MetaInfoService } from '../services/MetaInfoService';
import { PageService } from '../services/PageService';
import { UrlService } from '../services/UrlService';
import { setPageType } from '../store/ducks/pages';

const AboutUsTemplate = React.lazy(() => {
    return import('../templates/AboutUs/AboutUsTemplate');
});
const AboutUsPage = React.memo(() => {
    const currentLang = useSelector((state) => state.currentLang);
    const pagesFromStore = useSelector((state) => state.pages);
    const routerFromRedux = useSelector((state) => state.router);
    const dispatch = useDispatch();
    const pages = PagesData.getPages(pagesFromStore);
    const pageSEO = PageService.getPageSEOByPageName(pages, 'About Us');
    const url = UrlService.buildAbsoluteUrl(routerFromRedux);
    const enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.AboutUs)}/`;
    const addonLocales = PageService.getNonEnLocales(pageSEO);
    const localesPath = addonLocales.map((item) => ({
        lang: item,
        path: `/${pageSEO.slug[item]}/`,
    }));

    useEffect(() => {
        dispatch(setPageType(PageTypes.AboutUs));
    }, []);

    return (
        <>
            <Helmet
                title={pageSEO.metaHTMLTitle[currentLang]}
                link={[
                    { rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` },
                    ...MetaInfoService.getLinksInfo(enPathname, localesPath),
                ]}
                meta={[
                    { name: 'twitter:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    { property: 'og:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    {
                        name: 'description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        property: 'og:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        name: 'twitter:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                ]}
            />
            <ErrorBoundary>
                {MiscUtils.isServer ? (
                    <AppLoader fullPage />
                ) : (
                    <Suspense fallback={<AppLoader fullPage />}>
                        <AboutUsTemplate />
                    </Suspense>
                )}
            </ErrorBoundary>
        </>
    );
});

export default AboutUsPage;
