import React from 'react';

import { connect } from 'react-redux';

import styles from './RecentlyPlayedSlider.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import SwipeSlider from '../../atoms/SwipeSlider/SwipeSlider';
import { FavoritesAnalyticsClickPageTypes } from '../../features/Favorites/FavoritesAnalyticsAi';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { IGame } from '../../models/Game/Game';
import { GameCard, GameCardTypes } from '../GameCard/GameCard';


type RecentlyPlayedSliderProps = {
    items: IGame[];
    caption?: string;
    params?: { name: string };
    userFavoritesList: string[];
    isFavoriteGamesBlock?: boolean;
};

export class RecentlyPlayedSliderBase extends React.PureComponent<RecentlyPlayedSliderProps> {
    renderItem = (game: IGame) => {
        return (
            <GameCard
                game={game}
                isFavorite={game.isFavorite}
                gameCardType={GameCardTypes.SMALL}
                disableDescription
                disableTitle
                dataElementDescription="profilepage-recentlyplayed-slider"
                favoritePageType={FavoritesAnalyticsClickPageTypes.PROFILE}
            />
        );
    };

    render() {
        // doing this here, because can't do it in parent component and here already have userFavoritesList passed
        const isFavoriteGamesBlock = this.props.isFavoriteGamesBlock || false;
        const userFavoritesList = this.props.userFavoritesList;
        const items = !isFavoriteGamesBlock
            ? favoritesService.favoritesListSorter(this.props.items, userFavoritesList)
            : favoritesService.favoritesListFilter(this.props.items, userFavoritesList);
        const showFavorites = !isFavoriteGamesBlock || (userFavoritesList?.length && favoritesService.isOn);

        return showFavorites ? (
            <div className={styles.recentlyPlayedSliderContainer}>
                <div className={styles.recentlyPlayedSliderCaptionContainer}>
                    <I18nText
                        as="h2"
                        keyName={this.props.caption}
                        className={styles.caption}
                        params={this.props.params}
                    />
                </div>
                <SwipeSlider noShadows={false} items={items} renderItem={this.renderItem} />
            </div>
        ) : null;
    }
}

export const RecentlyPlayedSlider = connect((state) => ({
    userFavoritesList: state.userFavoritesList,
}))(RecentlyPlayedSliderBase);
