import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import { UserCollectionDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/collection';
import { useTranslation } from 'react-i18next';

import styles from './Collections.css';
import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { TabButton } from '../../../../atoms/Buttons/TabButton/TabButton';
import { BackgroundTypes } from '../../../../molecules/CollectionItemCard/CollectionItemCard';
import { CollectionItemsList } from '../../../../organisms/CollectionItemsList/CollectionItemsList';
import CollectionsService, { MergedCollectionType } from '../../../../services/CollectionsService';

const ERROR_MESSAGE = 'Sorry, try again later';
const DISCOVERED_TAB = 'Discovered';
const DISCOVERED_TAB_ID = `${DISCOVERED_TAB}-tabpanel`;
const DISCOVERED_TAB_ARIA = `${DISCOVERED_TAB}-tab`;
const UNDISCOVERED_TAB = 'Undiscovered';
const UNDISCOVERED_TAB_ID = `${UNDISCOVERED_TAB}-tabpanel`;
const UNDISCOVERED_TAB_ARIA = `${UNDISCOVERED_TAB}-tab`;
const DISCOVERED_KEY_NAME = 'COLLECTION_DISCOVERED';
const UNDISCOVERED_KEY_NAME = 'COLLECTION_UNDISCOVERED';
const enum Statuses {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  CONTENT_READY = 'CONTENT_READY',
}

export const CollectionsTemplate = () => {
  const { t, ready: isLocalizationReady } = useTranslation();
  const [status, setStatus] = useState<Statuses>(Statuses.LOADING);
  const [activeTab, setActiveTab] = useState<string>(DISCOVERED_TAB);
  const [discoveredCollections, setDiscoveredCollections] = useState<MergedCollectionType[] | []>([]);
  const [undiscoveredCollections, setUndiscoveredCollections] = useState<UserCollectionDto[] | []>([]);
  const [isCollectionsLoaded, setIsCollectionsLoaded] = useState<boolean>(false);
  const discoveredTabRef = useRef<HTMLButtonElement>(null);
  const undiscoveredTabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    void setUpCollections();
  }, []);

  useEffect(() => {
    if (isLocalizationReady && isCollectionsLoaded) {
      setStatus(Statuses.CONTENT_READY);
    }
  }, [isLocalizationReady, isCollectionsLoaded]);

  const setUpCollections = async () => {
    try {
      /**
       * Load list of discovered collections and list of undiscovered collections.
       * We could load them independently. Undiscovered result is ready for setting to state.
       */
      const [discoveredCollectionList, undiscoveredCollectionList] = await Promise.all([
        CollectionsService.getDiscoveredUserCollections(),
        CollectionsService.getUndiscoveredUserCollections()
      ]);
      /**
       * For every discovered collection we need to load full state, which will contain
       * discovered collection items, but not undiscovered items.
       */
      const collectionsFullStates = await Promise.all(
        discoveredCollectionList.map(({ sku }) => CollectionsService.getUserCollectionState(sku))
      );
      /**
       * To show discovered collections, where some collection items are not discovered,
       * we have to merge two arrays.
       */
      const mergedCollections = CollectionsService.getMergedCollections(
        discoveredCollectionList,
        collectionsFullStates
      );

      setDiscoveredCollections(mergedCollections);
      setUndiscoveredCollections(undiscoveredCollectionList);
      setIsCollectionsLoaded(true);
    } catch (err) {
      console.log(err.message);
      setStatus(Statuses.ERROR);
    }
  };
  const setDiscoveredTab = () => setActiveTab(DISCOVERED_TAB);
  const setUndiscoveredTab = () => setActiveTab(UNDISCOVERED_TAB);
  const handleArrowClick: KeyboardEventHandler<HTMLDivElement> = ({ key, preventDefault }) => {
    /** We use preventDefault method for preventing scrolling the page with arrow buttons usage.
     * If we call it outside of conditions Tab click behaviour will be broken.
     * */

    if (key === 'ArrowLeft') {
      preventDefault();
      setDiscoveredTab();
      discoveredTabRef.current?.focus();
    } else if (key === 'ArrowRight') {
      preventDefault();
      setUndiscoveredTab();
      undiscoveredTabRef.current?.focus();
    }
  };

  return (
    <div className={styles.container}>
      {status === Statuses.LOADING && (
        <div className={styles.loaderWrapper}>
          <AppLoader/>
        </div>
      )}
      {status === Statuses.ERROR && <div>{ERROR_MESSAGE}</div>}
      {status === Statuses.CONTENT_READY && (
        <>
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div className={styles.tabList} role="tablist" onKeyDown={handleArrowClick}>
            <TabButton
              ref={discoveredTabRef}
              tabKey={DISCOVERED_TAB}
              active={activeTab === DISCOVERED_TAB}
              onClick={setDiscoveredTab}
              className="profilePageCollectionsTab"
            >
              {t(DISCOVERED_KEY_NAME)} ({discoveredCollections.length})
            </TabButton>
            <TabButton
              ref={undiscoveredTabRef}
              tabKey={UNDISCOVERED_TAB}
              active={activeTab === UNDISCOVERED_TAB}
              onClick={setUndiscoveredTab}
              className="profilePageCollectionsTab"
            >
              {t(UNDISCOVERED_KEY_NAME)} ({undiscoveredCollections.length})
            </TabButton>
          </div>
          <>
            {activeTab === DISCOVERED_TAB && (
              <div
                className={styles.tabPanel}
                role="tabpanel"
                id={DISCOVERED_TAB_ID}
                aria-labelledby={DISCOVERED_TAB_ARIA}
                tabIndex={0}
              >
                {discoveredCollections.map((collection: MergedCollectionType) => (
                  <div key={collection.name} className={styles.collection}>
                    <CollectionItemsList
                      isTab={true}
                      collection={collection}
                      backgroundType={BackgroundTypes.LIGHT}
                    />
                  </div>
                ))}
              </div>
            )}
            {activeTab === UNDISCOVERED_TAB && (
              <div
                className={styles.tabPanel}
                role="tabpanel"
                id={UNDISCOVERED_TAB_ID}
                aria-labelledby={UNDISCOVERED_TAB_ARIA}
                tabIndex={0}
              >
                {undiscoveredCollections.map((collection: MergedCollectionType) => (
                  <div key={collection.name} className={styles.collection}>
                    <CollectionItemsList
                      isTab={true}
                      collection={collection}
                      backgroundType={BackgroundTypes.LIGHT}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};
