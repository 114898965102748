import React from 'react';

import { TickIcon } from '../../atoms/Icons/Styleguide/TickIcon';
import styles from './CircleWithIcon.css';

type CircleWithIconProps = {
    backgroundColor: string;
    size?: string;
    innerObject?: any;
    innerObjectColor?: string;
};

export const CircleWithIcon = React.memo((props: CircleWithIconProps) => {
    const defaultInnerObject = <TickIcon className={styles.tick} />;
    const innerObject = props.innerObject ? props.innerObject : defaultInnerObject;

    return (
        <div
            className={styles.circle}
            style={{
                width: props.size,
                height: props.size,
                backgroundColor: props.backgroundColor,
                border: '2px solid #00a371',
            }}
            data-testid="circle"
        >
            <div className={styles.innerObject} style={{ color: props.innerObjectColor }}>
                {innerObject}
            </div>
        </div>
    );
});
