import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import styles from './BlogPostSidePanel.css';
import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { IModularBlogCta } from '../../../models/Strapi';
import { UrlService } from '../../../services/UrlService';
import { setCurrentBlogCategory } from '../../../store/ducks/blog/blogArchieve';
import { BlogEffects } from '../../../store/effects/blog.effects';
import { Analytics } from '../../../services/Analytics/Analytics';

type Props = {
    categories: any[];
    blogCta: IModularBlogCta;
};

const BlogPostSidePanel = React.memo(({ categories, blogCta }: Props) => {
    const dispatch = useDispatch();

    const currentCategory = useSelector((state) => state.blogCurrentCategory);

    const handleCategoryChange = (category) => {
        dispatch(setCurrentBlogCategory(category));
        dispatch(BlogEffects.setBlogCategoryFilters());
    };

    const handleClick = (event, category) => {
        event.preventDefault();
        handleCategoryChange(category);
    };

    const handleKeyDown = (event, category) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCategoryChange(category);
        }
    };

    const getCtaStyles = ({ backgroundColor, textColor }: IModularBlogCta) => {
        const result = {} as React.CSSProperties;

        result.backgroundColor = backgroundColor || '#0042BB';
        result.color = textColor || '#F9F9F9';

        return result;
    };

    const handleAnalytics = (ctaText: string) => {
        Analytics.trackEvent(Analytics.blogAnalytics.blogCTAClick(ctaText));
    };

    return (
        <div>
            <Grid container direction="column" className={styles.sidebar}>
                <Grid item>
                    {blogCta && (
                        <Box className={styles.cta} style={getCtaStyles(blogCta)}>
                            {blogCta.heading && <h3>{blogCta.heading}</h3>}
                            {blogCta.text}
                            {blogCta.button && (
                                <div className={styles.buttonWrapper}>
                                    <StrapiButton
                                        action={blogCta.button.action}
                                        url={UrlService.checkIfAbsoluteUrlOrCreate(blogCta.button.url)}
                                        label={blogCta.button.label}
                                        analyticsCallback={() => {
                                            handleAnalytics(blogCta.analytics?.analyticId);
                                        }}
                                        gradient={{
                                            color1: blogCta.button.gradientColor1 || '#F9F9F9',
                                            color2: blogCta.button.gradientColor2 || '#F9F9F9',
                                            color3: blogCta.button.gradientColor3 || '#F9F9F9',
                                            textColor: blogCta.button.textColor || '#231F20',
                                        }}
                                    />
                                </div>
                            )}
                        </Box>
                    )}
                </Grid>

                <Grid item>
                    <Box>
                        {categories && (
                            <div className={styles.categoriesBlock}>
                                <h3>Blog Categories</h3>
                                <ul className={styles.categoriesList}>
                                    {categories.map((category) => (
                                        <li key={category}>
                                            <a
                                                role="button"
                                                className={currentCategory === category ? styles.activeCategory : ''}
                                                onClick={(event) => handleClick(event, category)}
                                                onKeyDown={(e) => handleKeyDown(e, category)}
                                            >
                                                {category}
                                            </a>
                                        </li>
                                    ))}
                                    <li>
                                        <a
                                            role="button"
                                            className={currentCategory === 'All' ? styles.activeCategory : ''}
                                            onClick={(event) => handleClick(event, 'All')}
                                            onKeyDown={(e) => handleKeyDown(e, 'All')}
                                        >
                                            All
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
});

export default BlogPostSidePanel;
